import React from 'react'


const SubHeader = () => {
    return (
        <div>

            <div className="sub-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="left-content">

                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4">
                            <div className="right-icons">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/mygrationhub" onClick={(e) => { e.preventDefault(); window.open("https://www.facebook.com/mygrationhub", '_blank'); }}>
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>

                                    <li>
                                    <a href="https://twitter.com/MygrationHub" onClick={(e) => { e.preventDefault(); window.open("https://twitter.com/MygrationHub", '_blank'); }}>
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                    <a href="https://www.instagram.com/mygrationhub" onClick={(e) => { e.preventDefault(); window.open("https://www.instagram.com/mygrationhub", '_blank'); }}>
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/company/mygration-hub" onClick={(e) => { e.preventDefault(); window.open("https://www.linkedin.com/company/mygration-hub", '_blank'); }}>
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default React.memo(SubHeader) 