import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-work-canada-permit.png';
import Meta from './Meta';

const WorkCanadaPermit = () => {
    const title = "There are several categories of work permits. You can apply directly to any Canadian company and get a closed work permit.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        There are several categories of work permits. You can apply directly to any Canadian company and get a closed work permit. The most common one is an open work permit where you can join any company you like regardless of the industry. The international students who graduate from a Canadian institution can get a post graduate work permit, which is an open work permit in nature. The process of applying for a work permit is given below: 
                                    </p>

                                    <h3>1.  Job Offer: </h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Secure a job offer from a Canadian employer. The job must be eligible for a work permit, and the employer may need to provide certain documents to support your application.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2.  Labor Market Impact Assessment (LMIA) (if required): </h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    In some cases, employers may need to obtain a positive LMIA from the Employment and Social Development Canada (ESDC) to demonstrate that hiring a foreign worker will not negatively impact the Canadian labor market.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>3.	Complete Application:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Submit a complete work permit application to Immigration, Refugees and Citizenship Canada (IRCC). Include all required documents such as a valid job offer, proof of identity, educational qualifications, and any necessary permits.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>4.	Biometrics and Medical Examination:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Depending on your nationality, you may be required to provide biometrics. Additionally, a medical examination might be necessary in certain cases to ensure you meet health requirements.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>5.	Pay Application Fees:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Pay the required application fees, which may vary based on the type of work permit and your situation.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>6.	Wait for Processing:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Wait for the processing of your application by IRCC. Processing times can vary, so it&apos;s advisable to apply well in advance of your intended start date.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    
                                    <h3>7.	Travel to Canada:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once the work permit is approved, you can travel to Canada and begin your employment.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>

            </section>
        </div>

    )
}

export default WorkCanadaPermit