import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-permit-process.png';
import Meta from './Meta';

const StadyCanadaPermitProcess = () => {
    const title = "The study permit process for Canada involves several steps, which we have outlined below. You can also book an appointment to consult with our immigration consultant for the most current information.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        The study permit process for Canada involves several steps, which we have outlined below. You can also book an appointment to consult with our immigration consultant for the most current information.
                                    </p>

                                    <h3>1. Determine Eligibility:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <p>
                                            Begin by ensuring you meet the basic requirements. To study in Canada, you generally need:
                                        </p>
                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Proof of acceptance from a Designated Learning Institution (DLI)
                                                </li>
                                                <li className='list-style' >
                                                    Proof of sufficient funds to cover your tuition fees, living expenses for the student themselves and any accompanying family members. The specified amount needed to demonstrate the ability to financially support oneself while studying in Canada can vary based on the province or territory and the length and type of the study program. The government updates these amounts periodically to reflect current costs of living and studying in Canada.
                                                </li>
                                                <li className='list-style' >
                                                    No criminal record (a police certificate might be requested)
                                                </li>
                                                <li className='list-style' >
                                                    A medical exam, if required
                                                </li>
                                                <li className='list-style' >
                                                    Intent to leave Canada upon the completion of your studies
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2. Language Proficiency:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            You may need to prove proficiency in English or French through standardized language tests like IELTS, TOEFL, CELPIP, or TEF.
                                        </p>
                                    </div>

                                    <h3>3. School Admission:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            Apply to a Canadian DLI and get your acceptance letter. This is required before you can apply for a study permit.
                                        </p>
                                    </div>

                                    <h3>4. Apply for a Study Permit:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            Once you&apos;ve got your acceptance letter, you can apply for your study permit. You have the option to apply online or at a Visa application center.
                                        </p>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Complete the application form (IMM 1294) and include the necessary documents such as your acceptance letter, passport, and proof of financial support.
                                                </li>
                                                <li className='list-style' >
                                                    Pay the application fee.
                                                </li>
                                                <li className='list-style' >
                                                    If required, attend an interview or provide biometrics.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>5. Wait for Processing:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            The processing time varies. This period is the time to prepare for any requested interviews or biometric appointments.
                                        </p>
                                    </div>

                                    <h3>6. Decision on Your Application:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            If approved, you&apos;ll be issued a Port of Entry (POE) Letter of Introduction, while students from certain countries will also get an Electronic Travel Authorization (eTA) or a temporary residence visa to enter Canada.
                                        </p>
                                    </div>


                                    <h3>7. Prepare for Arrival:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            At the Canadian border, you must present:
                                        </p>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Your passport
                                                </li>
                                                <li className='list-style' >
                                                    POE Letter of Introduction
                                                </li>
                                                <li className='list-style' >
                                                    Acceptance letter from the DLI
                                                </li>
                                                <li className='list-style' >
                                                    Proof of financial support
                                                </li>
                                                <li className='list-style' >
                                                    Any other documents recommended by the visa office
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>8. Arrival in Canada: </h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            Even with a study permit, admission to Canada is at the discretion of the Canada Border Services Agency (CBSA) officers at the POE. If deemed eligible, the officer will grant you entry and issue your study permit.
                                        </p>
                                    </div>



                                    <h3>9. Compliance with Study Permit Conditions:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            During your stay, you must:
                                        </p>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Always be enrolled at a DLI
                                                </li>
                                                <li className='list-style' >
                                                    Make progress towards completing your program
                                                </li>
                                                <li className='list-style' >
                                                    Respect any conditions listed on your study permit
                                                </li>
                                                <li className='list-style' >
                                                    Stop studying if you no longer meet the requirements
                                                </li>
                                                <li className='list-style' >
                                                    Leave Canada when your permit expires
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <p>
                                        This is a general guide, and the exact requirements may differ based on your country of origin and your personal circumstances. You can always book a call with our immigration lawyer for comprehensive and up-to-date information.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default StadyCanadaPermitProcess