import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    
    const { auth, setAuth } = useAuth();
    let localToken = "";
    const lua = localStorage.getItem('auth');
    if (typeof lua === 'string') {
        const luAuth = JSON.parse(localStorage.getItem('auth'));
        localToken = luAuth?.token;
    }

    const formData = {
        token:auth?.token? auth.token:localToken
    }

    const headers = {
        'Content-Type': 'application/json'
    }

    const refresh = async () => {
        const response = await axios.post('/refreshToken',JSON.stringify(formData), { headers: headers });
        
        setAuth(prev => {
            //console.log(JSON.stringify(prev));
            //console.log(response.data.accessToken);
            return { ...prev, accessToken: response?.data?.accessToken }
        });
        
        const data = {
            username: auth?.username,
            roles: auth?.roles,
            accessToken: response?.data?.accessToken,
            token: auth?.token
        }
        localStorage.removeItem('auth');

        if(response?.status===200){
            localStorage.setItem('auth', JSON.stringify(data));
        }
        
        //console.log(response);

        return response?.data?.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
