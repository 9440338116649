import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-work-uk.png';
import Meta from './Meta';

const WorkUk = () => {
    const title = "Are you dreaming of building your career in the United Kingdom? Whether you are seeking new opportunities, professional growth, or a change of scenery, working in the UK can be a rewarding experience.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        Are you dreaming of building your career in the United Kingdom? Whether you are seeking new opportunities, professional growth, or a change of scenery, working in the UK can be a rewarding experience. Our immigration experts are here to guide you through the intricate process of securing employment in the UK and help you make your dreams a reality.
                                    </p>

                                    <h3>
                                        Working in the UK: A World of Opportunities
                                    </h3>

                                    <p>
                                        The United Kingdom is renowned for its diverse job market, offering opportunities across various industries and sectors. From finance and technology to healthcare and creative arts, the UK welcomes skilled professionals from around the globe. As a prospective worker, you can benefit from a dynamic work environment, cultural richness, and a chance to connect with a global community.
                                    </p>

                                    <div className="section-heading-customise">
                                        <h2>Application Process Overview</h2>
                                    </div>

                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ol>
                                                <li className='list-style-number' >
                                                    <b>Determine Eligibility:</b> Before starting your journey to work in the UK, it&apos;s essential to determine your eligibility. Different visa categories exist for various employment scenarios, such as the Skilled Worker Visa, Intra-Company Transfer Visa, and the Global Talent Visa. Our experts will assess your qualifications, skills, and job offer to identify the most suitable visa option for you.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Secure a Job Offer:</b> To apply for a work visa in the UK, securing a job offer is often a prerequisite. Our team will assist you in preparing a compelling job application, ensuring that it aligns with UK immigration requirements. We provide valuable insights into crafting resumes and cover letters that resonate with UK employers, enhancing your chances of securing that coveted job offer.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Employer Sponsorship:</b> Many work visas in the UK require sponsorship from a licensed employer. We will guide you through the process of finding a sponsor, ensuring that both you and your prospective employer meet the necessary criteria. Our consultants will facilitate communication between you and your future employer to streamline the sponsorship process.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Visa Application:</b> Navigating the visa application process can be complex, but our experts are here to simplify it for you. We will guide you through the documentation requirements, assist in completing the application forms, and ensure that your submission is accurate and timely. Our goal is to make the process as smooth as possible, minimizing the stress associated with immigration paperwork.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Arrival and Settlement:</b> Once your visa is approved, we provide support in planning your arrival and settlement in the UK. From accommodation advice to cultural orientation, our team is dedicated to ensuring that your transition is seamless. We are committed to your success and will be with you every step of the way.
                                                </li>

                                            </ol>
                                        </div>
                                    </div>

                                    <p>
                                        Begin your journey to work in the UK with confidence, knowing that our experienced immigration consultants are by your side. Contact us today to start your consultation and take the first step towards a fulfilling career in the United Kingdom.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default WorkUk