import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-work-canada.png';
import Meta from './Meta';

const WorkCanada = () => {
    const title = "Welcome to our comprehensive guide on working in Canada! The Great White North is not only renowned for its stunning landscapes and friendly locals but also for the exciting professional opportunities it extends to individuals from around the world.";
    const description = title;
    return (
        <div>

            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        Welcome to our comprehensive guide on working in Canada! The Great White North is not only renowned for its stunning landscapes and friendly locals but also for the exciting professional opportunities it extends to individuals from around the world. The Canadian government actively encourages skilled workers to contribute to its thriving economy, making it an ideal destination for those seeking a fulfilling career and a high quality of life.
                                    </p>
                                    <p>
                                        Canada&apos;s commitment to providing a high quality of life, combined with its progressive immigration policies, makes it an attractive destination for professionals seeking exciting career opportunities and long-term settlements. Whether you&apos;re drawn to the vibrant cities, breathtaking landscapes, or the warmth of Canadian communities, working in Canada is a journey filled with promise and potential.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default WorkCanada