import React,{useState} from "react";
import {
    CCard,
    CCardBody,
    CCol,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
    CFormSelect,

  } from '@coreui/react'
  import { cilSchool} from '@coreui/icons'
  import CIcon from '@coreui/icons-react'
  import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";


function EducationInfoTab(){
  

    const [educationLevel1, setEducationLevel1] = useState([]);
    const [eduStartDate, setEduStartDate] = useState("");
    const [eduCompleteDate, setEduCompleteDate] = useState("");
    const [eduDegreeName, setEduDegreeName] = useState("");
    const [eduInstituteName, setEduInstituteName] = useState("");
  

  

 

    const [val,setVal]=useState([]);
    const [educationLevel, setEducationLevelAdd] = useState([]);
    const [startDateEdu, setStartDateEdu] = useState([]);

    const handleAdd =()=>{
        const abc=[...val,[]]
        const abcd=[...educationLevel,[]]
        const abcde=[...startDateEdu,[]]
        setVal(abc)
        setEducationLevelAdd(abcd)
        setStartDateEdu(abcde)
    }

    window.addEventListener('load', handleAdd);
    // window.removeEventListener('load', handleAdd);
    // return () => {
    //   window.removeEventListener('load', handleAdd);
    // };
    // if(educationLevel === null){
    //     handleAdd()
    //  }

    const handlePrint=()=>{
            console.log(val);
    }

    const handleChange=(onChnageValue,i)=>{
        const inputdata =[...val]
        inputdata[i]=onChnageValue.target.value;
        setVal(inputdata)
    }
    const setEducationLevel=(onChnageValue,i)=>{
        const inputdata =[...educationLevel]
        inputdata[i]=onChnageValue;
        setEducationLevelAdd(inputdata)
    }

    const setStartDateEduAdd=(onChnageValue,i)=>{
      const inputdata =[...startDateEdu]
      inputdata[i]=onChnageValue;
      setStartDateEdu(inputdata)
      // eduStartDate[0].to = "88";

      // setEduStartDate (inputdata)
  }

    const handleDelete=(i)=>{
        if(i!==0){
            const deleteVal=[...val]
            deleteVal.splice(i,1)
            setVal(deleteVal)
        }
        
    }
console.log(val,"data-",educationLevel,"data2",startDateEdu)

    return(
        <>
        <button onClick={()=>handlePrint()}>Log</button>
        <button onClick={()=>handleAdd()}>ADD</button>
        {val?.map((data,i)=>{
            return(

                
                <div key={i}>
                    {/* <input  value={data} onChange={e=>handleChange(e,i)} /> */}


                    <CCol xs={12}>
                    <CCard className="mb-2">
                      <CCardBody>
                        <CRow className="mb-12">
                          <div className="col-sm-4">
                            <CFormSelect size="sm" className="mb-3" aria-label="Large select example"
                              value={educationLevel1[i]} onChange={(event) => {setEducationLevel(event.target.value,i); }}>
                              <option>Degree level</option>
                              <option value="1">SSC</option>
                              <option value="2">HSC</option>
                              <option value="3">Bachelor</option>
                              <option value="4">Masters</option>
                            </CFormSelect>

                          </div>
                          <div className="col-sm-4">

                          <DatePicker 
                                      className="form-control" 
                                      type="text"
                                      size="lg"
                                      // id={eduStartDate[i]} 
                                      selected= {eduStartDate[i] }
                                      // selected={eduStartDate[i]}
                                      onChange={(date) => {setStartDateEduAdd(date,i);setEduStartDate(date)}}
                                      showIcon
                                      icon="fa fa-calendar"
                                      // dateFormat="yyyy-MM-dd"
                                      placeholderText="Date Of Birth"
                                    />

{/* 
                            <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                              value={eduStartDate} onChange={(event) => { setEduStartDate(event.target.value); }} /> */}
                          </div>
                          <div className="col-sm-4">
                            <CFormInput className="col-sm-2" placeholder="Date Completed" aria-label="eduEndDate"
                              value={eduCompleteDate} onChange={(event) => { setEduCompleteDate(event.target.value); }} />
                          </div>
                        </CRow>

                        <CInputGroup className="mb-3">
                          <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                          <CFormInput placeholder="Name Of Degree" aria-label="email"
                            value={eduDegreeName} onChange={(event) => { setEduDegreeName(event.target.value); }} />
                          <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                          <CFormInput placeholder="Name Of Institution" aria-label="optionalEmail"
                            value={eduInstituteName} onChange={(event) => { setEduInstituteName(event.target.value); }} />
                        </CInputGroup>
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <button onClick={()=>handleDelete(i)}>x</button>



                </div>
            )
        })}
        </>
    );
}

export default EducationInfoTab;