import React, {useEffect}from "react"
import { useParams ,useLocation} from "react-router-dom"
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Vortex} from  'react-loader-spinner'

const DocumentsViewer = (props) => {

    const {key,fileName,clientName} = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = React.useState("");
    const [extension, setExtension] = React.useState("");
    const [isSpenierOpen, setIsSpenierOpen] = React.useState(true);
    const allowedTypes = ["data:application/pdf;base64","data:image/jpeg;base64","data:image/jpg;base64","data:image/gif;base64","data:image/png;base64"];


    useEffect(() => {
        //let isMounted = true;
        const controller = new AbortController();
        const getUserDetails = async () => {


          const formData = {
            key: key,
            fileName: fileName,
          }

          console.log(formData);

          const headers = {
            'Content-Type': 'application/json'
          }

          await axiosPrivate.post("/downloadFile", JSON.stringify(formData), { headers: headers }).then((response) => {
            if (response.status === 200) {
                setData(response?.data?.code);
                setExtension(response?.data?.extension);
                setIsSpenierOpen(false);
            }
            else {
              
            }
    
          }, fail => {
           
          });

        }
        
    
        getUserDetails();
    
        return () => {
          //isMounted = false;
          controller.abort();
        }
      }, []);


  return (
    <div>

      {
        isSpenierOpen ?
          <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
          :
          allowedTypes.includes(extension) ?
            <object title={"Resume of " + clientName} name={fileName} aria-label="test" id="documentObjectiveId" data={data + "#zoom=Autometic Zoom&scrollbar=0&toolbar=1&navpanes=0"} width="100%" height="500" className="objectShowClass" ></object>
            :
            <a className="styled-like-a-button" download={fileName} href={data} >Download</a>

      }


    </div>
  )
}

export default DocumentsViewer
