import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
// import { CButton } from '@coreui/react'
import { MDBCol,MDBContainer,MDBRow,MDBCard,MDBCardText,MDBCardBody} from 'mdb-react-ui-kit';
// import { Vortex } from  'react-loader-spinner'
import avater from '../assets/images/avatars/avater.png';

export default function AgentDetails() {

  const [userDetails, setUsersDetails] = useState();
  const [clientList, setClientList] = useState();
  const axiosPrivate = useAxiosPrivate();
  // const navigate = useNavigate();
  const location = useLocation();
  const [isShowButton, setIsShowButton] = React.useState(true)
  // const [isShowMessage, setIsShowMessage] = React.useState(false)
  const navigate = useNavigate();
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [pictureContent, setPictureContent] = useState(avater);
  // const [backPage,setBackPage] = useState("");
  const [pageName,setPageName] = useState("");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const detailsUserId = localStorage.getItem('agent-user-id');
    setPageName(location?.state?.role?location.state.role:localStorage.getItem('page-name'));
    const getUserDetails = async () => {
      try {
        const path = `/getAgentInfo/${location?.state?.id?location.state.id:detailsUserId}`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });

        console.log(response.data);
        isMounted && setUsersDetails(response?.data?.userInfo);
        isMounted && setClientList(response?.data?.clientInfoList);
        console.log(response?.data?.userInfo?.picture);
        if (response?.data?.userInfo?.picture !== null && response?.data?.userInfo?.picture !== "" && response?.data?.userInfo?.picture !== 'undefine') {
          setPictureContent(response?.data?.userInfo?.picture);
        }

      } catch (err) {
        //console.error(err);
        console.log(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getUserDetails();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);

  async function approved(event) {
    event.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        id: userDetails.id,
        userName: userDetails.userName,
        status: 4

      }

      console.log(formData);

      setIsSpenierOpen(true);
      await axiosPrivate.post("/updateStatus", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          alert("Approved successfully");
          setIsShowButton(false);
          //setIsShowMessage(true);
        }
        else {
          alert("Please try again after sometimes later");
        }
        setIsSpenierOpen(false);

      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
    }

  }

  async function rejected(event) {
    event.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        id: userDetails.id,
        userName: userDetails.userName,
        status: 2

      }

      console.log(formData);
      setIsSpenierOpen(true);
      await axiosPrivate.post("/updateStatus", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          alert("Rejected successfully");
          navigate('/home/pending-users');
        }
        else {
          alert("Please try again after sometimes later");
        }
        setIsSpenierOpen(false);
      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
    }

  }

console.log(clientList);

function downloadPicture(){
  const downloadLink = document.createElement("a");
  let ex = pictureContent.split(";")[0].split("/")[1];
  downloadLink.href = pictureContent;
  downloadLink.download = userDetails?.fullName+"."+ex;
  downloadLink.click();

}

/* function viewHandleClick(value) {
  localStorage.setItem('edit-agent-id', value);
  localStorage.setItem('back-page', '/home/agent-details');
  navigate('/home/edit-agent/'+userDetails?.id, { state: { id: value, backPage: '/home/agent-details' }, replace: true });
} */

  return (
    <section style={{ backgroundColor: '#fff' }}>
      <h2 className="text-center">{pageName} Details {/* <CButton color="info" onClick={() => { viewHandleClick(userDetails?.id) }} >Edit</CButton> */}</h2>
      <MDBContainer className="py-4">
      
        <MDBRow>
          <MDBCol lg="4" >
            <MDBCard className="mb-4" style={{ height: '345px' }}>
              <MDBCardBody className="text-center">
                {/* <MDBCardImage
                  src={require('../assets/images/avatars/avater.png')}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid /> */}
                  <img src={pictureContent} alt="" onClick={downloadPicture} className="detailsPicture" ></img>

                <p className="text-muted mb-1">{userDetails?.fullName}</p>
                <p className="text-muted mb-2">
                  {userDetails?.userResidence?.map((row) => row?.country?.name)}
                </p>
                <p className="text-muted mb-4">
                  {userDetails?.userId}
                </p>
                <div className="d-flex justify-content-center mb-2">

                  {/* {userDetails?.applyStatus !== 4 && isShowButton ? (

                  isSpenierOpen?<Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480','#036480','#036480','#036480','#036480','#036480']}></Vortex>:
                    <>
                      <CButton color="success" onClick={approved}>Accept</CButton> &nbsp; &nbsp; &nbsp;
                      <CButton color="danger" onClick={rejected}>Reject</CButton>
                    </>

                  ) : ''} */}

                  {/* <CButton color="info" onClick={rejected}>Edit</CButton> */}


                  <br />
                </div>
              </MDBCardBody>
              <br />
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>First Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.firstName}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Last Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.lastName}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.officeEmail}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>WhatsApp</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.whatsApp}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Imo</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.imo}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.sex}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Viber</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.viber}</MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Date Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.dob ? moment(userDetails?.dob).format("DD-MM-YYYY") : ''}
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Merital Status</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.maritalStatus?.name}
                    </MDBCardText>
                  </MDBCol>

                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>User Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.userName}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>Role</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {pageName}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>



        <MDBRow>
          <MDBCol md={6}>

            <MDBCard className="mb-4 mb-md-0" style={{ height: 'auto' }}>
              <MDBCardBody>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Country of Residence</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.userResidence?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>


                {/* <div>
                  <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Consultation Requirement</span></MDBCardText>
                  <hr />
                  <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                    <ul>
                      {userDetails?.userConsultation?.map((row) => <li key={row?.id}>{row?.consultation?.name}</li>)}
                    </ul>
                  </MDBCardText>

                  <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Interested Country</span></MDBCardText>
                  <hr />
                  <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                    <ul>
                      {userDetails?.interestedCountry?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                    </ul>
                  </MDBCardText>

                  <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">List of Countries you visited in last 10 years</span></MDBCardText>
                  <hr />
                  <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                    <ul>
                      {userDetails?.visitedCountry?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                    </ul>
                  </MDBCardText>
                </div>    */}
                
                  
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4 mb-md-0" style={{ height: 'auto' }}>
              <MDBCardBody>
                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Address Information</span></MDBCardText>
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.address?.map((row) => (
                      <div key={row?.id}>
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Address</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.address}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        {/* 
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Address Line 2</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.addressLineTwo}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr /> 
                        */}
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>City</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.city}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>State / Province</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.state}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Postal / Zip Code</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.postal}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                      </div>
                    ))}
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>

            {/* <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Language Test Information</span></MDBCardText>

                {userDetails?.languageTest?.map((row, i) => (

                  <div key={row.id}>

                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Language Test Type</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.languageTestType?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Language Test Version</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.languageTestVersion?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of Language Test</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.date?moment(row?.date).format("DD-MM-YYYY"):''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of test results</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.resultDate?moment(row?.resultDate).format("DD-MM-YYYY"):''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Certificate number</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.certificateNo}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="12">
                        <MDBCardText>Language Test Score </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Listening </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.listeningScore}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Reading </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.readingScore}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Writing</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.writingScore}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Speaking</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.speakingScore}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Overall</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.overall}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        
                      </MDBCol>
                      <MDBCol sm="3">
                        
                      </MDBCol>
                    </MDBRow>


                  </div>

                ))}


              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Spouse Information</span></MDBCardText>

                {userDetails?.spouseInfo?.map((row, i) => (

                  <div key={row.id}>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Spouse First Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.firstName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Spouse Last Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.lastName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of Marriage</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                      <MDBCardText className="text-muted">: {row?.marriageDate ? moment(row?.marriageDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Have you been previously married?</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.previouslyMarried === true ? "Yes" : "No"}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard> */}

          </MDBCol>


          <MDBCol md={6}>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Assigned Client List</span></MDBCardText>
                <hr />
                
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>SL</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText>Clent Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText>Cleint ID</MDBCardText>
                  </MDBCol>
                </MDBRow>

                <ul>
                    {clientList?.map((row,i) => (
                      <div key={row?.id}>

                        <hr/>
                        <MDBRow>
                          <MDBCol sm="2">
                            <MDBCardText>{i+1}</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="5">
                            <MDBCardText>{row?.fullName}</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="5">
                            <MDBCardText>{row?.userId}</MDBCardText>
                          </MDBCol>
                        </MDBRow>

                      </div>
                    ))}
                  </ul>
              </MDBCardBody>
            </MDBCard>

            {/* <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Personal Information</span></MDBCardText>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Country Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.birthCountry.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>City Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.birthCity}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Have you ever used any other name?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.isOtherNameUsed ? userDetails?.personaInfo?.isOtherNameUsed === true ? "Yes" : "No" : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport (Country/territory of issue)</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportIssueCountry?.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport Issue date </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportIssueDate ? moment(userDetails?.personaInfo?.passportIssueDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport Expiry date</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportExpireDate ? moment(userDetails?.personaInfo?.passportExpireDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Have you applied Canada before?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.isCaAppliedBefore === true ? "Yes" : "No"}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Have any relative resident in Canada?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.haveAnyCaRelative === true ? "Yes" : "No"}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Relation with this relative</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.caRelativeRelation}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Province or Territory of relative</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.caRelativeAddress}</MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Educational Information</span></MDBCardText>
                {userDetails?.educationalInfo?.map((row, i) => (

                  <div key={row.id} >
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Level</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.educationLevel?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Name of Degree</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.degreeName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Name of Institute</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.instituteName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.startDate?moment(row?.startDate).format("DD-MM-YYYY"):''}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Com. Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.completeDate?moment(row?.completeDate).format("DD-MM-YYYY"):''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Work Experience Information</span></MDBCardText>

                {userDetails?.workExperience?.map((row, i) => (

                  <div key={row.id} >
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Organization</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.organization}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Designation</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.designation}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.startDate?moment(row?.startDate).format("DD-MM-YYYY"):''}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>End Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.endDate?moment(row?.endDate).format("DD-MM-YYYY"):''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard> */}

          </MDBCol>

        </MDBRow>

      </MDBContainer>
    </section>
  );
}