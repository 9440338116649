import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import axios from '../api/axios';
import { CButton, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormLabel,CFormTextarea } from '@coreui/react'
import '../assets/css/userDetails.css'

/* 
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons' 
*/



export default function NewsInfo() {

  // const navigate = useNavigate();
  const [newsInfoList, setNewsInfoList] = useState([]);
  const axiosPrivate = useAxiosPrivate();


  ////////////

  const [updateText] = useState("Update");
  const [saveText,] = useState("Save");
  const [buttonText, setButtonText] = useState(saveText);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [newsInfoId, setNewsInfoId] = useState("");
  const [headline, setHeadline] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [newsLink, setNewsLink] = useState("");
  const [details, setDetails] = useState("");
  const [validated, setValidated] = useState(false);


  const [e, setEvent] = useState(null);
  const [image, setImage] = useState("");
  const onFileChangeHandler = async (e) => {
    setEvent(e);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (e.target.files !== "") {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(e.target.files[0]?.type)) {
        confirmAlert({ title: 'MyGration Hub', message: "Only pdf jpeg, jpg and png file allowed", buttons: [{ label: 'OK' }] });
        e.target.value = null;
        setIsSpenierOpen(false);
        return;
      }

      await axios.post("/uploadFile", formData).then((res) => {
        if (res.status === 200) {
          setImage(res?.data?.fileName);
          setIsSpenierOpen(false);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
        setIsSpenierOpen(false);
      });
    } else {
    }

  };


  const [ed, setEventD] = useState(null);
  const [detailsImage, setDetailsImage] = useState("");
  const onFileChangeHandlerDetails = async (e) => {
    setEventD(e);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (e.target.files !== "") {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(e.target.files[0]?.type)) {
        confirmAlert({ title: 'MyGration Hub', message: "Only pdf jpeg, jpg and png file allowed", buttons: [{ label: 'OK' }] });
        e.target.value = null;
        setIsSpenierOpen(false);
        return;
      }

      await axios.post("/uploadFile", formData).then((res) => {
        if (res.status === 200) {
          setDetailsImage(res?.data?.fileName);
          setIsSpenierOpen(false);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
        setIsSpenierOpen(false);
      });
    } else {
    }

  };

  function deleteDocumentInfo(id, headerline) {

    const formData = {
      id: id
    }

    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to delete news " + headerline,
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "deleteNewsInfo", "News deleted successfully")
      },
      {
        label: 'No',
      }]
    });
  }



  function editNewsInfo(id, headerline, position, status, newsLink,details) {
    setButtonText(updateText);
    setIsUpdate(true);
    setNewsInfoId(id);
    setHeadline(headerline);
    setPosition(position);
    setStatus(status);
    setNewsLink(newsLink);
    setDetails(details);
  }

  function clear() {
    setIsUpdate(false);
    setButtonText(saveText);
    setHeadline("");
    setPosition("");
    setStatus("Initiated")
    setNewsLink("");
    setDetails("");
    setImage("");
    setDetailsImage("");
    setValidated(false);
    if(e!==null){
      e.target.value = null
    }
    if(ed!==null){
      ed.target.value = null
    }
  }




  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      const formData = {
        id: newsInfoId,
        position: position,
        status: status,
        headline: headline,
        details: details,
        image: image,
        detailsImage: detailsImage,
        newsLink: newsLink
      }

      let message = isUpdate ? "Are you sure? You want to update news information?" : "Are you sure? You want to save news information";

      confirmAlert({
        title: 'MyGration Hub', message: message,
        buttons: [{
          label: 'Yes',
          onClick: () => isUpdate ?  apicall(formData, "updateNewsInfo", "News updated successfully"): apicall(formData, "saveNewsInfo", "News saved successfully")
        },
        {
          label: 'No',
        }]
      });

    }

    setValidated(true);
    event.preventDefault();


  }

  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }

    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (message !== "") {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
        }
        getNewsInfoList();
        clear();
      }
      else {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK', }] });
      }

    }, fail => {
      confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
    });
  }


  ///////////////

  const getNewsInfoList = async () => {
    try {
      const path = "/getAllNewsInfoListForAdmin";
      await axiosPrivate.get(path).then((response) => {
        if (response.status === 200) {
          setNewsInfoList(response?.data);
        }
      });
    } catch (err) {
      confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
    }
  }


  async function downloadFile(key, fileName) {
    const formData = {
      key: key,
      fileName: fileName,
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    await axiosPrivate.post("/downloadFile", JSON.stringify(formData), { headers: headers }).then((response) => {
      if (response.status === 200) {
        const linkSource = response?.data?.code;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
      }
    }, fail => { });

  }

  async function view(key) {
    // navigate('/news-details/'+key, { state: { key: key}, replace: false });
    window.open("#/news-details/"+key);
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getNewsInfoListOnLoad = async () => {
      try {
        const path = `/getAllNewsInfoListForAdmin`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });
        isMounted && response?.data && setNewsInfoList(response?.data);
      } catch (err) {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      }
    }

    getNewsInfoListOnLoad();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);


  return (
    <section style={{ backgroundColor: '#fff' }}>
      <h2 className="padding10">News Information </h2>
      <MDBContainer className="">

        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>

                <CContainer>

                  <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >

                    
                    <CCol md={6}>
                      {/* <CFormLabel htmlFor="validationPosition">Position <span className='red'>*</span></CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">Position <span className='red'>*</span></CInputGroupText>
                        <CFormInput type="number" aria-describedby="validationPosition" id="validationPosition" value={position} onChange={(event) => { setPosition(event.target.value); }} required />
                      </CInputGroup>
                    </CCol>
                    <CCol md={6}>
                      {/* <CFormLabel htmlFor="validationStatus">Status <span className='red'>*</span></CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">Status <span className='red'>*</span></CInputGroupText>
                        <CFormSelect id="validationStatus"  value={status} onChange={(event) => { setStatus(event.target.value); }} required >
                          <option value="Initiated">Initiated</option>
                          <option value="Published">Published</option>
                          <option value="Unpublished">Unpublished</option>
                        </CFormSelect>
                      </CInputGroup>
                    </CCol>

                    <CCol md={12}>
                      {/* <CFormLabel htmlFor="validationHeadline">Headline <span className='red'>*</span></CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">Headline <span className='red'>*</span></CInputGroupText>
                        <CFormInput type="text" aria-describedby="validationHeadline" id="validationHeadline" value={headline} onChange={(event) => { setHeadline(event.target.value); }} required />
                      </CInputGroup>
                    </CCol>

                    <CCol md={12}>
                      {/* <CFormLabel htmlFor="validationNewsLink">News Link</CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px" style={{display:'flow'}}>
                          <div>Details <span className='red'>*</span></div>
                          <br/>
                          Line Break:<br/>
                          <CFormInput defaultValue={"<br/>"} width={70}/>
                          <br/>
                          HeadLine:<br/>
                          <CFormInput defaultValue={"<h3></h3>"} width={70}/>
                          
                        </CInputGroupText>
                        <CFormTextarea type="text" aria-describedby="validationNewsLink" id="validationNewsLink"  rows={10} value={details} onChange={(event) => { setDetails(event.target.value); }} required/>
                      </CInputGroup>
                    </CCol>


                    <CCol md={12}>
                      {/* <CFormLabel htmlFor="validationNewsLink">News Link</CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">News Link</CInputGroupText>
                        <CFormInput type="text" aria-describedby="validationNewsLink" id="validationNewsLink" value={newsLink} onChange={(event) => { setNewsLink(event.target.value); }} />
                      </CInputGroup>
                    </CCol>

                    {/* <CCol md={12}>
                      Upload Header Image (Preferred Image size 216px:164px) : <span className='red'>*</span> 
                      <br></br>
                      To upload document click on the <b>Choose File</b> or drag and drop in gray segment
                    </CCol> */}

                    <CCol md={12}>
                      <CFormLabel htmlFor="headerImageFile">Upload Header Image (Preferred Image size 216px:164px) : <span className='red'>*</span> </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput accept="image/*" type="file" size="lg" id="headerImageFile" onChange={onFileChangeHandler} required={!isUpdate} />
                      </CInputGroup>
                    </CCol>

                    <CCol md={12}>
                    <CFormLabel htmlFor="detailsImageFile">Upload Details Image (Preferred Image size 500px:400 to 500px) : <span className='red'>*</span> </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput accept="image/*"  type="file" size="lg" id="detailsImageFile" onChange={onFileChangeHandlerDetails} required={!isUpdate} />
                      </CInputGroup>
                    </CCol>

                    <CCol xs={12}>
                      {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <><CButton color="primary" type="submit"> {buttonText} </CButton> {isUpdate ? <CButton onClick={clear} color="info" > Clear </CButton> : ''}</>}
                    </CCol>

                  </CForm>
                </CContainer>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>


        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <CCol md={12} className='font-header text-primary'>
                  News Infomation List
                </CCol>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Headline</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Position</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Create Date</CTableHeaderCell>
                      {/* <CTableHeaderCell scope="col">News Link</CTableHeaderCell> */}
                      <CTableHeaderCell scope="col" >View</CTableHeaderCell>
                      <CTableHeaderCell scope="col" >Edit</CTableHeaderCell>
                      <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {newsInfoList?.map((news, i) => (
                      <CTableRow v-for="item in tableItems" key={i}>
                        <CTableDataCell>
                          <div>{i + 1}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          {news?.headline}
                        </CTableDataCell>
                        <CTableDataCell>
                          {news?.status}
                        </CTableDataCell>
                        <CTableDataCell>
                          {news?.position}
                        </CTableDataCell>
                        <CTableDataCell>
                          {news?.dateCreated ? moment(news?.dateCreated).format("DD-MM-YYYY") : ''}
                        </CTableDataCell>
                        {/* <CTableDataCell>
                          {news?.newsLink}
                        </CTableDataCell> */}
                        <CTableDataCell>
                          {/* <CButton color="primary" onClick={() => { downloadFile("news", news?.image) }} >View</CButton> */}
                          <CButton color="primary" onClick={() => { view(news?.id) }} >View</CButton>
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="success" onClick={() => { editNewsInfo(news?.id, news?.headline,news?.position,news?.status,news?.newsLink,news?.details) }} >Edit</CButton>
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="danger" onClick={() => { deleteDocumentInfo(news?.id, news?.headline) }} >Delete</CButton>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>


      </MDBContainer>
    </section>
  );
}