import React from "react";


const Accounce = () => {


    return (
        <>
            <section>
                Coming soon...............
            </section>
        </>

    )
}

export default Accounce;
