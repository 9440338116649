import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { confirmAlert } from 'react-confirm-alert';
// import { useNavigate, useLocation } from "react-router-dom";
// import moment from 'moment';
// import { cilArrowCircleLeft } from '@coreui/icons'
// import CIcon from '@coreui/icons-react'
// import { CAvatar } from '@coreui/react'
import '../assets/css/chatUi.css'
// import { cilUserPlus, cilPeople } from '@coreui/icons'
// import { CForm, CCol, CInputGroup, CFormLabel, CFormSelect, CButton, CTable, CTableBody, CTableDataCell, CTableRow, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CFormCheck } from '@coreui/react'
import { CForm, CFormSelect, CButton, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CFormCheck,CFormLabel,CInputGroup,CInputGroupText } from '@coreui/react'
import { Vortex } from 'react-loader-spinner'
// import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import TimezoneSelect from 'react-timezone-select';
import '../../src/assets/css/custom.css';
import DatePicker from "react-datepicker"

// const labelStyle = "original"
// const timezones = {
  // ...allTimezones,
  // "Europe/Berlin": "Frankfurt",
// }

const Scheduling = () => {

  const axiosPrivate = useAxiosPrivate();
  // const location = useLocation();
  // const navigate = useNavigate();
  // const [mySchedule, setMySchedule] = useState();

  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [visible, setVisible] = useState(false);


  const [val, setVal] = useState([]);
  const [isRecuring, setIsRecuring] = useState(false);
  const [recuringDate, setRecuringDate] = useState("");
  const [isChecked, setIsChecked] = useState([]);
  const [ids, setIds] = useState([]);
  const [dates, setDates] = useState([]);
  const [days, setDays] = useState([]);
  const [startDays, setStartDays] = useState([]);
  const [endDays, setEndDays] = useState([]);
  const [startVals, setStartVals] = useState([]);
  const [endVals, setEndVals] = useState([]);
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isInvalid, setIsInvalid] = useState([]);

  function openModal() {
    setIsSpenierOpen(false);
    setVisible(true);
    getMySchedule();

  }

  function closeModal() {
    setVisible(false);
    setValidated(false);
    setIsSpenierOpen(false);
    setIsRecuring(false);
    setRecuringDate("");
  }


  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }

    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (message !== "") {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK' }] });
        }
        if (apiName === "saveMySchedule") {
          setIsSpenierOpen(false);
          closeModal();
        }
        if(apiName === "syncMySchedule"){
          
        }
      }
      else {
        setIsSpenierOpen(false);
        //confirmAlert({ title: 'MyGration Hub', message: "Failed to complete process, Try again later", buttons: [{ label: 'OK' }] });
      }

    }, fail => {
      console.error(fail);
      setIsSpenierOpen(false);
      //confirmAlert({ title: 'MyGration Hub', message: "Please relogin and try again", buttons: [{ label: 'OK' }] });
    });
  }

  async function saveMySchedule() {
    try {
      let mySchedules = [];
      let c = 0;
      val.forEach((v, i) => {
        if(Number(startVals[i])>=Number(endVals[i]) && isChecked[i]){
          //setIsInvalidFunction(true,i);
          c++;
        }
        
        mySchedules[i] = {
          id: ids[i],
          userId: 0,
          timeZone: selectedTimezone,
          date: dates[i],
          dayName: days[i],
          startVal: startVals[i],
          endVal: endVals[i],
          startTime: startDays[i],
          endTime: endDays[i],
          isEnable: isChecked[i]
        }
      });


      if(c>0){
        //setValidated(false);
        // console.log(isInvalid);
        return false;
      }

      
      // console.log("calling again");

      const formData = {
        userId:0,
        timeZone:selectedTimezone,
        myScheduleList:mySchedules,
        isRecuring:isRecuring,
        recuringDate:isRecuring===false?"":recuringDate
      }

      setIsSpenierOpen(true);

      let path = "saveMySchedule";
      let message = "Schedule saved successfully";
      
      apicall(formData, path, message);

    } catch (err) {
      console.log(err);
      setIsSpenierOpen(false);
      confirmAlert({ title: 'MyGration Hub', message: "Please relogin and try again", buttons: [{ label: 'OK' }] });
    }

  }

  const handleFormSubmit = (event) => {

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      saveMySchedule();
    }

    //setValidated(true);
    event.preventDefault();


  }

  const setIsInvalidFunction = (onChnageValue, i) => {
    const inputdata = [...isInvalid]
    inputdata[i] = onChnageValue;
    setIsInvalid(inputdata);
    // console.log(i);
  }

  const setCheckUnCheck = (onChnageValue, i) => {
    const inputdata = [...isChecked]
    inputdata[i] = onChnageValue;
    setIsChecked(inputdata);
    if(startVals[i]===null){
      setStartValsFunction("0",i);
    }
    if(endVals[i]===null){
      setEndValsFunction("1",i);
    }
    
  }
  const setStartValsFunction = (onChnageValue, i) => {
    const inputdata = [...startVals]
    inputdata[i] = onChnageValue;
    setStartVals(inputdata);
    
  }
  const setEndValsFunction = (onChnageValue, i) => {
    const inputdata = [...endVals]
    inputdata[i] = onChnageValue;
    setEndVals(inputdata);


    const inp = [...isInvalid];
    if((Number(startVals[i])>=Number(onChnageValue)) && (isChecked[i] === true)){
      // console.log("true");
      inp[i] = true; 
    }else{
      // console.log("false");
      inp[i] = false; 
    }
    setIsInvalid(inp);

  }

  function saveAndSetSelfTimeZone(val) {
    // console.log(val);
    if (val.value !== null) {
      saveSelfTimeZone(val.value)
    }
  }

  function saveSelfTimeZone(timezone) {
    if (timezone !== null) {
      setSelectedTimezone(timezone);
      const formData = {
        userId: 0,
        timeZone: timezone
      }
      apicall(formData, "setSelfTimeZone", "");
    }
  }

  function syncMySchedule() {
    if (true) {
      
      const formData = {
        userId: 0
      }
      //confirmAlert({ title: 'MyGration Hub', message: "Please wait sync process is running...", buttons: [{ label: 'OK',id:'messageButtonId' }] });
      apicall(formData, "syncMySchedule", "Sync is done");

    }
  }


  async function getMySchedule() {
    const controller = new AbortController();
    try {
      const path = `/getSelfSchedule`;
      axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
        if (response.status === 200) {
          // console.log(response?.data);
          //setMySchedule(response?.data);
          setIsRecuring(response?.data[0]?.isRecurring);
          if(response?.data[0]?.isRecurring===true){
            setRecuringDate(response?.data[0]?.recurringDate);
          }

          let vVal = [];
          let vIsChecked = [];
          let vIds = [];
          let vDates = [];
          let vDays = [];
          let vST = [];
          let vET = [];
          let vSV = [];
          let vEV = [];
          let vIsInValid = [];

          response?.data && response?.data.forEach((v, i) => {
            vVal.push([]);
            
            if(v.id!==null && v.id!=="" && v.id > 0){
              vIsChecked.push(true);
            }else{
              vIsChecked.push(false);
            }
            vIds.push(v.id);
            vDates.push(v.date);
            vDays.push(v.dayName);
            vST.push(v.startTime);
            vET.push(v.startTime);
            vSV.push(v.startVal);
            vEV.push(v.endVal);
            if((Number(v.startVal)>=Number(v.endVal) && Number(v.id) > 0 )){
              vIsInValid.push(true);
            }else{
              vIsInValid.push(false);
            }

          });

          setVal(vVal);
          setIsChecked(vIsChecked);
          setIds(vIds);
          setDates(vDates);
          setDays(vDays);
          setStartDays(vST);
          setEndDays(vET);
          setStartVals(vSV);
          setEndVals(vEV);
          setIsInvalid(vIsInValid);

        }

      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });;

    } catch (err) {
      confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
    }
  }

  const timeList = [
    { value: '0', label: '12:00AM' },
    { value: '1', label: '1:00AM' },
    { value: '2', label: '2:00AM' },
    { value: '3', label: '3:00AM' },
    { value: '4', label: '4:00AM' },
    { value: '5', label: '5:00AM' },
    { value: '6', label: '6:00AM' },
    { value: '7', label: '7:00AM' },
    { value: '8', label: '8:00AM' },
    { value: '9', label: '9:00AM' },
    { value: '10', label: '10:00AM' },
    { value: '11', label: '11:00AM' },
    { value: '12', label: '12:00PM' },
    { value: '13', label: '1:00PM' },
    { value: '14', label: '2:00PM' },
    { value: '15', label: '3:00PM' },
    { value: '16', label: '4:00PM' },
    { value: '17', label: '5:00PM' },
    { value: '18', label: '6:00PM' },
    { value: '19', label: '7:00PM' },
    { value: '20', label: '8:00PM' },
    { value: '21', label: '9:00PM' },
    { value: '22', label: '10:00PM' },
    { value: '23', label: '11:00PM' },
    { value: '24', label: '11:59PM' }
  ]


  function addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
}

  useEffect(() => {
    //let isMounted = true;
    const controller = new AbortController();

    const getSelfTimeZone = async () => {
      try {
        axiosPrivate.get("/getSelfTimeZone", { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            let tz = response?.data?.timeZone;
            if (tz !== null && tz !== '') {
              setSelectedTimezone(response?.data?.timeZone);
            } else {
              setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
              saveSelfTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
            }
          }

        }, fail => {
          //confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
        });;

      } catch (err) {
        console.log(err);
      }
    }

    getSelfTimeZone();


    return () => {
      //isMounted = false;
      controller.abort();
    }
  }, []);

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => closeModal()}
        aria-labelledby="StaticBackdropExampleLabel"
        size="lg"

      >
        <CModalHeader onClose={() => closeModal()}>
          <CModalTitle id="LiveDemoExampleLabel">Select tha days and times that you are typically available for mentoring meetings</CModalTitle>
        </CModalHeader>
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} >
          <CModalBody>

            <CRow className="mb-12">
              <div className="col-sm-12"> Your timezone is set to <span className='red'>*</span>
                <TimezoneSelect value={selectedTimezone} onChange={saveAndSetSelfTimeZone} />
              </div>
            </CRow>


            <CRow className="mb-12">
              <div className="col-sm-2">&nbsp;
              </div>
            </CRow>

            

            <table className='col-sm-12'>
              <tbody>
                {val?.map((data, i) => (

                  isChecked[i] === true ?
                    <tr key={i}>
                      <td className='col-sm-1 text-center'>
                        <CFormCheck inline checked={isChecked[i]} onChange={(event) => setCheckUnCheck(event.target.checked, i)} />
                      </td>
                      <td className='col-sm-3'>
                        <CFormInput type="text" disabled={true} value={days[i]} required={true} />
                      </td>
                      <td className='col-sm-1 text-center'>
                        from
                      </td>
                      <td className='col-sm-3'>
                        <CFormSelect size="sm" className="form-control" aria-label="" options={timeList} value={startVals[i]} onChange={(event) => { setStartValsFunction(event.target.value, i); }} required ></CFormSelect>
                      </td>
                      <td className='col-sm-1 text-center'>
                        to
                      </td>
                      <td className='col-sm-3'>
                        <CFormSelect size="sm" className="form-control" aria-label="" options={timeList} value={endVals[i]} onChange={(event) => { setEndValsFunction(event.target.value, i); }} required invalid={isInvalid[i]} ></CFormSelect>
                      </td>
                    </tr>

                    :
                    <tr key={i}>
                      <td className='col-sm-1 text-center'>
                        <CFormCheck inline checked={isChecked[i]} onChange={(event) => setCheckUnCheck(event.target.checked, i)} />
                      </td>
                      <td className='col-sm-3'>
                        <CFormInput type="text" disabled={true} value={days[i]} required={true} />
                      </td>
                      <td className='col-sm-8' colSpan={4} >
                        <CFormInput type="text" style={{ backgroundColor: 'white', textAlign: 'center' }} disabled={true} value="unavailable" />
                      </td>
                    </tr>

                  // )
                ))}
              </tbody>
            </table>

            <CRow className="mb-12">
              <div className="col-sm-2">
                &nbsp;
              </div>
            </CRow>

            <CRow className="mb-12">
              <div className="col-sm-3" style={{paddingTop:"8px"}}>
                <CFormCheck inline checked={isRecuring} onChange={(event) => setIsRecuring(event.target.checked)} /> Is recurring
              </div>

              <div className="col-sm-9">
                

              {/* <CFormLabel htmlFor="validationCustomDateOfBirth">Till Date<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation customDatePickerWidth">
                  <CInputGroupText>Till Date</CInputGroupText>
                  Till Date:
                    

                  </CInputGroup> */} 
                  
                {isRecuring ?
                  <>Till Date :

                    <DatePicker
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      minDate={addDays(new Date(), 7)}
                      maxDate={addDays(new Date(), 90)}
                      id="validationCustomDateOfBirth"
                      className="form-control date-picker-custom"
                      selected={recuringDate.length>0?Date.parse(recuringDate):recuringDate} onChange={(date) => setRecuringDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                      required />
                  </>

                  :
                  ""
                }
                
                  

              </div>
            </CRow>




          </CModalBody>
          <CModalFooter>
            {isSpenierOpen ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Save </CButton>
            }
          </CModalFooter>
        </CForm>
      </CModal>


      <div className="mb-4 text-center" >Meeting Availability</div>
      <div className="mb-4 text-center">
        <span className="mygrationhubtext" >
          <span>{selectedTimezone}</span>
        </span>
      </div>
      <div className="mb-4 text-center" >
        <CButton color="dark" size="sm" variant="outline" onClick={syncMySchedule} >Sync Calander</CButton>
      </div>
      <div className="mb-4 text-center" >
        <CButton color="dark" onClick={openModal} size="sm" variant="outline" >Edit</CButton>
      </div>
    </>
  );
}

export default Scheduling