import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Vortex } from 'react-loader-spinner'
import axios from '../api/axios';
import { confirmAlert } from 'react-confirm-alert';
import { CButton, CCol, CContainer } from '@coreui/react'
import '../assets/css/casefile.css'

const CaseProfile = (props) => {

  const { key } = useParams();
  const [data, setData] = React.useState("");
  const [extension, setExtension] = React.useState("");
  const [isSpenierOpen, setIsSpenierOpen] = React.useState(false);
  const [isSpenierOpenForButton, setIsSpenierOpenForButton] = React.useState(false);
  const [isButtonShow, setIsButtonShow] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const allowedTypes = ["data:application/pdf;base64", "data:image/jpeg;base64", "data:image/jpg;base64", "data:image/gif;base64", "data:image/png;base64"];


  function approved() {
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        token: key,
        statusId: 6,

      }

      setIsSpenierOpenForButton(true);
      axios.post("/userAccept", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: 'Your Information is saved successfully', buttons: [{ label: 'OK', }] });
          setIsSpenierOpenForButton(false);
          setIsButtonShow(false);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: 'Something is wrong.please contact with connect@mygrationhub.com', buttons: [{ label: 'OK', }] });
          setIsSpenierOpenForButton(false);
        }
        setIsSpenierOpenForButton(false);

      }, fail => {
        console.error(fail);
        setIsSpenierOpenForButton(false);
      });
    }

    catch (err) {
      //alert(err);
    }

  }


 function reject() {
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        token: key,
        statusId: 7,

      }

      setIsSpenierOpenForButton(true);
      axios.post("/userAccept", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: 'Your Information is saved successfully', buttons: [{ label: 'OK', }] });          
          setIsSpenierOpenForButton(false);
          setIsButtonShow(false);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: 'Something is wrong.please contact with connect@mygrationhub.com', buttons: [{ label: 'OK', }] });
          setIsSpenierOpenForButton(false);
        }
        setIsSpenierOpenForButton(false);

      }, fail => {
        console.error(fail);
        setIsSpenierOpenForButton(false);
      });
    }
    catch (err) {
      //alert(err);
      //confirmAlert({ title: 'MyGration Hub',  message: 'Something is wrong.please contact with connect@mygrationhub.com', buttons: [ { label: 'OK', }  ] });
    }

  }

  useEffect(() => {
    // let isMounted = true;
    const controller = new AbortController();

    const getUserDetails = async () => {

      const formData = {
        token: key
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      await axios.post("/getCaseProfile", JSON.stringify(formData), { headers: headers }).then((response) => {
        if (response.status === 200) {
          setData(response?.data?.code);
          setExtension(response?.data?.extension);
          setFileName(response?.data?.fileName);
          setIsSpenierOpen(false);
          setIsButtonShow(response?.data?.isButtonShow);
        }
        else {
          setIsSpenierOpen(false);
          confirmAlert({ title: 'MyGration Hub', message: 'Something is wrong.please contact with connect@mygrationhub.com', buttons: [{ label: 'OK', }] });
        }

      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: 'Something is wrong.please contact with connect@mygrationhub.com', buttons: [{ label: 'OK', }] });
      });

    }
    getUserDetails();

    return () => {
      // isMounted = false;
      controller.abort();
    }
  }, []);


  return (
    <section>
      <div className='update-info'>
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CCol md={12} className='font-image' style={{textAlign:"center"}}>
              {
                isButtonShow ? (

                  <>
                    Dear Valued Client - Please review your case profile & book an appointment for consultation.<br /><br />
                    {isSpenierOpenForButton ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> :
                      <>
                        <CButton color="success" onClick={approved}>Reviewed</CButton> &nbsp; &nbsp; &nbsp;
                        <CButton color="danger" onClick={reject}>Discuss Later</CButton>
                      </>
                    }
                  </>


                ) : ''}
            </CCol>
            <CCol md={12}>

              {
                isSpenierOpen ?
                  <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                  :
                  allowedTypes.includes(extension) ?
                    <object name={fileName} aria-label="test" id="documentObjectiveId" data={data + "#zoom=Autometic Zoom&scrollbar=0&toolbar=0&navpanes=0"} width="100%" height="500" className="objectShowClass" ></object>
                    :
                    ""

              }
            </CCol>

          </CContainer>
        </div>
      </div>
    </section>



  )



}

export default CaseProfile
