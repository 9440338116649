import React from "react";


const Events = () => {


    return (
        <>
            <section>
                Coming soon...............
            </section>
        </>

    )
}

export default Events;
