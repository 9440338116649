import React,{useState}
// , {useRef} 
from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo/logo.png'
// import logo1 from '../assets/images/logo/test.png'

const MenuHeader = () => {
    // const ref = useRef(null);

    // const handleClick = () => {
    //     ref.current?.scrollIntoView({ behavior: 'smooth' });
    // };

    const [isActive, setIsActive] = useState(false);
    const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
    const [ismigrationOpen, setIsmigrationOpen] = useState(false);
    const [isWorkOpen, setIsWorkOpen] = useState(false);
    const [isStadyOpen, setIsStadyOpen] = useState(false);
    const [isVisitOpen, setIsVisitOpen] = useState(false);
    const [isBusinessOpen, setIsBisinessOpen] = useState(false);
    const [isConnectOpen, setIsConnectOpen] = useState(false);

  const handleClick = event => {
    event.preventDefault();
    setIsActive(current => !current);
  };

  const aboutUshandleClick = event => {
    event.preventDefault();
    setIsAboutUsOpen(current => !current);
  };

  const imigrationClick = event => {
    event.preventDefault();
    setIsmigrationOpen(current => !current);
  };

  const workClick = event => {
    event.preventDefault();
    setIsWorkOpen(current => !current);
  };

  const stadyClick = event => {
    event.preventDefault();
    setIsStadyOpen(current => !current);
  };

  const visitClick = event => {
    event.preventDefault();
    setIsVisitOpen(current => !current);
  };


  const businessClick = event => {
    event.preventDefault();
    setIsBisinessOpen(current => !current);
  };



  const connectClick = event => {
    event.preventDefault();
    setIsConnectOpen(current => !current);
  };

  const menuHide = event => {
    setIsActive(false);
    setIsAboutUsOpen(false);
  };


    return (
        <div>

            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">

                                <a href="/" className="logo">
                                    {/* MyGration Hub */}
                                    <img src={logo} alt=""/>
                                </a>

                                <ul className={isActive ? 'nav dblock' : 'nav'}>
                                    
                                    <li className={isAboutUsOpen ? 'has-sub is-open-sub' : 'has-sub'}> 
                                        <Link onClick={aboutUshandleClick}>About Us</Link>
                                        <ul className={isAboutUsOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/about-us"onClick={menuHide}>About MyGrationHub</Link>
                                            </li>
                                            <li>
                                                <Link to="/core-expertise" onClick={menuHide}>Core Expertise</Link>
                                            </li>
                                            <li>
                                                <Link to="/team" onClick={menuHide}>Our Team</Link>
                                            </li>
                                            
                                            <li>
                                                <Link to="/success-stories" onClick={menuHide}>Our Success Stories</Link>
                                            </li>
                                        </ul>

                                    </li>

                                    <li className={ismigrationOpen ? 'has-sub is-open-sub' : 'has-sub'}>
                                        <Link to="/" onClick={imigrationClick}>Immigration</Link>
                                        <ul className={ismigrationOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/canada" onClick={menuHide}>Canada</Link>
                                            </li>
                                            <li>
                                                <Link to="/australia" onClick={menuHide}>Australia</Link>
                                            </li>
                                            <li>
                                                <Link to="/usa" onClick={menuHide}>USA</Link>
                                            </li>
                                            <li>
                                                <Link to="/uk" onClick={menuHide}>UK</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className={isWorkOpen ? 'has-sub is-open-sub' : 'has-sub'}>
                                        <Link to="/" onClick={workClick}>Work</Link>
                                        <ul className={isWorkOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/work-canada" onClick={menuHide}>Working in Canada</Link>
                                            </li>
                                            <li>
                                                <Link to="/work-canada-permit" onClick={menuHide}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Work Permit Process</Link>
                                            </li>
                                            <li>
                                                <Link to="/work-canada-business-visitors" onClick={menuHide}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Business Visitors</Link>
                                            </li>
                                            <li>
                                                <Link to="/work-canada-lmia-based" onClick={menuHide}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LMIA-Based Work Permits</Link>
                                            </li>
                                            <li>
                                                <Link to="/work-australia" onClick={menuHide}>Working in Australia</Link>
                                            </li>
                                            <li>
                                                <Link to="/work-usa" onClick={menuHide}>Working in USA</Link>
                                            </li>
                                            <li>
                                                <Link to="/work-uk" onClick={menuHide}>Working in UK</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={isStadyOpen ? 'has-sub is-open-sub' : 'has-sub'}>
                                        <Link to="/" onClick={stadyClick}>Study</Link>
                                        <ul className={isStadyOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/stady-canada" onClick={menuHide}>Study in Canada</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/" onClick={menuHide}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Programs & Areas of Study</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/stady-canada-permit-process" onClick={menuHide}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Study Permit Process</Link>
                                            </li>
                                            <li>
                                                <Link to="/stady-canada-pr" onClick={menuHide}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Study Pathway to PR</Link>
                                            </li>
                                            <li>
                                                <Link to="/stady-australia" onClick={menuHide}>Study in Australia</Link>
                                            </li>
                                            <li>
                                                <Link to="/stady-usa" onClick={menuHide}>Study in USA</Link>
                                            </li>
                                            <li>
                                                <Link to="/stady-uk" onClick={menuHide}>Study in UK</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={isVisitOpen ? 'has-sub is-open-sub' : 'has-sub'}>
                                        <Link to="/" onClick={visitClick}>Visit</Link>
                                        <ul className={isVisitOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/">Australia</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Canada</Link>
                                            </li>
                                            <li>
                                                <Link to="/">USA</Link>
                                            </li>
                                            <li>
                                                <Link to="/">UK</Link>
                                            </li>
                                            <li>
                                                <Link to="/">EU</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className={isBusinessOpen ? 'has-sub is-open-sub' : 'has-sub'}>
                                        <Link to="/" onClick={businessClick}>Business</Link>
                                        <ul className={isBusinessOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/">Business in Canada</Link>
                                            </li>
                                            <li>
                                                <Link to="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Investor Programs</Link>
                                            </li>
                                            <li>
                                                <Link to="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entrepreneur Programs</Link>
                                            </li>
                                            <li>
                                                <Link to="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Self Employed Programs</Link>
                                            </li>
                                            <li>
                                                <Link to="/">Business Visa - Australia</Link>
                                            </li>
                                            <li>
                                                <Link to="/">US Business Visa</Link>
                                            </li>
                                            <li>
                                                <Link to="/">UK Business Immigration</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        {/* <Link to="/#news"></Link> */}
                                        <a href="#news" onClick={menuHide}>News</a>
                                    </li>

                                    <li className={isConnectOpen ? 'has-sub is-open-sub' : 'has-sub'}>
                                        <Link to="/" onClick={connectClick}>Connect With Us</Link>
                                        <ul className={isConnectOpen ? 'sub-menu dblock cblack' : 'sub-menu'}>
                                            <li>
                                                <Link to="/register" onClick={menuHide}>Connect With Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/agent-login" onClick={menuHide}>Partner Login</Link>
                                            </li>
                                            <li>
                                                <Link to="/client-login" onClick={menuHide}>Client Login</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <a href="#top" className={isActive ? 'menu-trigger active' : 'menu-trigger'} onClick={handleClick}>
                                    <span>Menu</span>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

        </div>

    )
}

export default React.memo(MenuHeader) 