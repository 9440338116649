import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { confirmAlert } from 'react-confirm-alert';
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { CForm, CFormSelect, CButton, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CFormCheck, CCard, CCardBody, CCol, CInputGroup, CInputGroupText, CFormLabel, CFormSwitch, CFormFeedback, CFormTextarea, CCardHeader, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react'
import { Vortex } from 'react-loader-spinner'
// import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import '../../src/assets/css/custom.css';
import DatePicker from "react-datepicker";
import avatar8 from '../assets/images/avatars/avater.png';
import { useNavigate } from 'react-router-dom';


const ClientProfileStatus = (props) => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [pictureContent, setPictureContent] = useState(avatar8);


  function openModal() {
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }




  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getProfilePic = async () => {
      try {
        let path = "/downloadProfilePicture";
        axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            if (response?.data !== null && response?.data !== "" && response?.data !== 'undefine') {
              setPictureContent(response?.data?.code);
            }
          }

        }, fail => {

        });;

      } catch (err) {
        console.log(err);
      }
    }

    getProfilePic();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [props.meetingCounter]);

  function onclickToAnother(page) {
    navigate(page, { replace: true });
  }

  return (
    <>



      {/* Meeting List view */}
      <CModal backdrop="static" visible={visible} onClose={() => closeModal()} aria-labelledby="StaticBackdropExampleLabelView" size="lg">
        <CModalHeader onClose={() => closeModal()}> <CModalTitle id="LiveDemoExampleLabel" >Profile Status</CModalTitle></CModalHeader>
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form"  >
          <CModalBody>
            <CRow className="mb-12">
              <div className="col-sm-12" style={{ backgroundColor: 'gray' }}>
              </div>
            </CRow>


            {(props?.profileData !== null)
              ? (
                <div>
                  <div>
                    <CTable align="middle" className="mb-0 border" hover responsive style={{ width: "100%" }}>
                      <CTableBody>
                        <CTableRow v-for="item in tableItems" key={1} >
                          <CTableDataCell>
                            Basic Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.basic > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>

                        <CTableRow v-for="item in tableItems" key={2} >
                          <CTableDataCell>
                            Personal Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.personal > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>


                        <CTableRow v-for="item in tableItems" key={3} >
                          <CTableDataCell>
                            Marital Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.marital > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>

                        <CTableRow v-for="item in tableItems" key={4} >
                          <CTableDataCell>
                            Address Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.address > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>


                        <CTableRow v-for="item in tableItems" key={5} >
                          <CTableDataCell>
                            Language Test Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.language > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>

                        <CTableRow v-for="item in tableItems" key={6} >
                          <CTableDataCell>
                            Education Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.education > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>

                        <CTableRow v-for="item in tableItems" key={7} >
                          <CTableDataCell>
                            Work Experience Information
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.work > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>

                        <CTableRow v-for="item in tableItems" key={8} >
                          <CTableDataCell>
                            Profile Picture Upload
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.ppicture > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>

                        <CTableRow v-for="item in tableItems" key={9} >
                          <CTableDataCell>
                            Resume Upload
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.resume > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>
                        <CTableRow v-for="item in tableItems" key={10} >
                          <CTableDataCell>
                            Onboard in MygrationHub
                          </CTableDataCell>
                          <CTableDataCell>
                            {props?.profileData?.acc > 0 ?
                              <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                            }
                          </CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </div>
                </div>
              ) : ""
            }

            <CRow className="mb-12"><div className="col-sm-2">&nbsp;</div></CRow>
          </CModalBody>
          <CModalFooter>
            <CButton color="danger"  onClick={() => closeModal()} > Cancle </CButton>
          </CModalFooter>
        </CForm>
      </CModal>


      {/* Main Code Start */}
      <div className="mb-4 text-center marginBottom15px" >Profile</div>
      <div className="mb-4 text-center marginBottom15px">
        <span className="dppc" >
          <img src={pictureContent} alt="" className="dprofilepic" ></img>
        </span>
      </div>
      <div className="mb-4 text-center marginBottom15px" >
          <span className='cursorPointer'  onClick={(event) => openModal(event)} >{props?.profileData?.total}% Complete</span> 
        </div>
      <div className="mb-4 text-center marginBottom15px" >
        <CButton color="dark" size="sm" onClick={() => onclickToAnother("/home/edit-user/0")} variant="outline" >View & Edit</CButton>
      </div>
    </>
  );
}

export default ClientProfileStatus;