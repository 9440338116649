import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import {CAvatar,  CButton, CTable, CTableBody, CTableDataCell, CTableHead,CTableHeaderCell, CTableRow} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilPeople} from '@coreui/icons'
import moment from 'moment';

const MyClientList = () => {
    const [users, setUsers] = useState();
    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/getMyClientInfo', {
                    signal: controller.signal
                });
                isMounted && setUsers(response.data);
            } catch (err) {
                navigate('/agent-login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    function viewHandleClick(value) {
        localStorage.setItem('details-user-id',value);
        navigate('/home/client-details', { state: { id: value }, replace: true });
    }


    return (
        <article>
            <h2>My Client List</h2>
            {users?.length
                ? (
                    <div>
                        
                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell className="text-center">
                                        <CIcon icon={cilPeople} />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Country of Residance</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Mobile/Email</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Details</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {users?.map((user, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell className="text-center">
                                            
                                            <CAvatar size="md" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus==='3'?'danger':'success'} />
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.fullName}</div>
                                            <div className="small text-medium-emphasis">
                                                <span>{user?.applyStatus=== 3 ? 'New Applied' : 'Approved'}</span> | Apply Date:{moment(user?.dateCreated).format("DD-MM-YYYY")}                                                
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.userResidence?.map((row) => row?.country?.name)}</div>
                                            <div className="small text-medium-emphasis">
                                                ID:{user?.userId}                                                
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>
                                                {user?.mobile}
                                            </div>
                                            <div>
                                                {user?.officeEmail}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {user?.applyStatus===4?"Assessment Ongoing":user?.applyStatus===5?"Info Submitted":user?.applyStatus===6?"Case Profile Reviewed":user?.applyStatus===7?"Discuss Later":"More"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <CButton  type="submit" color="info"   onClick={() => { viewHandleClick(user?.id) }} >View</CButton>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>

                        
                    </div>
                ) : <p>No users to display</p>
            }
        </article>
    )
}
export default MyClientList;
