import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-usa.png';
import Meta from './Meta';

const StadyUsa = () => {
    const title = "The United States government offers three student visa types including F, J, and M.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>
                                    <h3>What you need to do to study in USA</h3>
                                    <p>
                                        The United States government offers three student visa types including F, J, and M.
                                    </p>
                                    <p>
                                        The F-1 student visa is the most common type of student visa for individuals pursuing academic studies and language training programs in the United States. The F-1 visa is intended for full-time students enrolled in an academic or language program at an accredited U.S. institution.
                                    </p>
                                    <p>
                                        The process of obtaining a F-1 student visa for the United States involves several steps. Here is a detailed outline of the typical process:
                                    </p>

                                    <h3>1. Acceptance to a U.S. Institution:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Apply and gain acceptance to a U.S. academic institution that is authorized to enroll international students.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2. Receive Form I-20:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    After acceptance, the U.S. school will provide you with Form I-20, which is the Certificate of Eligibility for Nonimmigrant Student Status.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>3. Pay the SEVIS Fee:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Pay the Student and Exchange Visitor Information System (SEVIS) fee online. You can find information about the fee and payment process on the U.S. Immigration and Customs Enforcement (ICE) website.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>4. Complete the Nonimmigrant Visa Application (DS-160):</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Fill out the DS-160 form online, which is the application for a U.S. visa. You will receive a confirmation receipt with a barcode after completing the form.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>5. Pay the Visa Application Fee:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Pay the non-refundable visa application fee, which varies based on the type of visa and the U.S. embassy or consulate.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>6. Schedule a Visa Interview:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Schedule an appointment for a visa interview at the U.S. embassy or consulate in your country. The waiting time for interview appointments can vary, so it&apos;s advisable to schedule it well in advance.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>7. Attend the Visa Interview:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Attend the visa interview at the U.S. embassy or consulate. Be prepared to provide documentation supporting the purpose of your visit, your ties to your home country, and your ability to cover the costs of your education in the U.S.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>8. Visa Approval and Issuance:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If approved, the U.S. embassy or consulate will issue the F-1 student visa on your passport. Some applicants may be subject to additional administrative processing, which may delay the visa issuance.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>9. Travel to the U.S.:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once the F-1 visa is issued, you can travel to the U.S. Make sure to arrive no earlier than 30 days before the program start date mentioned on your Form I-20.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>10. Attend the U.S. School:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Upon arrival, attend the school mentioned on your Form I-20 and maintain full-time enrollment throughout your academic program.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>11. Optional Practical Training (OPT):</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If eligible, you may apply for Optional Practical Training (OPT) to gain practical work experience in your field of study after completing your program.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p>
                                        It&apos;s crucial to stay updated on the latest immigration regulations, as they may change. Consult the official website of the U.S. Department of State and our registered immigration lawyers are always there to help you. You can book your consultation here.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default StadyUsa