import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { confirmAlert } from 'react-confirm-alert';
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { CForm, CFormSelect, CButton, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CFormCheck, CCard, CCardBody, CCol, CInputGroup, CInputGroupText, CFormLabel, CFormSwitch, CFormFeedback, CFormTextarea } from '@coreui/react'
import { Vortex } from 'react-loader-spinner'
// import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import '../../src/assets/css/custom.css';
import DatePicker from "react-datepicker";


const UpcomingMeeting = (props) => {
  
  const axiosPrivate = useAxiosPrivate();
  const [myUpcomingMeetings, setMyUpcomingMeetings] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [slots, setSlots] = useState([]);

  ///modal add notes
  /* const [noteData, setNoteData] = useState("");
  const [visibleAddNotes, setVisibleAddNotes] = useState(false);
  const [isSpenierOpenAddNotes, setIsSpenierOpenAddNotes] = useState(false);
  const [myMeetings, setMyMeetings] = useState([]);
  const [validatedAddNotes, setValidatedAddNotes] = useState(false);
  const [object, setObject] = useState("");
  function openModalAddNotes(data,i) {
    setIsSpenierOpenAddNotes(false);
    setVisibleAddNotes(true);
    closeModalView();
    setNoteData(data?.callerRemarks===null?"":data.callerRemarks);
    setObject(data);
  }

  function closeModalAddNotes() {
    setIsSpenierOpenAddNotes(false);
    setVisibleAddNotes(false);
    setNoteData("");
    setObject("");
    setValidatedAddNotes(false);
  }

  const handleFormSubmitAddNotes = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      saveAddNotes();
    }
    setValidatedAddNotes(true);
    event.preventDefault();
  }

  async function saveAddNotes() {
    try {
      const formData = {
        id: object?.id,
        userInfoId:0,
        notes: noteData
      }
      setIsSpenierOpenAddNotes(true);
      let path = "saveMyMeetingNotes";
      let message = "Notes saved successfully";
      apicall(formData, path, message);

    } catch (err) {
      console.log(err);
      setIsSpenierOpenAddNotes(false);
    }

  } */

  
  ///modal view
  /* const [visibleView, setVisibleView] = useState(false);
  function openModalView() {
    setVisibleView(true);
    getMyMeeting();

  }

  function closeModalView() {
    setVisibleView(false);  
  }

  async function getMyMeeting() {
    const controller = new AbortController();
    try {
      const path = "/getMyPastMeeting";
      axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
        if (response.status === 200) {
          console.log(response?.data);
          setMyMeetings(response?.data);
        }

      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });;

    } catch (err) {
      confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
    }
  } */




  //Modal Create Meeting
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [duration, setDuration] = useState('30');
  const [participantList, setParticipantList] = useState([]);
  const [participant, setParticipant] = useState("");
  const [link, setLink] = useState("");
  const [frequency, setFrequency] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [slot, setSlot] = useState("");
  const [userScheduel, setUserSchedule] = useState([]);
  const [slotList, setSlotList] = useState([]);

  function openModal() {
    setDuration("30");
    getParticipantList();
    setFrequency("none")
    setMeetingDate("");
    setIsSpenierOpen(false);
    setVisible(true);
  }

  function closeModal() {
    setDuration("");
    setParticipant("")
    setFrequency("")
    setMeetingDate("");
    setVisible(false);
    setValidated(false);
    setIsSpenierOpen(false);
  }

  async function apicall(formData, apiName, message) {
    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (message !== "") {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK' }] });
        }
        if (apiName === "saveMyMeetingNotes") {
          //setIsSpenierOpenAddNotes(false);
        }else if(apiName === "getUserSchedule"){

            //console.log(res?.data);
            setUserSchedule(res?.data);
            setSlotList(res?.data?.slots);

            let vIsChecked = [];
            let vSlots = [];
            res?.data && res?.data?.slots.forEach((v, i) => {
              vIsChecked.push(false);
              vSlots.push(v?.slotName);
            });

            /* setIsChecked(vIsChecked);
            setSlots(vSlots);
            console.log(res?.data?.slots); */
        }else if(apiName === "saveMyMeeting"){

            // console.log(res);
        }
      }
      else {
        setIsSpenierOpen(false);
        // setIsSpenierOpenAddNotes(false);
      }

    }, fail => {
      console.error(fail);
      setIsSpenierOpen(false);
      // setIsSpenierOpenAddNotes(false);
    });
  }

  async function saveMyMeeting() {
    try {
    
      const formData = {
        duration: duration,
        participantId: participant,
        frequency: frequency,
        date:meetingDate,
        slot:slot,
        scheduleId:userScheduel?.id,
        link:link
      }
      setIsSpenierOpen(true);
      let path = "saveMyMeeting";
      let message = "Meeting saved successfully";
      apicall(formData, path, message);

    } catch (err) {
      //console.log(err);
      setIsSpenierOpen(false);
    }

    setIsSpenierOpen(false);

  }

  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      if(slot===""){
        confirmAlert({ title: 'MyGration Hub', message: "Please select Time slot first", buttons: [{ label: 'OK' }] });
        return false;
      }else{
        saveMyMeeting();
      }
    }
    setValidated(true);
    event.preventDefault();
  }

  const setIsCheckedFunction = (onChnageValue, i) => {
    const inputdata = [...isChecked]
    isChecked?.forEach((v, j) => {
      if(i===j){
        inputdata[j] = onChnageValue;
      }else{
        inputdata[j] = false;
      }

    });
    if(onChnageValue===true){
      setSlot(slots[i]);
      //console.log(slots[i]);
    }else{
      setSlot("");
    }
    setIsChecked(inputdata);
  } 
 
    /* const setCheckUnCheck = (onChnageValue, i) => {
    const inputdata = [...isChecked]
    inputdata[i] = onChnageValue;
    setIsChecked(inputdata);

  } */

    const getParticipantList = async () => {
      try {
        const controller = new AbortController();
        const res = await axiosPrivate.get('/getParticipantList', {
          signal: controller.signal
        });
        setParticipantList(res?.data);
        setParticipant(res.data[0]?.id);
      } catch (err) {
        
      }
    }


    async function loadSlot(date) {
      try {
      
        const formData = {
          duration: duration,
          userInfoId: participant,
          date:date
        }  
        // console.log(formData);
  
        let path = "getUserSchedule";
        apicall(formData, path, "");
  
      } catch (err) { }
    }

    const setMeetingDateFunction = (date) => {
    setMeetingDate(date);
    loadSlot(date);
  }


 
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getMyUpcomingMeeting() {
      try {
        const path = "/getMyUpComingMeting";
        axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            console.log(response?.data);
            isMounted && setMyUpcomingMeetings(response?.data);
            response?.data && props.upcmOnStageChange(response?.data?.length);
          }
  
        }, fail => { });;
  
      } catch (err) { }
    }

    getMyUpcomingMeeting();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [props.meetingCounter]);

  const durationList = [
    { value: '15', label: '15 minutes' },
    { value: '30', label: '30 minutes' },
    { value: '45', label: '45 minutes' },
    { value: '60', label: '60 minutes' },
    { value: '75', label: '75 minutes' },
    { value: '90', label: '90 minutes' }
  ]

  const frequencyList = [
    { value: 'None', label: 'None' },
    { value: 'Others', label: 'Others' }
  ]

  return (
    <>
      {/* Meeting Create Modal */}
      <CModal  backdrop="static" visible={visible}  onClose={() => closeModal()}  aria-labelledby="StaticBackdropExampleLabel" size="xl">
        <CModalHeader onClose={() => closeModal()}>
          <CModalTitle id="LiveDemoExampleLabel">Schedule Meeting</CModalTitle>
        </CModalHeader>
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} >
          <CModalBody>
            
            <CRow className="mb-12">
              <div className="col-sm-6"> <span className='fontSize20px'>Duration</span> <span className='red'>*</span>
                <CInputGroup className="has-validation">
                  <CFormSelect size="sm" className="form-control" aria-label="" options={durationList} value={duration} onChange={(event) => { setDuration(event.target.value); }} required ></CFormSelect>
                </CInputGroup>

              </div>
              <div className="col-sm-6"> <span className='fontSize20px'>Mentor/Mentee</span> <span className='red'>*</span>
                <CInputGroup className="has-validation">
                <CFormSelect aria-describedby="validationRelationship" id="validationRelationship" value={participant} onChange={(event) => { setParticipant(event.target.value); }} feedbackInvalid="Required!" required >
                    {participantList && participantList?.map((row) => <option key={row?.id} value={row?.id}  >{row?.name}</option>)}
                  </CFormSelect>
                </CInputGroup>
              </div>
            </CRow>

            <CRow className="mb-12"> <div className="col-sm-2">&nbsp; </div> </CRow>

            <CRow className="mb-12">
              <div className="col-sm-12"> <span className='fontSize20px'>Location</span> <span className='red'>*</span><span> (Please create google meeting and provide link here until intregration)</span>
                <CInputGroup className="has-validation">
                  <CFormInput aria-describedby="validationCustomLink" id="validationCustomLink" feedbackValid="Looks good" feedbackInvalid="Required!"  value={link} onChange={(event) => { setLink(event.target.value); }} required />
                </CInputGroup>
              </div>
            </CRow>

            <CRow className="mb-12"> <div className="col-sm-2">&nbsp; </div> </CRow>

            <CRow className="mb-12">
              <div className="col-sm-6"> <span className='fontSize20px'>Frequency</span>
                <CInputGroup className="has-validation">
                  <CFormSelect aria-describedby="validationFrequency" id="validationFrequency" options={frequencyList} value={frequency} onChange={(event) => { setFrequency(event.target.value); }} feedbackInvalid="Required!" ></CFormSelect>
                </CInputGroup>
              </div>
              <div className="col-sm-6"> <span className='fontSize20px'>Meeting Date</span> <span className='red'>*</span>
                <CInputGroup className="has-validation">
                  <DatePicker
                    showYearDropdown
                    showMonthDropdown
                    yearDropdownItemNumber={10}
                    scrollableYearDropdown
                    id="validationCustomMeetingDate"
                    aria-describedby="validationCustomMeetingDate"
                    minDate={new Date()}
                    className="form-control date-picker-custom"
                    selected={meetingDate} onChange={(date) => setMeetingDateFunction(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                    required
                  />
                </CInputGroup>

              </div>
            </CRow>

            <CRow className="mb-12"> <div className="col-sm-2">&nbsp; </div> </CRow>

            <CRow className="mb-12">
              
              <div className="col-sm-12"> <span className='fontSize20px'>Time Slot</span> <span className='red'>*</span>
                <div>

                  {(meetingDate !== null || meetingDate !== "") ?
                    <>
                      {slotList?.map((data,i)=>(
                        <span className='slotCheckBox' key={i}>
                          <CFormCheck className='' button={{ color: 'dark', variant: 'outline' }} id={"btn-check-outlined-"+i} autoComplete="off" label={data?.slotName}  checked={isChecked[i]} onChange={(event) => { setIsCheckedFunction(event.target.checked,i); }}/> &nbsp;
                        </span>
                              
                      ))}
                    </>
                    :
                    <>
                      <div>Please select a date</div>
                    </>
                  }
                </div>
              </div>
            </CRow>

          </CModalBody>
          <CModalFooter>
            {isSpenierOpen ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Save </CButton>
            }
          </CModalFooter>
        </CForm>
      </CModal>


      {/* Meeting List view */}
      {/* <CModal backdrop="static"  visible={visibleView} onClose={() => closeModalView()} aria-labelledby="StaticBackdropExampleLabelView" size="lg">
        <CModalHeader onClose={() => closeModalView()}> <CModalTitle id="LiveDemoExampleLabel" >Scheduled Meeting</CModalTitle></CModalHeader>
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} >
          <CModalBody>
            <CRow className="mb-12">
              <div className="col-sm-12" style={{ backgroundColor: 'gray' }}>
              </div>
            </CRow>

            {myMeetings?.map((data, i) => (
              <>
                <CCard className="col-sm-12" key={i}>
                  <CCardBody className="col-sm-12">

                    <CRow className="mb-12">
                      <div className="col-sm-6">
                        <div className="col-sm-12 fontSize20px">Date and Time</div>
                        <div className="col-sm-12">{data?.startTime}</div>
                      </div>

                      <div className="col-sm-6">
                        <div className="col-sm-12 fontSize20px">Mentor/Mentee/Group</div>
                        <div className="col-sm-12">
                          <CFormSelect id="validationCustomMentee" >
                            <option value={data?.meetingCaller?.id}>{data?.meetingCaller?.fullName}</option>
                          </CFormSelect>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="col-sm-12">Locacation</div>
                        <div className="col-sm-12 link">
                          {data?.link}
                          </div>
                      </div>

                      <div className="col-sm-12 text-right">
                        <CButton color="dark" size="sm" variant="outline" onClick={() => openModalAddNotes(data,i)}>Add Meeting Notes</CButton>
                      </div>

                    </CRow>
                  </CCardBody>
                </CCard >
              </>

            ))}

            <CRow className="mb-12"><div className="col-sm-2">&nbsp;</div></CRow>
          </CModalBody>
          <CModalFooter>
            {isSpenierOpen ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Save </CButton>
            }
          </CModalFooter>
        </CForm>
      </CModal> */}


      {/* Meeting Notes modal */}
      {/* <CModal backdrop="static" visible={visibleAddNotes} onClose={() => closeModalAddNotes()} aria-labelledby="StaticBackdropExampleLabelAddNodes" size="lg" >
        <CModalHeader onClose={() => closeModalAddNotes()}> <CModalTitle id="LiveDemoExampleLabel" >Notes</CModalTitle></CModalHeader>
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validatedAddNotes} onSubmit={handleFormSubmitAddNotes} >
          <CModalBody>
            <CRow className="mb-12">
              <div className="col-sm-12">
                Enter notes about your mentoring meeting to help you remember the important details discussed. These note are only seen by you.
              </div>
            </CRow>

            <CRow className="mb-12">
              <div className="col-sm-12">
                &nbsp;
              </div>
            </CRow>

            <CRow className="mb-12">
            <div className="col-sm-12">
              <CFormTextarea rows={5}  id="validationCustomRelation"  feedbackInvalid="Required!" value={noteData} onChange={(event) => { setNoteData(event.target.value); }} required/>
              </div>
            </CRow>

          </CModalBody>
          <CModalFooter>
            {isSpenierOpenAddNotes ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <>
              <CButton color="danger" onClick={() => closeModalAddNotes()}> Cancle </CButton>
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Save </CButton>
              </>
            }
          </CModalFooter>
        </CForm>
      </CModal> */}

      {/* Main Code Start */}        

      <CRow className="mb-12">
        <div className="col-sm-12">
          {/* <div className="col-sm-12 fontSize20px">Upcoming Meetings</div> */}
          <h2>Upcoming Meetings ({myUpcomingMeetings?.length > 0 ? myUpcomingMeetings.length : 0}) </h2>
        </div>
      </CRow>

      {myUpcomingMeetings && myUpcomingMeetings?.map((data, i) => (
              <div key={i}>
                <CCard className="col-sm-12" key={i}>
                  <CCardBody className="col-sm-12">

                    <CRow className="mb-12">
                      <div className="col-sm-6">
                        <div className="col-sm-12 fontSize20px">Date and Time</div>
                        <div className="col-sm-12">{data?.sdate}</div>
                      </div>

                      <div className="col-sm-6">
                        <div className="col-sm-12 fontSize20px">Mentor/Mentee/Group</div>
                        <div className="col-sm-12">
                          <CFormSelect id="validationCustomMentee" >
                            <option value={data?.meetingParticipantId}>{data?.meetingParticipantName}</option>
                          </CFormSelect>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="col-sm-12">Locacation</div>
                        <div className="col-sm-12 link">
                          {data?.link}
                          </div>
                      </div>

                      <div className="col-sm-12 text-right">
                            <CIcon icon={cilUser} customClassName="icon" /> {data?.meetingCallerName}
                      </div>

                    </CRow>
                  </CCardBody>
                </CCard >
              </div>

            ))}
    </>
  );
}

export default UpcomingMeeting;