import React from 'react'
import { Helmet } from 'react-helmet';
import logo from '../assets/images/logo/logo.png';

const url = "https://mygrationhub.com";
// const site_name = "MyGrationHub"
const title = "MyGrationHub";
const keywords = "Migration hub, migrationhub,MygrationHub,MyGration Hub, My Gration Hub";
const description = "Navigate the world with ease - Trusted consultancy for immigration, education, and travel";
// const type = "website";
// const iwidth = 600;
// const iheight = 315;
const image = url + logo;


const Meta = (props) => {

    /* document.title = props?.title!==""?props.title:title;
    document.getElementsByTagName('meta')["title"].content = props?.title!==""?props.title:title;
    document.getElementsByTagName('meta')["keywords"].content = props?.keywords!==""?props.keywords:keywords;
    document.getElementsByTagName('meta')["description"].content = props?.description!==""?props.description:description;
 
    document.querySelectorAll('meta[property=og\\:title]')[0].setAttribute('content', props?.title!==""?props.title:title);
    document.querySelectorAll('meta[property=og\\:description]')[0].setAttribute('content', props?.description!==""?props.description:description);
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', props?.image!==""?props.image:image);
    document.querySelectorAll('meta[property=og\\:image\\:width]')[0].setAttribute('content', props?.iwidth!==""?props.iwidth:iwidth);
    document.querySelectorAll('meta[property=og\\:image\\:height]')[0].setAttribute('content', props?.iheight!==""?props.iheight:iheight);

    document.querySelectorAll('meta[name=twitter\\:title]')[0].setAttribute('content', props?.title!==""?props.title:title);
    document.querySelectorAll('meta[name=twitter\\:description]')[0].setAttribute('content', props?.description!==""?props.description:description);
    document.querySelectorAll('meta[name=twitter\\:image]')[0].setAttribute('content', props?.image!==""?props.image:image);
 
 */

    return (


       <Helmet>
            {/* <meta charSet="utf-8" />
            <link rel="canonical" href={url} />
            <meta property="og:site_name" content={site_name}></meta>
            <meta property="og:url" content={url}></meta>
            <meta property="og:type" content={props?.type!==""?props.type:type}></meta>
            <meta property="og:image:width" content={props?.iwidth!==""?props.iwidth:iwidth}></meta>
            <meta property="og:image:height" content={props?.iheight!==""?props.iheight:iheight}></meta>
            <meta property="fb:app_id" content=""></meta>
            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content={site_name}></meta>
            <meta name="twitter:url" content={url}></meta>
            <meta name="twitter:creator" content={props?.title!==""?props.title:title}></meta> */}

            <title>{props?.title!==""?props.title:title}</title>

            <meta name="title" content={props?.title!==""?props.title:title}></meta>
            <meta name="keywords" content={props?.keywords!==""?props.keywords:keywords}></meta>
            <meta name="description" content={props?.description!==""?props.description:description}></meta>

            <meta property="og:title" content={props?.title!==""?props.title:title}></meta>
            <meta property="og:image" content={props?.image!==""?props.image:image}></meta>
            <meta property="og:description" content={props?.description!==""?props.description:description}></meta>
            
            <meta name="twitter:title" content={props?.title!==""?props.title:title}></meta>
            <meta name="twitter:description" content={props?.description!==""?props.description:description}></meta>
            <meta name="twitter:image" content={props?.image!==""?props.image:image}></meta>

        </Helmet>


    )
}

export default Meta