import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, Link, useParams } from "react-router-dom";
import { CForm, CCol, CFormLabel, CInputGroup, CInputGroupText,  CButton,  CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput } from '@coreui/react'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import '../assets/css/userDetails.css'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody } from 'mdb-react-ui-kit';
import avater from '../assets/images/avatars/avater.png';
import generatePDF, { Options } from "react-to-pdf";

export default function UserDetails() {

  const { key } = useParams();

  const [userDetails, setUsersDetails] = useState();
  const axiosPrivate = useAxiosPrivate();
  // const location = useLocation();
  const [isShowButton, setIsShowButton] = React.useState(true)
  const [isShowMessage, setIsShowMessage] = React.useState(false)
  const navigate = useNavigate();
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);

  const [isModalSpenierOpen, setIsModalSpenierOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [userName, setUserName] = useState("");
  const [clientId, setClientId] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [pictureContent, setPictureContent] = useState(avater);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    // const detailsUserId = localStorage.getItem('details-user-id');
    const getUserDetails = async () => {
      try {
        // const path = `/getUserInfo/${location?.state?.id ? location.state.id : detailsUserId}`;
        const path = `/getUserInfo/${key}`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });
        isMounted && setUsersDetails(response.data);
        setClientId(response?.data?.id);
        setClientName(response?.data?.fullName);
        setUserName(response?.data?.officeEmail);
        setIsEnabled(response?.data?.isEnabled);
        if (response?.data.picture !== null && response?.data?.picture !== "" && response?.data?.picture !== 'undefine') {
          setPictureContent(response?.data?.picture);
        }

      } catch (err) {
        confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
      }
    }

    getUserDetails();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [key]);


  async function getResume(event) {
    event.preventDefault();
    downloadFile(userDetails?.id, userDetails.resume);
  }

  async function downloadFile(key, fileName) {
    setIsResumeSpinOpen(true);

    const formData = {
      key: key,
      fileName: fileName,
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    await axiosPrivate.post("/downloadFile", JSON.stringify(formData), { headers: headers }).then((response) => {
      if (response.status === 200) {
        setIsResumeSpinOpen(false);
        const linkSource = response?.data?.code;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
        setIsResumeSpinOpen(false);
      }
    }, fail => { setIsResumeSpinOpen(false); });

  }

  async function approved(event) {
    event.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        id: userDetails.id,
        userName: userDetails.userName,
        status: 4

      }
      console.log(formData);
      setIsSpenierOpen(true);
      await axiosPrivate.post("/updateStatus", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Approved successfully", buttons: [{ label: 'OK' }] });
          setIsShowButton(false);
          setIsShowMessage(true);
        }
        else {
          alert("Please try again after sometimes later");
        }
        setIsSpenierOpen(false);

      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
    }

  }

  async function rejected(event) {
    event.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        id: userDetails.id,
        userName: userDetails.userName,
        status: 2

      }

      console.log(formData);
      setIsSpenierOpen(true);
      await axiosPrivate.post("/updateStatus", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          alert("Rejected successfully");
          navigate('/home/pending-users');
        }
        else {
          alert("Please try again after sometimes later");
        }
        setIsSpenierOpen(false);
      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
    }

  }

  const [isResumeSpinOpen, setIsResumeSpinOpen] = React.useState(false);
  const [isDownloadSpinOpen, setIsDownloadSpinOpen] = React.useState(false);


  function viewHandleClick(value) {
    localStorage.setItem('edit-user-id', value);
    localStorage.setItem('back-page', '/home/user-details');
    navigate('/home/edit-user/'+value, { state: { id: value, backPage: '/home/user-details' }, replace: true });
  }

  function viewHandleAgentView(value) {
    localStorage.setItem('details-user-id', value);
    navigate('/home/client-details', { state: { id: value }, replace: true });
  }


  //modal

  const [password, setPassword] = useState("abcd1234@");
  const [visible, setVisible] = useState(false)
  const [messageColor, setMessageColor] = useState("green");
  const messageRef = useRef();
  const [message, setMessage] = useState("");

  function openModal(name, id, selectedId) {
    setMessage('');
    setValidated(false);
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }



  const [validated, setValidated] = useState(false);
  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      const formData = {
        id: clientId,
        userName: userName,
        password: password
      }
      apicall(formData, "enableClient", "Client enabled successfully");
    }
    setValidated(true);
    event.preventDefault();
  }

  function disableClientFromSyntem() {
    const formData = {
      id: clientId,
      userName: userName,
      password: password
    }
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to disable " + userDetails?.fullName + " from mygrationhub ?",
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "disableClient", "Client disabled successfully")
      },
      {
        label: 'No',
      }]
    });
  }

  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));
    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (apiName === 'enableClient') {
          setIsModalSpenierOpen(false);
          setMessageColor("green");
          setMessage(message);
          messageRef.current.focus();
          setIsEnabled(true);
        } else if (apiName === 'disableClient') {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
          setIsEnabled(false);
        }
      }
      else {
        if (apiName === 'enableClient') {
          setIsModalSpenierOpen(false);
          setMessageColor("red");
          setMessage('Refresh this page and try again later');
          messageRef.current.focus();
        } else if (apiName === 'disableClient') {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again later", buttons: [{ label: 'OK', }] });
        }
      }

    }, fail => {
      if (apiName === 'enableClient') {
        setIsModalSpenierOpen(false);
        setMessageColor("red");
        setMessage('Refresh this page and try again later');
        messageRef.current.focus();
      } else if (apiName === 'disableClient') {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again later", buttons: [{ label: 'OK', }] });
      }
    });
  }


  function downloadPicture(){
    const downloadLink = document.createElement("a");
    let ex = pictureContent.split(";")[0].split("/")[1];
    downloadLink.href = pictureContent;
    downloadLink.download = userDetails?.fullName+"."+ex;
    downloadLink.click();

  }



  const options = {
    filename: userDetails?.fullName+".pdf",
    page: {
      margin: 10,
    }
  };

const getTargetElement = () => document.getElementById("container");

  const downloadPdf = () => {
    setIsDownloadSpinOpen(true);
    generatePDF(getTargetElement, options);
    setTimeout(() => {
      setIsDownloadSpinOpen(false);
    }, 2000);
  }
  


  return (
    <section style={{ backgroundColor: '#fff' }}>

      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel" >

        <CModalHeader onClose={() => closeModal()}>
          <CModalTitle id="LiveDemoExampleLabel">Client Enable To Mygrationhub</CModalTitle>
        </CModalHeader>
        <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" validated={validated} onSubmit={handleFormSubmit} >
          <CModalBody>
            <CCol md={12}>
              <CFormLabel htmlFor="validationClientName">
                <p ref={messageRef} className={messageColor + " font-size-20px"} aria-live="assertive">&nbsp; {message} &nbsp;</p>
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CInputGroupText>Client Name</CInputGroupText>
                <CFormInput type="text" aria-describedby="validationClientName" id="validationClientName" value={clientName} disabled feedbackInvalid="Required!" required></CFormInput>
              </CInputGroup>
            </CCol>

            <CCol md={12}>
              <CFormLabel htmlFor="validationClientName">
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CInputGroupText>Username</CInputGroupText>
                <CFormInput type="text" aria-describedby="validationClientName" id="validationClientName" value={userName} disabled feedbackInvalid="Required!" required></CFormInput>
              </CInputGroup>
            </CCol>

            <CCol md={12}>
              <CFormLabel htmlFor="validationCustomAddressPostal">Password (Default abcd1234@)<span className='red'>*</span></CFormLabel>
              <CInputGroup className="has-validation">
                <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                <CFormInput type="password" id="validationCustomAddressPostal" feedbackValid="Looks good" feedbackInvalid="Required!" value={password} onChange={(event) => { setPassword(event.target.value); }} required={true} />
              </CInputGroup>
            </CCol>


          </CModalBody>
          <CModalFooter>
            {isModalSpenierOpen ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Enable </CButton>
            }
          </CModalFooter>
        </CForm>
      </CModal>







      {/* isResumeSpinOpen ?
                          <Vortex visible={true} height="40" width="40" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                          :
                          <Link onClick={getResume}>Download</Link>
                        : */}


      <h2 className="padding10">User Details  
        <div className="floatRight" >
          <CButton color="info" onClick={() => { viewHandleAgentView(userDetails?.id) }} > <i className="fa fa-tasks"></i> </CButton> &nbsp;
          <CButton color="success" onClick={() => { viewHandleClick(userDetails?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
          {isDownloadSpinOpen ?
            <Vortex visible={true} height="40" width="40" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
            :
            <CButton color="primary" onClick={downloadPdf}><i className="fa fa-download"></i></CButton>
          }
          
        </div>
      </h2>
      <MDBContainer id="container" className="py-5">
        <MDBRow>
          <MDBCol lg="12" >
            {/* {(userDetails?.applyStatus === 4 || isShowMessage) ? <p> www.mygrationhub.com/#/update-info/{userDetails?.id}/{userDetails?.officeEmail}/{userDetails?.firstName}/{userDetails?.lastName}/{userDetails?.mobile}</p> : ""} */}
            {(userDetails?.applyStatus === 4 || isShowMessage) ? <p> www.mygrationhub.com/#/update-info/{userDetails?.userId}</p> : ""}
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="12" >
            {(userDetails?.refUserInfo !== null) ? <p>Referred by: {userDetails?.refUserInfo?.fullName} ({userDetails?.refUserInfo?.userId})</p> : ""}
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="4" >
            <MDBCard className="mb-4" style={{ height: '345px' }}>
              <MDBCardBody className="text-center">
                <img src={pictureContent} alt="" onClick={downloadPicture} className="detailsPicture" ></img>

                <p className="text-muted mb-1">{userDetails?.fullName}</p>
                <p className="text-muted mb-2">
                  {userDetails?.userResidence?.map((row) => row?.country?.name)}
                </p>
                <p className="text-muted mb-4">
                  {userDetails?.userId}
                </p>
                <div className="d-flex justify-content-center mb-2">
                  {userDetails?.applyStatus < 4 && isShowButton ? (

                    isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> :
                      <>
                        <CButton color="success" onClick={approved}>Accept</CButton> &nbsp; &nbsp; &nbsp;
                        <CButton color="danger" onClick={rejected}>Reject</CButton>
                      </>

                  ) : isEnabled ? <CButton color="danger" onClick={disableClientFromSyntem}>Disable From System</CButton> : <CButton color="success" onClick={openModal}>Enable To System</CButton>}


                  <br />
                </div>
              </MDBCardBody>
              <br />
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>First Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.firstName}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Last Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.lastName}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.officeEmail}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>WhatsApp</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.whatsApp}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Imo</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.imo}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Viber</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.viber}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.sex}</MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Merital Status</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.maritalStatus?.name}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>Date Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.dob ? moment(userDetails?.dob).format("DD-MM-YYYY") : ''}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Family Member</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.familyMemberNo > 0 ? userDetails.familyMemberNo : ''}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>Resume</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      :
                      {userDetails?.resume ?
                        isResumeSpinOpen ?
                          <Vortex visible={true} height="40" width="40" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                          :
                          <Link onClick={getResume}>Download</Link>
                        :
                        ""
                      }
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>



        <MDBRow>
          <MDBCol md={6}>

            <MDBCard className="mb-4 mb-md-0" style={{ height: 'auto' }}>
              <MDBCardBody>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Country of Residence</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.userResidence?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Consultation Requirement</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.userConsultation?.map((row) => <li key={row?.id}>{row?.consultation?.name}</li>)}
                  </ul>
                </MDBCardText>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Interested Country</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.interestedCountry?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">List of Countries you visited in last 10 years</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.visitedCountry?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>

              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4 mb-md-0" style={{ height: 'auto' }}>
              <MDBCardBody>
                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Address Information</span></MDBCardText>
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.address?.map((row) => (
                      <div key={row?.id}>
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Address Line 1</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.address}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Address Line 2</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.addressLineTwo}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>City</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.city}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>State / Province</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.state}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Postal / Zip Code</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.postal}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                      </div>
                    ))}
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Language Test Information</span></MDBCardText>

                {userDetails?.languageTest?.map((row, i) => (

                  <div key={row.id}>

                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Language Test Type</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.languageTestType?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Language Test Version</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.languageTestVersion?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of Language Test</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.date ? moment(row?.date).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of test results</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.resultDate ? moment(row?.resultDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Certificate number</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.certificateNo}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="12">
                        <MDBCardText>Language Test Score </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Listening </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.listeningScore}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Reading </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.readingScore}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Writing</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.writingScore}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Speaking</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.speakingScore}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Overall</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.overall}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">

                      </MDBCol>
                      <MDBCol sm="3">

                      </MDBCol>
                    </MDBRow>


                  </div>

                ))}


              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Spouse Information</span></MDBCardText>

                {userDetails?.spouseInfo?.map((row, i) => (

                  <div key={row.id}>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Spouse First Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.firstName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Spouse Last Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.lastName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of Marriage</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.marriageDate ? moment(row?.marriageDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Have you been previously married?</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.previouslyMarried === true ? "Yes" : "No"}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

          </MDBCol>


          <MDBCol md={6}>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Personal Information</span></MDBCardText>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Country Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.birthCountry.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>City Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.birthCity}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Have you ever used any other name?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.isOtherNameUsed ? userDetails?.personaInfo?.isOtherNameUsed === true ? "Yes" : "No" : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport (Country/territory of issue)</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportIssueCountry?.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport Issue date </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportIssueDate ? moment(userDetails?.personaInfo?.passportIssueDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport Expiry date</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportExpireDate ? moment(userDetails?.personaInfo?.passportExpireDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Applied before for your interested country?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.isCaAppliedBefore === true ? "Yes" : userDetails?.personaInfo?.isCaAppliedBefore === false ? "No" : ""}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Any Family/Friends in the desired country?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.haveAnyCaRelative === true ? "Yes" : userDetails?.personaInfo?.haveAnyCaRelative === false ? "No" : ""}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Relations with this Family/Friend</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.caRelativeRelation}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Province or Territory where he/she live</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.caRelativeAddress}</MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Educational Information</span></MDBCardText>
                {userDetails?.educationalInfo?.map((row, i) => (

                  <div key={row.id} >
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Level</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.educationLevel?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Name of Degree</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.degreeName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Name of Institute</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.instituteName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.startDate ? moment(row?.startDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Com. Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.completeDate ? moment(row?.completeDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Work Experience Information</span></MDBCardText>

                {userDetails?.workExperience?.map((row, i) => (

                  <div key={row.id} >
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Organization</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.organization}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Designation</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.designation}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.startDate ? moment(row?.startDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>End Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.endDate ? moment(row?.endDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

          </MDBCol>

        </MDBRow>

        {/* {userDetails?.applyStatus >= 5 ?

          <MDBRow>
            <MDBCol md={12}>
              <MDBCard className="mb-4">
                <MDBCardBody>

                  <CContainer>

                    <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >
                      <CCol md={12} className='font-header text-primary'>
                        Document Upload Section
                      </CCol>

                      <CCol md={12}>
                        <CInputGroup className="has-validation">
                          <CInputGroupText> Document Category <span className='red'>*</span></CInputGroupText>
                          <CFormSelect id="validationCustomGender" feedbackValid="Looks good" feedbackInvalid="Required!" value={documentCategoryId} onChange={(event) => { setDocumentCategoryId(event.target.value); }} required disabled>
                            <option value="1">Case Profile</option>
                          </CFormSelect>
                        </CInputGroup>
                      </CCol>

                      <CCol md={12}>
                        Upload Document (PDF Only) : <span className='red'>*</span> <br></br>
                        To upload document click on the <b>Choose File</b> or drag and drop in gray segment
                      </CCol>

                      <CCol md={12}>
                        <CInputGroup className="has-validation">
                          <CFormInput accept="application/pdf" className="fileUpload" type="file" size="lg" id="formFileLg" onChange={onFileChangeHandler} required />
                        </CInputGroup>

                      </CCol>

                      <CCol xs={12}>
                        {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <><CButton color="primary" type="submit"> {buttonText} </CButton> {isUpdate ? <CButton onClick={clear} color="info" > Clear </CButton> : ''}</>}
                      </CCol>

                    </CForm>
                  </CContainer>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          :
          ""
        }
        {userDetails?.applyStatus >= 5 ?

          <MDBRow>
            <MDBCol md={12}>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Document Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Upload Date</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Is visiable ot client</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Make</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Edit</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Download</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {documents?.map((docu, i) => (
                    <CTableRow v-for="item in tableItems" key={i}>
                      <CTableDataCell>
                        <div>{i + 1}</div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{docu?.displayName}</div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          {docu?.dateCreated ? moment(docu?.dateCreated).format("DD-MM-YYYY") : ''}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell style={{ textAlign: 'center' }}>
                        <div className="clearfix">
                          {docu?.isLinkEnabled === true ? 'Yes' : 'No'}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        {docu.documentCategoryId === 1 ?
                          <CButton color="success" onClick={() => { enableDisable(docu?.id, docu?.documentName, docu?.isLinkEnabled) }} >{docu?.isLinkEnabled === true ? 'Invisiable' : 'Visiable'}</CButton> :
                          'N/A'
                        }
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton color="success" onClick={() => { editDocuments(docu?.id, docu?.documentName) }} >Edit</CButton>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton color="success" onClick={() => { deleteDocumentInfo(docu?.id, docu?.documentName) }} >Delete</CButton>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton color="success" onClick={() => { downnloadDocu(docu?.userInfoId, docu?.documentName) }} >download</CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </MDBCol>
          </MDBRow>
          :
          ""
        } */}

      </MDBContainer>
    </section>
  );
}