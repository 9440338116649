import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody } from 'mdb-react-ui-kit';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import axios from '../api/axios';
import { CButton, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow,  CFormLabel, CRow } from '@coreui/react'
import '../assets/css/userDetails.css'
import { cilOptions } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import prCanada from '../assets/pdf/PR-Application-Form.pdf';
import citizenCanad from '../assets/pdf/CitizenshipApplicationForm.pdf';
import trvCanada from '../assets/pdf/TRV-Application-Form.pdf'
import superVisaCanad from '../assets/pdf/Super-Visa-Application-Form.pdf'
import stadyPermitCanad from '../assets/pdf/Student-Assessment-form.pdf'
import pnpApplicationForm from '../assets/pdf/Service-Payment-Advice-BRAC.pdf'
import bbusa from '../assets/pdf/Service-Payment-Advice-BA.pdf'
import sa from '../assets/pdf/Service-Agreement-PR.pdf'
import avater from '../assets/images/avatars/avater.png';

export default function UserDetails() {
  const [userDetails, setUsersDetails] = useState();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [documentCheckList, setDocumentCheckList] = useState([]);
  const [isSpouse, setIsSpouse] = useState(false);
  const [isDocumentCheckListPrepared, setIsDocumentCheckListPrepared] = useState(false);
  const [pictureContent, setPictureContent] = useState(avater);


  ////////////

  const [updateText] = useState("Update");
  const [saveText,] = useState("Save");
  const [buttonText, setButtonText] = useState(saveText);
  const [isUpdate, setIsUpdate] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [documentCategoryId, setDocumentCategoryId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [displayName, setDisplayName] = useState(false);
  const [e, setEvent] = useState("");
  const [validated, setValidated] = useState(false);
  const [validatedCheckList, setValidatedCheckList] = useState(false);

  const onFileChangeHandler = async (e) => {
    setEvent(e);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (e.target.files !== "") {

      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(e.target.files[0]?.type)) {
        confirmAlert({ title: 'MyGration Hub', message: "Only pdf supported", buttons: [{ label: 'OK' }] });
        e.target.value = null;
        setIsSpenierOpen(false);
        return;
      }

      await axios.post("/uploadFile", formData).then((res) => {
        if (res.status === 200) {
          setDocumentName(res?.data?.fileName);
          setIsSpenierOpen(false);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
        setIsSpenierOpen(false);
      });
    } else {
      console.log("empty")
    }
    //console.log(e.target.files);
  };

  async function uploadDocumentInfo() {
    setDisplayName("Case Profile");
    try {
      const formData = {
        userInfoId: userDetails?.id,
        documentCategoryId: documentCategoryId,
        documentName: documentName,
        displayName: displayName,
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      console.log(JSON.stringify(formData));



      await axiosPrivate.post("/uploadDocumentInfo", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Document saved successfully", buttons: [{ label: 'OK' }] });
          e.target.value = null;
          setValidated(false);
          getDocumentList();
          clear();

        } else if (res.status === 404) {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }

      }, fail => {
        console.error(fail);
      });


    }

    catch (err) {
      alert(err);
    }

  }

  async function updateDocumentInfo() {
    setDocumentCategory(documentCategoryId);

    if (documentId === "") {
      confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
    } else {

      try {
        const formData = {
          id: documentId,
          userInfoId: userDetails?.id,
          documentCategoryId: documentCategoryId,
          documentName: documentName,
          displayName: displayName,
        }

        const headers = {
          'Content-Type': 'application/json'
        }
        await axiosPrivate.post("/updateDocumentInfo", JSON.stringify(formData), { headers: headers }).then((res) => {

          if (res.status === 200) {
            confirmAlert({ title: 'MyGration Hub', message: "Document updated successfully", buttons: [{ label: 'OK' }] });
            e.target.value = null;
            setValidated(false);
            getDocumentList();
            clear();
          }
          else {
            confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
          }

        }, fail => {
          console.error(fail);
          setIsSpenierOpen(false);
        });
      }

      catch (err) {
        setIsSpenierOpen(false);
      }

    }

  }


  function enableDisable(key, fileName, isEnable) {
    let message = isEnable ? "Are you sure? You want to invisiable document of " + userDetails?.fullName + " ?" : "Are you sure? You want to visiable document of " + userDetails?.fullName + " ?";
    confirmAlert({
      title: 'MyGration Hub', message: message,
      buttons: [{
        label: 'Yes',
        onClick: () => documentVisiableInvissable(key, fileName, isEnable)
      },
      {
        label: 'No',
      }]
    });
  }


  async function documentVisiableInvissable(key, fileName, isEnable) {
    try {
      const formData = {
        key: key,
        fileName: fileName
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/documentInfoActiveInactive", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Document update successfully", buttons: [{ label: 'OK' }] });
          getDocumentList();
        } else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });
    }
    catch (err) {
    }

  }


  function deleteDocumentInfo(key, fileName, displayName) {
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to delete document - "+ displayName + " of " + userDetails?.fullName + " ?",
      buttons: [{
        label: 'Yes',
        onClick: () => deleteDocument(key, fileName)
      },
      {
        label: 'No',
      }]
    });
  }


  async function deleteDocument(key, fileName) {
    try {
      const formData = {
        key: key,
        fileName: fileName
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/deleteDocumentInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Document deleted successfully", buttons: [{ label: 'OK' }] });
          getDocumentList();
          getDocumentCheckList();
        } else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });
    }
    catch (err) {
    }

  }


  function editDocuments(id, fileName, documentCategoryId,displayName) {
    setIsUpdate(true);
    setButtonText(updateText);
    setDocumentId(id);
    setDocumentCategoryId(documentCategoryId);
    setDisplayName(displayName);
  }

  function clear() {
    setIsUpdate(false);
    setButtonText(saveText);
    setDocumentId("");
    setDocumentCategoryId("");
    setDisplayName("");
  }




  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      let message = isUpdate ? "Are you sure? You want to do update document of " + userDetails?.fullName + " ?" : "Are you sure? You want to do save document of " + userDetails?.fullName + " ?";

      confirmAlert({
        title: 'MyGration Hub', message: message,
        buttons: [{
          label: 'Yes',
          onClick: () => isUpdate ? updateDocumentInfo() : uploadDocumentInfo()
        },
        {
          label: 'No',
        }]
      });

    }

    setValidated(true);
    event.preventDefault();


  }


  ///////////////

  const getDocumentList = async () => {

    try {

      const formData = {
        id: userDetails?.id,
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/getUserDocumentList", JSON.stringify(formData), { headers: headers }).then((response) => {
        if (response.status === 200) {
          setDocuments(response?.data);

        } else {
        }
      }, fail => {
      });

    } catch (err) {

    }
  }

  const getDocumentCheckList = async () => {

    try {

      const formData = {
        id: userDetails?.id,
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/getDocumentCheckListAfterSave", JSON.stringify(formData), { headers: headers }).then((response) => {
        if (response.status === 200) {
          setDocumentCheckList(response?.data);
          response?.data.length>0?setIsDocumentCheckListPrepared(true):setIsDocumentCheckListPrepared(false);

        } else {
        }
      }, fail => {
      });

    } catch (err) {

    }
  }


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const detailsUserId = localStorage.getItem('details-user-id');
    const getUserDetails = async () => {
      try {
        const path = `/getUserInfo/${location?.state?.id ? location.state.id : detailsUserId}`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });
        //console.log(response.data);
        isMounted && setUsersDetails(response?.data);
        setApplicationType(response?.data?.applicationType);
        setApplicationTypeId(response?.data?.applicationTypeId);
        //setCarySpouse(response?.data?.carySpouse);
        setCaryChild(response?.data?.caryChild);
        setIsSpouse(response?.data?.maritalStatus?.id===2);
        setCarySpouse(response?.data?.maritalStatus?.id===2?response?.data?.carySpouse:false);
        if (response?.data.picture !== null && response?.data?.picture !== "" && response?.data?.picture !== 'undefine') {
          setPictureContent(response?.data?.picture);
        }



        /* setChecList([Array(0),Array(0)]);
        setName(['shamim','khan']);
        setDocumentListId([1,2]); */

      } catch (err) {
        //console.error(err);
        console.log(err);
        navigate('/agent-login', { state: { from: location }, replace: true });
      }
    }


    const getDocumentListOnload = async () => {

      try {

        const formData = {
          id: detailsUserId,
        }
        const headers = {
          'Content-Type': 'application/json'
        }

        await axiosPrivate.post("/getUserDocumentList", JSON.stringify(formData), { headers: headers }).then((response) => {
          if (response.status === 200) {
            setDocuments(response?.data);

          } else {
          }
        }, fail => {
        });

      } catch (err) {

      }
    }

    const getDocumentCheckListOnload = async () => {

      try {

        const formData = {
          id: detailsUserId,
        }
        const headers = {
          'Content-Type': 'application/json'
        }

        await axiosPrivate.post("/getDocumentCheckListAfterSave", JSON.stringify(formData), { headers: headers }).then((response) => {
          if (response.status === 200) {
            setDocumentCheckList(response?.data);
            response?.data.length>0?setIsDocumentCheckListPrepared(true):setIsDocumentCheckListPrepared(false);

          } else {
          }
        }, fail => {
        });

      } catch (err) {

      }
    }

    getUserDetails();
    getDocumentListOnload();
    getDocumentCheckListOnload();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);



  const [isResumeSpinOpen, setIsResumeSpinOpen] = React.useState(false);



  async function getResume(event) {
    event.preventDefault();
    downloadFile(userDetails?.id, userDetails.resume);
  }

  function downnloadDocu(userId, fileName) {
    downloadFile(userId, fileName);
  }

  async function downloadFile(key, fileName) {
    setIsResumeSpinOpen(true);
    const formData = {
      key: key,
      fileName: fileName,
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    await axiosPrivate.post("/downloadFile", JSON.stringify(formData), { headers: headers }).then((response) => {
      if (response.status === 200) {
        setIsResumeSpinOpen(false);
        const linkSource = response?.data?.code;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
        setIsResumeSpinOpen(false);
      }
    }, fail => { setIsResumeSpinOpen(false); });

  }

  function viewHandleClick(value) {
    localStorage.setItem('edit-user-id', value);
    localStorage.setItem('back-page', '/home/client-details');
    navigate('/home/edit-user/'+value, { state: { id: value, backPage:'/home/client-details'}, replace: true });
  }


  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if(message!==""){
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
        }
        if (apiName === 'updateApplicationType') {
          setValidatedAT(false);
        } else if(apiName==='getDocumentCheckList'){


          //work 1
          
          const cCheckList = []; 
          const cName = [];
          const cType = [];
          const cDocumentListId = [];
          const l = res?.data?.selfDocuList.length;
          res?.data?.selfDocuList.forEach((v, i) => {
            cCheckList.push(Array(0));
            cName.push(v.name);
            cDocumentListId.push(v.id);
            cType.push(1);
            if(i===l-1){
                setChecList(cCheckList);
                setName(cName);
                setType(cType);
                setDocumentListId(cDocumentListId);
            }
          });

          const cCheckListS = []; 
          const cNameS = [];
          const cTypeS = [];
          const cDocumentListIdS = [];
          const ls = res?.data?.spouseDocuList.length;
          res?.data?.spouseDocuList.forEach((v, i) => {
            cCheckListS.push(Array(0));
            cNameS.push(v.name);
            cDocumentListIdS.push(v.id);
            cTypeS.push(2);
            if(i===ls-1){
                setSChecList(cCheckListS);
                setSName(cNameS);
                setSType(cTypeS);
                setSDocumentListId(cDocumentListIdS);
            }
          });


          const cCheckListD = []; 
          const cNameD = [];
          const cTypeD = [];
          const cDocumentListIdD = [];
          const ld = res?.data?.childDocuList.length;
          res?.data?.childDocuList.forEach((v, i) => {
            cCheckListD.push(Array(0));
            cNameD.push(v.name);
            cDocumentListIdD.push(v.id);
            cTypeD.push(3);
            if(i===ld-1){
                setDChecList(cCheckListD);
                setDName(cNameD);
                setDType(cTypeD);
                setDDocumentListId(cDocumentListIdD);
            }
          });

          //work 1
      

        }else if(apiName==='saveDocumentCheckList'){

          setChecList([]);
          setName([]);
          setType([]);
          setDocumentListId([]);

          setSChecList([]);
          setSName([]);
          setSType([]);
          setSDocumentListId([]);

          setDChecList([]);
          setDName([]);
          setDType([]);
          setDDocumentListId([]);

          getDocumentCheckList();


        }else if(apiName==="deleteDocumentCheckListItem"){
          getDocumentCheckList();
        }
      }
      else {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK', }] });
      }

    }, fail => {
      confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
    });
  }

  const [validatedAT, setValidatedAT] = useState(false);
  const [applicationType, setApplicationType] = useState(false);
  const [applicationTypeId, setApplicationTypeId] = useState(false);
  const [carySpouse, setCarySpouse] = useState("");
  const [caryChild, setCaryChild] = useState("");
  function setApplicationTypeIdAndText(val){
    setApplicationTypeId(val);
    let sel = document.getElementById("validationApplyTypeId");
    setApplicationType(sel.options[sel.selectedIndex].text);
}
  const handleSubmitAT = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const formData = {
        id: userDetails?.id,
        applicationType: applicationType,
        applicationTypeId: applicationTypeId,
        carySpouse: carySpouse,
        caryChild: caryChild
      }
      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update applycation type " + userDetails?.fullName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateApplicationType", "Information successfully updated")
        }, { label: 'No', }]
      });
    }
    setValidatedAT(true);
    event.preventDefault();
  }

  async function downloadApplicationForm(event) {
    event.preventDefault();
    let link = "";  
    switch (applicationType) {
      case 'PR Canada':
        link = prCanada;
        break;
      case 'Canada - Citizenship':
        link = citizenCanad;
        break;
      case 'TRV Canada':
        link = trvCanada
        break;
      case 'SUPER VISA Canada':
        link = superVisaCanad;
        break;
      case 'Study Permit Canada':
        link = stadyPermitCanad
        break;
      case 'PNP Application Canada':
        link = pnpApplicationForm
        break;
      case 'B1 B2 USA':
        link = bbusa;
        break;
      case 'Sub Class 600 - Australia':
        link = sa;
        break;
      default:
        break;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.download = applicationType+".pdf";
    downloadLink.click();
    
  }
  
  

  function setDocumentCategory(val){
      setDocumentCategoryId(val);
      let sel = document.getElementById("validationCustomCategory");
      setDisplayName(sel.options[sel.selectedIndex].text);
  }



  


  
//Garbase Code Start

  const [checList, setChecList] = useState([]);
  const [name, setName] = useState([]);
  const [type, setType] = useState([]);
  const [documentListId, setDocumentListId] = useState([]);

  const [sChecList, setSChecList] = useState([]);
  const [sName, setSName] = useState([]);
  const [sType, setSType] = useState([]);
  const [sDocumentListId, setSDocumentListId] = useState([]);

  const [dChecList, setDChecList] = useState([]);
  const [dName, setDName] = useState([]);
  const [dType, setDType] = useState([]);
  const [dDocumentListId, setDDocumentListId] = useState([]);



  //Add Delete Function


  const setNameFunction = (onChnageValue, i) => {
    const inputdata = [...name]
    inputdata[i] = onChnageValue;
    setName(inputdata)
  }
  const setDocumentCategoryIdFunction = (onChnageValue, i) => {
    const inputdata = [...documentListId]
    inputdata[i] = onChnageValue;
    setDocumentListId(inputdata)
  }

  const addDocuemntCheckListItem = () => {
    const cCheckList = [...checList, []];
    const cName = [...name, []];
    const cType = [...type, [1]];
    const cDocumentListId = [...documentListId, []];
    setChecList(cCheckList);
    setName(cName);
    setType(cType);
    setDocumentListId(cDocumentListId);
  
  }

  const deleteDocuemntCheckListItem = (i) => {
    if (i >= 0) {
      const deleteVal = [...checList];
      deleteVal.splice(i, 1);
      setChecList(deleteVal);

      const deleteName = [...name];
      deleteName.splice(i, 1);
      setName(deleteName);

      
      const typeId = [...type];
      typeId.splice(i, 1);
      setType(typeId);

      const deleteId = [...documentListId];
      deleteId.splice(i, 1);
      setDocumentListId(deleteId);
    }

  }



  const setNameFunctionS = (onChnageValue, i) => {
    const inputdata = [...sName]
    inputdata[i] = onChnageValue;
    setSName(inputdata)
  }

  const addDocuemntCheckListItemS = () => {
    const cCheckLists = [...sChecList, []];
    const cNames = [...sName, []];
    const cTypes = [...sType, [2]];
    const cDocumentListIds = [...sDocumentListId, []];
    setSChecList(cCheckLists);
    setSName(cNames);
    setSType(cTypes);
    setSDocumentListId(cDocumentListIds);
  
  }

  const deleteDocuemntCheckListItemS = (i) => {
    if (i >= 0) {
      const deleteVals = [...sChecList];
      deleteVals.splice(i, 1);
      setSChecList(deleteVals);
      
      const deleteNames = [...sName];
      deleteNames.splice(i, 1);
      setSName(deleteNames);

      const typeIds = [...sType];
      typeIds.splice(i, 1);
      setSType(typeIds);

      const deleteIds = [...sDocumentListId];
      deleteIds.splice(i, 1);
      setSDocumentListId(deleteIds);
    }

  }

  const setNameFunctionD = (onChnageValue, i) => {
    const inputdatas = [...dName]
    inputdatas[i] = onChnageValue;
    setDName(inputdatas)
  }

  const addDocuemntCheckListItemD = () => {
    const cCheckListD = [...dChecList, []];
    const cNameD = [...dName, []];
    const cTypeD = [...dType, [1]];
    const cDocumentListIdD = [...dDocumentListId, []];
    setDChecList(cCheckListD);
    setDName(cNameD);
    setDType(cTypeD);
    setDDocumentListId(cDocumentListIdD);
  
  }

  const deleteDocuemntCheckListItemD = (i) => {
    if (i >= 0) {
      const deleteValD = [...dChecList];
      deleteValD.splice(i, 1);
      setDChecList(deleteValD);
      
      const deleteNameD = [...dName];
      deleteNameD.splice(i, 1);
      setDName(deleteNameD);

      
      const typeIdD = [...dType];
      typeIdD.splice(i, 1);
      setDType(typeIdD);

      const deleteIdD = [...dDocumentListId];
      deleteIdD.splice(i, 1);
      setDDocumentListId(deleteIdD);
    }

  }

  //close add delete


  async function prepareDocumentCheckList(event) {
    event.preventDefault();
    const formData = {
      id: userDetails?.id,
      applicationType: applicationTypeId,
      isSpouseAccompanied: carySpouse,
      isDependentAccompanied: caryChild
    }
    apicall(formData, "getDocumentCheckList", "");
    
  }


  const handleSubmitCheckList = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      let message = "Are you sure? You want to do save document check list of " + userDetails?.fullName + " ?";
      if (checList.length<=0 && sChecList.length<=0 &&  dChecList.length<=0) {
        confirmAlert({ title: 'MyGration Hub', message: "Check List is Empty, Please Load Check List by selection 'Applycation Type and Accompanied' segment information ", buttons: [{ label: 'OK', }] });

      } else {


        //work 2 
        let count = 0;
        let userDocumentCheckList = [];
        checList.forEach((v, i) => {
          userDocumentCheckList.push({
            position: count++,
            name: name[i],
            type: 1,
            documentListId: documentListId[i].length!==0?documentListId[i]:null
          })
        });

        sChecList.forEach((v, i) => {
          userDocumentCheckList.push({
            position: count++,
            name: sName[i],
            type: 2,
            documentListId: sDocumentListId[i].length!==0?sDocumentListId[i]:null
          })
        });

        dChecList.forEach((v, i) => {
          userDocumentCheckList.push({
            position: count++,
            name: dName[i],
            type: 3,
            documentListId: dDocumentListId[i].length!==0?dDocumentListId[i]:null
          })
        });

        const formData = {
          userInfoId: userDetails?.id,
          userDocumentCheckLists: userDocumentCheckList,
        }

        //console.log(formData);

        //work 2

        confirmAlert({
          title: 'MyGration Hub', message: message,
          buttons: [{
            label: 'Yes',
            onClick: () => apicall(formData, "saveDocumentCheckList", "Docment check list saved successfully")
            
          },
          {
            label: 'No',
          }]
        });

      }

    }

    setValidatedCheckList(true);
    event.preventDefault();
  }


  function deleteCheckListItems(key, fileName) {
    const formData = {
      id: key,
      name: fileName,
    }

    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to delete "+ fileName + " for " + userDetails?.fullName + " ?",
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "deleteDocumentCheckListItem", "Docment check list item deleted successfully")
      },
      {
        label: 'No',
      }]
    });
  }

  function downloadPicture(){
    const downloadLink = document.createElement("a");
    let ex = pictureContent.split(";")[0].split("/")[1];
    downloadLink.href = pictureContent;
    downloadLink.download = userDetails?.fullName+"."+ex;
    downloadLink.click();

  }


  return (
    <section style={{ backgroundColor: '#fff' }}>
      <h2 className="padding10">Client Details  <CButton className="floatRight" color="info" onClick={() => { viewHandleClick(userDetails?.id) }} >Edit</CButton></h2>
      <MDBContainer className="">
        <MDBRow>
          <MDBCol lg="4" >
            <MDBCard className="mb-4" style={{ height: '345px' }}>
              <MDBCardBody className="text-center">
                {/* <MDBCardImage
                  src={require('../assets/images/avatars/avater.png')}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid /> */}
                  <img src={pictureContent} alt="" onClick={downloadPicture} className="detailsPicture" ></img>
                <p className="text-muted mb-1">{userDetails?.fullName}</p>
                <p className="text-muted mb-2">
                  {userDetails?.userResidence?.map((row) => row?.country?.name)}
                </p>
                <p className="text-muted mb-4">
                  {userDetails?.userId}
                </p>
              </MDBCardBody>
              <br />
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>First Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.firstName}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Last Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.lastName}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.officeEmail}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>WhatsApp</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.whatsApp}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Imo</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.imo}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Viber</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.viber}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.sex}</MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Merital Status</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.maritalStatus?.name}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>Date Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.dob ? moment(userDetails?.dob).format("DD-MM-YYYY") : ''}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Family Member</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      : {userDetails?.familyMemberNo > 0 ? userDetails.familyMemberNo : ''}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>Resume</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      :
                      {userDetails?.resume ?
                        isResumeSpinOpen ?
                          <Vortex visible={true} height="40" width="40" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                          :
                          <Link onClick={getResume}>Download</Link>
                        :
                        ""
                      }
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>



        <MDBRow>
          <MDBCol md={6}>

            <MDBCard className="mb-4 mb-md-0" style={{ height: 'auto' }}>
              <MDBCardBody>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Country of Residence</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.userResidence?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Consultation Requirement</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.userConsultation?.map((row) => <li key={row?.id}>{row?.consultation?.name}</li>)}
                  </ul>
                </MDBCardText>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Interested Country</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.interestedCountry?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>

                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">List of Countries you visited in last 10 years</span></MDBCardText>
                <hr />
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.visitedCountry?.map((row) => <li key={row?.id}>{row?.country?.name}</li>)}
                  </ul>
                </MDBCardText>

              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4 mb-md-0" style={{ height: 'auto' }}>
              <MDBCardBody>
                <MDBCardText className="mb-1"><span className="text-primary font-italic me-1">Address Information</span></MDBCardText>
                <MDBCardText className="mb-4" style={{ fontSize: '15px' }}>
                  <ul>
                    {userDetails?.address?.map((row) => (
                      <div key={row?.id}>
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Address Line 1</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.address}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Address Line 2</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.addressLineTwo}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>City</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.city}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>State / Province</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.state}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Postal / Zip Code</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              : {row?.postal}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                      </div>
                    ))}
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Language Test Information</span></MDBCardText>

                {userDetails?.languageTest?.map((row, i) => (

                  <div key={row.id}>

                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Language Test Type</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.languageTestType?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Language Test Version</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.languageTestVersion?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of Language Test</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.date ? moment(row?.date).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of test results</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.resultDate ? moment(row?.resultDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Certificate number</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.certificateNo}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="12">
                        <MDBCardText>Language Test Score </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Listening </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.listeningScore}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Reading </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.readingScore}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Writing</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.writingScore}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Speaking</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.speakingScore}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Overall</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.overall}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">

                      </MDBCol>
                      <MDBCol sm="3">

                      </MDBCol>
                    </MDBRow>


                  </div>

                ))}


              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Spouse Information</span></MDBCardText>

                {userDetails?.spouseInfo?.map((row, i) => (

                  <div key={row.id}>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Spouse First Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.firstName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Spouse Last Name</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.lastName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Date of Marriage</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.marriageDate ? moment(row?.marriageDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Have you been previously married?</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.previouslyMarried === true ? "Yes" : "No"}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

          </MDBCol>


          <MDBCol md={6}>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Personal Information</span></MDBCardText>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Country Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.birthCountry.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>City Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.birthCity}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Have you ever used any other name?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.isOtherNameUsed ? userDetails?.personaInfo?.isOtherNameUsed === true ? "Yes" : "No" : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport (Country/territory of issue)</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportIssueCountry?.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport Issue date </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportIssueDate ? moment(userDetails?.personaInfo?.passportIssueDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Passport Expiry date</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.passportExpireDate ? moment(userDetails?.personaInfo?.passportExpireDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Applied before for your interested country?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.isCaAppliedBefore === true ? "Yes" : userDetails?.personaInfo?.isCaAppliedBefore === false ? "No" : ""}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Any Family/Friends in the desired country?</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.haveAnyCaRelative === true ? "Yes" : userDetails?.personaInfo?.haveAnyCaRelative === false ? "No" : ""}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Relations with this Family/Friend</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.caRelativeRelation}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="7">
                    <MDBCardText>Province or Territory where he/she live</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="5">
                    <MDBCardText className="text-muted">: {userDetails?.personaInfo?.caRelativeAddress}</MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Educational Information</span></MDBCardText>
                {userDetails?.educationalInfo?.map((row, i) => (

                  <div key={row.id} >
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Level</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.educationLevel?.name}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Name of Degree</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.degreeName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="6">
                        <MDBCardText>Name of Institute</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="6">
                        <MDBCardText className="text-muted">: {row?.instituteName}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.startDate ? moment(row?.startDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Com. Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.completeDate ? moment(row?.completeDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">Work Experience Information</span></MDBCardText>

                {userDetails?.workExperience?.map((row, i) => (

                  <div key={row.id} >
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Organization</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.organization}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>Designation</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.designation}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Start Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.startDate ? moment(row?.startDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText>End Date</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="3">
                        <MDBCardText className="text-muted">: {row?.endDate ? moment(row?.endDate).format("DD-MM-YYYY") : ''}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </div>

                ))}
              </MDBCardBody>
            </MDBCard>

          </MDBCol>

        </MDBRow>

        {userDetails?.applyStatus >= 5 ?

          <MDBRow>
            <MDBCol md={12}>
              <MDBCard className="mb-4">
                <MDBCardBody>

                  <CContainer>

                    <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >
                      <CCol md={12} className='font-header text-primary'>
                        Document Upload Section
                      </CCol>

                      <CCol md={12}>
                        <CInputGroup className="has-validation">
                          <CInputGroupText> Document Category <span className='red'>*</span></CInputGroupText>
                          <CFormSelect id="validationCustomCategory" feedbackValid="Looks good" feedbackInvalid="Required!" value={documentCategoryId} onChange={(event) => { setDocumentCategory(event.target.value); }} required >
                            <option value="">Select</option>
                            <option value="1">Case Profile</option>
                            <option value="2">Application Form</option>
                          </CFormSelect>
                        </CInputGroup>
                      </CCol>

                      <CCol md={12}>
                        Upload Document (PDF Only) : <span className='red'>*</span> <br></br>
                        To upload document click on the <b>Choose File</b> or drag and drop in gray segment
                      </CCol>

                      <CCol md={12}>
                        <CInputGroup className="has-validation">
                          <CFormInput accept="application/pdf" className="fileUpload" type="file" size="lg" id="formFileLg" onChange={onFileChangeHandler} required />
                        </CInputGroup>

                      </CCol>

                      <CCol xs={12}>
                        {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <><CButton color="primary" type="submit"> {buttonText} </CButton> {isUpdate ? <CButton onClick={clear} color="info" > Clear </CButton> : ''}</>}
                      </CCol>

                    </CForm>
                  </CContainer>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          :
          ""
        }

        {userDetails?.applyStatus >= 5 ?

          <MDBRow>
            <MDBCol md={12}>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Document Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Upload Date</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Is visiable ot client</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Make</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Edit</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Download</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {documents?.map((docu, i) => (
                    <CTableRow v-for="item in tableItems" key={i}>
                      <CTableDataCell>
                        <div>{i + 1}</div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{docu?.displayName}</div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          {docu?.dateCreated ? moment(docu?.dateCreated).format("DD-MM-YYYY") : ''}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell style={{ textAlign: 'center' }}>
                        <div className="clearfix">
                          {docu.documentCategoryId === 1 ?
                            docu?.isLinkEnabled === true ? 'Yes' : 'No':
                          'N/A'
                        }
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        {docu.documentCategoryId === 1 && docu.userDocumentCheckListId === null?
                          <CButton color="success" onClick={() => { enableDisable(docu?.id, docu?.documentName, docu?.isLinkEnabled) }} >{docu?.isLinkEnabled === true ? 'Invisiable' : 'Visiable'}</CButton> :
                          'N/A'
                        }
                      </CTableDataCell>
                      <CTableDataCell>
                      {docu.userDocumentCheckListId === null?
                          <CButton color="success" onClick={() => { editDocuments(docu?.id, docu?.documentName,docu?.documentCategoryId,docu?.displayName) }} >Edit</CButton>
                          :
                          'N/A'
                        }
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton color="success" onClick={() => { deleteDocumentInfo(docu?.id, docu?.documentName, docu?.displayName) }} >Delete</CButton>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton color="success" onClick={() => { downnloadDocu(docu?.userInfoId, docu?.documentName) }} >download</CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </MDBCol>
          </MDBRow>
          :
          ""
        }




        <CCol xs={12}>
          &nbsp;
        </CCol>
        {/* Apply Type Update and Form Download */}

        {userDetails?.applyStatus >= 5 ?

          <>

            <MDBRow>
              <MDBCol md={12}>
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedAT} onSubmit={handleSubmitAT} >
                      <CCol md={12} className='font-header text-primary'>
                        Applycation Type and Accompanied
                      </CCol>
                      <CCol md={4}>
                        <CFormLabel htmlFor="validationCustomFamilyMamber">Change Application Type<span className='red'>*</span></CFormLabel>
                        <CInputGroup className="has-validation">
                          <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                          <CFormSelect id="validationApplyTypeId" feedbackValid="Looks good" feedbackInvalid="Required!" value={applicationTypeId} onChange={(event) => { setApplicationTypeIdAndText(event.target.value); }} required >
                            <option value="">Select...</option>
                            <option value="1">PR Canada</option>
                            <option value="2">Canada - Citizenship</option>
                            <option value="3">TRV Canada</option>
                            <option value="4">SUPER VISA Canada</option>
                            <option value="5">Study Permit Canada</option>
                            <option value="6">PNP Application Canada</option>
                            <option value="7">B1 B2 USA</option>
                            <option value="8">Sub Class 600 - Australia</option>
                          </CFormSelect>
                        </CInputGroup>
                      </CCol>

                      <CCol md={4}>
                        <CFormLabel htmlFor="validationCustomFamilyMamber">Is Spouse Accompanied<span className='red'>*</span></CFormLabel>
                        <CInputGroup className="has-validation">
                          <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                          <CFormSelect id="validationcarySpouse" feedbackValid="Looks good" feedbackInvalid="Required!" value={carySpouse} onChange={(event) => { setCarySpouse(event.target.value); }} required disabled={!isSpouse} >
                            <option value="">Select...</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </CFormSelect>
                        </CInputGroup>
                      </CCol>

                      <CCol md={4}>
                        <CFormLabel htmlFor="validationCustomFamilyMamber">Is Dependent Accompanied<span className='red'>*</span></CFormLabel>
                        <CInputGroup className="has-validation">
                          <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                          <CFormSelect id="validationCaryChild" feedbackValid="Looks good" feedbackInvalid="Required!" value={caryChild} onChange={(event) => { setCaryChild(event.target.value); }} required >
                            <option value="">Select...</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </CFormSelect>
                        </CInputGroup>
                      </CCol>

                      <CCol xs={12} className="paddingTop20">
                        <CButton color="success" type="submit"> Save </CButton> <CButton color="primary" onClick={downloadApplicationForm} > Download Form </CButton>
                      </CCol>
                    </CForm>

                    <CContainer>
                    </CContainer>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

          </>
          :
          ""
        }

       

       {/* Document Check List */}


          <MDBRow>
            <MDBCol md={12}>
              <MDBCard className="mb-4">
                <MDBCardBody>
                  <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedCheckList} onSubmit={handleSubmitCheckList} >

                    <CCol md={12} className='font-header text-primary'>
                      <div className="document-check-list-header">Prepare Document Check List </div>
                    </CCol>

                    {/* self documents */}
                    <CCol md={12} className='font-header text-primary'>
                      <div className="document-check-list-sub-header">Principle Applicant</div>
                    </CCol>
              
                    {checList?.map((data, i) => {
                      return (
                        <div key={i} className="margin0">
                          <CCol xs={12}>
                            <CRow className="mb-12">
                              <div className="col-sm-12">
                                <CInputGroup className="has-validation">
                                  <CInputGroupText>
                                    {/* <CIcon icon={cilOptions} /> */}
                                    <CButton color="danger" size="sm" variant="outline" onClick={() => deleteDocuemntCheckListItem(i)}>Delete</CButton>
                                    <CFormInput type="hidden" placeholder="" aria-label="" value={documentListId[i]} onChange={(event) => { setDocumentCategoryIdFunction(event.target.value, i); }} />
                                    <CFormInput type="hidden" placeholder="" aria-label="" value={type[i]}/>
                                  </CInputGroupText>
                                  <CFormInput placeholder="" aria-label="optionalEmail" required value={name[i]} onChange={(event) => { setNameFunction(event.target.value, i); }} />
                                </CInputGroup>
                              </div>
                              {/* <div className="col-sm-4">
                                <CInputGroup className="has-validation">
                                  <CButton color="danger" size="sm" variant="outline" onClick={() => deleteDocuemntCheckListItem(i)}>Delete</CButton>
                                </CInputGroup>
                              </div> */}
                            </CRow>
                          </CCol>
                        </div>
                      )
                    })}

                  <CCol md={12}>
                    <CButton variant="outline" color="success" onClick={() => addDocuemntCheckListItem()}>Add more</CButton> &nbsp;&nbsp;&nbsp;&nbsp;
                  </CCol>


                  {/* Spouse Document     */}
                  <CCol md={12} className='font-header text-primary'>
                      {/* <h3>Dependant Spouse</h3> */}
                      <div className="document-check-list-sub-header">Dependant Spouse</div>
                    </CCol>
              
                    {sChecList?.map((data, i) => {
                      return (
                        <div key={i} className="margin0">
                          <CCol xs={12}>
                            <CRow className="mb-12">
                              <div className="col-sm-12">
                                <CInputGroup className="has-validation">
                                  <CInputGroupText>
                                    {/* <CIcon icon={cilOptions} /> */}
                                    <CButton color="danger" size="sm" variant="outline" onClick={() => deleteDocuemntCheckListItemS(i)}>Delete</CButton>
                                    <CFormInput type="hidden" placeholder="" aria-label="" value={sDocumentListId[i]} />
                                    <CFormInput type="hidden" placeholder="" aria-label="" value={sType[i]}/>
                                  </CInputGroupText>
                                  <CFormInput placeholder="" aria-label="optionalEmail" required value={sName[i]} onChange={(event) => { setNameFunctionS(event.target.value, i); }} />
                                </CInputGroup>
                              </div>
                              {/* <div className="col-sm-4">
                                <CInputGroup className="has-validation">
                                  <CButton color="danger" size="sm" variant="outline" onClick={() => deleteDocuemntCheckListItem(i)}>Delete</CButton>
                                </CInputGroup>
                              </div> */}
                            </CRow>
                          </CCol>
                        </div>
                      )
                    })}

                  <CCol md={12}>
                    <CButton variant="outline" color="success" onClick={() => addDocuemntCheckListItemS()}>Add more</CButton> &nbsp;&nbsp;&nbsp;&nbsp;
                  </CCol>


                  {/* child documents */}
                  <CCol md={12} className='font-header text-primary'>
                      {/* <h3>Dependant Child</h3> */}
                      <div className="document-check-list-sub-header">Dependant Child</div>
                    </CCol>
              
                    {dChecList?.map((data, i) => {
                      return (
                        <div key={i} className="margin0">
                          <CCol xs={12}>
                            <CRow className="mb-12">
                              <div className="col-sm-12">
                                <CInputGroup className="has-validation">
                                  <CInputGroupText>
                                    {/* <CIcon icon={cilOptions} /> */}
                                    <CButton color="danger" size="sm" variant="outline" onClick={() => deleteDocuemntCheckListItemD(i)}>Delete</CButton>
                                    <CFormInput type="hidden" placeholder="" aria-label="" value={dDocumentListId[i]} />
                                    <CFormInput type="hidden" placeholder="" aria-label="" value={dType[i]}/>
                                  </CInputGroupText>
                                  <CFormInput placeholder="" aria-label="optionalEmail" required value={dName[i]} onChange={(event) => { setNameFunctionD(event.target.value, i); }} />
                                </CInputGroup>
                              </div>
                              {/* <div className="col-sm-4">
                                <CInputGroup className="has-validation">
                                  <CButton color="danger" size="sm" variant="outline" onClick={() => deleteDocuemntCheckListItem(i)}>Delete</CButton>
                                </CInputGroup>
                              </div> */}
                            </CRow>
                          </CCol>
                        </div>
                      )
                    })}

                  <CCol md={12}>
                    <CButton variant="outline" color="success" onClick={() => addDocuemntCheckListItemD()}>Add more</CButton> &nbsp;&nbsp;&nbsp;&nbsp;
                  </CCol>


                    <CCol md={12}>
                      {isDocumentCheckListPrepared ? "" :
                        <><CButton color="primary" onClick={prepareDocumentCheckList} > Load Document Check List </CButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      }
                      <CButton color="success" type="submit"> Save Document Check List </CButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </CCol>

                  </CForm>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md={12}>
              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Document Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Document Of</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                    <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {documentCheckList?.map((docu, i) => (
                    <CTableRow v-for="item in tableItems" key={i}>
                      <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                        {i + 1}
                      </CTableDataCell>
                      <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                        {docu?.name}
                      </CTableDataCell>
                      <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                        {docu?.docType===1?'Principle Applicant':docu?.docType===2?'Dependant Spouse':docu?.docType===3?'Dependant Child':'N/A'}
                      </CTableDataCell>
                      <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                        {docu?.status}
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton color="danger" onClick={() => { deleteCheckListItems(docu?.id, docu?.name) }} >Delete</CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </MDBCol>
          </MDBRow>
      </MDBContainer>
    </section>
  );
}