import React from "react";
import AddResource from "./AddResource";


const AddStaff = () => {


  return (
    <>
      <AddResource role="Staff"></AddResource>
    </>

  )
}

export default AddStaff;
