import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-work-canda-lmia.jpg';
import Meta from './Meta';

const WorkCanadaLmiaBased = () => {
    const title = "The Labour Market Impact Assessment (LMIA) is a crucial step for many work permits, but there are cases where certain individuals are exempt from this requirement.";
    const description = title;
    return (
        <div>

            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>


                                    <div className="section-heading-customise">
                                        <h2>
                                            LMIA-Exempt Work Permits:
                                        </h2>
                                    </div>

                                    <p>
                                        <b>What is LMIA Exempt Work Permit?<br /></b>
                                        The Labour Market Impact Assessment (LMIA) is a crucial step for many work permits, but there are cases where certain individuals are exempt from this requirement. This exemption applies to specific categories of foreign workers, making the process more accessible and efficient. In some cases, employers may need to obtain a positive LMIA from Employment and Social Development Canada (ESDC) to demonstrate that hiring a foreign worker will not negatively impact the Canadian labor market. Some individuals may be exempt from the LMIA requirement, such as those under international agreements, intra-company transferees, or those with certain types of temporary job offers. For individuals aspiring to work in Canada, certain categories of work permit applications are exempt from the Labor Market Impact Assessment (LMIA) process. These exemptions are designed to facilitate the hiring of foreign workers in specific circumstances where the Canadian government recognizes unique benefits or circumstances.
                                    </p>

                                    <h3>1.	Identify LMIA Exemption Category:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Determine if you fall into a category that is exempt from the LMIA requirement. Common LMIA-exempt categories include International Mobility Program (IMP) work permits, NAFTA work permits, Intra-Company Transfer work permits, Spousal Open Work Permits, and more.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2.  Employer Compliance:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Ensure that your employer is aware of the LMIA exemption category that applies to your situation. They should be willing to support your application and provide necessary documents to demonstrate eligibility.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>3.	Job Offer and Employment Contract:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Obtain a valid job offer from a Canadian employer. The job offer should include details such as job title, duties, salary, and duration of employment. For certain LMIA-exempt categories, the job offer might need to meet specific criteria.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>4.	Submit Application Online:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Most work permit applications, including LMIA-exempt categories, are typically submitted online through the IRCC website. Create an account, fill out the required forms, and upload supporting documents.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>5.	Biometrics and Medical Examination:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Depending on your country of residence and the length of your stay, you may be required to provide biometrics (fingerprints and photo) and undergo a medical examination. Check the specific requirements for your situation.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>6.	Wait for Processing:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once you have submitted your application, it will be processed by IRCC. Processing times may vary, and you can check the status of your application online.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>7.	Receive Work Permit:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If your application is approved, you will receive a work permit. Ensure that the details on the work permit are accurate, including the job title, employer name, and conditions of employment.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>8.	Travel to Canada:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If you are outside Canada when your work permit is approved, you can travel to Canada and present your work permit at the port of entry.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>




                                    <div className="section-heading-customise">
                                        <h2>
                                            LMIA based work permit: 
                                        </h2>
                                    </div>

                                    <p>
                                        For individuals seeking employment in Canada, particularly those who are not citizens or permanent residents, the Labor Market Impact Assessment (LMIA) plays a crucial role in obtaining a work permit. An LMIA is a document issued by Employment and Social Development Canada (ESDC) that assesses the impact of hiring a foreign worker on the Canadian labor market. The steps for getting an LMIA based work permit is given below: 
                                    </p>

                                    <h3>1.	Job Offer:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Obtain a job offer from a Canadian employer. The employer must be willing to go through the LMIA process to demonstrate that there is a need for a foreign worker to fill the position.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <h3>2.	Employer&apos;s LMIA Application:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    The employer needs to apply for an LMIA from Employment and Social Development Canada (ESDC). This involves proving that they have attempted to hire a Canadian citizen or permanent resident for the position but were unable to find a suitable candidate.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    
                                    <h3>3.	Positive LMIA Result:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If the LMIA application is approved, the employer will receive a positive LMIA, indicating that hiring a foreign worker is justified for the given position.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <h3>4.	Temporary Job Offer Letter:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once the employer receives a positive LMIA, they should provide you with a temporary job offer letter. This letter should include details about the position, salary, working conditions, and any other relevant information.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    
                                    <h3>5.	Work Permit Application:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    With the positive LMIA and the job offer letter, you can then apply for a work permit through the IRCC. Ensure that you have all the required documents, including your passport, proof of job offer, and any other supporting documents.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <h3>6.	LMIA Exemption:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    In some cases, you may be eligible for an LMIA exemption. Certain job categories or situations, such as intra-company transfers or certain international agreements, may allow you to apply for a work permit without the need for an LMIA.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>7.	Biometrics and Medical Examination:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    As part of the application process, you may need to provide biometrics (fingerprints and photo) and undergo a medical examination, depending on the nature of your work and the length of your stay in Canada. 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>8.	Decision on Work Permit:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    After submitting your application, the IRCC will process it, and you will be informed of the decision. If approved, you will receive a work permit.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <p>
                                        Embark on your Canadian adventure and explore the possibilities that await you in this welcoming and thriving country. Our team of experienced immigration consultants is here to guide you through the process, ensuring a smooth transition to your new professional chapter in Canada. Connect with us today and take the first step towards a brighter and prosperous future!
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default WorkCanadaLmiaBased