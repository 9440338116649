import React from 'react'
import {
  CRow,
  CCol,
 // CDropdown,
 // CDropdownMenu,
 // CDropdownItem,
 // CDropdownToggle,
  CWidgetStatsA,
} from '@coreui/react'
// import { getStyle } from '@coreui/utils'
// import { CChartBar, CChartLine } from '@coreui/react-chartjs'
// import CIcon from '@coreui/icons-react'
// import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons'
// import { cilArrowBottom, cilOptions } from '@coreui/icons'
import { useNavigate } from 'react-router-dom';


const AWidgetsDropdown = (props) => {
  const navigate = useNavigate();

  function goToList(url){
    if(props?.id>0){
      
    }else{
      navigate(url, { replace: true });
    }
    
  }

  return (
    <CRow>

      {props?.id>0?
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="success"
            value={
              <>
                {props?.agentName} {' '}
                <span className="fs-6 fw-normal">
                  {/* (-12.4% <CIcon icon={cilArrowBottom} />) */}
                </span>
              </>
            }
            title="Partner"

          />
        </CCol>
      
      
      :""}
      <CCol sm={6} lg={3}>
        <CWidgetStatsA  onClick={() => { goToList("/home/connection-list") }}
          className="mb-4"
          color="primary"
          value={
            <>
              {props?.clients?props.clients:0} {' '}
              <span className="fs-6 fw-normal">
                {/* (-12.4% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="My Clients"
          
        />
      </CCol>
      
      <CCol sm={6} lg={3}>
        <CWidgetStatsA 
          className="mb-4"
          color="warning"
          value={
            <>
              {props?.partners?props.partners:0} {' '}
              <span className="fs-6 fw-normal">
                {/* (84.7% <CIcon icon={cilArrowTop} />) */}
              </span>
            </>
          }
          title="Partners"
          
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="danger"
          value={
            <>
              {props?.admins?props.admins:0} {' '}
              <span className="fs-6 fw-normal">
                {/* (-23.6% <CIcon icon={cilArrowBottom} />) */}
              </span>
            </>
          }
          title="Admins"
          
        />
      </CCol>
    </CRow>
  )
}

export default AWidgetsDropdown
