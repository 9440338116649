import React from 'react'
import { Helmet } from 'react-helmet'
import './../assets/css/custom.css'
import core from './../assets/images/banner-core-1.png';
// import Meta from './Meta';

const CoreExpertise = () => {
    const url = "https://mygrationhub.com";
    const title = "MyGrationHub";
    const keywords = "Migration hub, migrationhub, MygrationHub, MyGration Hub, My Gration Hub";
    const description = "Navigate the world with ease - Trusted consultancy for immigration, education, and travel";
    const image = url + core;

    return (
        <div>
            {/* <Meta title="With offices in Bangladesh and Canada, we hold profound insights into the complexities and advantages inherent in immigration. Our global perspective allows us to navigate the intricacies of diverse immigration systems, while our local roots enable us to provide personalized guidance that resonates with your unique circumstances." keywords="" description="With offices in Bangladesh and Canada, we hold profound insights into the complexities and advantages inherent in immigration. Our global perspective allows us to navigate the intricacies of diverse immigration systems, while our local roots enable us to provide personalized guidance that resonates with your unique circumstances." type="" image={core} iwidth="" iheight=""></Meta> */}
            <Helmet>

                <title>{title}</title>
                <meta name="title" content={title}></meta>
                <meta name="keywords" content={keywords}></meta>
                <meta name="description" content={description}></meta>

                <meta property="og:title" content={title}></meta>
                <meta property="og:description" content={description}></meta>
                <meta property="og:image" content={image}></meta>

                <meta name="twitter:title" content={title}></meta>
                <meta name="twitter:description" content={description}></meta>
                <meta name="twitter:image" content={image}></meta>

            </Helmet>

            <section className="padding-top-adjuster common-segment" id="core-ex">
            
                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='' alt='' src={core}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <h3>Global Expertise, Local Understanding</h3>
                                <p>
                                    With offices in Bangladesh and Canada, we hold profound insights into the complexities and advantages inherent in immigration. Our global perspective allows us to navigate the intricacies of diverse immigration systems, while our local roots enable us to provide personalized guidance that resonates with your unique circumstances.
                                </p>

                                <h3>Client-Centric Approach</h3>
                                <p>
                                    Your dreams are our priority. At Mygration Hub, we believe in a client-centric approach that puts you at the center of every decision. Our dedicated team of consultants is committed to understand your goals, addressing your concerns, and guiding you through a seamless immigration process.
                                </p>

                                <h3>Ethical Practices </h3>
                                <p>
                                    Integrity is the cornerstone of our operations. We uphold the highest ethical standards, ensuring transparency, honesty, and fairness in all our interactions. Your trust is our most valuable asset, and we are committed to preserving it throughout your entire immigration process.
                                </p>

                                <h3>Accuracy and Confidentiality in Documentation</h3>
                                <p>
                                    We understand that accuracy is paramount in the immigration procedure. Our dedicated team focus on detail and unwavering commitment to precision guarantee that your documentation remains error-free. This not only reduces potential delays but also enhances the confidentiality of your information, thereby maximizing the chances of achieving successful outcomes.
                                </p>

                                <h3>Innovation in Immigration Consultancy </h3>
                                <p>
                                    We embrace technology to enhance the immigration experience. Mygration Hub leverages cutting-edge tools and platforms to streamline communication, provide real-time updates, and ensure efficiency in the application process. Our commitment to innovation sets us apart, making your journey with us not only successful but also technologically advanced.
                                </p>
                                <p>
                                    Once you Signup in our portal it will take you to a world of opportunities where you’ll be able to navigate every step of your immigration journey. Following are a glimpse of capabilities once you’re inside the client portal of our website: 
                                </p>
                                
                            </div>
                        </div>

                        
                        <div className='row margin-top-50p items'>
                            <div className='col-lg-12'>
                                <div className='imgCenter'>
                                    <img className='imgCenter50p' alt='' src={require('./../assets/images/core-mh-1.png')}></img>
                                </div>
                                
                            </div>


                            


                            {/* <div className='col-lg-6'>
                                <div className="core-right is-first-expanded">
                                    <img src={require('./../assets/images/core-2.png')}></img>
                                </div>
                            </div> */}

                        </div>

                        
                    </div>
                </div>

                <br></br>
                            <br></br>

            </section>
        </div>

    )
}

export default CoreExpertise