import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-uk.png';
import Meta from './Meta';

const StadyUk = () => {
    const title = "Studying in the UK as an international student involves several steps, including choosing a course, applying to universities, obtaining an offer of admission, and securing a student visa. Here is a detailed outline of the typical process";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <h3>What you need to do to study in UK</h3>

                                    <p>
                                        Studying in the UK as an international student involves several steps, including choosing a course, applying to universities, obtaining an offer of admission, and securing a student visa. Here is a detailed outline of the typical process:
                                    </p>

                                    <h3>1. Choose a Course and University: </h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Secure a job offer from a Canadian employer. The job must be eligible for a work permit, and the employer may need to provide certain documents to support your application.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2. Check Entry Requirements: </h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Review the entry requirements for the chosen course. This may include academic qualifications, English language proficiency, and any additional requirements specific to the program.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>3. English Language Proficiency:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If English is not your first language, you will likely need to demonstrate proficiency through an English language test such as IELTS or TOEFL.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>4. Apply for Admission:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Submit your application to the chosen university through the UCAS (Universities and Colleges Admissions Service) platform or directly to the university. Include all required documents, such as academic transcripts, letters of recommendation, and a personal statement.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>5. Receive Offer of Admission:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    If your application is successful, you will receive an offer of admission from the university. This offer will include details about the course, fees, and any conditions you need to fulfill.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>6. Confirmation of Acceptance for Studies (CAS):</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once you accept the offer, the university will issue a Confirmation of Acceptance for Studies (CAS), which is a crucial document needed for your student visa application.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>7. Financial Proof:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Demonstrate that you have enough funds to cover your tuition fees and living expenses. This can be done through bank statements, scholarship awards, or financial guarantees.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>8. Student Visa Application:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Apply for a student visa (Tier 4) online. You will need your CAS, proof of funds, passport, passport-sized photographs, and other relevant documents.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>9. Biometrics and Health Check:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Attend a biometric appointment at a Visa Application Centre (VAC) to provide fingerprints and a photograph. In some cases, you may need to undergo a health examination.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>10. Visa Decision:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Wait for a decision on your visa application. The processing time can vary, so it&apos;s advisable to apply well in advance of your course start date.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>11. Travel to the UK:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once your visa is approved, make travel arrangements to the UK. Arrive in the UK no earlier than one month before the start of your course.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>12. Register with the University:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Upon arrival, register with the university and attend any orientation sessions provided.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>13. Comply with Visa Conditions:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Follow the conditions of your student visa, including attending classes regularly and maintaining the required academic progress.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <p>
                                        It&apos;s important to note that immigration policies may change, and it&apos;s advisable to check the official UK government website and the website of the chosen university for the most up-to-date information. You can always book an appointment with our registered immigration lawyer to asses you status.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default StadyUk