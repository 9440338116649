import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { CForm, CCol, CFormLabel, CInputGroup, CInputGroupText, CFormSelect, CAvatar, CButton, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CLink, CTableFoot, CFormCheck } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPeople, cilUser, cilMobile } from '@coreui/icons'
import moment from 'moment';
import { Vortex } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import '../assets/css/pagination.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/* const Quill = ReactQuill.Quill;
var Font = Quill.import('formats/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto'];
Quill.register(Font, true); */

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        // ['link', 'image', 'video', 'formula'],

        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        // [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        // [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};

/* const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ]; */

const countryList = [
    { value: '', label: 'Select country' },
    { value: '1', label: 'Afghanistan' },
    { value: '2', label: 'Albania' },
    { value: '3', label: 'Algeria' },
    { value: '4', label: 'American Samoa' },
    { value: '5', label: 'Andorra' },
    { value: '6', label: 'Angola' },
    { value: '7', label: 'Anguilla' },
    { value: '8', label: 'Antarctica' },
    { value: '9', label: 'Antigua and Barbuda' },
    { value: '10', label: 'Argentina' },
    { value: '11', label: 'Armenia' },
    { value: '12', label: 'Aruba' },
    { value: '13', label: 'Australia' },
    { value: '14', label: 'Austria' },
    { value: '15', label: 'Azerbaijan' },
    { value: '16', label: 'Bahamas' },
    { value: '17', label: 'Bahrain' },
    { value: '18', label: 'Bangladesh' },
    { value: '19', label: 'Barbados' },
    { value: '20', label: 'Belarus' },
    { value: '21', label: 'Belgium' },
    { value: '22', label: 'Belize' },
    { value: '23', label: 'Benin' },
    { value: '24', label: 'Bermuda' },
    { value: '25', label: 'Bhutan' },
    { value: '26', label: 'Bosnia and Herzegovina' },
    { value: '27', label: 'Botswana' },
    { value: '28', label: 'Bouvet Island' },
    { value: '29', label: 'Brazil' },
    { value: '30', label: 'British Indian Ocean Territory' },
    { value: '31', label: 'Brunei Darussalam' },
    { value: '32', label: 'Bulgaria' },
    { value: '33', label: 'Burkina Faso' },
    { value: '34', label: 'Burundi' },
    { value: '35', label: 'Cambodia' },
    { value: '36', label: 'Cameroon' },
    { value: '37', label: 'Canada' },
    { value: '38', label: 'Cape Verde' },
    { value: '39', label: 'Cayman Islands' },
    { value: '40', label: 'Central African Republic' },
    { value: '41', label: 'Chad' },
    { value: '42', label: 'Chile' },
    { value: '43', label: 'China' },
    { value: '44', label: 'Christmas Island' },
    { value: '45', label: 'Cocos (Keeling) Islands' },
    { value: '46', label: 'Colombia' },
    { value: '47', label: 'Comoros' },
    { value: '48', label: 'Congo' },
    { value: '49', label: 'Cook Islands' },
    { value: '50', label: 'Costa Rica' },
    { value: '51', label: 'Croatia' },
    { value: '52', label: 'Cuba' },
    { value: '53', label: 'Cyprus' },
    { value: '54', label: 'Czech Republic' },
    { value: '55', label: 'Denmark' },
    { value: '56', label: 'Djibouti' },
    { value: '57', label: 'Dominica' },
    { value: '58', label: 'Dominican Republic' },
    { value: '59', label: 'Ecuador' },
    { value: '60', label: 'Egypt' },
    { value: '61', label: 'El Salvador' },
    { value: '62', label: 'Equatorial Guinea' },
    { value: '63', label: 'Eritrea' },
    { value: '64', label: 'Estonia' },
    { value: '65', label: 'Ethiopia' },
    { value: '66', label: 'Falkland Islands (Malvinas)' },
    { value: '67', label: 'Faroe Islands' },
    { value: '68', label: 'Fiji' },
    { value: '69', label: 'Finland' },
    { value: '70', label: 'France' },
    { value: '71', label: 'French Guiana' },
    { value: '72', label: 'French Polynesia' },
    { value: '73', label: 'French Southern Territories' },
    { value: '74', label: 'Gabon' },
    { value: '75', label: 'Gambia' },
    { value: '76', label: 'Georgia' },
    { value: '77', label: 'Germany' },
    { value: '78', label: 'Ghana' },
    { value: '79', label: 'Gibraltar' },
    { value: '80', label: 'Greece' },
    { value: '81', label: 'Greenland' },
    { value: '82', label: 'Grenada' },
    { value: '83', label: 'Guadeloupe' },
    { value: '84', label: 'Guam' },
    { value: '85', label: 'Guatemala' },
    { value: '86', label: 'Guernsey' },
    { value: '87', label: 'Guinea' },
    { value: '88', label: 'Guinea-Bissau' },
    { value: '89', label: 'Guyana' },
    { value: '90', label: 'Haiti' },
    { value: '91', label: 'Heard Island and McDonald Islands' },
    { value: '92', label: 'Holy See (Vatican City State)' },
    { value: '93', label: 'Honduras' },
    { value: '94', label: 'Hong Kong' },
    { value: '95', label: 'Hungary' },
    { value: '96', label: 'Iceland' },
    { value: '97', label: 'India' },
    { value: '98', label: 'Indonesia' },
    { value: '99', label: 'Iran' },
    { value: '100', label: 'Iraq' },
    { value: '101', label: 'Ireland' },
    { value: '102', label: 'Isle of Man' },
    { value: '103', label: 'Israel' },
    { value: '104', label: 'Italy' },
    { value: '105', label: 'Jamaica' },
    { value: '106', label: 'Japan' },
    { value: '107', label: 'Jersey' },
    { value: '108', label: 'Jordan' },
    { value: '109', label: 'Kazakhstan' },
    { value: '110', label: 'Kenya' },
    { value: '111', label: 'Kiribati' },
    { value: '112', label: 'Kuwait' },
    { value: '113', label: 'Kyrgyzstan' },
    { value: '114', label: 'Lao Peoples Democratic Republic' },
    { value: '115', label: 'Latvia' },
    { value: '116', label: 'Lebanon' },
    { value: '117', label: 'Lesotho' },
    { value: '118', label: 'Liberia' },
    { value: '119', label: 'Libya' },
    { value: '120', label: 'Liechtenstein' },
    { value: '121', label: 'Lithuania' },
    { value: '122', label: 'Luxembourg' },
    { value: '123', label: 'Macao' },
    { value: '124', label: 'Madagascar' },
    { value: '125', label: 'Malawi' },
    { value: '126', label: 'Malaysia' },
    { value: '127', label: 'Maldives' },
    { value: '128', label: 'Mali' },
    { value: '129', label: 'Malta' },
    { value: '130', label: 'Marshall Islands' },
    { value: '131', label: 'Martinique' },
    { value: '132', label: 'Mauritania' },
    { value: '133', label: 'Mauritius' },
    { value: '134', label: 'Mayotte' },
    { value: '135', label: 'Mexico' },
    { value: '136', label: 'Monaco' },
    { value: '137', label: 'Mongolia' },
    { value: '138', label: 'Montenegro' },
    { value: '139', label: 'Montserrat' },
    { value: '140', label: 'Morocco' },
    { value: '141', label: 'Mozambique' },
    { value: '142', label: 'Myanmar' },
    { value: '143', label: 'Namibia' },
    { value: '144', label: 'Nauru' },
    { value: '145', label: 'Nepal' },
    { value: '146', label: 'Netherlands' },
    { value: '147', label: 'New Caledonia' },
    { value: '148', label: 'New Zealand' },
    { value: '149', label: 'Nicaragua' },
    { value: '150', label: 'Niger' },
    { value: '151', label: 'Nigeria' },
    { value: '152', label: 'Niue' },
    { value: '153', label: 'Norfolk Island' },
    { value: '154', label: 'Northern Mariana Islands' },
    { value: '155', label: 'Norway' },
    { value: '156', label: 'Oman' },
    { value: '157', label: 'Pakistan' },
    { value: '158', label: 'Palau' },
    { value: '159', label: 'Panama' },
    { value: '160', label: 'Papua New Guinea' },
    { value: '161', label: 'Paraguay' },
    { value: '162', label: 'Peru' },
    { value: '163', label: 'Philippines' },
    { value: '164', label: 'Pitcairn' },
    { value: '165', label: 'Poland' },
    { value: '166', label: 'Portugal' },
    { value: '167', label: 'Puerto Rico' },
    { value: '168', label: 'Qatar' },
    { value: '169', label: 'Romania' },
    { value: '170', label: 'Russian Federation' },
    { value: '171', label: 'Rwanda' },
    { value: '172', label: 'Saint Kitts and Nevis' },
    { value: '173', label: 'Saint Lucia' },
    { value: '174', label: 'Saint Martin (French part)' },
    { value: '175', label: 'Saint Pierre and Miquelon' },
    { value: '176', label: 'Saint Vincent and the Grenadines' },
    { value: '177', label: 'Samoa' },
    { value: '178', label: 'San Marino' },
    { value: '179', label: 'Sao Tome and Principe' },
    { value: '180', label: 'Saudi Arabia' },
    { value: '181', label: 'Senegal' },
    { value: '182', label: 'Serbia' },
    { value: '183', label: 'Seychelles' },
    { value: '184', label: 'Sierra Leone' },
    { value: '185', label: 'Singapore' },
    { value: '186', label: 'Sint Maarten (Dutch part)' },
    { value: '187', label: 'Slovakia' },
    { value: '188', label: 'Slovenia' },
    { value: '189', label: 'Solomon Islands' },
    { value: '190', label: 'Somalia' },
    { value: '191', label: 'South Africa' },
    { value: '192', label: 'South Georgia and the South Sandwich Islands' },
    { value: '193', label: 'South Sudan' },
    { value: '194', label: 'Spain' },
    { value: '195', label: 'Sri Lanka' },
    { value: '196', label: 'State of Palestine' },
    { value: '197', label: 'Sudan' },
    { value: '198', label: 'Suriname' },
    { value: '199', label: 'Svalbard and Jan Mayen' },
    { value: '200', label: 'Swaziland' },
    { value: '201', label: 'Sweden' },
    { value: '202', label: 'Switzerland' },
    { value: '203', label: 'Syrian Arab Republic' },
    { value: '204', label: 'Tajikistan' },
    { value: '205', label: 'Thailand' },
    { value: '206', label: 'Timor-Leste' },
    { value: '207', label: 'Togo' },
    { value: '208', label: 'Tokelau' },
    { value: '209', label: 'Tonga' },
    { value: '210', label: 'Trinidad and Tobago' },
    { value: '211', label: 'Tunisia' },
    { value: '212', label: 'Turkey' },
    { value: '213', label: 'Turkmenistan' },
    { value: '214', label: 'Turks and Caicos Islands' },
    { value: '215', label: 'Tuvalu' },
    { value: '216', label: 'Uganda' },
    { value: '217', label: 'Ukraine' },
    { value: '218', label: 'United Arab Emirates' },
    { value: '219', label: 'United Kingdom' },
    { value: '220', label: 'United States' },
    { value: '221', label: 'United States Minor Outlying Islands' },
    { value: '222', label: 'Uruguay' },
    { value: '223', label: 'Uzbekistan' },
    { value: '224', label: 'Vanuatu' },
    { value: '225', label: 'Viet Nam' },
    { value: '226', label: 'Wallis and Futuna' },
    { value: '227', label: 'Western Sahara' },
    { value: '228', label: 'Yemen' },
    { value: '229', label: 'Zambia' },
    { value: '230', label: 'Zimbabwe' }
]

const statusList = [
    { value: '', label: 'Select' },
    { value: '4', label: 'Assessment Ongoing' },
    { value: '5', label: 'Info Submitted' },
    { value: '6', label: 'Discuss Later' },
    { value: '7', label: 'Case Profile Reviewed' },
    { value: '8', label: 'Onboard in System' },
    { value: '9', label: 'Others' }

]


const ApprovedUsers = () => {
    const [users, setUsers] = useState();
    const [advisors, setAdvisors] = useState([]);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const [visibleA, setVisibleA] = useState(false);
    const [visibleMV, setVisibleMV] = useState(false);
    const [visibleMS, setVisibleMS] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientId, setClientId] = useState("");
    const [agentId, setAgentId] = useState("");
    // const [selectedAgent, setSelectedAgent] = useState("");
    const [isSpenierOpen, setIsSpenierOpen] = useState(false);
    const [messageColor, setMessageColor] = useState("green");

    const [activityId, setActivityId] = useState("");
    const [activity, setActivity] = useState("");
    // const [activityDate, setActivityDate] = useState("");

    const [userActivities, setUserActivities] = useState([]);

    ///
    const [fullName, setFullName] = useState("");
    const [applyDate, setApplyDate] = useState("");
    const [countryOfResidence, setCountryOfResidence] = useState("");
    const [userId, setUserId] = useState("");
    const [officeEmail, setOfficeEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [statusId, setStatusId] = useState("");
    const [assignedPartnerId, setAssignedPartnerId] = useState("");
    ///


    const messageRef = useRef();
    const [message, setMessage] = useState("");

    const [validated, setValidated] = useState(false);
    const [validatedA, setValidatedA] = useState(false);
    const [isActivityEdited, setIsActivityEdited] = useState(false);
    const [activityActionText, setActivityActionText] = useState("Save");


    function openModal(name, id, selectedId) {
        setClientName(name);
        setClientId(id);
        //setSelectedAgent(selectedId);
        setAgentId(selectedId);

        setMessage('');
        setValidated(false);
        setVisible(true);

    }

    function closeModal() {
        setClientName("");
        setClientId("");
        //setSelectedAgent("");
        setAgentId("");
        setVisible(false);
    }

    function openModalA(client) {
        console.log(client);
        setUserActivities();
        setClientId(client?.id);
        setClientName(client?.fullName);
        setVisibleA(true);
        setValidatedA(false);
        loadActivities(client?.id);
    }

    function viewHandleEditActivities(id, note, date) {
        setActivityId(id);
        setActivity(note);
        // setActivityDate(date);
        setIsActivityEdited(true);
        setActivityActionText("Update");
    }

    function cleanActivity() {
        setActivityId("");
        setActivity("");
        // setActivityDate("");
        setIsActivityEdited(false);
        setActivityActionText("Save");
    }


    const loadActivities = async (id) => {
        const controller = new AbortController();
        try {
            const path = `/getClientActivities/${id}`;
            const response = await axiosPrivate.get(path, {
                signal: controller.signal
            });
            setUserActivities(response.data);
        } catch (err) {
            confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
        }
    }


    const handleFormSubmitA = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            //console.log("no");
            event.preventDefault()
            event.stopPropagation()
        } else {
            //console.log("yes");
            saveActivities(true);
        }

        setValidatedA(false);
        event.preventDefault();


    }

    async function saveActivities() {
        try {
            const formData = {
                id: activityId,
                userInfoId: 0,
                clientInfoId: clientId,
                note: activity
                //,date: activityDate
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            setIsSpenierOpen(true);

            console.log(JSON.stringify(formData));

            let message = isActivityEdited ? "Note updated successfully" : "Note saved successfully";

            await axiosPrivate.post("/saveClientActivities", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    setIsSpenierOpen(false);
                    confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK' }] });
                    cleanActivity();
                    loadActivities(clientId);
                }
                else {
                    setIsSpenierOpen(false);
                    confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
                }

            }, fail => {
                console.error(fail);
                setIsSpenierOpen(false);
                confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
            });
        }

        catch (err) {
            console.log(err);
            setIsSpenierOpen(false);
            confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
        }

    }


    function closeModalA() {
        setClientId("");
        setClientName("");
        setVisibleA(false);
        setUserActivities();
        cleanActivity();
    }


    async function saveClientAgentMap() {
        try {
            const formData = {
                agentId: agentId !== "" ? parseInt(agentId) : 0,
                clientId: clientId
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            setIsSpenierOpen(true);

            console.log(JSON.stringify(formData));

            await axiosPrivate.post("/agentClientMap", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    setIsSpenierOpen(false);
                    setMessageColor("green");
                    setMessage('Partner assigned successfully');
                    messageRef.current.focus();
                }
                else {
                    setIsSpenierOpen(false);
                    setMessageColor("red");
                    setMessage('Partner assigned failed, Try again later');
                    messageRef.current.focus();
                }

            }, fail => {
                console.error(fail);
                setIsSpenierOpen(false);
                setMessageColor("red");
                setMessage('Try Again Later with Re-Login');
                messageRef.current.focus();
            });
        }

        catch (err) {
            console.log(err);
            setIsSpenierOpen(false);
        }

    }


    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            //console.log("no");
            event.preventDefault()
            event.stopPropagation()
        } else {
            //console.log("yes");
            saveClientAgentMap(true);
        }

        setValidated(true);
        event.preventDefault();


    }


    function deleteUserInfo(id, userName, fullName, index) {
        console.log(index);
        confirmAlert({
            title: 'MyGration Hub', message: "Are you sure? You want to delete user - " + fullName,
            buttons: [{
                label: 'Yes',
                onClick: () => deleteDocument(id, userName, index)
            },
            {
                label: 'No',
            }]
        });
    }

    async function deleteDocument(id, userName, index) {
        try {
            const formData = {
                id: id,
                userName: userName
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            await axiosPrivate.post("/deleteUserInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    //console.log(searchedVal.length);
                    confirmAlert({ title: 'MyGration Hub', message: "User deleted successfully", buttons: [{ label: 'OK' }] });
                    if (searchedVal.length > 0) {
                        setUsers(users.filter((v, i) => v.id !== id));
                    } else {
                        setUsers(users.filter((v, i) => i !== index));
                    }
                } else {
                    confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
                }
            }, fail => {
                confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
            });
        }
        catch (err) {
        }

    }


    function deleteActivitiesConfirm(id, note, index) {
        console.log(index);
        confirmAlert({
            title: 'MyGration Hub', message: "Are you sure? You want to delete note - " + note,
            buttons: [{
                label: 'Yes',
                onClick: () => deleteActivities(id, note, index)
            },
            {
                label: 'No',
            }]
        });
    }

    async function deleteActivities(id, note, index) {
        try {
            const formData = {
                id: id,
                userName: note
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            await axiosPrivate.post("/deleteClientActivities", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {

                    confirmAlert({ title: 'MyGration Hub', message: "Note deleted successfully", buttons: [{ label: 'OK' }] });
                    if (userActivities?.length > 0) {
                        setUserActivities(userActivities.filter((v, i) => v.id !== id));
                    } else {
                        setUserActivities(userActivities.filter((v, i) => i !== index));
                    }
                } else {
                    confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
                }
            }, fail => {
                confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
            });
        }
        catch (err) {
        }

    }


    async function handleClear(params) {
        setFullName("");
        setApplyDate("");
        setCountryOfResidence("");
        setUserId("");
        setOfficeEmail("");
        setMobile("");
        setStatusId("");
        setAssignedPartnerId("");
        haddleSearch();


    }
    async function haddleSearch() {
        loadUserList(1, 10);
        setPerPageEntry(10);
        setPageNumber(1);
    }




    useEffect(() => {
        // let isMounted = true;
        let isAdvisorMounted = true;
        const controller = new AbortController();

        /* const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/getAllApprovedUserList', {
                    signal: controller.signal
                });
                isMounted && setUsers(response.data);
            } catch (err) {
                console.log(err);
            }
        } */

        const getAdvisors = async () => {
            try {
                const res = await axiosPrivate.get('/getAllAgentInfoList', {
                    signal: controller.signal
                });
                // console.log(res.data);
                isAdvisorMounted && setAdvisors(res?.data);
            } catch (err) {
                //console.error(err);
                //console.log(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        // getUsers();
        loadUserList(pageNumber, perPageEntry);
        getAdvisors();

        return () => {
            // isMounted = false;
            isAdvisorMounted = false;
            controller.abort();
        }
    }, []);

    function viewHandleClick(client) {
        openModalA(client);
    }

    function viewHandleEditClick(value) {
        localStorage.setItem('edit-user-id', value);
        localStorage.setItem('back-page', '/home/approved-users');
        navigate('/home/edit-user/' + value, { state: { id: value, backPage: '/home/approved-users' }, replace: true });
    }

    function viewMailHistory(client) {
        openModalMV(client);
    }

    /* function idToStatus(id) {
        return id === 4 ? "Assessment Ongoing" : id === 5 ? "Info Submitted" : id === 6 ? "Case Profile Reviewed" : id === 7 ? "Discuss Later" : "More"

    }

    function idToAdvisor(id) {
        let name = "";
        if (id) {
            const obj = advisors?.filter((row) => row?.id === id);
            if (obj) {
                name = obj[0]?.fullName
            }
        }
        if (name === "" || name === "undefined") {
            name = "Select"
        }
        return name;
    } */




    const [searchedVal, setSearchedVal] = useState("");



    const [perPageEntry, setPerPageEntry] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalEntry, setTotalEntry] = useState(0);
    // const [isPrev, setIsPrev] = useState(false);
    // const [isNext, setIsNext] = useState(true);
    const [pageCount, setPageCount] = useState(5);
    const [fromNumber, setFromNumber] = useState("");
    const [toNumber, setToNumber] = useState("5");

    const onPerpageChange = (perPageEntryVal) => {
        setPerPageEntry(perPageEntryVal);
        loadUserList(1, perPageEntryVal);
    };


    const onPageNumbeChange = (event) => {
        const pn = event.selected + 1;
        setPageNumber(pn);
        loadUserList(pn, perPageEntry);
    };



    async function loadUserList(pn, ipp) {
        try {
            const formData = {
                fullName: fullName,
                applyDate: applyDate,
                countryId: countryOfResidence,
                userId: userId,
                mobile: mobile,
                officeEmail: officeEmail,
                agentId: assignedPartnerId,
                applyStatus: statusId,
                pageNumber: pn,
                perPageItem: ipp
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            // console.log(JSON.stringify(formData));

            await axiosPrivate.post("/getAllApprovedUserListBySearch", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    setUsers(res?.data);
                    if (res?.data.length > 0) {
                        const totalUser = res?.data[0]?.fullCount;
                        setTotalEntry(totalUser);
                        const totalPage = Math.ceil(totalUser / ipp);
                        setPageCount(totalPage);
                        console.log(totalUser);
                        console.log(ipp);
                        console.log(totalPage);

                    }

                    const fromN = Number((ipp * (pn - 1)) + 1);
                    const toN = Number((ipp * (pn - 1))) + res?.data.length;
                    setFromNumber(fromN);
                    setToNumber(toN);
                    setIsAllChecked(false);
                    setIsChecked([]);
                }


            }, fail => {

            });
        } catch (err) { }

    }

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [isChecked, setIsChecked] = useState([]);

    const setAllCheckUnCheck = (onChnageValue) => {
        setIsAllChecked(onChnageValue);
        const inputdata = []
        users.forEach((v, i) => {
            inputdata[i] = onChnageValue;
        });
        setIsChecked(inputdata);
    }

    const setCheckUnCheck = (onChnageValue, i, id) => {
        const inputdata = [...isChecked]
        inputdata[i] = onChnageValue;
        setIsChecked(inputdata);

        let checkCount = inputdata?.filter((row) => row === true).length;

        if (checkCount === 0) {
            setIsAllChecked(false);
        } else if (checkCount === users?.length) {
            setIsAllChecked(true);
        } else {
            setIsAllChecked(false);
        }
    }

    

    const [userMailHistory, setUserMailHistory] = useState([]);
    function openModalMV(client) {
        setUserMailHistory([]);
        setClientId(client?.id);
        setClientName(client?.fullName);
        setVisibleMV(true);
        loadUserMailHistory(client?.officeEmail);
    }

    function closeModalMV() {
        setUserMailHistory([]);
        setClientId("");
        setClientName("");
        setVisibleMV(false);
    }


    const loadUserMailHistory = async (mail) => {
        const controller = new AbortController();
        try {
            const path = `/getClientMailHistory/${mail}`;
            const response = await axiosPrivate.get(path, {
                signal: controller.signal
            });
            setUserMailHistory(response?.data);
            console.log(response?.data);
        } catch (err) {
            confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
        }
    }


    const [mailBody, setMailBody] = useState('');
    const [mailSubject, setMailSubject] = useState('');
    const [validatedSM, setValidatedSM] = useState(false);


    async function openMailPopup() {
        try {
            let userList = [];
            let c = 0;
            users.forEach((v, i) => {
                if (isChecked[i]) {
                    userList.push(v?.id);
                    c++;
                }
            });
            if (c === 0) {
                confirmAlert({ title: 'MyGration Hub', message: "Please select at least one check box", buttons: [{ label: 'OK' }] });
                return false;
            }
            openModalMS();
        } catch (err) {
            console.log(err);
        }

    }


    function openModalMS(client) {
        setMailSubject("");
        setMailBody("<p>Dear @name,</p><p><br/>&nbsp;</p>");
        setVisibleMS(true);

    }

    function closeModalMS() {
        setMailBody("<p>Dear @name,</p><p><br/>&nbsp;</p>");
        setMailSubject("");
        setVisibleMS(false);
    }


    const handleFormSubmitSendMail = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            //console.log("no");
            event.preventDefault()
            event.stopPropagation()
        } else {
            //console.log("yes");
            // setValidatedSM(true);
            sendMail();
        }

        setValidatedSM(false);
        event.preventDefault();

    }
    async function sendMail() {
        try {
            let userList = [];
            let data = [];
            let c = 0;
            users.forEach((v, i) => {
                if (isChecked[i]) {
                    // userList.push(v?.id);
                    userList.push(v);
                    c++;
                }
            });


            setIsSpenierOpen(true);

            const formData = {
                users: userList,
                subject: mailSubject,
                body: "<!DOCTYPE html><html><head><title></title><meta charset='UTF-8'><meta name='viewport' content='width=device-width, initial-scale=1'></head><body> "+mailBody +" </body> </html>"
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            console.log(formData);

            await axiosPrivate.post("/saveEmail", JSON.stringify(formData), { headers: headers }).then((res) => {
                if (res.status === 200) {
                    setIsSpenierOpen(false);
                    confirmAlert({ title: 'MyGration Hub', message: "Email Sent Successfully", buttons: [{ label: 'OK' }] });
                }
                else {
                    setIsSpenierOpen(false);
                    confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
                }

            }, fail => {
                console.error(fail);
                setIsSpenierOpen(false);
                confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
            });

        } catch (err) {
            console.log(err);
            // setIsSpenierOpen(false);
            confirmAlert({ title: 'MyGration Hub', message: "Please relogin and try again", buttons: [{ label: 'OK' }] });
        }

    }


    return (
        <article>

            <CModal
                backdrop="static"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="StaticBackdropExampleLabel" >
                <CModalHeader onClose={() => closeModal()}>
                    <CModalTitle id="LiveDemoExampleLabel">Client Partner Mapping</CModalTitle>
                </CModalHeader>
                <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" validated={validated} onSubmit={handleFormSubmit} >
                    <CModalBody>
                        <CCol md={12}>
                            <CFormLabel htmlFor="validationClientName">
                                <p ref={messageRef} className={messageColor + " font-size-20px"} aria-live="assertive">&nbsp; {message} &nbsp;</p>
                            </CFormLabel>
                            <CInputGroup className="has-validation">
                                <CInputGroupText>Client</CInputGroupText>
                                <CFormInput type="text" aria-describedby="validationClientName" id="validationClientName" value={clientName} disabled feedbackInvalid="Required!" required></CFormInput>
                            </CInputGroup>
                        </CCol>


                        <CCol md={12}>
                            <CFormLabel htmlFor="validationAdvisor"></CFormLabel>
                            <CInputGroup className="has-validation">
                                <CInputGroupText>Partner</CInputGroupText>
                                <CFormSelect aria-describedby="validationAdvisor" id="validationAdvisor" value={agentId} onChange={(event) => { setAgentId(event.target.value); }} feedbackInvalid="Required!" required>
                                    <option value="">Select...</option>
                                    {
                                        advisors && advisors?.map((row) => <option key={row?.id} value={row?.id}  >{row?.fullName}</option>)

                                    }
                                </CFormSelect>
                            </CInputGroup>
                        </CCol>


                    </CModalBody>
                    <CModalFooter>
                        {/* <CButton color="danger" onClick={() => closeModal()}> Cancel </CButton> */}
                        {isSpenierOpen ?
                            <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                            :
                            <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Assign </CButton>
                        }
                    </CModalFooter>
                </CForm>
            </CModal>

            {/* Activities Modal */}
            <CModal
                size="xl"
                backdrop="static"
                visible={visibleA}
                onClose={() => closeModalA()}
                aria-labelledby="StaticBackdropExampleLabelActivities" >
                <CModalHeader onClose={() => closeModalA()}>
                    <CModalTitle id="LiveDemoExampleLabelActivities">Activities on {clientName} </CModalTitle>
                </CModalHeader>
                {/* <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} ></CForm> */}
                <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" validated={validatedA} onSubmit={handleFormSubmitA} >
                    <CModalBody>





                        {/* <ClientActivities id={1}></ClientActivities> */}

                        <CRow className="mb-12">
                            <div className="col-sm-9"> Note <span className='red'>*</span>
                                <CInputGroup className="has-validation">
                                    <CFormInput aria-describedby="validationCustomGoal" id="validationCustomGoal" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={activity} onChange={(event) => { setActivity(event.target.value); }} maxLength={255} required />
                                </CInputGroup>

                            </div>
                            {/* <div className="col-sm-3">Date <span className='red'>*</span>
                                <CInputGroup className="has-validation">
                                    <DatePicker
                                        //showMonthDropdown
                                        //showYearDropdown
                                        //yearDropdownItemNumber={100}
                                        //scrollableYearDropdown
                                        // id="validationlanguageTestReDate"
                                        aria-describedby="validationCustomActivityDate"
                                        // minDate={new Date()}
                                        className="form-control date-picker-custom"
                                        selected={Date.parse(activityDate)} onChange={(date) => setActivityDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                        required
                                    />
                                </CInputGroup>
                            </div> */}
                            <div className="col-sm-3"> &nbsp;
                                <CInputGroup className="has-validation">
                                    {isSpenierOpen ?
                                        <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                                        :
                                        <>
                                            <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> {activityActionText} </CButton> &nbsp;
                                            {isActivityEdited ? <CButton className="btn btn-danger" onClick={() => { cleanActivity() }} style={{ borderRadius: "5px" }} color="danger" > Cancel </CButton> : ''}
                                        </>
                                    }
                                </CInputGroup>

                            </div>
                        </CRow>


                    </CModalBody>



                    <CModalFooter style={{ display: "unset" }}>

                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell scope="col" width="5%"> SL</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="55%">Note</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="10%">Date</CTableHeaderCell>
                                    {/* <CTableHeaderCell scope="col" width="10%" className="text-center">Created Date</CTableHeaderCell> */}
                                    <CTableHeaderCell scope="col" width="15%" className="text-center">Created By</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="15%" className="text-center" >Actions</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {userActivities?.length > 0 && userActivities?.map((row, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell>{i + 1} </CTableDataCell>
                                        <CTableDataCell>{row?.note}</CTableDataCell>
                                        {/* <CTableDataCell>{row?.date}</CTableDataCell> */}
                                        <CTableDataCell className="text-center">{row?.dateCreated}</CTableDataCell>
                                        <CTableDataCell className="text-center">{row?.userName}</CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {row?.isOperational === 1 ?
                                                <>

                                                    <CButton color="success" onClick={() => { viewHandleEditActivities(row?.id, row?.note, row?.date) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                                    <CButton color="danger" onClick={() => { deleteActivitiesConfirm(row?.id, row?.note, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                                </>
                                                :
                                                <>N/A</>
                                            }


                                        </CTableDataCell>

                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>

                    </CModalFooter>
                </CForm>
            </CModal>


            {/* view mail Modal */}
            <CModal
                size="xl"
                backdrop="static"
                visible={visibleMV}
                onClose={() => closeModalMV()}
                aria-labelledby="StaticBackdropExampleLabelActivitiesMV" >
                <CModalHeader onClose={() => closeModalMV()}>
                    <CModalTitle id="LiveDemoExampleLabelActivities">Mail Sent to {clientName} </CModalTitle>
                </CModalHeader>
                <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form"  >
                    <CModalBody>


                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell scope="col" width="5%"> SL</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="10%">Date</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="15%" className="text-center" >Mail Subject</CTableHeaderCell>
                                    <CTableHeaderCell scope="col" width="70%" className="text-center" >Mail Body</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {userMailHistory?.length > 0 && userMailHistory?.map((row, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell>{i + 1} </CTableDataCell>
                                        <CTableDataCell>{moment(row?.createDate).format("YYYY-MM-DD h:mm A (Z)")}</CTableDataCell>
                                        <CTableDataCell className="text-center">{row?.subject}</CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            <p id="mailBody" style={{ textAlign: "justify" }} className='mailBody' dangerouslySetInnerHTML={{ __html: row?.body }}></p>


                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>



                    </CModalBody>

                    <CModalFooter style={{ display: "unset" }}> </CModalFooter>

                </CForm>
            </CModal>


            {/* send mail modal */}
            <CModal
                size="xl"
                backdrop="static"
                visible={visibleMS}
                onClose={() => closeModalMS()}
                aria-labelledby="StaticBackdropExampleLabelActivitiesMS" >
                <CModalHeader onClose={() => closeModalMS()}>
                    <CModalTitle id="LiveDemoExampleLabelMailSend">Send email to selected users </CModalTitle>
                </CModalHeader>
                <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" validated={validatedSM} onSubmit={handleFormSubmitSendMail}  >
                    <CModalBody>


                        <CRow>
                            <CCol md={12}>
                                <CInputGroup className="has-validation">
                                    <CInputGroupText>Subject</CInputGroupText>
                                    <CFormInput type="text" aria-describedby="validationMailSend" id="validationMailSend" value={mailSubject} onChange={(event) => { setMailSubject(event.target.value); }} feedbackInvalid="Required!" required></CFormInput>
                                </CInputGroup>
                            </CCol>
                        </CRow>

                        
                        <CRow>

                            <CCol md={12}>
                                <br></br>
                                <ReactQuill modules={modules} theme="snow" value={mailBody} onChange={setMailBody} />
                            </CCol>

                        </CRow>
                    </CModalBody>



                    <CModalFooter style={{ display: "unset" }}>

                        

                        {isSpenierOpen ?
                            <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                            :
                            <CButton color="success" type="submit" >Send Mail</CButton>
                        }
                    </CModalFooter>

                </CForm>
            </CModal>


            <>
                <CRow >
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomFirstName">Name</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                            <CFormInput
                                id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={fullName} onChange={(event) => { setFullName(event.target.value); }} />
                        </CInputGroup>
                    </CCol>
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomDateOfBirth">Apply Date</CFormLabel>
                        <CInputGroup className="has-validation customDatePickerWidth">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                                maxDate={new Date()}
                                id="validationCustomDateOfBirth"
                                className="form-control date-picker-custom"
                                selected={applyDate} onChange={(date) => setApplyDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                required />
                        </CInputGroup>
                    </CCol>
                </CRow>
                <CRow >
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomCR">Country</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CFormSelect id="validationCustomCR" size="md" aria-label="" options={countryList} value={countryOfResidence} onChange={(event) => { setCountryOfResidence(event.target.value); }}  ></CFormSelect>
                        </CInputGroup>
                    </CCol>
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomFirstName">User Id</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                            <CFormInput
                                id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={userId} onChange={(event) => { setUserId(event.target.value); }} />
                        </CInputGroup>
                    </CCol>
                </CRow>

                <CRow >
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomMobile">Mobile</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                            <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={mobile} onChange={(event) => { setMobile(event.target.value); }} />
                        </CInputGroup>
                    </CCol>
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomOfficeEmail">Email</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CInputGroupText>@</CInputGroupText>
                            <CFormInput type="text" aria-describedby="validationCustomOfficeEmail" feedbackValid="Looks good!" id="validationCustomOfficeEmail" value={officeEmail} onChange={(event) => { setOfficeEmail(event.target.value); }} />
                        </CInputGroup>
                    </CCol>
                </CRow>

                <CRow >
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomCR">Status</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CFormSelect id="validationCustomCR" size="md" aria-label="" options={statusList} value={statusId} onChange={(event) => { setStatusId(event.target.value); }}  ></CFormSelect>
                        </CInputGroup>

                    </CCol>
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CFormLabel htmlFor="validationCustomCR">Assigned Partner</CFormLabel>
                        <CInputGroup className="has-validation">
                            <CFormSelect value={assignedPartnerId} onChange={(event) => { setAssignedPartnerId(event.target.value); }} >
                                <option value="" >Select</option>
                                {advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} >{row?.fullName}</option>)}/</CFormSelect>
                        </CInputGroup>
                    </CCol>
                </CRow>

                <CRow >
                    <CCol md={12} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <CButton type="submit" color="info" onClick={() => { haddleSearch() }} >Search</CButton> &nbsp;
                        <CButton type="submit" color="danger" onClick={() => { handleClear() }} >Clear</CButton> &nbsp;

                    </CCol>
                </CRow>

                {/* <CRow >
                <CCol md={12} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                    <h3 className="text-center">Approved Users List</h3>
                </CCol>
            </CRow> */}


                <CRow >
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        <h2>Approved Users List</h2>
                    </CCol>
                    <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
                        {/* <CInputGroup className="has-validation">
                            <CInputGroupText> <i className="fa fa-search"></i></CInputGroupText>
                            <CFormInput id="searchItemId1" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={searchedVal} onChange={(e) => setSearchedVal(e.target.value)} />
                        </CInputGroup> */}
                    </CCol>
                </CRow>
            </>
            {/* {console.log(users?.length)} */}
            {users?.length
                ? (
                    <div>

                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell scope="col">

                                        <CFormCheck className="emailButton" inline checked={isAllChecked} onChange={(event) => setAllCheckUnCheck(event.target.checked)} />

                                    </CTableHeaderCell>
                                    <CTableHeaderCell className="text-center">
                                        <CButton type="submit" color="info" onClick={() => { openMailPopup() }} ><i className="fa fa-envelope"></i></CButton>
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Country of Residance</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Mobile/Email</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Partner</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            {/* <CTableBody>
                                {users.filter((row) =>
                                    (row?.fullName + " " + row?.userId + " " + row?.userResidence[0]?.country?.name + " " + row?.mobile + " " + row?.officeEmail + " " + idToStatus(row?.applyStatus) + " " + idToAdvisor(row?.agentId) + " " + moment(row?.dateCreated).format("DD-MM-YYYY"))
                                        .toString()
                                        .toLowerCase()
                                        .includes(searchedVal.toString().toLowerCase())

                                )?.map((user, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell className="text-center">

                                            {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                                                <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                                :
                                                <CAvatar size="md" className="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                            }




                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.fullName}</div>
                                            <div className="small text-medium-emphasis">
                                                <span>{user?.applyStatus === 3 ? 'New Applied' : 'Approved'}</span> | Apply Date:{moment(user?.dateCreated).format("DD-MM-YYYY")}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.userResidence?.map((row) => row?.country?.name)}</div>
                                            <div className="small text-medium-emphasis">
                                                ID:{user?.userId}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="clearfix">
                                                <div>{user?.mobile}</div>
                                                <div>{user?.officeEmail}</div>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {user?.applyStatus === 4 ? "Assessment Ongoing" : user?.applyStatus === 5 ? "Info Submitted" : user?.applyStatus === 6 ? "Case Profile Reviewed" : user?.applyStatus === 7 ? "Discuss Later" : "More"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {
                                                user?.agentId ?
                                                    <CFormSelect onChange={(e) => { openModal(user?.fullName, user?.id, e.target.value) }}>
                                                        <option value="" >Select</option>
                                                        {
                                                            advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} selected={row?.id === user.agentId ? 'selected' : ''} >{row?.fullName}</option>)

                                                        }
                                                    </CFormSelect>


                                                    :

                                                    <CFormSelect onChange={(e) => { openModal(user?.fullName, user?.id, e.target.value) }}>
                                                        <option value="" selected>Select</option>
                                                        {
                                                            advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} selected={row?.id === user.agentId ? 'selected' : ''} >{row?.fullName}</option>)

                                                        }
                                                    </CFormSelect>
                                            }

                                        </CTableDataCell>
                                        <CTableDataCell width={220}>
                                            <CButton type="submit" color="info" onClick={() => { viewHandleClick(user) }} ><i className="fa fa-comment"></i></CButton> &nbsp;
                                            <CButton type="submit" color="info"   > <CLink target="_blank" href={'/#/home/user-details/' + user?.id} style={{ color: "black" }} > <i className="fa fa-eye"></i> </CLink> </CButton> &nbsp;
                                            <CButton type="submit" color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                            <CButton type="submit" color="danger" onClick={() => { deleteUserInfo(user?.id, user?.userName, user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody> */}

                            <CTableBody>
                                {users.filter((row) =>
                                    (row?.fullName + " " + row?.userId + " " + row?.country + " " + row?.mobile + " " + row?.officeEmail + " " + row?.status + " " + row?.agentName + " " + row?.dateCreated)
                                        .toString()
                                        .toLowerCase()
                                        .includes(searchedVal.toString().toLowerCase())

                                )?.map((user, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell>
                                            <CFormCheck inline checked={isChecked[i]} onChange={(event) => setCheckUnCheck(event.target.checked, i, user?.id)} /> <br></br>

                                            <i className="fa fa-eye" onClick={() => viewMailHistory(user)}></i>

                                        </CTableDataCell>

                                        <CTableDataCell className="text-center">

                                            {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                                                <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                                :
                                                <CAvatar size="md" className="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                            }

                                        </CTableDataCell>

                                        <CTableDataCell>
                                            <div>{user?.fullName}</div>
                                            <div className="small text-medium-emphasis">
                                                <span>{user?.applyStatus === 3 ? 'New Applied' : 'Approved'}</span> | Apply Date:{user?.dateCreated}
                                            </div>
                                        </CTableDataCell>

                                        <CTableDataCell>
                                            <div>{user?.country}</div>
                                            <div className="small text-medium-emphasis">
                                                ID:{user?.userId}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="clearfix">
                                                <div>{user?.mobile}</div>
                                                <div>{user?.officeEmail}</div>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {user?.status}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {
                                                user?.agentId ?
                                                    <CFormSelect onChange={(e) => { openModal(user?.fullName, user?.id, e.target.value) }}>
                                                        <option value="" >Select</option>
                                                        {
                                                            advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} selected={row?.id === user.agentId ? 'selected' : ''} >{row?.fullName}</option>)

                                                        }
                                                    </CFormSelect>


                                                    :

                                                    <CFormSelect onChange={(e) => { openModal(user?.fullName, user?.id, e.target.value) }}>
                                                        <option value="" selected>Select</option>
                                                        {
                                                            advisors && advisors?.map((row) => <option key={row?.id} value={row?.id} selected={row?.id === user.agentId ? 'selected' : ''} >{row?.fullName}</option>)

                                                        }
                                                    </CFormSelect>
                                            }

                                        </CTableDataCell>
                                        <CTableDataCell width={220}>
                                            <CButton type="submit" color="info" onClick={() => { viewHandleClick(user) }} ><i className="fa fa-comment"></i></CButton> &nbsp;
                                            <CButton type="submit" color="info"   > <CLink target="_blank" href={'/#/home/user-details/' + user?.id} style={{ color: "black" }} > <i className="fa fa-eye"></i> </CLink> </CButton> &nbsp;
                                            <CButton type="submit" color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                            <CButton type="submit" color="danger" onClick={() => { deleteUserInfo(user?.id, user?.userName, user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>

                            <CTableFoot className="footer-pagination">

                                <CTableRow v-for="item in tableItems">
                                    <CTableDataCell colSpan={6}>

                                        <ReactPaginate
                                            breakLabel="..."
                                            className="pagination"
                                            nextLabel="&raquo;"
                                            onPageChange={onPageNumbeChange}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="&laquo;"
                                            renderOnZeroPageCount={null}
                                        />


                                    </CTableDataCell>


                                    <CTableDataCell colSpan={2}>

                                        <CFormSelect className="width100pni inlineLeft" value={perPageEntry} onChange={(event) => { onPerpageChange(event.target.value); }} >
                                            <option value="10" >10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value={totalEntry}> ALL</option>
                                        </CFormSelect>

                                        {/* <div className="inlineLeft padleft5Top10p">Showing {Number((perPageEntry * (pageNumber - 1)) + 1)} to {Number((pageNumber - 1) + perPageEntry) < Number(totalEntry) ? Number((pageNumber - 1) + perPageEntry) : totalEntry} of {totalEntry}</div> */}
                                        <div className="inlineLeft padleft5Top10p">Showing {fromNumber} to {toNumber} of {totalEntry}</div>



                                        {/* <Items currentItems={currentItems} />
 */}

                                    </CTableDataCell>
                                </CTableRow>


                            </CTableFoot>

                        </CTable>


                    </div>
                ) : <p>No users to display</p>
            }
        </article>
    )
}
export default ApprovedUsers;
