import React, {useState,useEffect} from "react";
import {CCardHeader, CTable, CTableBody, CTableDataCell,  CTableRow,CCard, CCardBody,  CCol, CProgress, CRow,CAvatar, CTableHead, CTableHeaderCell } from '@coreui/react';
import useAxiosPrivate from "../../src/hooks/useAxiosPrivate";
import {
  // useNavigate,
  Link,
  useLocation} from 'react-router-dom';
import { CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';

import Goals from '../components/Goals';
import Scheduling from "src/components/Scheduling";
import Meeting from "src/components/Meeting";
import UpcomingMeeting from "src/components/UpcomingMeeting";
import ClientProfileStatus from "src/components/ClientProfileStatus";
// import avatar8 from '../assets/images/avatars/avater.png';
import mygrationhubText from '../assets/images/logo/mygrationhub-text.jpg';
import '../assets/css/custom.css';
import CIcon from '@coreui/icons-react'
import { cilPeople } from '@coreui/icons'
import referralPromo from '../assets/bg/referral_promo.mp4'

const ClientDashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  // const navigate = useNavigate();
  const location = useLocation();

  const [profileData, setProfileData] = useState([]);
  const [doumentsData, setDoumentsData] = useState([]);
  const [meetingCounter, setMeetingCounter] = useState(0);
  const [upcommingMeetingCounter, setUpcommingMeetingCounter] = useState(0);
  const [applyStatus, setAppplyStatus] = useState();
  const [referreredUserList, setReferreredUserList] = useState([]);
  const [referrerUserId, setReferrerUserId] = useState();
  // const [pictureContent, setPictureContent] = useState(avatar8);



  useEffect(() => {
    const controller = new AbortController();
    const lsAgenId = localStorage.getItem('clientboard-user-id');
    let id = location?.state?.id ? location.state.id : lsAgenId ? lsAgenId : 0;

    const getUsers = async () => {
      try {
        const formData = {
          id: id,
        }

        const headers = {
          'Content-Type': 'application/json'
        }

        await axiosPrivate.post("/getClientDashboard", JSON.stringify(formData), { headers: headers }).then((res) => {
          if (res.status === 200) {
            setProfileData(res?.data?.profileStatus);
            setDoumentsData(res?.data?.documentStatus);
            setAppplyStatus(res?.data?.applyStatus);
            setReferreredUserList(res?.data?.referredUserList);
            setReferrerUserId(res?.data?.referrerUserId);

          }

        }, fail => {

        });
      }
      catch (err) {
      }
    }

    /* const getProfilePic = async () => {
      try {
        let path = "/downloadProfilePicture";
        axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            if (response?.data !== null && response?.data !=="" && response?.data!=='undefine') {
              setPictureContent(response?.data?.code);
            }
          }
  
        }, fail => {
  
        });;
  
      } catch (err) {
        console.log(err);
      }
    }

    getProfilePic(); */
    getUsers();
    return () => {
      controller.abort();
    }
  }, []);


  function getColor(val) {
    if (val <= 50) {
      return "danger";
    } else if (val <= 60 && val > 50) {
      return "warning";
    } else if (val <= 70 && val > 60) {
      return "primary";
    } else if (val <= 80 && val > 70) {
      return "info";
    } else if (val <= 90 && val > 80) {
      return "success";
    } else if (val <= 100 && val > 90) {
      return "success";
    } else {
      return "";
    }

  }

  // function onclickToAnother(page){
  //   navigate(page, { replace: true });
  // }


  const handleStateChange = (value) => {
    setMeetingCounter(value);
  };

  const upMcStateChange = (value) => {
    setUpcommingMeetingCounter(value);
  };

  return (
    <>

      {/* <AWidgetsDropdown  clients={dashboardData?.aclient} partners={dashboardData?.partners} admins={dashboardData?.admins}   /> */}
      {applyStatus && applyStatus===100 ?
        <CCard className="mb-4">

          <CCardBody >
            <CRow className="padding2px pl15 pr15">

              <CCol md={6}>
                <video width="100%" controls autoPlay muted >
                  <source src={referralPromo} type="video/mp4" />
                  {/* <source src={referralPromo} type="video/ogg" /> */}
                  Your browser does not support the video tag.
                </video>
              </CCol>

              <CCol md={6}>
                <CCardBody>

                  <div className="mb-4 text-center" >FUNDED BY</div>
                  <div className="mb-4 text-center">
                    <span className="" >
                      <img src={mygrationhubText} alt="" className="mygrationhubtext" style={{maxWidth:"60%"}} ></img>
                    </span>
                  </div>
                  {/* <div className="mb-4 text-center" >&nbsp;</div> */}
                  <div className="mb-4 text-center" >
                    {/* <CButton color="" size="sm" variant="outline" >&nbsp;</CButton> */}

                    Click below link to refer client <br/>
                    
                    <Link className='hover-under-line' target="_blank" to={"https://mygrationhub.com/#/register/"+referrerUserId}><p className="linkColor">{"https://mygrationhub.com/#/register/"+referrerUserId}</p></Link>
                    &nbsp;
                  </div>

                </CCardBody>
              </CCol>
            </CRow>

            <CRow className="padding2px pl15 pr15">

              <CCol lg={12}>
                 <h3>My Referred List</h3>
              </CCol>

            </CRow>

            <CRow className="padding2px pl15 pr15">

              <CCol lg={12}>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell className="text-center">
                        <CIcon icon={cilPeople} />
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Country</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Mobile/Email</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                      {/* <CTableHeaderCell scope="col" className="text-center">Actions</CTableHeaderCell> */}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {referreredUserList && referreredUserList?.map((user, i) => (
                      <CTableRow v-for="item in tableItems" key={i}>
                        <CTableDataCell className="text-center">

                          {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                            <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                            :
                            <CAvatar size="md" className="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                          }


                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{user?.fullName}</div>
                          <div className="small text-medium-emphasis">
                            <span>{user?.applyStatus === 3 ? 'New Applied' : 'Approved'}</span> | Apply Date:{user?.dateCreated}
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{user?.country}</div>
                          <div className="small text-medium-emphasis">
                            ID:{user?.userId}
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div className="clearfix">
                            {/* {user?.mobile} */}
                            <div>{user?.mobile}</div>
                            <div>{user?.officeEmail}</div>

                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{user?.status}</div>

                          {/* <div>{user?.agentName}</div> */}
                        </CTableDataCell>
                        {/* <CTableDataCell width={220}>
                        <CButton type="submit" color="info" onClick={() => { viewHandleClickA(user) }} ><i className="fa fa-comment"></i></CButton> &nbsp;
                        <CButton type="submit" color="info"   > <CLink target="_blank" href={'/#/home/user-details/' + user?.id} style={{ color: "black" }} > <i className="fa fa-eye"></i> </CLink> </CButton> &nbsp;
                        <CButton type="submit" color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                        <CButton type="submit" color="danger" onClick={() => { deleteUserInfo(user?.id, user?.userName, user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                      </CTableDataCell> */}
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>





          </CCardBody>


        </CCard>
      :
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={12}>
              <h4 id="traffic" className="card-title mb-0">
                Hi, {profileData?.name}
              </h4>
              <div className="small text-medium-emphasis">{profileData?.partnerName ? "Your assigned partner is " + profileData?.partnerName : ""}</div>
            </CCol>
          </CRow>
        </CCardBody>


        <CRow className="padding2px pl15 pr15">
        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

                {(profileData!==null) &&
                  <ClientProfileStatus profileData={profileData}></ClientProfileStatus>
                  }
              
              {/* <div className="mb-4 text-center marginBottom15px" >Profile</div>
              <div className="mb-4 text-center marginBottom15px">
                <span className="dppc" >
                  <img src={pictureContent} alt="" className="dprofilepic" ></img>
                </span>
              </div>
              <div className="mb-4 text-center marginBottom15px" >{profileData?.total}% Complete</div>
              <div className="mb-4 text-center marginBottom15px" >
                <CButton color="dark" size="sm" onClick={() => onclickToAnother("/home/edit-user")} variant="outline" >View & Edit</CButton> 
              </div> */}

            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <Meeting onStageChange={handleStateChange} value={meetingCounter} upMc={upcommingMeetingCounter}></Meeting>

            </CCardBody>
          </CCard>
        </CCol>


        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <Scheduling></Scheduling>

            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight">
            <CCardBody>

              <div className="mb-4 text-center" >FUNDED BY</div>
              <div className="mb-4 text-center">
                <span className="" >
                  <img src={mygrationhubText} alt="" className="mygrationhubtext" ></img>
                </span>
              </div>
              <div className="mb-4 text-center" >&nbsp;</div>
              <div className="mb-4 text-center" >
                {/* <CButton color="" size="sm" variant="outline" >&nbsp;</CButton> */}
                &nbsp;
              </div>
              

            </CCardBody>
          </CCard>
        </CCol>

      </CRow>


      <CRow className="padding2px">
        <CCol md={6}>
          <CCard className="mb-4 cutomCardHight">
            {/* <CCardHeader>Upcoming Meetings</CCardHeader> */}
            <CCardBody>

                <UpcomingMeeting upcmOnStageChange={upMcStateChange} meetingCounter={meetingCounter}></UpcomingMeeting>

            </CCardBody>
          </CCard>
        </CCol>
      

        <CCol md={6}>
          <CCard className="mb-4 cutomCardHight">
            {/* <CCardHeader>&nbsp;</CCardHeader> */}
            <CCardBody>
              <Goals></Goals>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>


        {/* 

        <CCardBody>
          <CRow>
            <CCardHeader>Profile Status</CCardHeader>
            <CCol md={6}>
              <CCardBody>

                <div className="float-start">
                  <strong>{profileData?.total} %</strong> Completed
                </div><br></br>

                <CProgress thin color={getColor(profileData?.total)} value={profileData?.total} />

              </CCardBody>
            </CCol>
            <CCol md={6}>
              <CCardBody>
                {profileData
                  ? (
                    <div>

                      <CTable align="middle" className="mb-0 border" hover responsive>
                      <CTableHead color="light">
                          <CTableRow>
                            <CTableHeaderCell scope="col">Line Items</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Status</CTableHeaderCell>

                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                              Basic Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.basic>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                              Personal Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.personal>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>


                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                            Marital Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.marital>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                            Address Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.address>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>


                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                            Language Test Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.language>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                            Education Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.education>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                              Work Experience Information
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.work>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                            Profile Picture Upload
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.ppicture>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                              Resume Upload
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.resume>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow v-for="item in tableItems" key={1} >
                            <CTableDataCell>
                              Onboard in MygrationHub
                            </CTableDataCell>
                            <CTableDataCell>
                              {profileData?.acc>0?
                              <i className="fa fa-check " style={{fontSize:"20px",color:"green"}} aria-hidden="true"></i>
                              :
                              <i className="fa fa-close" style={{fontSize:"20px",color:"red"}} aria-hidden="true"></i>
                              }
                            </CTableDataCell>
                          </CTableRow>


                        </CTableBody>

                      </CTable>


                    </div>
                  ) : ""
                }


              </CCardBody>
            </CCol>
          </CRow>
        </CCardBody> 
        
        */}


        <CRow className="padding2px">

          <CCol md={12}>
            <CCard className="mb-4">
              <CCardHeader>Profile Status</CCardHeader>
              <CCardBody>
                {profileData
                  ? (
                    <div>

                      <CDropdown variant="nav-item">
                        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                          <div>
                            <div className="float-start">
                              <strong>{profileData?.total} %</strong> Completed
                            </div><br></br>
                            <CProgress thin color={getColor(profileData?.total)} value={profileData?.total} />
                          </div>
                        </CDropdownToggle>
                        <CDropdownMenu className="pt-0" placement="bottom-end">

                          <CDropdownHeader className="bg-light fw-semibold py-2">Profile Completion Status</CDropdownHeader>
                          <CDropdownItem >

                            <div>

                              <CTable align="middle" className="mb-0 border" hover responsive style={{width:"100%"}}>
                                <CTableBody>
                                  <CTableRow v-for="item in tableItems" key={1} >
                                    <CTableDataCell>
                                      Basic Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.basic > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={2} >
                                    <CTableDataCell>
                                      Personal Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.personal > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>


                                  <CTableRow v-for="item in tableItems" key={3} >
                                    <CTableDataCell>
                                      Marital Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.marital > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={4} >
                                    <CTableDataCell>
                                      Address Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.address > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>


                                  <CTableRow v-for="item in tableItems" key={5} >
                                    <CTableDataCell>
                                      Language Test Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.language > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={6} >
                                    <CTableDataCell>
                                      Education Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.education > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={7} >
                                    <CTableDataCell>
                                      Work Experience Information
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.work > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={8} >
                                    <CTableDataCell>
                                      Profile Picture Upload
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.ppicture > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={9} >
                                    <CTableDataCell>
                                      Resume Upload
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.resume > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={10} >
                                    <CTableDataCell>
                                      Onboard in MygrationHub
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {profileData?.acc > 0 ?
                                        <i className="fa fa-check " style={{ fontSize: "20px", color: "green" }} aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-close" style={{ fontSize: "20px", color: "red" }} aria-hidden="true"></i>
                                      }
                                    </CTableDataCell>
                                  </CTableRow>


                                </CTableBody>

                              </CTable>


                            </div>
                          </CDropdownItem>


                        </CDropdownMenu>
                      </CDropdown>


                    </div>
                  ) : ""
                }
              </CCardBody>
            </CCard>
          </CCol>

          <CCol md={12}>
            <CCard className="mb-4">
              <CCardHeader>Document Upload Status</CCardHeader>
              <CCardBody>
                {doumentsData
                  ? (
                    <div>

                      <CDropdown variant="nav-item">
                        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                          <div>
                            <div className="float-start">
                              <strong>{doumentsData?.uploadedPercent} %</strong> Completed
                            </div><br></br>
                            <CProgress thin color={getColor(doumentsData?.uploadedPercent)} value={doumentsData?.uploadedPercent} />
                          </div>
                        </CDropdownToggle>
                        <CDropdownMenu className="pt-0" placement="bottom-end">

                          <CDropdownHeader className="bg-light fw-semibold py-2">Document Completion Status</CDropdownHeader>
                          <CDropdownItem >

                            <div>

                              <CTable align="middle" className="mb-0 border" hover responsive style={{width:"100%"}}>
                                <CTableBody>
                                  <CTableRow v-for="item in tableItems" key={1} >
                                    <CTableDataCell>
                                      Uploaded
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {doumentsData?.uploaded}
                                    </CTableDataCell>
                                  </CTableRow>

                                  <CTableRow v-for="item in tableItems" key={2} >
                                    <CTableDataCell>
                                      Pending
                                    </CTableDataCell>
                                    <CTableDataCell>
                                    {doumentsData?.pending}
                                    </CTableDataCell>
                                  </CTableRow>

                                </CTableBody>

                              </CTable>


                            </div>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>


                    </div>
                  ) : ""
                }
              </CCardBody>
            </CCard>
          </CCol>
          
        </CRow>


      </CCard>
      }
      
    </>
  )
}

export default ClientDashboard
