import React from 'react'
import './../assets/css/custom.css'
import logo from './../assets/images/banner-australia.png';
import Meta from './Meta';

const ToAus = () => {
    const title = "Immigrating to Australia offers various pathways for individuals and families from around the world.";
    const description = title;
    return (
        <div>

            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="imi-usa">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        Immigrating to Australia offers various pathways for individuals and families from around the world. The Australian immigration system is points-based, focusing on skills, work experience, and adaptability to address the country&apos;s labor market needs and economic growth. Here&apos;s an overview of the main immigration streams:
                                    </p>

                                    <div className="section-heading-customise">
                                        <h2>1. Skilled Migration</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <h4>Skilled Independent Visa (Subclass 189):</h4>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>For skilled workers who are not sponsored by an employer, a state or territory, or a family member.</li>
                                            <li className='list-style'>Points-tested and requires an invitation to apply.</li>
                                        </ul>

                                        <h4>Skilled Nominated Visa (Subclass 190):</h4>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>For skilled workers who are nominated by an Australian state or territory.</li>
                                            <li className='list-style'>Requires a skills assessment and an expression of interest through the SkillSelect system.</li>
                                        </ul>

                                        <h4>Skilled Work Regional (Provisional) Visa (Subclass 491):</h4>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>For skilled workers nominated by a state or territory government or sponsored by an eligible family member to live and work in regional Australia.</li>
                                            <li className='list-style'>Offers a pathway to permanent residency.</li>
                                        </ul>

                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>2. Employer-Sponsored Migration</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <h4>Temporary Skill Shortage Visa (Subclass 482):</h4>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>For skilled workers sponsored by an Australian employer for a job in Australia.</li>
                                        </ul>

                                        <h4>Employer Nomination Scheme (Subclass 186):</h4>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>Offers permanent residency for skilled workers nominated by their Australian employer.</li>
                                        </ul>

                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>3. Business Innovation and Investment</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>Aimed at business owners, investors, and entrepreneurs looking to establish business operations in Australia.</li>
                                            <li className='list-style'>Includes provisional visas, leading to permanent residency for successful business operators and investors.</li>
                                        </ul>

                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>4. Family Stream</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>Allows Australian citizens, permanent residents, and eligible New Zealand citizens to sponsor family members for migration to Australia.</li>
                                            <li className='list-style'>Includes partner visas, parent visas, and child visas.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>5. Student Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>For international students seeking to study in Australian educational institutions.</li>
                                            <li className='list-style'>Can sometimes provide a pathway to permanent residency.</li>
                                        </ul>

                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>6. Working Holiday Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>For young adults (usually 18-30 years) to have an extended holiday while working in Australia to support themselves.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>General Eligibility and Application Process</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-50px'>
                                            <li className='list-style'>Points System: Many visas, especially skilled migration visas, use a points-based system. Points are awarded based on age, English language ability, skilled employment experience, qualification level, and other factors.</li>
                                            <li className='list-style'>Expression of Interest (EOI): For certain visas, candidates must submit an EOI through Skill Select and be invited to apply.</li>
                                            <li className='list-style'>Health and Character Requirements: Applicants must meet health and character requirements.</li>
                                        </ul>

                                    </div>

                                    <p>
                                        Australia&apos;s immigration policies are designed to attract skilled and talented individuals to contribute to its economic growth while also reuniting families and meeting humanitarian commitments. The process involves meeting specific criteria and often a points-based assessment to ensure that immigrants skills align with Australia&apos;s economic needs and social values.
                                    </p>

                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default ToAus