import React, { useState} from "react";
import { CButton, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CFormLabel } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser, cilMobile, cilHouse,  cilOptions } from '@coreui/icons'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate } from 'react-router-dom';
import './../assets/css/update-info.css';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex} from  'react-loader-spinner'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// import axios from "src/api/axios";

const countryList = [
  { value: '', label: 'Select country' },
  { value: '1', label: 'Afghanistan' },
  { value: '2', label: 'Albania' },
  { value: '3', label: 'Algeria' },
  { value: '4', label: 'American Samoa' },
  { value: '5', label: 'Andorra' },
  { value: '6', label: 'Angola' },
  { value: '7', label: 'Anguilla' },
  { value: '8', label: 'Antarctica' },
  { value: '9', label: 'Antigua and Barbuda' },
  { value: '10', label: 'Argentina' },
  { value: '11', label: 'Armenia' },
  { value: '12', label: 'Aruba' },
  { value: '13', label: 'Australia' },
  { value: '14', label: 'Austria' },
  { value: '15', label: 'Azerbaijan' },
  { value: '16', label: 'Bahamas' },
  { value: '17', label: 'Bahrain' },
  { value: '18', label: 'Bangladesh' },
  { value: '19', label: 'Barbados' },
  { value: '20', label: 'Belarus' },
  { value: '21', label: 'Belgium' },
  { value: '22', label: 'Belize' },
  { value: '23', label: 'Benin' },
  { value: '24', label: 'Bermuda' },
  { value: '25', label: 'Bhutan' },
  { value: '26', label: 'Bosnia and Herzegovina' },
  { value: '27', label: 'Botswana' },
  { value: '28', label: 'Bouvet Island' },
  { value: '29', label: 'Brazil' },
  { value: '30', label: 'British Indian Ocean Territory' },
  { value: '31', label: 'Brunei Darussalam' },
  { value: '32', label: 'Bulgaria' },
  { value: '33', label: 'Burkina Faso' },
  { value: '34', label: 'Burundi' },
  { value: '35', label: 'Cambodia' },
  { value: '36', label: 'Cameroon' },
  { value: '37', label: 'Canada' },
  { value: '38', label: 'Cape Verde' },
  { value: '39', label: 'Cayman Islands' },
  { value: '40', label: 'Central African Republic' },
  { value: '41', label: 'Chad' },
  { value: '42', label: 'Chile' },
  { value: '43', label: 'China' },
  { value: '44', label: 'Christmas Island' },
  { value: '45', label: 'Cocos (Keeling) Islands' },
  { value: '46', label: 'Colombia' },
  { value: '47', label: 'Comoros' },
  { value: '48', label: 'Congo' },
  { value: '49', label: 'Cook Islands' },
  { value: '50', label: 'Costa Rica' },
  { value: '51', label: 'Croatia' },
  { value: '52', label: 'Cuba' },
  { value: '53', label: 'Cyprus' },
  { value: '54', label: 'Czech Republic' },
  { value: '55', label: 'Denmark' },
  { value: '56', label: 'Djibouti' },
  { value: '57', label: 'Dominica' },
  { value: '58', label: 'Dominican Republic' },
  { value: '59', label: 'Ecuador' },
  { value: '60', label: 'Egypt' },
  { value: '61', label: 'El Salvador' },
  { value: '62', label: 'Equatorial Guinea' },
  { value: '63', label: 'Eritrea' },
  { value: '64', label: 'Estonia' },
  { value: '65', label: 'Ethiopia' },
  { value: '66', label: 'Falkland Islands (Malvinas)' },
  { value: '67', label: 'Faroe Islands' },
  { value: '68', label: 'Fiji' },
  { value: '69', label: 'Finland' },
  { value: '70', label: 'France' },
  { value: '71', label: 'French Guiana' },
  { value: '72', label: 'French Polynesia' },
  { value: '73', label: 'French Southern Territories' },
  { value: '74', label: 'Gabon' },
  { value: '75', label: 'Gambia' },
  { value: '76', label: 'Georgia' },
  { value: '77', label: 'Germany' },
  { value: '78', label: 'Ghana' },
  { value: '79', label: 'Gibraltar' },
  { value: '80', label: 'Greece' },
  { value: '81', label: 'Greenland' },
  { value: '82', label: 'Grenada' },
  { value: '83', label: 'Guadeloupe' },
  { value: '84', label: 'Guam' },
  { value: '85', label: 'Guatemala' },
  { value: '86', label: 'Guernsey' },
  { value: '87', label: 'Guinea' },
  { value: '88', label: 'Guinea-Bissau' },
  { value: '89', label: 'Guyana' },
  { value: '90', label: 'Haiti' },
  { value: '91', label: 'Heard Island and McDonald Islands' },
  { value: '92', label: 'Holy See (Vatican City State)' },
  { value: '93', label: 'Honduras' },
  { value: '94', label: 'Hong Kong' },
  { value: '95', label: 'Hungary' },
  { value: '96', label: 'Iceland' },
  { value: '97', label: 'India' },
  { value: '98', label: 'Indonesia' },
  { value: '99', label: 'Iran' },
  { value: '100', label: 'Iraq' },
  { value: '101', label: 'Ireland' },
  { value: '102', label: 'Isle of Man' },
  { value: '103', label: 'Israel' },
  { value: '104', label: 'Italy' },
  { value: '105', label: 'Jamaica' },
  { value: '106', label: 'Japan' },
  { value: '107', label: 'Jersey' },
  { value: '108', label: 'Jordan' },
  { value: '109', label: 'Kazakhstan' },
  { value: '110', label: 'Kenya' },
  { value: '111', label: 'Kiribati' },
  { value: '112', label: 'Kuwait' },
  { value: '113', label: 'Kyrgyzstan' },
  { value: '114', label: 'Lao Peoples Democratic Republic' },
  { value: '115', label: 'Latvia' },
  { value: '116', label: 'Lebanon' },
  { value: '117', label: 'Lesotho' },
  { value: '118', label: 'Liberia' },
  { value: '119', label: 'Libya' },
  { value: '120', label: 'Liechtenstein' },
  { value: '121', label: 'Lithuania' },
  { value: '122', label: 'Luxembourg' },
  { value: '123', label: 'Macao' },
  { value: '124', label: 'Madagascar' },
  { value: '125', label: 'Malawi' },
  { value: '126', label: 'Malaysia' },
  { value: '127', label: 'Maldives' },
  { value: '128', label: 'Mali' },
  { value: '129', label: 'Malta' },
  { value: '130', label: 'Marshall Islands' },
  { value: '131', label: 'Martinique' },
  { value: '132', label: 'Mauritania' },
  { value: '133', label: 'Mauritius' },
  { value: '134', label: 'Mayotte' },
  { value: '135', label: 'Mexico' },
  { value: '136', label: 'Monaco' },
  { value: '137', label: 'Mongolia' },
  { value: '138', label: 'Montenegro' },
  { value: '139', label: 'Montserrat' },
  { value: '140', label: 'Morocco' },
  { value: '141', label: 'Mozambique' },
  { value: '142', label: 'Myanmar' },
  { value: '143', label: 'Namibia' },
  { value: '144', label: 'Nauru' },
  { value: '145', label: 'Nepal' },
  { value: '146', label: 'Netherlands' },
  { value: '147', label: 'New Caledonia' },
  { value: '148', label: 'New Zealand' },
  { value: '149', label: 'Nicaragua' },
  { value: '150', label: 'Niger' },
  { value: '151', label: 'Nigeria' },
  { value: '152', label: 'Niue' },
  { value: '153', label: 'Norfolk Island' },
  { value: '154', label: 'Northern Mariana Islands' },
  { value: '155', label: 'Norway' },
  { value: '156', label: 'Oman' },
  { value: '157', label: 'Pakistan' },
  { value: '158', label: 'Palau' },
  { value: '159', label: 'Panama' },
  { value: '160', label: 'Papua New Guinea' },
  { value: '161', label: 'Paraguay' },
  { value: '162', label: 'Peru' },
  { value: '163', label: 'Philippines' },
  { value: '164', label: 'Pitcairn' },
  { value: '165', label: 'Poland' },
  { value: '166', label: 'Portugal' },
  { value: '167', label: 'Puerto Rico' },
  { value: '168', label: 'Qatar' },
  { value: '169', label: 'Romania' },
  { value: '170', label: 'Russian Federation' },
  { value: '171', label: 'Rwanda' },
  { value: '172', label: 'Saint Kitts and Nevis' },
  { value: '173', label: 'Saint Lucia' },
  { value: '174', label: 'Saint Martin (French part)' },
  { value: '175', label: 'Saint Pierre and Miquelon' },
  { value: '176', label: 'Saint Vincent and the Grenadines' },
  { value: '177', label: 'Samoa' },
  { value: '178', label: 'San Marino' },
  { value: '179', label: 'Sao Tome and Principe' },
  { value: '180', label: 'Saudi Arabia' },
  { value: '181', label: 'Senegal' },
  { value: '182', label: 'Serbia' },
  { value: '183', label: 'Seychelles' },
  { value: '184', label: 'Sierra Leone' },
  { value: '185', label: 'Singapore' },
  { value: '186', label: 'Sint Maarten (Dutch part)' },
  { value: '187', label: 'Slovakia' },
  { value: '188', label: 'Slovenia' },
  { value: '189', label: 'Solomon Islands' },
  { value: '190', label: 'Somalia' },
  { value: '191', label: 'South Africa' },
  { value: '192', label: 'South Georgia and the South Sandwich Islands' },
  { value: '193', label: 'South Sudan' },
  { value: '194', label: 'Spain' },
  { value: '195', label: 'Sri Lanka' },
  { value: '196', label: 'State of Palestine' },
  { value: '197', label: 'Sudan' },
  { value: '198', label: 'Suriname' },
  { value: '199', label: 'Svalbard and Jan Mayen' },
  { value: '200', label: 'Swaziland' },
  { value: '201', label: 'Sweden' },
  { value: '202', label: 'Switzerland' },
  { value: '203', label: 'Syrian Arab Republic' },
  { value: '204', label: 'Tajikistan' },
  { value: '205', label: 'Thailand' },
  { value: '206', label: 'Timor-Leste' },
  { value: '207', label: 'Togo' },
  { value: '208', label: 'Tokelau' },
  { value: '209', label: 'Tonga' },
  { value: '210', label: 'Trinidad and Tobago' },
  { value: '211', label: 'Tunisia' },
  { value: '212', label: 'Turkey' },
  { value: '213', label: 'Turkmenistan' },
  { value: '214', label: 'Turks and Caicos Islands' },
  { value: '215', label: 'Tuvalu' },
  { value: '216', label: 'Uganda' },
  { value: '217', label: 'Ukraine' },
  { value: '218', label: 'United Arab Emirates' },
  { value: '219', label: 'United Kingdom' },
  { value: '220', label: 'United States' },
  { value: '221', label: 'United States Minor Outlying Islands' },
  { value: '222', label: 'Uruguay' },
  { value: '223', label: 'Uzbekistan' },
  { value: '224', label: 'Vanuatu' },
  { value: '225', label: 'Viet Nam' },
  { value: '226', label: 'Wallis and Futuna' },
  { value: '227', label: 'Western Sahara' },
  { value: '228', label: 'Yemen' },
  { value: '229', label: 'Zambia' },
  { value: '230', label: 'Zimbabwe' }
]


const AddResource = (props) => {

  const axiosPrivate = useAxiosPrivate();

  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const navigate = useNavigate();


  const [id,setId] = useState("");
  const [firstName,setFirstName] = useState("");
  const [lastName,setlastName] = useState("");
  const [officeEmail,setOfficeEmail] = useState("");
  const [mobile,setMobile] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [sex, setSex] = useState("");
  // const [role, setRole] = useState("");
  const [dob, setDob] = useState("");
  const [password, setPassword] = useState("abcd1234$");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  


  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressPostal, setAddressPostal] = useState("");


  /* const onFileChangeHandler = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (e.target.files !== "") {

      await axios.post("/uploadFile", formData).then((res) => {
        if (res.status === 201) {

          setIsSpenierOpen(false);
        }
        else {

          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({ title: 'MyGration Hub', message: fail?.response?.data || "Try Again Later with Re-Login", buttons: [{ label: 'OK' }] });
        setIsSpenierOpen(false);
      });
    } else {
      alert("empty")
    }
    console.log(e.target.files);
  }; */


  function isExternalValid(){

    let inputEmail = officeEmail
    if (typeof inputEmail !== "undefined") {
      let lastAtPos = inputEmail.lastIndexOf("@");
      let lastDotPos = inputEmail.lastIndexOf(".");
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && inputEmail.indexOf("@@") === -1 && lastDotPos > 2 && inputEmail.length - lastDotPos > 2 )) {
        confirmAlert({title: 'MyGration Hub',message: 'Please Provide valid email addresss',buttons: [{label: 'OK'}]});
        return false;
      }
    }

    if (typeof mobile === "undefined") {
      confirmAlert({title: 'MyGration Hub',message: 'Please Provide valid phone number',buttons: [{label: 'OK'}]});
      return false;

    }
    else if (mobile.length < 8 || mobile.length > 16) {
      confirmAlert({title: 'MyGration Hub',message: 'Please Provide valid phone number',buttons: [{label: 'OK'}]});
      return false;
    }

    return true;

  }

  async function agentRegister() {
    setId("");
    try {
      const formData = {
        id: id,
        firstName: firstName,
        lastName: lastName,
        officeEmail: officeEmail,
        mobile: mobile,
        whatsApp: whatsApp ,
        sex: sex,
        dob: dob,
        password:password,
        userResidence: [
          {
              country: { id: countryOfResidence }
          }
        ],
        address: [
          {
            position: 1,
            addressType: 3,
            address: addressLineOne,
            city: addressCity,
            state: addressState,
            postal: addressPostal
          }
        ],
        role:props.role
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      setIsSpenierOpen(true);

      console.log(JSON.stringify(formData));

      await axiosPrivate.post("/saveAgent", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 201) {
          setIsSpenierOpen(false);
          if(props.role==="Partner"){
            confirmAlert({title: 'MyGration Hub',message: 'Partner Saved Successfully',buttons: [{label: 'OK'}]});
            navigate('/home/agent-list');
          }else if(props.role==="Referrer"){
            confirmAlert({title: 'MyGration Hub',message: 'Referrer Saved Successfully',buttons: [{label: 'OK'}]});
            navigate('/home/referrer-list');
          }else if(props.role==="Staff"){
            confirmAlert({title: 'MyGration Hub',message: 'Staff Saved Successfully',buttons: [{label: 'OK'}]});
            navigate('/home/staff-list');
          }

          
        }
        else {
          confirmAlert({title: 'MyGration Hub',message: 'Failed to Save, Try Again Later',buttons: [{label: 'OK'}]});
          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({title: 'MyGration Hub',message:"Try Again Later with Re-Login",buttons: [{label: 'OK'}]});
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
      setIsSpenierOpen(false);
    }

  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }else if(isExternalValid()===false){
      setValidated(true);
    } else {
      agentRegister(true);
    }

    setValidated(true);
    event.preventDefault();


  }


  return (
    <>
      <section>
        <div className='update-info'>
          <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >
                <CCol md={12} className='font-header'>
                  {props.role} Enlistment Form 
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomFirstName">First Name <span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                    <CFormInput
                      id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={firstName} required={true} onChange={(event) => { setFirstName(event.target.value); }} />
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomLastName">Last Name <span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                    <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomLastName"  value={lastName} required={true} onChange={(event) => { setlastName(event.target.value); }}/>
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomOfficeEmail">Email<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput type="text"  aria-describedby="validationCustomOfficeEmail" feedbackValid="Looks good!" id="validationCustomOfficeEmail" value={officeEmail}  required={true} onChange={(event) => { setOfficeEmail(event.target.value); }}/>
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomMobile">Mobile<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                    {/* <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={mobile}  required={true} onChange={(event) => { setMobile(event.target.value); }}/> */}
                    <PhoneInput  className='form-control' international countryCallingCodeEditable={false} defaultCountry="BD" value={mobile} onChange={setMobile} required style={{ width: "80%", display: "inline flex" }} />
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomMobile">WhatsApp</CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                    <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={whatsApp}  onChange={(event) => { setWhatsApp(event.target.value); }}/>
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomGender">Gender <span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                    <CFormSelect id="validationCustomGender" feedbackValid="Looks good" feedbackInvalid="Required!" onChange={(event) => { setSex(event.target.value); }} required>
                      <option value="">Select...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </CFormSelect>
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomDateOfBirth">Date Of Birth</CFormLabel>
                  <CInputGroup className="has-validation customDatePickerWidth">
                    <DatePicker
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      maxDate={new Date()}
                      id="validationCustomDateOfBirth"
                      className="form-control date-picker-custom"
                      selected={dob} onChange={(date) => setDob(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                       />
                  </CInputGroup>
                </CCol>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomCountyOfBirth">Country<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                    <CFormSelect id="validationCustomCountyOfBirth" feedbackValid="Looks good" feedbackInvalid="Required!" options={countryList} value={countryOfResidence} onChange={(event) => { setCountryOfResidence(event.target.value); }} required={true}></CFormSelect>
                  </CInputGroup>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomAddressLine1">Address<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                    <CFormInput id="validationCustomAddressLine1" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressLineOne} onChange={(event) => { setAddressLineOne(event.target.value); }} required />
                  </CInputGroup>
                </CCol>
                
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomAddressCity">City</CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                    <CFormInput id="validationCustomAddressCity" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressCity} onChange={(event) => { setAddressCity(event.target.value); }} />
                  </CInputGroup>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomAddressState">State / Province</CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                    <CFormInput id="validationCustomAddressState" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressState} onChange={(event) => { setAddressState(event.target.value); }} />
                  </CInputGroup>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomAddressPostal">Postal / Zip Code</CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                    <CFormInput id="validationCustomAddressPostal" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressPostal} onChange={(event) => { setAddressPostal(event.target.value); }} />
                  </CInputGroup>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomAddressUserName">User Name<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                    <CFormInput id="validationCustomAddressUserName" feedbackValid="Looks good" feedbackInvalid="Required!" value={officeEmail} disabled={true} required={true}/>
                  </CInputGroup>
                </CCol>


                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustomAddressPassword">Password (Default abcd1234$)<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                    <CFormInput type="password" id="validationCustomAddressPassword" feedbackValid="Looks good" feedbackInvalid="Required!" value={password} onChange={(event) => { setPassword(event.target.value); }} required={true}/>
                  </CInputGroup>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationMonitoringRole">Role<span className='red'>*</span></CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                    <CFormSelect id="validationCustomRole" feedbackValid="Looks good" feedbackInvalid="Required!" onChange={(event) => { setSex(event.target.value); }} required>
                      <option value={props.role}>{props.role}</option>
                    </CFormSelect>
                  </CInputGroup>
                </CCol>

                {/* <CCol md={12}>
                  <CInputGroup className="has-validation">
                    <CFormInput className="fileUpload" type="file" size="lg" id="formFileLg" onChange={onFileChangeHandler} required   />
                  </CInputGroup>
                  <div className="fileUplaodText">Click Choose File Or Drad and Drop Here</div>
                </CCol> */}


                <CCol xs={12}>
                {isSpenierOpen?<Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480','#036480','#036480','#036480','#036480','#036480']}></Vortex>:<CButton className="form-control btn btn-success" type="submit"> Save {props.role}</CButton>}
                  
                </CCol>
              </CForm>
            </CContainer>
          </div>
        </div>
      </section>
    </>

  )
}

export default AddResource;
