import React from 'react';
import logo from '../assets/images/banner-work-autralia.png';
import './../assets/css/custom.css';
import Meta from './Meta';

const WorkAustralia = () => {
    const title = "Embarking on a career in Australia is a rewarding journey, offering unparalleled opportunities for professional development and a vibrant lifestyle.";
    const description = title;
    return (
        <div>
            
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' style={{ backgroundColor: 'white' }} src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">
                            <div className='col-lg-12 items'>
                                <div className='col-lg-12 items'>
                                    <p>
                                        Embarking on a career in Australia is a rewarding journey, offering unparalleled opportunities for professional development and a vibrant lifestyle. Our immigration consultation services are tailored to guide you seamlessly through the intricate process of working in Australia. Here&apos;s a comprehensive overview of the application process to help you take the first step toward your dream job Down Under.
                                    </p>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ol>
                                                <li className='list-style-number' >
                                                    <b>Determine Your Eligibility:</b> Before diving into the application process, it&apos;s crucial to assess your eligibility. Australia has various visa options for skilled workers, each with its specific requirements. Our experienced consultants can assist you in evaluating your qualifications and skills against the Australian immigration criteria to identify the most suitable visa for your unique profile.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Select the Right Visa Pathway:</b> Australia offers several visa pathways for skilled professionals, including the Skilled Independent Visa (subclass 189), Skilled Nominated Visa (subclass 190), and the Temporary Skill Shortage Visa (subclass 482). Each visa has its own set of criteria and benefits. We&apos;ll guide you through the nuances of each option to help you make an informed decision aligned with your career goals.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Skills Assessment:</b> Many skilled migration visas require a thorough skills assessment from relevant Australian assessing authorities. Our consultants will assist you in compiling the necessary documentation, ensuring that your skills and qualifications meet the stringent Australian standards.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Expression of Interest (EOI):</b> Once your skills are assessed, you&apos;ll need to submit an Expression of Interest through the SkillSelect online system. This system allows Australian employers and state and territory governments to view and select skilled individuals. We&apos;ll help you craft a compelling EOI that showcases your expertise and increases your chances of being invited to apply for a visa.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Invitation to Apply (ITA):</b> If your EOI is successful, you may receive an Invitation to Apply for your chosen visa. Our team will guide you through the documentation required for this stage, ensuring a smooth and efficient application process.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Visa Application Submission:</b> With an ITA in hand, it&apos;s time to submit your visa application. Our consultants will assist you in preparing a thorough and accurate application, minimizing the risk of delays or rejections.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Health and Character Checks:</b> As part of the visa process, you&apos;ll undergo health and character assessments. We&apos;ll guide you on the necessary examinations and documentation to meet these requirements.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Visa Grant and Arrival in Australia:</b> Upon approval, you&apos;ll receive your visa grant, and you can start planning your relocation to Australia. Our services extend beyond the application process, offering support and advice on settling into your new professional and personal life in Australia. Embark on your journey to professional success in Australia with confidence. Our team is committed to providing expert guidance and support at every step of the working visa application process. Contact us today to schedule your immigration consultation and take the first step towards a thriving career in Australia.
                                                </li>

                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default WorkAustralia