import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-usa.png';
import Meta from './Meta';

const ToUsa = () => {
    const title = "Immigration to the United States is a multifaceted process with various pathways and eligibility criteria tailored to diverse groups of immigrants.";
    const description = title;
    return (
        <div>
            
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        Immigration to the United States is a multifaceted process with various pathways and eligibility criteria tailored to diverse groups of immigrants. Here`&apos;s an overview of the primary categories and their general eligibility requirements:
                                    </p>

                                    <div className="section-heading-customise">
                                        <h2>1. Family-Based Immigration</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Eligibility: U.S. citizens and lawful permanent residents can sponsor certain family members for a visa.
                                                </li>
                                                <li className='list-style'>Categories:</li>
                                                <div className='padding-left-20px'>
                                                    <ul className='square'>
                                                        <li className='list-style'>Immediate Relative Visas (IR): For spouses, unmarried children under 21, and parents of U.S. citizens. </li>
                                                        <li className='list-style'>Family Preference Visas: For adult children, siblings, and married children of U.S. citizens, and spouses and children of green card holders.</li>
                                                    </ul>
                                                </div>
                                            </ul>
                                        </div>


                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>2. Employment-Based Immigration</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Based on the applicant&apos;s skills, job offer from a U.S. employer, and sometimes country of origin.</li>
                                            <li className='list-style'>Categories:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>EB-1: Priority workers (e.g., outstanding professors, researchers, multinational managers).</li>
                                                    <li className='list-style'>EB-2: Professionals with advanced degrees or exceptional ability.</li>
                                                    <li className='list-style'>EB-3: Skilled workers, professionals, and other workers.</li>
                                                    <li className='list-style'>EB-4: Special immigrants (e.g., religious workers, certain employees of U.S. government abroad).</li>
                                                    <li className='list-style'>EB-5: Investors who invest a significant amount in a U.S. business that creates or preserves at least 10 full-time jobs.</li>
                                                </ul>
                                            </div>

                                        </ul>

                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>3. Refugee and Asylum Immigration</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: For individuals who have been persecuted or fear persecution due to race, religion, nationality, membership in a particular social group, or political opinion.</li>
                                            <li className='list-style'>Process: Must apply for refugee status while outside of the U.S.; for asylum, must be in the U.S. or at a port of entry.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>4. Diversity Lottery</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Applicants from countries with historically low rates of immigration to the U.S.</li>
                                            <li className='list-style'>Process: Winners are randomly selected and must meet education or work experience requirements.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>5. Temporary Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Varies based on the type of visa.</li>
                                            <li className='list-style'>Types:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Visitor Visas (B-1/B-2): For business or tourism.</li>
                                                    <li className='list-style'>Student Visas (F-1, M-1): For academic or vocational study.</li>
                                                    <li className='list-style'>Work Visas: Various types (e.g., H-1B, L-1) for different employment purposes.</li>
                                                </ul>
                                            </div>

                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>6. Humanitarian Protections</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Types:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Temporary Protected Status (TPS): For nationals of designated countries affected by severe conditions (e.g., war, natural disasters).</li>
                                                    <li className='list-style'>Deferred Enforced Departure (DED): Protection from deportation for individuals from certain countries.</li>
                                                    <li className='list-style'>Special Situations: Other categories for specific circumstances (e.g., Cuban Adjustment Act, victims of human trafficking).</li>
                                                </ul>
                                            </div>
                                            <li className='list-style'>General Eligibility Considerations:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Admissibility: All applicants must pass admissibility criteria, including health, criminal, and security checks.</li>
                                                    <li className='list-style'>Financial Stability: Proof of financial support may be required, especially in family-based categories.</li>
                                                </ul>
                                            </div>
                                            <li className='list-style'>Application Process: Involves submitting forms, documentation, and sometimes interviews.</li>
                                            <li className='list-style'>Quotas and Caps: Some categories have annual limits</li>

                                        </ul>

                                    </div>

                                    <p>
                                        U.S. immigration laws and procedures are complex and subject to change. Each immigration category has specific requirements, and policies can vary. Potential immigrants should refer to the latest information from U.S. Citizenship and Immigration Services (USCIS) or consult with an immigration attorney for personalized advice and guidance.
                                    </p>


                                </div>

                            </div>
                        </div>



                    </div>
                </div>

            </section>
        </div>

    )
}

export default ToUsa