import axios from 'axios';
//Production Mode
//const BASE_URL = 'http://35.213.150.114:8080/api';
// const BASE_URL = 'http://35.213.150.114:8080/api';
//Local mode
// const BASE_URL = 'http://localhost:8080/api';
const BASE_URL = 'https://mygrationhub.com/api';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
});