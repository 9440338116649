import React , { Component , Suspense } from "react"
import { HashRouter , Route , Routes } from "react-router-dom"
import "./scss/style.scss"
import RequireAuth from "./components/RequireAuth"
import UserDetails from "./components/UserDetails"
import RegisterNew from "./components/RegisterNew"
import UpdateInfo from  "./components/UpdateInfo"
import Register from "./components/Register"
import Home from "./layout/Home"
import HomeDefault from "./layout/HomeDefault"
import CoreExpertise from "./layout/CoreExpertise"
import Team from "./layout/Team"
import AboutUs from "./layout/Aboutus"
import Success from "./layout/SuccessStories"
import ToCanada from "./layout/ToCanada"
import ToAus from "./layout/ToAus"
import ToUsa from "./layout/ToUsa"
import ToUk from "./layout/ToUk"
import WorkCanadaPermit from "./layout/WorkCanadaPermit"
import WorkCanadaBV from "./layout/WorkCanadaBV"
import WorkCanada from "./layout/WorkCanada"
import WorkCanadaLmiaBased from "./layout/WorkCanadaLmiaBased"
import WorkUsa from "./layout/WorkUsa"
import WorkUk from "./layout/WorkUk"
import WorkAustralia from "./layout/WorkAustralia"
import NewsDetails from "./layout/NewsDetails"

import StadyCanada from "./layout/StadyCanada"
import StadyCanadaPermitProcess from "./layout/StadyCanadaPermitProcess"
import StadyCanadaPR from "./layout/StadyCanadaPR"
import StadyAustralia from "./layout/StadyAustralia"
import StadyUsa from "./layout/StadyUsa"
import StadyUk from "./layout/StadyUk"


import ApprovedUsers from "./components/ApprovedUsers"
import EducationInfoTab from "./components/EducationInfoTab"
import ChangePassword from "./components/ChangePassword"
import Unauthorized from "./components/Unauthorized"
import AddAgent from "./components/AddAgent"
import AgentList from "./components/AgentList"
import DocumentsViewer from "./components/DocumentsViewer"
import AgentDetails from "./components/AgentDetails"
import EditAgent from "./components/EditAgent"


import MyClientList from "./components/MyClientList"
import MyUserDetails from "./components/MyUserDetails"
import CaseProfile from "./components/CaseProfile"
import EditUser from "./components/EditUser"
import MyDetails from "./components/MyDetails"
import MyDocuments from "./components/MyDocuments"
import NewsInfo from "./components/NewsInfo"
import Chat from "./components/Chats"
import UpdateProfilePicture from "./components/UpdateProfilePicture"
import Disclaimer from "./layout/Disclaimer"
import ClientDashboard from "./components/ClientDashboard"
import Scheduling from "./components/Scheduling"
import AgentDashboard from "./components/AgentDashboard"
import Dashboard from "./views/dashboard/Dashboard"
import PostsInfo from "./components/PostsInfo"
import Goals from "./components/Goals"
import Meeting from "./components/Meeting"
import UpcomingMeeting from "./components/UpcomingMeeting"
import AddRefarrer from "./components/AddRefarrer"
import AddStaff from "./components/AddStaff"
import ReferrerList from "./components/ReferrerList"
import StaffList from "./components/StaffList"
import Accounce from "./components/Accounce"
import Events from "./components/Events"
import Courses from "./components/Courses"
import RefeererDetails from "./components/RefeererDetails"



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"))
// const HomePage = React.lazy(() => import("./layout/HomePage"))

// Pages
// const Login = React.lazy(() => import("./views/pages/login/Login"))
const Login = React.lazy(() => import("./components/Login"))
const LoginAgent = React.lazy(() => import("./components/LoginAgent"))
const LoginClient = React.lazy(() => import("./components/LoginClient"))
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"))
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"))


const Colors = React.lazy(() => import("./views/theme/colors/Colors"))
const Typography = React.lazy(() => import("./views/theme/typography/Typography"))


// Base
const Accordion = React.lazy(() => import("./views/base/accordion/Accordion"))
const Breadcrumbs = React.lazy(() => import("./views/base/breadcrumbs/Breadcrumbs"))
const Cards = React.lazy(() => import("./views/base/cards/Cards"))
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"))
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"))
const ListGroups = React.lazy(() => import("./views/base/list-groups/ListGroups"))
const Navs = React.lazy(() => import("./views/base/navs/Navs"))
const Paginations = React.lazy(() => import("./views/base/paginations/Paginations"))
const Placeholders = React.lazy(() => import("./views/base/placeholders/Placeholders"))
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"))
const Progress = React.lazy(() => import("./views/base/progress/Progress"))
const Spinners = React.lazy(() => import("./views/base/spinners/Spinners"))
const Tables = React.lazy(() => import("./views/base/tables/Tables"))
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"))

// Buttons
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"))
const ButtonGroups = React.lazy(() => import("./views/buttons/button-groups/ButtonGroups"))
const Dropdowns = React.lazy(() => import("./views/buttons/dropdowns/Dropdowns"))

//Forms
const ChecksRadios = React.lazy(() => import("./views/forms/checks-radios/ChecksRadios"))
const FloatingLabels = React.lazy(() => import("./views/forms/floating-labels/FloatingLabels"))
const FormControl = React.lazy(() => import("./views/forms/form-control/FormControl"))
const InputGroup = React.lazy(() => import("./views/forms/input-group/InputGroup"))
const Layout = React.lazy(() => import("./views/forms/layout/Layout"))
const Range = React.lazy(() => import("./views/forms/range/Range"))
const Select = React.lazy(() => import("./views/forms/select/Select"))
const Validation = React.lazy(() => import("./views/forms/validation/Validation"))

const Charts = React.lazy(() => import("./views/charts/Charts"))

// Icons
const CoreUIIcons = React.lazy(() => import("./views/icons/coreui-icons/CoreUIIcons"))
const Flags = React.lazy(() => import("./views/icons/flags/Flags"))
const Brands = React.lazy(() => import("./views/icons/brands/Brands"))

// Notifications
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"))
const Badges = React.lazy(() => import("./views/notifications/badges/Badges"))
const Modals = React.lazy(() => import("./views/notifications/modals/Modals"))
const Toasts = React.lazy(() => import("./views/notifications/toasts/Toasts"))

const Widgets = React.lazy(() => import("./views/widgets/Widgets"))
const UserList = React.lazy(() => import("../src/components/Users"))

const ROLES = {
  "User": "ROLE_USER", 
  "Editor": "ROLE_AGENT",  
  "Admin": "ROLE_ADMIN"
}

class App extends Component {

  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>

            <Route path="/" name="HomePage" element={<Home />} >
                <Route index name="HomePage" element={<HomeDefault />} />
                <Route path="/news" name="HomePage" element={<HomeDefault />} />
                <Route path="/news-details/:key" name="HomePage" element={<NewsDetails />} />
                <Route path="/top" name="HomePage" element={<HomeDefault />} />
                <Route path="/meetings" name="HomePage" element={<HomeDefault />} />
                <Route path="/courses" name="HomePage" element={<HomeDefault />} />
                <Route path="/outfacts" name="HomePage" element={<HomeDefault />} />
                <Route path="/contact" name="HomePage" element={<HomeDefault />} />
                <Route exact path="/about-us" name="About Us" element={<AboutUs />} />
                <Route exact path="/core-expertise" name="About Us" element={<CoreExpertise />} />
                <Route exact path="/team" name="About Us" element={<Team />} />
                <Route exact path="/success-stories" name="About Us" element={<Success />} />
                
                <Route exact path="/canada" name="Canada" element={<ToCanada />} />
                <Route exact path="/australia" name="Australia" element={<ToAus />} />
                <Route exact path="/usa" name="USA" element={<ToUsa />} />
                <Route exact path="/uk" name="UK" element={<ToUk />} />
                
                
                <Route exact path="/work-canada-permit" name="Work in Canada" element={<WorkCanadaPermit />} />
                <Route exact path="/work-canada-business-visitors" name="Work in Canada" element={<WorkCanadaBV />} />
                <Route exact path="/work-canada" name="Work in Canada" element={<WorkCanada />} />
                <Route exact path="/work-canada-lmia-based" name="Work in Canada" element={<WorkCanadaLmiaBased />} />
                <Route exact path="/work-usa" name="Work in Canada" element={<WorkUsa />} />
                <Route exact path="/work-uk" name="Work in Canada" element={<WorkUk />} />
                <Route exact path="/work-australia" name="Work in Canada" element={<WorkAustralia />} />
                
                <Route exact path="/stady-canada" name="Stady in Canada" element={<StadyCanada />} />
                <Route exact path="/stady-canada-permit-process" name="Stady in Canada" element={<StadyCanadaPermitProcess />} />
                <Route exact path="/stady-canada-pr" name="Stady in Canada" element={<StadyCanadaPR />} />
                <Route exact path="/stady-australia" name="Stady in Australia" element={<StadyAustralia />} />
                <Route exact path="/stady-usa" name="Stady in USA" element={<StadyUsa />} />
                <Route exact path="/stady-uk" name="Stady in UK" element={<StadyUk />} />
                <Route exact path="/disclimer" name="Disclaimer & Privacy Policy" element={<Disclaimer />} />

                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/register/:key" name="Register Page" element={<Register />} />
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/agent-login" name="Login Page" element={<LoginAgent />} />
                <Route exact path="/client-login" name="Login Page" element={<LoginClient />} />

            </Route> 
            
            <Route exact path="/update-infoformation/:key/:email/:fName/:lName/:cell" name="Register" element={<RegisterNew />} />
            {/* <Route exact path="/update-info/:key/:email/:fName/:lName/:cell" name="Register" element={<UpdateInfo />} /> */}
            <Route exact path="/update-info/:key" name="Register" element={<UpdateInfo />} />

            <Route exact path="/education" name="Education" element={<EducationInfoTab />} />

            <Route path="case-file/:key" name="Documents" element={<CaseProfile />} />

            
            {/* <Route path="/" name="HomePage" element={<HomePage />} />
            <Route path="/top" name="HomePage" element={<HomePage />} />
            <Route path="/meetings" name="HomePage" element={<HomePage />} />
            <Route path="/courses" name="HomePage" element={<HomePage />} />
            <Route path="/outfacts" name="HomePage" element={<HomePage />} />
            <Route path="/contact" name="HomePage" element={<HomePage />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} /> */}

            {/* <Route exact path="/register-new" name="Register" element={<RegisterNew />} /> */}
            {/* <Route exact path="/user-register" name="Register Page" element={<UserRegister />} /> */}
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/*" name="Page 500" element={<Page500 />} />

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Editor]} />}>
              <Route path="/home" element={<DefaultLayout />} >

              <Route path="change-password" name="ChangePassword" element={<ChangePassword />} />
              <Route path="edit-user" name="Edit User" element={<EditUser />} />
              <Route path="edit-user/:key" name="Edit User" element={<EditUser />} />
              <Route path="profile-picture" name="Profile Picture" element={<UpdateProfilePicture />} />
              <Route path="my-details" name="Details" element={<MyDetails />} />
              <Route path="my-documents" name="Documents" element={<MyDocuments />} />
              <Route path="chat-api" name="Chat" element={<Chat />} />
              {/* <Route path="my-schedule" name="My chedule" element={<Scheduling />} /> */}

              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="dashboard" name="Dashboard" element={<Dashboard />} />
                <Route path="pending-users" name="Pending List" element={<UserList />} />
                <Route path="approved-users" name="Approved User List" element={<ApprovedUsers />} />
                <Route path="user-details" name="Client List" element={<UserDetails />} />
                <Route path="user-details/:key" name="Client List" element={<UserDetails />} />
                
                <Route path="add-referrer" name="Referrer" element={<AddRefarrer />} />
                <Route path="referrer-list" name="Referrer List" element={<ReferrerList />} />
                <Route path="referrer-details/:key" name="Referrer List" element={<RefeererDetails />} />

                <Route path="add-staff" name="Staff" element={<AddStaff />} />
                <Route path="staff-list" name="Staff" element={<StaffList />} />


                <Route path="add-agent" name="Partner Agent" element={<AddAgent />} />
                <Route path="edit-agent/:key" name="Partner Agent" element={<EditAgent />} />
                <Route path="agent-list" name="Partner List" element={<AgentList />} />
                <Route path="agent-details" name="Partner Details" element={<AgentDetails />} />
                <Route path="news-info" name="News Information" element={<NewsInfo />} />
                <Route path="post-info" name="Post Information" element={<PostsInfo />} />


                <Route path="accounce-section" name="Accounce" element={<Accounce />} />
                <Route path="events" name="Events" element={<Events />} />
                <Route path="courses" name="Courses" element={<Courses />} />
              </Route>


              <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
                <Route path="connection-list" name="My Clients" element={<MyClientList />} />
                
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Editor]} />}>
                <Route path="client-details" name="My Clients" element={<MyUserDetails />} />
                <Route path="agent-dashboard" name="My Clients" element={<AgentDashboard />} />
              </Route>
              
              <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                {/* <Route path="client-dashboard" name="My Clients" element={<ClientDashboard />} /> */}
              </Route>

              <Route path="client-dashboard" name="My Clients" element={<ClientDashboard />} />
              <Route path="my-schedule" name="My Schedule" element={<Scheduling />} />
              <Route path="my-goals" name="My Goals" element={<Goals />} />
              <Route path="my-meeting" name="My Meeting" element={<Meeting />} />
              <Route path="my-meeting" name="My Meeting" element={<UpcomingMeeting/>} />
                
                
                <Route path="theme/colors" name="Color" element={<Colors />} />
                <Route path="theme" name="Theme" element={<Colors />} />
                <Route path="documents-viewer/:key/:fileName/:clientName" name="Documents" element={<DocumentsViewer  />} />
                <Route path="unauthorized" name="unauthorized" element={<Unauthorized />} />
                <Route path="theme" name="Theme" element={<Colors />} />
                <Route path="theme/colors" name="Color" element={<Colors />} />
                <Route path="theme/typography" name="Typography" element={<Typography />} />
                <Route path="base" name="Base" element={<Cards />} />
                <Route path="base/accordion" name="Accordion" element={<Accordion />} />
                <Route path="base/breadcrumbs" name="Breadcrumbs" element={<Breadcrumbs />} />
                <Route path="base/cards" name="Cards" element={<Cards />} />
                <Route path="base/carousels" name="Carousel" element={<Carousels />} />
                <Route path="base/collapses" name="Collapse" element={<Collapses />} />
                <Route path="base/list-groups" name="List Groups" element={<ListGroups />} />
                <Route path="base/navs" name="Navs" element={<Navs />} />
                <Route path="base/paginations" name="Paginations" element={<Paginations />} />
                <Route path="base/placeholders" name="Placeholders" element={<Placeholders />} />
                <Route path="base/popovers" name="Popovers" element={<Popovers />} />
                <Route path="base/progress" name="Progress" element={<Progress />} />
                <Route path="base/spinners" name="Spinners" element={<Spinners />} />
                <Route path="base/tables" name="Tables" element={<Tables />} />
                <Route path="base/tooltips" name="Tooltips" element={<Tooltips />} />
                <Route path="buttons" name="Buttons" element={<Buttons />} />
                <Route path="buttons/buttons" name="Buttons" element={<Buttons />} />
                <Route path="buttons/dropdowns" name="Dropdowns" element={<Dropdowns />} />
                <Route path="buttons/button-groups" name="Button Groups" element={<ButtonGroups />} />
                <Route path="charts" name="Charts" element={<Charts />} />
                <Route path="forms" name="Forms" element={<FormControl />} />
                <Route path="forms/form-control" name="Form Control" element={<FormControl />} />
                <Route path="forms/select" name="Select" element={<Select />} />
                <Route path="forms/checks-radios" name="Checks & Radios" element={<ChecksRadios />} />
                <Route path="forms/range" name="Range" element={<Range />} />
                <Route path="forms/input-group" name="Input Group" element={<InputGroup />} />
                <Route path="forms/floating-labels" name="Floating Labels" element={<FloatingLabels />} />
                <Route path="forms/layout" name="Layout" element={<Layout />} />
                <Route path="forms/validation" name="Validation" element={<Validation />} />
                <Route path="icons" name="Icons" element={<CoreUIIcons />} />
                <Route path="icons/coreui-icons" name="CoreUI Icons" element={<CoreUIIcons />} />
                <Route path="icons/flags" name="Flags" element={<Flags />} />
                <Route path="icons/brands" name="Brands" element={<Brands />} />
                <Route path="notifications" name="Notifications" element={<Alerts />} />
                <Route path="notifications/alerts" name="Alerts" element={<Alerts />} />
                <Route path="notifications/badges" name="Badges" element={<Badges />} />
                <Route path="notifications/modals" name="Modals" element={<Modals />} />
                <Route path="notifications/toasts" name="Toasts" element={<Toasts />} />
                <Route path="widgets" name="Widgets" element={<Widgets />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
