import React, { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import { useParams, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import { CButton, CCol, CContainer, CCard, CCardBody, CRow, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CFormLabel, CFormCheck, CFormSwitch, CFormFeedback } from '@coreui/react'



const ChangePassword = () => {

    const axiosPrivate = useAxiosPrivate();

    const [isSpenierOpen, setIsSpenierOpen] = useState(null);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");





    async function register() {


        try {
            const formData = {
                oldPassword: currentPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            await axiosPrivate.post("/updatePassword", JSON.stringify(formData), { headers: headers }).then((res) => {

                if (res.status === 200) {
                    setIsSpenierOpen(false);
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    setValidated(false);
                    confirmAlert({
                        title: 'MyGration Hub',
                        message: 'Password Successfully Updated',
                        buttons: [
                            {
                                label: 'OK',
                            }
                        ]
                    });
                    //   navigate('/');
                }
                else {
                    confirmAlert({
                        title: 'MyGration Hub',
                        message: 'Try Again Letter',
                        buttons: [
                            {
                                label: 'OK',
                            }
                        ]
                    });
                }
                setIsSpenierOpen(false);
        
              }, fail => {
                console.error(fail);
                confirmAlert({
                    title: 'MyGration Hub',
                    message: 'Invalid Information',
                    buttons: [
                        {
                            label: 'OK',
                        }

                    ]

                });
                setIsSpenierOpen(false);
              });
        }
        catch (err) {
            alert(err);
            setIsSpenierOpen(false);
        }

    }



    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {

        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }else if(newPassword!==confirmPassword){
            event.preventDefault()
            event.stopPropagation()
            confirmAlert({
                title: 'MyGration Hub',
                message: 'New Password and confirm password not matching',
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });


        }else if(newPassword === currentPassword){
            event.preventDefault()
            event.stopPropagation()
            confirmAlert({
                title: 'MyGration Hub',
                message: 'Current Password and New password should not same',
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
        } else {
            setIsSpenierOpen(true);
            register(true);
        }

        setValidated(true);
        event.preventDefault();


    }


    return (
        <>
            <section>

<CContainer>
                {/* <div className="container"> */}
                    {/* <div className="row justify-content-center"> */}
                        <CCol md={6}>
                            <CCard>
                                <CCardBody>
                                <CCardBody>
                                <CCardBody>
                            <h2>Change Password</h2>
                            <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >
                                <CCol md={12}>
                                    <CFormLabel htmlFor="validationCurrentPassword">Current Password</CFormLabel>
                                    <CInputGroup className="has-validation">
                                        <CFormInput aria-describedby="inputCurrentPassword" id="validationCurrentPassword" type="password" feedbackValid="Looks good" feedbackInvalid="Required!" value={currentPassword} onChange={(event) => { setCurrentPassword(event.target.value); }} required  style={{color:'black'}}/>
                                    </CInputGroup>
                                </CCol>
                                <CCol md={12}>
                                    <CFormLabel htmlFor="validationNewPassword">New Passport</CFormLabel>
                                    <CInputGroup className="has-validation">
                                        <CFormInput aria-describedby="inputNewPassword" id="validationNewPassword" type="password" feedbackValid="Looks good" feedbackInvalid="Minimum 8 characters" value={newPassword} onChange={(event) => { setNewPassword(event.target.value); }} required minLength={8} style={{color:'black !important'}}/>
                                    </CInputGroup>
                                </CCol>
                                <CCol md={12}>
                                    <CFormLabel htmlFor="validationConfirmPassword">Confirm Password</CFormLabel>
                                    <CInputGroup className="has-validation">
                                        <CFormInput aria-describedby="inputConfiramPassword" id="validationConfirmPassword" type="password" feedbackInvalid="Required!" value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value); }} required style={{color:'black !important'}} />
                                    </CInputGroup>
                                </CCol>

                                <CCol xs={12}>
                                    {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <CButton md={12} className="form-control btn btn-success submit px-3" type="submit"> Update</CButton>}
                                </CCol>

                            </CForm>
                            </CCardBody>
                            </CCardBody>
                            </CCardBody>
                            </CCard>
                        </CCol>
                        </CContainer>
                        
{/* 
                    </div>
                </div> */}
            </section>
        </>

    )
}

export default ChangePassword
