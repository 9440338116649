import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
// import { CForm, CCol, CFormLabel, CInputGroup, CInputGroupText, CFormSelect, CAvatar, CButton, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow,CFormCheck } from '@coreui/react'
import { CForm, CCol, CInputGroup, CFormLabel, CFormSelect, CButton, CTable, CTableBody, CTableDataCell, CTableRow, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow } from '@coreui/react'
// import CIcon from '@coreui/icons-react';
// import { cilPeople } from '@coreui/icons';
// import moment from 'moment';
import { Vortex } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-datepicker";
import '../../src/assets/css/custom.css';


const Goals = () => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  const [actionText, setActionText] = useState("Save");
  const [searchedVal, setSearchedVal] = useState("");
  const [goals, setGoals] = useState([]);
  const [relationship, setRelationship] = useState([]);
  const [goalId, setGoalId] = useState(0);
  const [relationUserInfoId, setRelationUserInfoId] = useState("");
  const [goal, setGoal] = useState("");
  const [goalDate, setGoalDate] = useState("");

  const [val, setVal] = useState([]);
  const [subGoalId, setSubGoalId] = useState([]);
  const [subGoal, setSubGoal] = useState([]);
  const [subGoalDate, setSubGoalDate] = useState([]);

  const handleAdd = () => {
    const abc = [...val, []];
    const abcd = [...subGoal, []];
    const abcde = [...subGoalDate, []];
    const abcdef = [...subGoalId, null];
    setVal(abc);
    setSubGoal(abcd);
    setSubGoalDate(abcde);
    setSubGoalId(abcdef);
  }


  const handleDelete = (i) => {
    if (i >= 0) {
      const deleteVal = [...val]
      deleteVal.splice(i, 1)
      setVal(deleteVal)

      const deleteSubGoal = [...subGoal]
      deleteSubGoal.splice(i, 1)
      setSubGoal(deleteSubGoal)

      const deleteSubGoalDate = [...subGoalDate]
      deleteSubGoalDate.splice(i, 1)
      setSubGoalDate(deleteSubGoalDate)

      const deleteSubGoalId = [...subGoalId]
      deleteSubGoalId.splice(i, 1)
      setSubGoalId(deleteSubGoalId)
    }

  }

  const setSubGoalFunction = (onChnageValue, i) => {
    const inputdata = [...subGoal]
    inputdata[i] = onChnageValue;
    setSubGoal(inputdata);
  }

  const setSubGoalDateFunction = (onChnageValue, i) => {
    const inputdata = [...subGoalDate]
    inputdata[i] = onChnageValue;
    setSubGoalDate(inputdata);
  }


  const setPriorityUrgent = (val) => {
    setUrgent(true);
    setHigh(false);
    setLow(false);
    setIsPriorityRequired(false);
  }
  const setPriorityHigh = (val) => {
    setUrgent(false);
    setHigh(true);
    setLow(false);
    setIsPriorityRequired(false);
  }
  const setPriorityLow = (val) => {
    setUrgent(false);
    setHigh(false);
    setLow(true);
    setIsPriorityRequired(false);
  }




  const [urgent, setUrgent] = useState(false);
  const [high, setHigh] = useState(false);
  const [low, setLow] = useState(false);
  const [isPriorityRequired, setIsPriorityRequired] = useState(true);


  const [isSpenierOpen, setIsSpenierOpen] = useState(false);

  const [validated, setValidated] = useState(false);


  function editGoals(obj) {
    //console.log(obj);
    // console.log(obj.subGoals);
    setVisible(true);
    setActionText("Update");
    setGoalId(obj.id);
    setRelationUserInfoId(obj.relationUserInfoId);
    setGoal(obj.goal);
    setGoalDate(Date.parse(obj.goalDate));
    if (obj.priority === 1) {
      setPriorityLow(true);
    } else if (obj.priority === 2) {
      setPriorityHigh(true);
    } else if (obj.priority === 3) {
      setPriorityUrgent(true);
    }

    if (obj.subGoals !== null) {

      let vVal = [];
      let vName = [];
      let vDate = [];
      let vId = [];
      obj.subGoals.forEach((v, i) => {
        vVal.push([]);
        vName.push(v.name);
        vDate.push(v.goalDate);
        vId.push(v.id);
      });

      setVal(vVal);
      setSubGoal(vName);
      setSubGoalDate(vDate);
      setSubGoalId(vId);
    }


  }

  function openModal() {
    setGoalId(0);
    handleAdd();
    setVisible(true);
    setActionText("Save");

  }

  function closeModal() {
    setRelationUserInfoId("");
    setGoal("");
    setGoalDate("");
    setVal([]);
    setSubGoal([]);
    setSubGoalDate([]);
    setSubGoalId([]);
    setVisible(false);
    setUrgent(false);
    setHigh(false);
    setLow(false);
    setValidated(false);
  }


  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }

    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (message !== "") {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK' }] });
        }
        if (apiName === "updateSelfGoals") {
          setIsSpenierOpen(false);
          getMyGoals();
          closeModal();
        }
        else if (apiName === "saveSelfGoals") {
          setIsSpenierOpen(false);
          getMyGoals();
          closeModal();

        } else if (apiName === "updateGoalStatus") {
          setIsSpenierOpen(false);
          getMyGoals();
          closeModal();

        } else if (apiName === "deleteSelfGoals") {
          setIsSpenierOpen(false);
          getMyGoals();
          closeModal();
        }

      }
      else {
        setIsSpenierOpen(false);
        confirmAlert({ title: 'MyGration Hub', message: "Failed to complete process, Try again later", buttons: [{ label: 'OK' }] });
      }

    }, fail => {
      console.error(fail);
      setIsSpenierOpen(false);
      confirmAlert({ title: 'MyGration Hub', message: "Please relogin and try again", buttons: [{ label: 'OK' }] });
    });
  }


  async function deleteGoal() {
    const formData = {
      id: goalId
    }

    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You wants to delete goal",
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "deleteSelfGoals", "Your goal is deleted successfully")

      },
      {
        label: 'No',
      }]
    });

  }


  async function completeGoal() {
    const formData = {
      id: goalId,
      status: 4
    }

    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You wants to complete goal",
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "updateGoalStatus", "Your goal is completed successfully")

      },
      {
        label: 'No',
      }]
    });

  }




  async function saveMyGoal() {
    try {
      let subGoalList = [];
      val.forEach((v, i) => {
        subGoalList[i] = {
          id: subGoalId[i],
          name: subGoal[i],
          goalDate: subGoalDate[i],
        }
      });

      const formData = {
        id: goalId,
        relationUserInfoId: relationUserInfoId === "" ? document.getElementById("validationRelationship").value : relationUserInfoId,
        goal: goal,
        goalDate: goalDate,
        priority: urgent === true ? 3 : high === true ? 2 : 1,
        subGoals: subGoalList
      }

      setIsSpenierOpen(true);

      let path = "";
      let message = "";
      if (goalId > 0) {
        path = "updateSelfGoals";
        message = "Goal updated successfully";
      } else {
        path = "saveSelfGoals";
        message = "Goal saved successfully";
      }

      apicall(formData, path, message);

    } catch (err) {
      console.log(err);
      setIsSpenierOpen(false);
      confirmAlert({ title: 'MyGration Hub', message: "Please relogin and try again", buttons: [{ label: 'OK' }] });
    }

  }


  const handleFormSubmit = (event) => {
    // alert(val);
    /* console.log(val);
    console.log(subGoal);
    console.log(subGoalDate);
    console.log(subGoalId); */

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      saveMyGoal();
    }

    setValidated(true);
    event.preventDefault();


  }


  const getMyGoals = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get('/getSelfGoals', {
        signal: controller.signal
      });
      // console.log(response.data);
      setGoals(response.data);
      controller.abort();
    } catch (err) {
      controller.abort();
    }
  }



  useEffect(() => {
    // let isMounted = true;
    let isRelationshipMounted = true;
    const controller = new AbortController();

    const getRelationship = async () => {
      try {
        const res = await axiosPrivate.get('/getSelfRelationship', {
          signal: controller.signal
        });
        isRelationshipMounted && setRelationship(res?.data);
      } catch (err) {
        navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getMyGoals();
    getRelationship();

    return () => {
      // isMounted = false;
      isRelationshipMounted = false;
      controller.abort();
    }
  }, []);



  return (
    <article>

      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => closeModal()}
        aria-labelledby="StaticBackdropExampleLabel"
        size="xl"

      >
        <CModalHeader onClose={() => closeModal()}>
          <CModalTitle id="LiveDemoExampleLabel">Task</CModalTitle>
        </CModalHeader>
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} >
          <CModalBody>

            <CRow className="mb-12">
              <div className="col-sm-12"> Related To <span className='red'>*</span>
                <CInputGroup className="has-validation">
                  <CFormSelect aria-describedby="validationRelationship" id="validationRelationship" value={relationUserInfoId} onChange={(event) => { setRelationUserInfoId(event.target.value); }} feedbackInvalid="Required!" required style={{ zIndex: 0 }} >
                    {relationship && relationship?.map((row) => <option key={row?.id} value={row?.id}  >{row?.name}</option>)}
                  </CFormSelect>
                </CInputGroup>
              </div>
            </CRow>


            <CRow className="mb-12">
              <div className="col-sm-8"> Task <span className='red'>*</span>
                <CInputGroup className="has-validation">
                  <CFormInput aria-describedby="validationCustomGoal" id="validationCustomGoal" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={goal} onChange={(event) => { setGoal(event.target.value); }} maxLength={255} required />
                </CInputGroup>

              </div>
              <div className="col-sm-4"> Due Date <span className='red'>*</span>
                <CInputGroup className="has-validation">
                  <DatePicker
                    //showMonthDropdown
                    //showYearDropdown
                    //yearDropdownItemNumber={100}
                    //scrollableYearDropdown
                    // id="validationlanguageTestReDate"
                    aria-describedby="validationCustomGoalDate"
                    minDate={new Date()}
                    className="form-control date-picker-custom"
                    selected={goalDate} onChange={(date) => setGoalDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                    required
                  />
                </CInputGroup>
              </div>
            </CRow>



            <CRow className="mb-12">
              &nbsp;
            </CRow>

            {val?.map((data, i) => {
              return (


                <CRow className="mb-12" key={i}>
                  <div className="col-sm-8"> Sub-Task <span className='red'>*</span>
                    <CInputGroup className="has-validation">
                      <CFormInput id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={subGoal[i]} onChange={(event) => { setSubGoalFunction(event.target.value, i); }} maxLength={255} required />
                    </CInputGroup>

                  </div>
                  <div className="col-sm-3"> Due Date <span className='red'>*</span>
                    <CInputGroup className="has-validation ">

                      <DatePicker
                        minDate={new Date()}
                        className="form-control date-picker-custom"
                        selected={subGoalDate.length ? Date.parse(subGoalDate[i]) : ''} onChange={(date) => setSubGoalDateFunction(date, i)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                        required
                      />
                    </CInputGroup>
                  </div>
                  <div className="col-sm-1"> &nbsp;
                    <CInputGroup className="has-validation">
                      <CButton color="danger" size="sm" variant="outline" onClick={() => handleDelete(i)}>X</CButton>
                    </CInputGroup>
                  </div>
                </CRow>

              )
            })}



            <CRow className="mb-12">
              <div className="col-sm-2">&nbsp;
                <CInputGroup className="has-validation">
                  <CButton variant="outline" color="success" onClick={() => handleAdd()}>Add Sub-Task</CButton>
                </CInputGroup>
              </div>
            </CRow>


            <CRow className="mb-12">
              <div className="col-sm-12">
                <CFormLabel htmlFor="validationCustomMobile">&nbsp;</CFormLabel>
                <CInputGroup className="has-validation">
                  {/* <CInputGroupText>Priority </CInputGroupText> */}
                  Priority <span className='red'>*</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="checkbox" id="priorityUrgent" className="checkBoxSpetial" checked={urgent} onChange={(event) => { setPriorityUrgent(event.target.checked); }} required={isPriorityRequired} />    <CButton onClick={(event) => { setPriorityUrgent(true); }} className="btn btn-danger height40" > Urgent </CButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="checkbox" id="priorityHigh" className="checkBoxSpetial" checked={high} onChange={(event) => { setPriorityHigh(event.target.checked); }} required={isPriorityRequired} />        <CButton onClick={(event) => { setPriorityHigh(true); }} className="btn btn-warning height40"  > High </CButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="checkbox" id="priorityLow" className="checkBoxSpetial" checked={low} onChange={(event) => { setPriorityLow(event.target.checked); }} required={isPriorityRequired} />            <CButton onClick={(event) => { setPriorityLow(true); }} className="btn btn-info height40" > Low      </CButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  {/* <CFormCheck inline id="priorityUrgent" class="checkBoxSpetial" checked={urgent} onChange={(event) => { setPriorityUrgent(event.target.checked); }} required={isPriorityRequired} />    <CButton className="btn btn-danger height40" > Urgent </CButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <CFormCheck inline id="priorityHigh" class="checkBoxSpetial" checked={high} onChange={(event) => { setPriorityHigh(event.target.checked); }} required={isPriorityRequired} />        <CButton className="btn btn-warning height40"  > High </CButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <CFormCheck inline id="priorityLow" class="checkBoxSpetial" checked={low} onChange={(event) => { setPriorityLow(event.target.checked); }} required={isPriorityRequired} />            <CButton className="btn btn-info height40" > Low      </CButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                </CInputGroup>
              </div>
            </CRow>

          </CModalBody>
          <CModalFooter>
            {/* { goalId>0 ?<CButton color="danger" onClick={() => closeModal()}> Cancel </CButton>:''} */}
            {goalId > 0 ? <CButton color="danger" onClick={() => deleteGoal()}> Delete </CButton> : ''}
            {goalId > 0 ? <CButton color="success" onClick={() => completeGoal()}> Completed </CButton> : ''}
            {isSpenierOpen ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> {actionText} </CButton>
            }
          </CModalFooter>
        </CForm>
      </CModal>




      <CRow >
        <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>
          <h2>To-do List ({goals?.length > 0 ? goals.length : 0})</h2>
        </CCol>
        <CCol md={6} style={{ paddingRight: "15px", paddingLeft: "15px", paddingBottom: "10px" }}>


          <CFormSelect value={searchedVal} onChange={(event) => { setSearchedVal(event.target.value); }}>
            <option value="">Related To</option>
            {relationship && relationship?.map((row) => <option key={row?.id} value={row?.id}  >{row?.name}</option>)}
          </CFormSelect>
        </CCol>
      </CRow>

      <CRow >
        <CCol md={6}>
          Set a new Task
        </CCol>
        <CCol md={6} className="text-right">
          <CButton color='success' onClick={openModal} >+</CButton>
        </CCol>
      </CRow>

      <CRow className="mb-12">
        &nbsp;
      </CRow>


      <CRow className="mb-12">
        {goals?.length
          ? (
            <div>

              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableBody>
                  {goals?.filter((row) =>
                    /* !searchedVal.length || row.fullName
                    .toString()
                    .toLowerCase()
                    .includes(searchedVal.toString().toLowerCase())) */


                    (row?.relationUserInfoId)
                      .toString()
                      .includes(searchedVal.toString())

                  )?.map((goal, i) => (
                    <CTableRow v-for="item in tableItems" key={i} onClick={() => editGoals(goal)}>
                      {/* <CTableDataCell className="text-center"> </CTableDataCell> */}
                      <CTableDataCell><div>{goal?.goal}</div></CTableDataCell>
                      <CTableDataCell className="text-right">
                        <CButton className="height40" color={goal.priority === 1 ? 'info' : goal.priority === 2 ? 'warning' : goal.priority === 3 ? 'danger' : ''} >{goal.priority === 1 ? '  Low  ' : goal.priority === 2 ? 'High' : goal.priority === 3 ? 'Urgent' : ''} </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>


            </div>
          ) : <p></p>
        }

      </CRow>
    </article>
  )
}
export default Goals;
