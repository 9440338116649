import React, { useState, useEffect } from "react";
import { CButton, CCol, CContainer, CCard, CCardBody, CRow, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CFormLabel, CFormSwitch, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser, cilMobile, cilSchool, cibWhenIWork, cilHouse, cilInfo, cilCalculator, cilOptions } from '@coreui/icons'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
// import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
// import './../assets/css/update-info.css';
import { confirmAlert } from 'react-confirm-alert';
// import { Vortex } from 'react-loader-spinner'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from 'moment';
import '../assets/css/userDetails.css';
import Select from 'react-select';


const countryList = [
  { value: '', label: 'Select country' },
  { value: '1', label: 'Afghanistan' },
  { value: '2', label: 'Albania' },
  { value: '3', label: 'Algeria' },
  { value: '4', label: 'American Samoa' },
  { value: '5', label: 'Andorra' },
  { value: '6', label: 'Angola' },
  { value: '7', label: 'Anguilla' },
  { value: '8', label: 'Antarctica' },
  { value: '9', label: 'Antigua and Barbuda' },
  { value: '10', label: 'Argentina' },
  { value: '11', label: 'Armenia' },
  { value: '12', label: 'Aruba' },
  { value: '13', label: 'Australia' },
  { value: '14', label: 'Austria' },
  { value: '15', label: 'Azerbaijan' },
  { value: '16', label: 'Bahamas' },
  { value: '17', label: 'Bahrain' },
  { value: '18', label: 'Bangladesh' },
  { value: '19', label: 'Barbados' },
  { value: '20', label: 'Belarus' },
  { value: '21', label: 'Belgium' },
  { value: '22', label: 'Belize' },
  { value: '23', label: 'Benin' },
  { value: '24', label: 'Bermuda' },
  { value: '25', label: 'Bhutan' },
  { value: '26', label: 'Bosnia and Herzegovina' },
  { value: '27', label: 'Botswana' },
  { value: '28', label: 'Bouvet Island' },
  { value: '29', label: 'Brazil' },
  { value: '30', label: 'British Indian Ocean Territory' },
  { value: '31', label: 'Brunei Darussalam' },
  { value: '32', label: 'Bulgaria' },
  { value: '33', label: 'Burkina Faso' },
  { value: '34', label: 'Burundi' },
  { value: '35', label: 'Cambodia' },
  { value: '36', label: 'Cameroon' },
  { value: '37', label: 'Canada' },
  { value: '38', label: 'Cape Verde' },
  { value: '39', label: 'Cayman Islands' },
  { value: '40', label: 'Central African Republic' },
  { value: '41', label: 'Chad' },
  { value: '42', label: 'Chile' },
  { value: '43', label: 'China' },
  { value: '44', label: 'Christmas Island' },
  { value: '45', label: 'Cocos (Keeling) Islands' },
  { value: '46', label: 'Colombia' },
  { value: '47', label: 'Comoros' },
  { value: '48', label: 'Congo' },
  { value: '49', label: 'Cook Islands' },
  { value: '50', label: 'Costa Rica' },
  { value: '51', label: 'Croatia' },
  { value: '52', label: 'Cuba' },
  { value: '53', label: 'Cyprus' },
  { value: '54', label: 'Czech Republic' },
  { value: '55', label: 'Denmark' },
  { value: '56', label: 'Djibouti' },
  { value: '57', label: 'Dominica' },
  { value: '58', label: 'Dominican Republic' },
  { value: '59', label: 'Ecuador' },
  { value: '60', label: 'Egypt' },
  { value: '61', label: 'El Salvador' },
  { value: '62', label: 'Equatorial Guinea' },
  { value: '63', label: 'Eritrea' },
  { value: '64', label: 'Estonia' },
  { value: '65', label: 'Ethiopia' },
  { value: '66', label: 'Falkland Islands (Malvinas)' },
  { value: '67', label: 'Faroe Islands' },
  { value: '68', label: 'Fiji' },
  { value: '69', label: 'Finland' },
  { value: '70', label: 'France' },
  { value: '71', label: 'French Guiana' },
  { value: '72', label: 'French Polynesia' },
  { value: '73', label: 'French Southern Territories' },
  { value: '74', label: 'Gabon' },
  { value: '75', label: 'Gambia' },
  { value: '76', label: 'Georgia' },
  { value: '77', label: 'Germany' },
  { value: '78', label: 'Ghana' },
  { value: '79', label: 'Gibraltar' },
  { value: '80', label: 'Greece' },
  { value: '81', label: 'Greenland' },
  { value: '82', label: 'Grenada' },
  { value: '83', label: 'Guadeloupe' },
  { value: '84', label: 'Guam' },
  { value: '85', label: 'Guatemala' },
  { value: '86', label: 'Guernsey' },
  { value: '87', label: 'Guinea' },
  { value: '88', label: 'Guinea-Bissau' },
  { value: '89', label: 'Guyana' },
  { value: '90', label: 'Haiti' },
  { value: '91', label: 'Heard Island and McDonald Islands' },
  { value: '92', label: 'Holy See (Vatican City State)' },
  { value: '93', label: 'Honduras' },
  { value: '94', label: 'Hong Kong' },
  { value: '95', label: 'Hungary' },
  { value: '96', label: 'Iceland' },
  { value: '97', label: 'India' },
  { value: '98', label: 'Indonesia' },
  { value: '99', label: 'Iran' },
  { value: '100', label: 'Iraq' },
  { value: '101', label: 'Ireland' },
  { value: '102', label: 'Isle of Man' },
  { value: '103', label: 'Israel' },
  { value: '104', label: 'Italy' },
  { value: '105', label: 'Jamaica' },
  { value: '106', label: 'Japan' },
  { value: '107', label: 'Jersey' },
  { value: '108', label: 'Jordan' },
  { value: '109', label: 'Kazakhstan' },
  { value: '110', label: 'Kenya' },
  { value: '111', label: 'Kiribati' },
  { value: '112', label: 'Kuwait' },
  { value: '113', label: 'Kyrgyzstan' },
  { value: '114', label: 'Lao Peoples Democratic Republic' },
  { value: '115', label: 'Latvia' },
  { value: '116', label: 'Lebanon' },
  { value: '117', label: 'Lesotho' },
  { value: '118', label: 'Liberia' },
  { value: '119', label: 'Libya' },
  { value: '120', label: 'Liechtenstein' },
  { value: '121', label: 'Lithuania' },
  { value: '122', label: 'Luxembourg' },
  { value: '123', label: 'Macao' },
  { value: '124', label: 'Madagascar' },
  { value: '125', label: 'Malawi' },
  { value: '126', label: 'Malaysia' },
  { value: '127', label: 'Maldives' },
  { value: '128', label: 'Mali' },
  { value: '129', label: 'Malta' },
  { value: '130', label: 'Marshall Islands' },
  { value: '131', label: 'Martinique' },
  { value: '132', label: 'Mauritania' },
  { value: '133', label: 'Mauritius' },
  { value: '134', label: 'Mayotte' },
  { value: '135', label: 'Mexico' },
  { value: '136', label: 'Monaco' },
  { value: '137', label: 'Mongolia' },
  { value: '138', label: 'Montenegro' },
  { value: '139', label: 'Montserrat' },
  { value: '140', label: 'Morocco' },
  { value: '141', label: 'Mozambique' },
  { value: '142', label: 'Myanmar' },
  { value: '143', label: 'Namibia' },
  { value: '144', label: 'Nauru' },
  { value: '145', label: 'Nepal' },
  { value: '146', label: 'Netherlands' },
  { value: '147', label: 'New Caledonia' },
  { value: '148', label: 'New Zealand' },
  { value: '149', label: 'Nicaragua' },
  { value: '150', label: 'Niger' },
  { value: '151', label: 'Nigeria' },
  { value: '152', label: 'Niue' },
  { value: '153', label: 'Norfolk Island' },
  { value: '154', label: 'Northern Mariana Islands' },
  { value: '155', label: 'Norway' },
  { value: '156', label: 'Oman' },
  { value: '157', label: 'Pakistan' },
  { value: '158', label: 'Palau' },
  { value: '159', label: 'Panama' },
  { value: '160', label: 'Papua New Guinea' },
  { value: '161', label: 'Paraguay' },
  { value: '162', label: 'Peru' },
  { value: '163', label: 'Philippines' },
  { value: '164', label: 'Pitcairn' },
  { value: '165', label: 'Poland' },
  { value: '166', label: 'Portugal' },
  { value: '167', label: 'Puerto Rico' },
  { value: '168', label: 'Qatar' },
  { value: '169', label: 'Romania' },
  { value: '170', label: 'Russian Federation' },
  { value: '171', label: 'Rwanda' },
  { value: '172', label: 'Saint Kitts and Nevis' },
  { value: '173', label: 'Saint Lucia' },
  { value: '174', label: 'Saint Martin (French part)' },
  { value: '175', label: 'Saint Pierre and Miquelon' },
  { value: '176', label: 'Saint Vincent and the Grenadines' },
  { value: '177', label: 'Samoa' },
  { value: '178', label: 'San Marino' },
  { value: '179', label: 'Sao Tome and Principe' },
  { value: '180', label: 'Saudi Arabia' },
  { value: '181', label: 'Senegal' },
  { value: '182', label: 'Serbia' },
  { value: '183', label: 'Seychelles' },
  { value: '184', label: 'Sierra Leone' },
  { value: '185', label: 'Singapore' },
  { value: '186', label: 'Sint Maarten (Dutch part)' },
  { value: '187', label: 'Slovakia' },
  { value: '188', label: 'Slovenia' },
  { value: '189', label: 'Solomon Islands' },
  { value: '190', label: 'Somalia' },
  { value: '191', label: 'South Africa' },
  { value: '192', label: 'South Georgia and the South Sandwich Islands' },
  { value: '193', label: 'South Sudan' },
  { value: '194', label: 'Spain' },
  { value: '195', label: 'Sri Lanka' },
  { value: '196', label: 'State of Palestine' },
  { value: '197', label: 'Sudan' },
  { value: '198', label: 'Suriname' },
  { value: '199', label: 'Svalbard and Jan Mayen' },
  { value: '200', label: 'Swaziland' },
  { value: '201', label: 'Sweden' },
  { value: '202', label: 'Switzerland' },
  { value: '203', label: 'Syrian Arab Republic' },
  { value: '204', label: 'Tajikistan' },
  { value: '205', label: 'Thailand' },
  { value: '206', label: 'Timor-Leste' },
  { value: '207', label: 'Togo' },
  { value: '208', label: 'Tokelau' },
  { value: '209', label: 'Tonga' },
  { value: '210', label: 'Trinidad and Tobago' },
  { value: '211', label: 'Tunisia' },
  { value: '212', label: 'Turkey' },
  { value: '213', label: 'Turkmenistan' },
  { value: '214', label: 'Turks and Caicos Islands' },
  { value: '215', label: 'Tuvalu' },
  { value: '216', label: 'Uganda' },
  { value: '217', label: 'Ukraine' },
  { value: '218', label: 'United Arab Emirates' },
  { value: '219', label: 'United Kingdom' },
  { value: '220', label: 'United States' },
  { value: '221', label: 'United States Minor Outlying Islands' },
  { value: '222', label: 'Uruguay' },
  { value: '223', label: 'Uzbekistan' },
  { value: '224', label: 'Vanuatu' },
  { value: '225', label: 'Viet Nam' },
  { value: '226', label: 'Wallis and Futuna' },
  { value: '227', label: 'Western Sahara' },
  { value: '228', label: 'Yemen' },
  { value: '229', label: 'Zambia' },
  { value: '230', label: 'Zimbabwe' }
]

const ConsultationReList = [
  { value: '1', label: 'Immigration' },
  { value: '2', label: 'Work' },
  { value: '3', label: 'Sponsorship' },
  { value: '4', label: 'Study' },
  { value: '5', label: 'Visit' },
  { value: '6', label: 'Citizenship' }
]

const countrys = [
  { value: '13', label: 'Australia' },
  { value: '37', label: 'Canada' },
  { value: '219', label: 'United Kingdom (UK)' },
  { value: '220', label: 'United States America (USA)' },
  { value: '1000', label: 'Others' }
]


const EditUser = (props) => {

  // const [userDetails, setUsersDetails] = useState();
  // const {key} = props;
  const { key } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [backPage,setBackPage] = useState("");
  const [referrerList, setRefereerList] = useState([]);
  const [refUserInfoId, setRefUserInfoId] = useState("");



  // const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  // const navigate = useNavigate();

  const [id, setId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [officeEmail, setOfficeEmail] = useState();
  const [isOtherNameUsed, setIsOtherNameUsed] = useState(false);
  const [mobile, setMobile] = useState();
  const [whatsApp, setWhatsApp] = useState("");
  const [imo, setImo] = useState("");
  const [viber, setViber] = useState("");

  const [sex, setSex] = useState("");
  const [dob, setDob] = useState("");
  const [birthCountry, setBirthCountry] = useState("");
  const [cityOfBirth, setCityOfBirth] = useState("");
  // const [resume, setResume] = useState("");

  const [interestedCountry, setInterestedCountry] = useState([]);
  const onMultiSelectCountry = (e) => {
    setInterestedCountry(Array.isArray(e) ? e?.map(x => x.value) : []);
  }

  const [consultation, setConsultation] = useState([]);
  const onMultiSelectCon = (e) => {
    setConsultation(Array.isArray(e) ? e?.map(x => x.value) : []);
  }


  const [personalInfoId, setPersonalInfoId] = useState("");
  const [passportIssueCountry, setPassportIssueCountry] = useState("");
  const [passportIssueDate, setPassportIssueDate] = useState("");
  const [passportExpireDate, setPassportExpireDate] = useState("");
  const [familyMemberNo, setFamilyMemberNo] = useState("");
  const [isCaAppliedBefore, setIsCaAppliedBefore] = useState("");
  const [haveAnyCaRelative, setHaveAnyCaRelative] = useState("");
  const [caRelativeRelation, setCaRelativeRelation] = useState("");
  const [caRelativeAddress, setCaRelativeAddress] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [spouseInfoId, setSpouseInfoId] = useState("");
  const [sFirstName, setSFirstName] = useState("");
  const [sLastName, setSLastName] = useState("");
  const [marriageDate, setMarriageDate] = useState("");
  const [previouslyMarried, setPreviouslyMarried] = useState("");


  const [addressid, setAddressid] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressPostal, setAddressPostal] = useState("");


  const [haveAnyLangTest, setHaveAnyLangTest] = useState("");
  const [languageTestId, setLanguageTestId] = useState("");
  const [languageTestType, setLanguageTestType] = useState("");
  const [languageTestVersion, setLanguageTestVersion] = useState("");
  const [languageTestDate, setLanguageTestDate] = useState("");
  const [languageTestRDate, setLanguageTestRDate] = useState("");
  const [certificateNo, setCertificateNo] = useState("");
  const [listeningScore, setListeningScore] = useState("");
  const [readingScore, setReadingScore] = useState("");
  const [writingScore, setWritingScore] = useState("");
  const [speakingScore, setSpeakingScore] = useState("");
  const [overallScore, setOverallScore] = useState("");


  const [education, setEducation] = useState([]);
  const [work, setWork] = useState([]);


  const [educationId, setEducationId] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [educationStartDate, setEducationStartDate] = useState("");
  const [educationEndDate, setEducationEndDate] = useState("");
  const [educationDegreeName, setEducationDegreeName] = useState("");
  const [educationInstituteName, setEducationInstituteName] = useState("");

  const [workId, setWorkId] = useState("");
  const [workStartDate, setWorkStartDate] = useState("");
  const [workEndDate, setWorkEndDate] = useState("");
  const [workPosition, setWorkPosition] = useState("");
  const [workOrganization, setWorkOrganization] = useState("");


  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));
    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
        if(apiName==='updateEducationInfo'){
           clearEdu(); 
           reloadFrom();
        }else if(apiName==='updateWorkInfo'){
          clearWork();
          reloadFrom();
        }else if(apiName==='deleteEducationInfo' || apiName==='deleteWorkInfo'){
          reloadFrom();
        }else{

        }
        //reloadFrom();
      }
      else {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK', }] });
      }

    }, fail => {
      confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
    });
  }



  const resultsCon = [];
  var consultati = {}
  const resultsIntCountry = [];
  var IntCountry = {}
  const [countryOfResidence, setCountryOfResidence] = useState("");

  const [validatedBasic, setValidatedBasic] = useState(false);
  const handleSubmitBasic = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      for (const consultationId of consultation) {
        resultsCon.push(
            consultati = {
                consultation: { id: consultationId }
            })
    }


    for (const countryId of interestedCountry) {
        resultsIntCountry.push(
            IntCountry = {
                country: { id: countryId }
            })
    }

    console.log(interestedCountry);
    console.log(consultation);

      const formData = {
        id: id,
        firstName: firstName,
        lastName: lastName,
        officeEmail: officeEmail,
        mobile: mobile,
        whatsApp: whatsApp,
        imo: imo,
        viber: viber,
        sex: sex,
        dob: dob,
        familyMemberNo: familyMemberNo,
        interestedCountry: resultsIntCountry,
        userConsultation: resultsCon,
        userResidence: [
          {
            country: { id: countryOfResidence }
          }
        ],
        refUserInfoId:refUserInfoId
        
      }
      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update basic information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateBasicInfo", "Basic information successfully updated")
        }, { label: 'No', }]
      });
    }
    setValidatedBasic(true);
    event.preventDefault();
  }



  const [validatedPersonal, setValidatedPersonal] = useState(false);
  const handleSubmitPersonal = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {

      const formData = {
        userInfoId:id,
        id: personalInfoId,
        legalFirstName: "",
        legalLastName: "",
        isOtherNameUsed: isOtherNameUsed,
        dateOfBirth: dob,
        birthCountry: { id: birthCountry },
        birthCity: cityOfBirth,
        citizenCountry: { id: passportIssueCountry },
        passportIssueCountry: { id: passportIssueCountry },
        passportExpireDate: passportExpireDate,
        passportIssueDate: passportIssueDate,
        isCaAppliedBefore: isCaAppliedBefore,
        haveAnyCaRelative: haveAnyCaRelative,
        caRelativeRelation: caRelativeRelation,
        caRelativeAddress: caRelativeAddress
      }

      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update personal information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updatePersonalInfo", "Personal information successfully updated")
        }, { label: 'No', }]
      });

    }
    setValidatedPersonal(true);
    event.preventDefault();
  }




  const [validatedMarital, setValidatedMarital] = useState(false);
  const handleSubmitMarital = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {

      const formData = {
        userInfoId:id,
        maritalStatus: { id: maritalStatus },
        spouseInfo: maritalStatus === "2" ? [
          {
            id: spouseInfoId,
            firstName: sFirstName,
            lastName: sLastName,
            marriageDate: marriageDate,
            previouslyMarried: previouslyMarried
          }

        ] : [],
       
      }

      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update marital information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateMaritalInfo", "Marital information successfully updated")
        }, { label: 'No', }]
      });
    }
    setValidatedMarital(true);
    event.preventDefault();
  }



  const [validatedAddress, setValidatedAddress] = useState(false);
  const handleSubmitAddress = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {

      const formData = {
        userInfoId:id,
        address: [
          {
            id: addressid,
            position: 1,
            addressType: 3,
            address: addressLineOne,
            addressLineTwo: addressLineTwo,
            city: addressCity,
            state: addressState,
            postal: addressPostal
          }
        ]
      }

      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update address information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateAddressInfo", "Address information successfully updated")
        }, { label: 'No', }]
      });
    }
    setValidatedAddress(true);
    event.preventDefault();
  }


  const [validatedLanguageTest, setLalidatedLanguageTest] = useState(false);
  const handleSubmitLanguageTest = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {

      console.log(languageTestType);
      console.log(languageTestVersion);

      const formData = {
        userInfoId:id,
        haveAnyLangTest: haveAnyLangTest,
        languageTest: haveAnyLangTest === "true" ? [
          {
            id: languageTestId,
            languageTestType: {
              id: languageTestType,
            },
            languageTestVersion:languageTestVersion===""? null: {
              id: languageTestVersion,
            },
            date: languageTestDate,
            resultDate: languageTestRDate,
            certificateNo: certificateNo,
            listeningScore: listeningScore,
            readingScore: readingScore,
            writingScore: writingScore,
            speakingScore: speakingScore,
            overall: overallScore

          }
        ] : []

      }

      console.log(languageTestType);
      console.log(languageTestVersion);

      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update language test information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateLanguageTestInfo", "Language test information successfully updated")
        }, { label: 'No', }]
      });
    }
    setLalidatedLanguageTest(true);
    event.preventDefault();


  }

  const [validatedEducation, setValidatedEducation] = useState(false);
  const handleSubmitEducation = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {

      const formData = {
        userInfoId: id,
        educationalInfo:[
          {
            id: educationId,
            educationLevel: { id: educationLevel },
            startDate: educationStartDate,
            completeDate: educationEndDate,
            degreeName: educationDegreeName,
            instituteName: educationInstituteName,
            country: null
          }
        ],
        

      }

      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update education information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateEducationInfo", "Education information successfully updated")
        }, { label: 'No', }]
      });
    }
    setValidatedEducation(true);
    event.preventDefault();
  }


  const [validatedWork, setValidatedWork] = useState(false);
  const handleSubmitWork = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      const formData = {
        userInfoId: id,
        workExperience:[{
          id: workId,
          position: 1,
          startDate: workStartDate,
          endDate: workEndDate,
          designation: workPosition,
          organization: workOrganization,
          responsibilities: ""

        }]
      }

      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to update work experience information of " + firstName + " " + lastName + " ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "updateWorkInfo", "Work experience information successfully updated")
        }, { label: 'No', }]
      });
    }
    setValidatedWork(true);
    event.preventDefault();


  }


  const [updateText] = useState("Update");
  const [saveText,] = useState("Save");
  const [eduButtonText, setEduButtonText] = useState(saveText);
  const [workButtonText, setWorkButtonText] = useState(saveText);
  const [isEduUpdate, setIsEduUpdate] = useState(false);
  const [isWorkUpdate, setIsWorkUpdate] = useState(false);

  function editEducation(data){
    setEduButtonText(updateText);
    setIsEduUpdate(true);

    setEducationId(data?.id);
    setEducationLevel(data?.educationLevel?.id);
    setEducationStartDate(data?.startDate?Date.parse(data?.startDate):'');
    setEducationEndDate(data?.completeDate?Date.parse(data?.completeDate):'');
    setEducationDegreeName(data?.degreeName);
    setEducationInstituteName(data?.instituteName);

    
  }

  function clearEdu() {
    setEduButtonText(saveText);
    setIsEduUpdate(false);

    setEducationId('');
    setEducationLevel('');
    setEducationStartDate('');
    setEducationEndDate('');
    setEducationDegreeName('');
    setEducationInstituteName('');
    setValidatedEducation(false);
  }



  function editWork(data){
    setWorkButtonText(updateText);
    setIsWorkUpdate(true);

    setWorkId(data?.id);
    setWorkStartDate(data?.startDate?Date.parse(data?.startDate):'');
    setWorkEndDate(data?.endDate?Date.parse(data?.endDate):'');
    setWorkPosition(data?.designation);
    setWorkOrganization(data?.organization);
    
  }

  function clearWork() {
    setWorkButtonText(saveText);
    setIsWorkUpdate(false);
    setWorkId('');
    setWorkStartDate('');
    setWorkEndDate('');
    setWorkPosition('');
    setWorkOrganization('');
    setValidatedWork(false);
  }




  function deleteEducation(data){
    const formData = {
      userInfoId: id,
      id: data?.id
    }
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? you want to delete education " + firstName + " " + lastName + " ?", buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "deleteEducationInfo", "Education deleted successfully")
      }, { label: 'No', }]
    });
    
  }
  
  function deleteWork(data){
    const formData = {
      userInfoId: id,
      id: data?.id
    }
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? you want to delete work experience " + firstName + " " + lastName + " ?", buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "deleteWorkInfo", "Work experience deleted successfully")
      }, { label: 'No', }]
    });
    
  }


  function setData(data) {

    let conArray = [];
    for (const consultation of data?.userConsultation) {
      conArray.push("" + consultation?.consultation?.id + "");
    }
    let intArray = [];
    for (const interestedCountry of data?.interestedCountry) {
      intArray.push("" + interestedCountry?.country?.id + "");
    }


    setId(data?.id);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setOfficeEmail(data?.officeEmail);
    setMobile(data?.mobile);
    setWhatsApp(data?.whatsApp);
    setImo(data?.imo);
    setViber(data?.viber);
    setSex(data?.sex);
    setDob(data?.dob ? Date.parse(data?.dob) : '');
    setFamilyMemberNo(data?.familyMemberNo);
    

    setCountryOfResidence(data?.userResidence[0]?.country?.id);
    setConsultation(conArray);
    setInterestedCountry(intArray);
    
    setPersonalInfoId(data?.personaInfo?.id);
    setIsOtherNameUsed(data?.personaInfo?.isOtherNameUsed);
    let x = data?.personaInfo?.isOtherNameUsed ? document.getElementById("validationCustomAlternativeName").click() : "";
    setBirthCountry(data?.personaInfo?.birthCountry?.id);
    setCityOfBirth(data?.personaInfo?.birthCity);
    setPassportIssueCountry(data?.personaInfo?.passportIssueCountry?.id);
    setPassportIssueDate(data?.personaInfo?.passportIssueDate ? Date.parse(data?.personaInfo?.passportIssueDate) : '');
    setPassportExpireDate(data?.personaInfo?.passportExpireDate ? Date.parse(data?.personaInfo?.passportExpireDate) : '');
    setIsCaAppliedBefore(data?.personaInfo?.isCaAppliedBefore);
    setIsCaAppliedBefore(data?.personaInfo?.isCaAppliedBefore);
    setHaveAnyCaRelative(data?.personaInfo?.haveAnyCaRelative ? "true" : "false");
    setCaRelativeRelation(data?.personaInfo?.caRelativeRelation);
    setCaRelativeAddress(data?.personaInfo?.caRelativeAddress);
    setMaritalStatus(data?.maritalStatus?.id);
    //
    setSpouseInfoId(data?.spouseInfo[0]?.id)
    setSFirstName(data?.spouseInfo[0]?.firstName);
    setSLastName(data?.spouseInfo[0]?.lastName);
    setMarriageDate(data?.spouseInfo[0]?.marriageDate ? Date.parse(data?.spouseInfo[0]?.marriageDate) : '');
    setPreviouslyMarried(data?.spouseInfo[0]?.previouslyMarried ? "true" : "false");

    //
    setAddressid(data?.address[0]?.id);
    setAddressLineOne(data?.address[0]?.address);
    setAddressLineTwo(data?.address[0]?.addressLineTwo);
    setAddressCity(data?.address[0]?.city);
    setAddressState(data?.address[0]?.state);
    setAddressPostal(data?.address[0]?.postal);

    setHaveAnyLangTest(data?.haveAnyLangTest ? "true" : "false");
    setLanguageTestId(data?.languageTest[0]?.id);
    setLanguageTestType(data?.languageTest[0]?.languageTestType?.id);
    setLanguageTestVersion(data?.languageTest[0]?.languageTestVersion?.id);
    setLanguageTestDate(data?.languageTest[0]?.date ? Date.parse(data?.languageTest[0]?.date) : "");
    setLanguageTestRDate(data?.languageTest[0]?.resultDate ? Date.parse(data?.languageTest[0]?.resultDate) : "");
    setCertificateNo(data?.languageTest[0]?.certificateNo);
    setListeningScore(data?.languageTest[0]?.listeningScore);
    setReadingScore(data?.languageTest[0]?.readingScore);
    setWritingScore(data?.languageTest[0]?.writingScore);
    setSpeakingScore(data?.languageTest[0]?.speakingScore);
    setOverallScore(data?.languageTest[0]?.overall);
    setEducation(data?.educationalInfo);
    setWork(data?.workExperience);

  }


  function reloadFrom() {
    const controller = new AbortController();
    // const detailsUserId = localStorage.getItem('edit-user-id');
    try {
      // const path = `/getUserInfo/${location?.state?.id ? location.state.id : detailsUserId}`;
      // let id = location?.state?.id ? location.state.id : detailsUserId?detailsUserId:0;
        let path = "";
        if(key===0 || key==="0"){
          path = `/getUserInfo`;
        }else{
          path = `/getUserInfo/${key}`;
        }

      axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
        if (response.status === 200) {
          setData(response?.data);
        }

      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });;

    } catch (err) {
      console.log(err);
      confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
    }
  }



  function viewHandleBack() {
    navigate(backPage, { state: { id: location?.state?.id ? location.state.id : localStorage.getItem('edit-user-id') }, replace: true });
  }


  useEffect(() => {
    //let isMounted = true;
    const controller = new AbortController();

    const backPage = location?.state?.backPage?location.state.backPage:localStorage.getItem('back-page');
    setBackPage(backPage);


    // const detailsUserId = localStorage.getItem('edit-user-id');
    const getUserDetails = async () => {
      try {


        // let id = location?.state?.id ? location.state.id : detailsUserId?detailsUserId:0;
        // let id = props?.key;
        let path = "";
        if(key===0 || key==="0"){
          path = `/getUserInfo`;
        }else{
          path = `/getUserInfo/${key}`;
        }
        
        console.log(path);
        axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            setData(response?.data);
            if(response?.data?.userType===1|| response?.data?.userType==="1"){
              getRefererList(response?.data?.refUserInfo?.id);
            }
          }

        }, fail => {
          confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
        });;

      } catch (err) {
        console.log(err);
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      }
    }
    
    const getRefererList = async (id) => {
      try {
        let role = JSON.parse(localStorage.getItem('auth'))?.roles[0];
        if(role==="ROLE_ADMIN"){
          let path = "/getActiveReferrerListForDropdown";
          axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
            if (response.status === 200) {
              setRefereerList(response?.data);
              setRefUserInfoId(id);
              //console.log(response?.data);
            }
  
          }, fail => {});;
        }
      } catch (err) {
        console.log(err);}
    }



    getUserDetails();


    return () => {
      //isMounted = false;
      controller.abort();
    }
  },[key]);

  return (
    <>
      <section>
        
        <h2 className="paddingBottom30">Update Details info  {/* {backPage!==""?<CButton className="floatRight" color="info" onClick={() => { viewHandleBack()}} >Back </CButton>:''}  */}</h2>

        <div className='update-info clear-both'>
          <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>

              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedBasic} onSubmit={handleSubmitBasic} >
                <CRow className="border1 paddingBottom10 marginLeft8 marginRight8 width99p">
                  <CCol md={12}>
                    <h2 className="paddingTop10">Basic Information</h2>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomFirstName">First Name</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                      <CFormInput
                        id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={firstName} onChange={(event) => { setFirstName(event.target.value); }} required={true} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomLastName">Last Name</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                      <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomLastName" value={lastName} onChange={(event) => { setLastName(event.target.value); }} required={true} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomOfficeEmail">Email</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText>@</CInputGroupText>
                      <CFormInput type="text" aria-describedby="validationCustomOfficeEmail" feedbackValid="Looks good!" id="validationCustomOfficeEmail" value={officeEmail} onChange={(event) => { setOfficeEmail(event.target.value); }} required={true} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomMobile">Mobile</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                      <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={mobile} onChange={(event) => { setMobile(event.target.value); }} required={true} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomMobile">WhatsApp</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                      <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={whatsApp} onChange={(event) => { setWhatsApp(event.target.value); }} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomMobile">Imo</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                      <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={imo} onChange={(event) => { setImo(event.target.value); }} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomMobile">Viber</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilMobile} /></CInputGroupText>
                      <CFormInput type="text" feedbackValid="Looks good" feedbackInvalid="Required!" id="validationCustomMobile" value={viber} onChange={(event) => { setViber(event.target.value); }} />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomGender">Gender <span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                      <CFormSelect id="validationCustomGender" feedbackValid="Looks good" feedbackInvalid="Required!" value={sex} onChange={(event) => { setSex(event.target.value); }} required>
                        <option value="">Select...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </CFormSelect>
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomDateOfBirth">Date Of Birth<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation customDatePickerWidth">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        maxDate={new Date()}
                        id="validationCustomDateOfBirth"
                        className="form-control date-picker-custom"
                        selected={dob} onChange={(date) => setDob(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                        required />
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomFamilyMamber">How many family members do you have?<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                      <CFormSelect id="validationCustomFamilyMamber" feedbackValid="Looks good" feedbackInvalid="Required!" value={familyMemberNo} onChange={(event) => { setFamilyMemberNo(event.target.value); }} required >
                        <option value="">Select...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={8}>
                    <CFormLabel htmlFor="validationCustomCR">Country of Residence<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CFormSelect id="validationCustomCR" size="md" aria-label="" options={countryList} value={countryOfResidence} onChange={(event) => { setCountryOfResidence(event.target.value); }} required ></CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={6}>
                    <CFormLabel htmlFor="validationCustomConsutration">Consultation Requirement<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <Select isMulti name="Consultation" options={ConsultationReList} id="validationCustomConsutration"
                        className="width100p"
                        classNamePrefix="Consultation Requirement"
                        value={ConsultationReList.filter(obj => consultation.includes(obj.value))}
                        onChange={onMultiSelectCon}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        required
                      />
                    </CInputGroup>
                  </CCol>
                  

                  <CCol md={6}>
                    <CFormLabel htmlFor="validationCustomIC">Interested Country<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <Select isMulti name="country" id="validationCustomIC" options={countrys} className="width100p"
                        classNamePrefix="Interested Country" value={countrys.filter(obj => interestedCountry.includes(obj.value))}
                        onChange={onMultiSelectCountry}
                        required
                      />
                    </CInputGroup>
                  </CCol>

                  {referrerList?.length > 0 ?
                    <>
                      <CCol md={12}>
                        <CFormLabel htmlFor="validationCustomIC">Referred By<span className='red'>*</span></CFormLabel>
                        <CInputGroup className="has-validation">
                          <CFormSelect name="referredBy"className="width100p"  id="validationCustomReferredBy" value={refUserInfoId} onChange={(event) => { setRefUserInfoId(event.target.value); }} >
                          <option value="" >Select ...</option>
                          {referrerList && referrerList?.map((row) => <option key={row?.id} value={row?.id}  >{row?.name}</option>)}
                          </CFormSelect>
                        </CInputGroup>
                      </CCol>
                    </>
                    : ""}

                  <CCol md={4}>



                  </CCol>

                  <CCol xs={12} className="paddingTop20">
                    <CButton color="primary" type="submit"> Save </CButton>
                  </CCol>
                </CRow>
              </CForm>



              <CCol md={12}>
                &nbsp;
              </CCol>

              <CCol md={12}>
                &nbsp;
              </CCol>

              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedPersonal} onSubmit={handleSubmitPersonal} >
                <CRow className="border1 paddingBottom10 marginLeft8 marginRight8 width99p">
                  <CCol md={12}>
                    <h2 className="paddingTop10">Personal Information</h2>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomAlternativeName">Have you ever used any other name?</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CFormSwitch id="validationCustomAlternativeName" feedbackValid="Looks good" value={isOtherNameUsed} onChange={(event) => { setIsOtherNameUsed(event.target.checked); }} />
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomCountyOfBirth">Country Of Birth<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormSelect id="validationCustomCountyOfBirth" feedbackValid="Looks good" feedbackInvalid="Required!" value={birthCountry} options={countryList} onChange={(event) => { setBirthCountry(event.target.value); }} required></CFormSelect>
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomCityOfBirth">City Of Birth<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormInput id="validationCustomCityOfBirth" feedbackValid="Looks good" feedbackInvalid="Required!" value={cityOfBirth} onChange={(event) => { setCityOfBirth(event.target.value); }} required />
                    </CInputGroup>
                  </CCol>
                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomPassportCountry">Passport (Country/territory of issue)<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormSelect id="validationCustomPassportCountry" feedbackValid="Looks good" feedbackInvalid="Required!" value={passportIssueCountry} options={countryList} onChange={(event) => { setPassportIssueCountry(event.target.value); }} required></CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomPassportIssueDate">Passport Issue date</CFormLabel>
                    <CInputGroup className="has-validation customDatePickerWidth">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        maxDate={new Date()}
                        id="validationCustomPassportIssueDate"
                        className="form-control date-picker-custom"
                        selected={passportIssueDate} onChange={(date) => setPassportIssueDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                      />

                      <div className={true ? "dnone" : "dblock"} id="validationCustom03Feedback">Please select valid date</div>
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomPassportExpireDate">Passport Expiry date<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation customDatePickerWidth">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        id="validationCustomPassportExpireDate"
                        className="form-control date-picker-custom"
                        selected={passportExpireDate} onChange={(date) => setPassportExpireDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                        required />

                      <div className={true ? "dnone" : "dblock"} id="validationCustom03Feedback">Please select valid date</div>
                    </CInputGroup>
                  </CCol>


                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomApliedBefore">Applied before for interested country?<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                      <CFormSelect id="validationCustomApliedBefore" feedbackValid="Looks good" feedbackInvalid="Required!" value={isCaAppliedBefore} onChange={(event) => { setIsCaAppliedBefore(event.target.value); }} required >
                        <option value="">Select...</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomRelatives">Any Family/Friends in desired country?<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                      <CFormSelect id="validationCustomRelatives" feedbackValid="Looks good" feedbackInvalid="Required!" value={haveAnyCaRelative} onChange={(event) => { setHaveAnyCaRelative(event.target.value); }} required >
                        <option value="">Select...</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <div className={haveAnyCaRelative === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomRelation">Relations with this Family/Friend<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                        <CFormInput type="text" id="validationCustomRelation" feedbackValid="Looks good" feedbackInvalid="Required!" value={caRelativeRelation} onChange={(event) => { setCaRelativeRelation(event.target.value); }} required={haveAnyCaRelative === "true"} />
                      </CInputGroup>
                    </div>
                  </CCol>
                  <CCol md={12}>
                    <div className={haveAnyCaRelative === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomRelativeAddress" >Province or Territory where he/she live<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                        <CFormInput type="text" id="validationCustomRelativeAddress" feedbackValid="Looks good" feedbackInvalid="Required!" value={caRelativeAddress} onChange={(event) => { setCaRelativeAddress(event.target.value); }} required={haveAnyCaRelative === "true"} />
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol xs={12} className="paddingTop20">
                    <CButton color="primary" type="submit"> Save </CButton>
                  </CCol>
                </CRow>
              </CForm>


              <CCol md={12}>
                &nbsp;
              </CCol>


              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedMarital} onSubmit={handleSubmitMarital} >

                <CRow className="border1 paddingBottom10 marginLeft8 marginRight8 width99p">
                  <CCol md={12}>
                    <h2 className="paddingTop10">Marital Information</h2>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomMaritalStatus">Marital Status<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                      <CFormSelect id="validationCustomMaritalStatus" feedbackValid="Looks good" feedbackInvalid="Required!" value={maritalStatus} onChange={(event) => { setMaritalStatus(event.target.value); }} required >
                        <option value="">Select...</option>
                        <option value="1">Single</option>
                        <option value="2">Married</option>
                        <option value="3">Divorced</option>
                        <option value="4">Widowed</option>
                      </CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <div className={Number(maritalStatus) === 2 ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomSpouseFName">Spouse First Name<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                        <CFormInput type="text" id="validationCustomSpouseFName" feedbackValid="Looks good" feedbackInvalid="Required!" value={sFirstName} onChange={(event) => { setSFirstName(event.target.value); }} required={Number(maritalStatus) === 2} />
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={Number(maritalStatus) === 2 ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomSLastName">Spouse Last Name<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                        <CFormInput type="text" id="validationCustomSLastName" feedbackValid="Looks good" feedbackInvalid="Required!" value={sLastName} onChange={(event) => { setSLastName(event.target.value); }} required={Number(maritalStatus) === 2} />
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={Number(maritalStatus) === 2 ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomMarageDate">Date of Marriage<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation customDatePickerWidth">
                        <DatePicker
                          showMonthDropdown
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          maxDate={new Date()}
                          id="validationCustomMarageDate"
                          className="form-control date-picker-custom"
                          selected={marriageDate} onChange={(date) => setMarriageDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                          required={Number(maritalStatus) === 2} />

                        <div className={true ? "dnone" : "dblock"} id="validationCustom03Feedback">Please select valid date</div>
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={Number(maritalStatus) === 2 ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomPMaried">Have you been previously married?<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                        <CFormSelect id="validationCustomPMaried" feedbackValid="Looks good" feedbackInvalid="Required!" value={previouslyMarried} onChange={(event) => { setPreviouslyMarried(event.target.value); }} required={Number(maritalStatus) === 2} >
                          <option value="">Select...</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </CFormSelect>
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol xs={12} className="paddingTop20">
                    <CButton color="primary" type="submit"> Save </CButton>
                  </CCol>
                </CRow>
              </CForm>


              <CCol md={12}>
                &nbsp;
              </CCol>



              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedAddress} onSubmit={handleSubmitAddress} >
                <CRow className="border1 paddingBottom10 marginLeft8 marginRight8 width99p">
                  <CCol md={12}>
                    <h2 className="paddingTop10">Address Information</h2>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomAddressLine1">Address line 1<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormInput id="validationCustomAddressLine1" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressLineOne} onChange={(event) => { setAddressLineOne(event.target.value); }} required />
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomAddressLine2">Address line 2</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormInput id="validationCustomAddressLine2" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressLineTwo} onChange={(event) => { setAddressLineTwo(event.target.value); }} />
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomAddressCity">City<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormInput id="validationCustomAddressCity" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressCity} onChange={(event) => { setAddressCity(event.target.value); }} required />
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomAddressState">State / Province<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormInput id="validationCustomAddressState" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressState} onChange={(event) => { setAddressState(event.target.value); }} required />
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomAddressPostal">Postal / Zip Code<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                      <CFormInput id="validationCustomAddressPostal" feedbackValid="Looks good" feedbackInvalid="Required!" value={addressPostal} onChange={(event) => { setAddressPostal(event.target.value); }} required />
                    </CInputGroup>
                  </CCol>

                  <CCol xs={12} className="paddingTop20">
                    <CButton color="primary" type="submit"> Save </CButton>
                  </CCol>
                </CRow>
              </CForm>

              <CCol md={12}>
                &nbsp;
              </CCol>


              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedLanguageTest} onSubmit={handleSubmitLanguageTest} >
                <CRow className="border1 paddingBottom10 marginLeft8 marginRight8 width99p">

                  <CCol md={12}>
                    <h2 className="paddingTop10">Language Test Information</h2>
                  </CCol>

                  <CCol md={4}>
                    <CFormLabel htmlFor="validationCustomLanguageTest">Have you taken any Language test?<span className='red'>*</span></CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                      <CFormSelect id="validationCustomLanguageTest" feedbackValid="Looks good" feedbackInvalid="Required!" value={haveAnyLangTest} onChange={(event) => { setHaveAnyLangTest(event.target.value); }} required >
                        <option value="">Select...</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </CFormSelect>
                    </CInputGroup>
                  </CCol>

                  <CCol md={4}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomLanguageTestType">Test Type<span className='red'>*</span></CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                        <CFormSelect id="validationCustomLanguageTestType" feedbackValid="Looks good" feedbackInvalid="Required!" value={languageTestType} onChange={(event) => { setLanguageTestType(event.target.value); }} required={haveAnyLangTest === "true"} >
                          <option value="">Select...</option>
                          <option value="1">IELTS</option>
                          <option value="2">CELPIP</option>
                          <option value="3">Dualingo</option>
                          <option value="4">PTE</option>
                        </CFormSelect>
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationCustomLanguageTestVersion">Language test version</CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilOptions} /></CInputGroupText>
                        <CFormSelect id="validationCustomLanguageTestVersion" feedbackValid="Looks good" feedbackInvalid="Required!" value={languageTestVersion} onChange={(event) => { setLanguageTestVersion(event.target.value); }} >
                          <option value="">Select...</option>
                          <option value="1">General Training</option>
                          <option value="2">Academic</option>
                        </CFormSelect>
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageTestDate">Date of language test</CFormLabel>
                      <CInputGroup className="has-validation customDatePickerWidth">
                        <DatePicker
                          showMonthDropdown
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          maxDate={new Date()}
                          id="validationLanguageTestDate"
                          className="form-control date-picker-custom"
                          selected={languageTestDate} onChange={(date) => setLanguageTestDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                        />

                        <div className={true ? "dnone" : "dblock"} id="validationCustom03Feedback">Please select valid date</div>
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationlanguageTestReDate">Date of test results</CFormLabel>
                      <CInputGroup className="has-validation customDatePickerWidth">
                        <DatePicker
                          showMonthDropdown
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          maxDate={new Date()}
                          id="validationlanguageTestReDate"
                          className="form-control date-picker-custom"
                          selected={languageTestRDate} onChange={(date) => setLanguageTestRDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                        />

                        <div className={true ? "dnone" : "dblock"} id="validationCustom03Feedback">Please select valid date</div>
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol md={4}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageTestCer">Certificate number</CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cibWhenIWork} /></CInputGroupText>
                        <CFormInput id="validationLanguageTestCer" feedbackValid="Looks good" feedbackInvalid="Required!" value={certificateNo} onChange={(event) => { setCertificateNo(event.target.value); }} />
                      </CInputGroup>
                    </div>
                  </CCol>
                  <CCol md={2}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'} style={{ paddingTop: 30 }}>
                      Language Score
                    </div>
                  </CCol>
                  <CCol md={2}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageTestList">Listening</CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilCalculator} /></CInputGroupText>
                        <CFormInput type='number' id="validationLanguageTestList" feedbackValid="Looks good" feedbackInvalid="Required!" value={listeningScore} onChange={(event) => { setListeningScore(event.target.value); }} />
                      </CInputGroup>
                    </div>
                  </CCol>
                  <CCol md={2}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageReading">Reading</CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilCalculator} /></CInputGroupText>
                        <CFormInput type='number' id="validationLanguageReading" feedbackValid="Looks good" feedbackInvalid="Required!" value={readingScore} onChange={(event) => { setReadingScore(event.target.value); }} />
                      </CInputGroup>
                    </div>
                  </CCol>
                  <CCol md={2}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageTestWrite">Writing </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilCalculator} /></CInputGroupText>
                        <CFormInput type='number' id="validationLanguageTestWrite" feedbackValid="Looks good" feedbackInvalid="Required!" value={writingScore} onChange={(event) => { setWritingScore(event.target.value); }} />
                      </CInputGroup>
                    </div>
                  </CCol>
                  <CCol md={2}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageTestSpeak">Speaking</CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilCalculator} /></CInputGroupText>
                        <CFormInput type='number' id="validationLanguageTestSpeak" feedbackValid="Looks good" feedbackInvalid="Required!" value={speakingScore} onChange={(event) => { setSpeakingScore(event.target.value); }} />
                      </CInputGroup>
                    </div>
                  </CCol>
                  <CCol md={2}>
                    <div className={haveAnyLangTest === "true" ? 'dblock' : 'dnone'}>
                      <CFormLabel htmlFor="validationLanguageTestOverall">Overall</CFormLabel>
                      <CInputGroup className="has-validation">
                        <CInputGroupText><CIcon icon={cilCalculator} /></CInputGroupText>
                        <CFormInput type='number' id="validationLanguageTestOverall" feedbackValid="Looks good" feedbackInvalid="Required!" value={overallScore} onChange={(event) => { setOverallScore(event.target.value); }} />
                      </CInputGroup>
                    </div>
                  </CCol>

                  <CCol xs={12} className="paddingTop20">
                    <CButton color="primary" type="submit"> Save </CButton>
                  </CCol>
                </CRow>
              </CForm>




              <CCol md={12}>
                &nbsp;
              </CCol>


              {/* <CCol xs={12}> */}
              <CCard className="mb-2">
                <h2 className="paddingTop10 paddingLeft20">Educational Information</h2>
                <CCardBody>
                  <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedEducation} onSubmit={handleSubmitEducation} >
                    <CRow className="mb-12">
                      <div className="col-sm-4">
                        Education level <span className='red'>*</span>
                        <CInputGroup className="has-validation">
                          <CInputGroupText><CIcon icon={cilSchool} /></CInputGroupText>
                          <CFormSelect feedbackValid="Looks good" feedbackInvalid="Required!"
                            value={educationLevel} onChange={(event) => { setEducationLevel(event.target.value); }} required>
                            <option value="">Select</option>
                            <option value="1">Secondary</option>
                            <option value="2">Higher Secondary</option>
                            <option value="3">Bachelor</option>
                            <option value="4">Masters</option>
                            <option value="5">PHD</option>
                          </CFormSelect>
                        </CInputGroup>

                      </div>
                      <div className="col-sm-4"> Start Date
                        <CInputGroup className="has-validation customDatePickerWidth">

                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="form-control date-picker-custom"
                            selected={educationStartDate} onChange={(date) => setEducationStartDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                          />
                        </CInputGroup>
                      </div>
                      <div className="col-sm-4"> End Date
                        <CInputGroup className="has-validation customDatePickerWidth">

                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="form-control date-picker-custom"
                            selected={educationEndDate} onChange={(date) => setEducationEndDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                          />
                        </CInputGroup>
                      </div>
                    </CRow>
                    <CRow className="mb-12">
                      <div className="col-sm-6">
                        Name Of Degree <span className='red'>*</span>
                        <CInputGroup className="mb-2">

                          <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                          <CFormInput placeholder="" aria-label="email" required
                            feedbackValid="Looks good" feedbackInvalid="Required!"
                            value={educationDegreeName} onChange={(event) => { setEducationDegreeName(event.target.value); }} />

                        </CInputGroup>
                      </div>
                      <div className="col-sm-6">
                        Name Of Institution <span className='red'>*</span>
                        <CInputGroup className="mb-2">
                          <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                          <CFormInput placeholder="" aria-label="optionalEmail" required
                            feedbackValid="Looks good" feedbackInvalid="Required!"
                            value={educationInstituteName} onChange={(event) => { setEducationInstituteName(event.target.value); }} />
                        </CInputGroup>
                      </div>
                    </CRow>
                    <CRow>
                      <div className="col-sm-12">
                        <CButton color="primary" type="submit"> {eduButtonText} </CButton> {isEduUpdate ? <CButton onClick={clearEdu} color="info" > Clear </CButton> : ''}
                      </div>
                    </CRow>
                  </CForm>
                </CCardBody>


                <CCardBody>

                  <CTable align="middle" height={100} className="mb-0 border" hover responsive>
                    <CTableHead color="light">
                      <CTableRow>
                        <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Level</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Name of Degree</CTableHeaderCell>
                        <CTableHeaderCell scope="col" >Name of Institute</CTableHeaderCell>
                        <CTableHeaderCell scope="col" >Start Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col" >Com. Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col" >Edit</CTableHeaderCell>
                        <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {education?.map((edu, i) => (
                        <CTableRow v-for="item in tableItems" key={i}>
                          <CTableDataCell>
                            <div>{i + 1}</div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div>{edu?.educationLevel?.name}</div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="clearfix">
                              {edu?.degreeName}
                            </div>
                          </CTableDataCell>
                          <CTableDataCell style={{ textAlign: 'center' }}>
                            <div className="clearfix">
                              {edu?.instituteName}
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            {edu?.startDate ? moment(edu?.startDate).format("DD-MM-YYYY") : ''}
                          </CTableDataCell>
                          <CTableDataCell>
                            {edu?.completeDate ? moment(edu?.completeDate).format("DD-MM-YYYY") : ''}
                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton color="success" onClick={() => { editEducation(edu) }} >Edit</CButton>
                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton color="success" onClick={() => { deleteEducation(edu) }} >Delete</CButton>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
              {/* </CCol> */}


              <CCol md={12}>
                &nbsp;
              </CCol>


              <CCard className="mb-2">
                <h2 className="paddingTop10 paddingLeft20">Work Experience</h2>
                <CCardBody>
                  <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedWork} onSubmit={handleSubmitWork} >
                    <CRow className="mb-12">
                      <div className="col-sm-6"> Start Date
                        <CInputGroup className="has-validation customDatePickerWidth">

                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="form-control date-picker-custom"
                            selected={workStartDate} onChange={(date) => setWorkStartDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                          />
                        </CInputGroup>
                      </div>
                      <div className="col-sm-6"> End Date
                        <CInputGroup className="has-validation customDatePickerWidth">

                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="form-control date-picker-custom"
                            selected={workEndDate} onChange={(date) => setWorkEndDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                          />
                        </CInputGroup>
                      </div>
                    </CRow>
                    <CRow className="mb-12">
                      <div className="col-sm-6">
                        Designation/Position<span className='red'>*</span>
                        <CInputGroup className="mb-2">

                          <CInputGroupText>  <CIcon icon={cilInfo} /></CInputGroupText>
                          <CFormInput placeholder="" aria-label="email" required
                            feedbackValid="Looks good" feedbackInvalid="Required!"
                            value={workPosition} onChange={(event) => { setWorkPosition(event.target.value); }} />

                        </CInputGroup>
                      </div>
                      <div className="col-sm-6">
                        Organization<span className='red'>*</span>
                        <CInputGroup className="mb-2">
                          <CInputGroupText> <CIcon icon={cilInfo} /></CInputGroupText>
                          <CFormInput placeholder="" aria-label="optionalEmail" required
                            feedbackValid="Looks good" feedbackInvalid="Required!"
                            value={workOrganization} onChange={(event) => { setWorkOrganization(event.target.value); }} />
                        </CInputGroup>
                      </div>
                    </CRow>
                    <CRow>
                      <div className="col-sm-12">
                        <CButton color="primary" type="submit"> {workButtonText} </CButton> {isWorkUpdate ? <CButton onClick={clearWork} color="info" > Clear </CButton> : ''}
                      </div>
                    </CRow>
                  </CForm>

                </CCardBody>


                <CCardBody>
                  <CTable align="middle" height={100} className="mb-0 border" hover responsive>
                    <CTableHead color="light">
                      <CTableRow>
                        <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Start Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col">End Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Designation/Position</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Organization</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Edit</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Delete</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {work?.map((row, i) => (
                        <CTableRow v-for="item in tableItems" key={i}>
                          <CTableDataCell>
                            <div>{i + 1}</div>
                          </CTableDataCell>
                          <CTableDataCell>
                            {row?.startDate ? moment(row?.startDate).format("DD-MM-YYYY") : ''}
                          </CTableDataCell>
                          <CTableDataCell>
                            {row?.endDate ? moment(row?.endDate).format("DD-MM-YYYY") : ''}
                          </CTableDataCell>
                          <CTableDataCell style={{ textAlign: 'center' }}>
                            <div className="clearfix">
                              {row?.designation}
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            {row?.organization}
                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton color="success" onClick={() => { editWork(row) }} >Edit</CButton>
                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton color="success" onClick={() => { deleteWork(row) }} >Delete</CButton>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>

                </CCardBody>
              </CCard>




              {/* <>


                  <CCol md={12}>
                    &nbsp;
                  </CCol>


                  <CCol md={12}>
                    Education Information: (Add your recent education only. If you do not want to add education information, click on delete button to remove this segment)
                  </CCol>
                  {val?.map((data, i) => {
                    return (

                      <div key={i}>

                        <CCol xs={12}>
                          <CCard className="mb-2">
                            <CCardBody>
                              <CRow className="mb-12">
                                <div className="col-sm-4">
                                  Education level <span className='red'>*</span>
                                  <CInputGroup className="has-validation">
                                    <CInputGroupText><CIcon icon={cilSchool} /></CInputGroupText>
                                    <CFormSelect feedbackValid="Looks good" feedbackInvalid="Required!"
                                      value={educationLevel[i]} onChange={(event) => { setEducationLevel(event.target.value, i); }} required>
                                      <option value="">Select</option>
                                      <option value="1">Secondary</option>
                                      <option value="2">Higher Secondary</option>
                                      <option value="3">Bachelor</option>
                                      <option value="4">Masters</option>
                                      <option value="5">PHD</option>
                                    </CFormSelect>
                                  </CInputGroup>

                                </div>
                                <div className="col-sm-4"> Start Date 
                                  <CInputGroup className="has-validation customDatePickerWidth">

                                        <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        className="form-control date-picker-custom"
                                        selected={startDateEdu.length?Date.parse(startDateEdu[i]):''} onChange={(date) => setStartDateEduAdd(date,i)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                         />
                                  </CInputGroup>
                                </div>
                                <div className="col-sm-4"> End Date 
                                  <CInputGroup className="has-validation customDatePickerWidth">

                                        <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        className="form-control date-picker-custom"
                                        selected={endDateEdu.length?Date.parse(endDateEdu[i]):''} onChange={(date) => setEndDateEduAdd(date,i)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                         />
                                  </CInputGroup>
                                </div>
                              </CRow>
                              <CRow className="mb-12">
                                <div className="col-sm-6">
                                  Name Of Degree <span className='red'>*</span>
                                  <CInputGroup className="mb-2">

                                    <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                                    <CFormInput placeholder="" aria-label="email" required
                                      feedbackValid="Looks good" feedbackInvalid="Required!"
                                      value={degreeNameEdu[i]} onChange={(event) => { setDegreeNameAdd(event.target.value, i); }} />

                                  </CInputGroup>
                                </div>
                                <div className="col-sm-6">
                                  Name Of Institution <span className='red'>*</span>
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                                    <CFormInput placeholder="" aria-label="optionalEmail" required
                                      feedbackValid="Looks good" feedbackInvalid="Required!"
                                      value={instituteNameEdu[i]} onChange={(event) => { setInstituteNameEduAdd(event.target.value, i); }} />
                                  </CInputGroup>
                                </div>
                              </CRow>
                            </CCardBody>
                          </CCard>
                        </CCol>

                        
                          <CCol xs={12} style={{ textAlign: 'right' }}>
                            <CIcon />
                            <CButton color="danger" size="sm" variant="outline" onClick={() => handleDelete(i)}>Delete</CButton>
                          </CCol>
                        

                      </div>
                    )
                  })}

                </> */}

              {/*  <CCol md={12}>
                  <CButton variant="outline" color="success" onClick={() => handleAdd()}>Add more</CButton>
                </CCol> */}


              {/* <>


                  <CCol md={12}>
                    &nbsp;
                  </CCol>


                  <CCol md={12}>
                    Work Experience: (If you have no work experience, click on delete button to remove this segment)
                  </CCol>
                  {wVal?.map((data, i) => {

                    return (

                      <div key={i}>

                        <CCol xs={12}>
                          <CCard className="mb-2">
                            <CCardBody>
                              <CRow className="mb-12">
                                <div className="col-sm-6"> Start Date
                                  <CInputGroup className="has-validation customDatePickerWidth">
                                    
                                        <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        // id="validationlanguageTestReDate"
                                        className="form-control date-picker-custom"
                                        selected={wStartDate.length?Date.parse(wStartDate[i]):''} onChange={(date) => setWStartDateOC(date,i)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                         />
                                  </CInputGroup>
                                </div>
                                <div className="col-sm-6"> End Date
                                  <CInputGroup className="has-validation customDatePickerWidth">
                                    
                                        <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        // id="validationlanguageTestReDate"
                                        className="form-control date-picker-custom"
                                        selected={wStartDate.length?Date.parse(wEndDate[i]):''} onChange={(date) => setWEndDateOc(date,i)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                         />
                                  </CInputGroup>
                                </div>
                              </CRow>
                              <CRow className="mb-12">
                                <div className="col-sm-6">
                                  Designation/Position<span className='red'>*</span>
                                  <CInputGroup className="mb-2">

                                    <CInputGroupText>  <CIcon icon={cilInfo} /></CInputGroupText>
                                    <CFormInput placeholder="" aria-label="email" required
                                      feedbackValid="Looks good" feedbackInvalid="Required!"
                                      value={wPosition[i]} onChange={(event) => { setWPositionOC(event.target.value, i); }} />

                                  </CInputGroup>
                                </div>
                                <div className="col-sm-6">
                                  Organization<span className='red'>*</span>
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText> <CIcon icon={cilInfo} /></CInputGroupText>
                                    <CFormInput placeholder="" aria-label="optionalEmail" required
                                      feedbackValid="Looks good" feedbackInvalid="Required!"
                                      value={wOrganization[i]} onChange={(event) => { setWOrganizationOC(event.target.value, i); }} />
                                  </CInputGroup>
                                </div>
                              </CRow>
                            </CCardBody>
                          </CCard>
                        </CCol>
                          <CCol xs={12} style={{ textAlign: 'right' }}>
                            <CIcon />
                            <CButton color="danger" size="sm" variant="outline" onClick={() => workHandleDelete(i)}>Delete</CButton>
                          </CCol>

                      </div>
                    )
                  })}

                </> */}

              {/* <CCol md={12}>
                  <CButton variant="outline" color="success" onClick={() => workHandleAdd()}>Add more</CButton>
                </CCol> */}


              {/* <CCol md={12}>
                  &nbsp;
                </CCol> */}

              {/* <CCol md={12}>
                  Upload your CV/Resume (Only pdf,docx and doc file) : <span className='red'>*</span> <br></br>
                  To upload CV/Resume click on the <b>Choose File</b> or drag and drop in gray segment
                </CCol>
 */}
              {/* <CCol md={12}>
                  <CInputGroup className="has-validation">
                    <CFormInput accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword" className="fileUpload" type="file" size="lg" id="formFileLg" onChange={onFileChangeHandler} required   />
                  </CInputGroup>
                  
                  

                </CCol> */}


              {/* <CCol xs={12}>
                  <CFormCheck
                    type="checkbox"
                    id="invalidCheck"
                    label="I hereby declare that the personal data I provide is done so on a voluntary basis and I understand that the data will be used by concerned team members for the purpose of discussing the immigration options for me & my family. I understand this information will not be shared with any third party without my consent"
                    required
                  />
                  <CFormFeedback invalid>You must agree before submitting.</CFormFeedback>
                </CCol> */}
              {/* <CCol xs={12}>
                {isSpenierOpen?<Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480','#036480','#036480','#036480','#036480','#036480']}></Vortex>:<CButton color="primary" type="submit"> Submit form </CButton>}
                  
                </CCol> */}
            </CContainer>

          </div>
        </div>
      </section>
    </>

  )
}

export default EditUser
