import React ,{ useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    let login = localStorage.getItem('login');
    let redirect = login?login:auth.redirect;
    
    const lua = localStorage.getItem('auth');
      if (typeof lua === 'string') {
        if(lua?.length>0){
            redirect = location.state?.from?.pathname || redirect;
        } 
      }

    // console.log(redirect);

    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.roles
                ? <Navigate to="/home/unauthorized" state={{ from: location }} replace />
                : <Navigate to={redirect?redirect:"/"} state={{ from: location }} replace />
    );
}

export default RequireAuth;