import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-pathway.png';
import Meta from './Meta';

const StadyCanadaPR = () => {
    const title = "The pathway to Permanent Residency (PR) in Canada involves several steps, and the process may vary based on individual circumstances. You can always book an appointment to consult with our immigration consultant for the most current information.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        The pathway to Permanent Residency (PR) in Canada involves several steps, and the process may vary based on individual circumstances. You can always book an appointment to consult with our immigration consultant for the most current information. This is a general outline for the study pathway to PR in Canada. Keep in mind that immigration policies may change, and You can always book an appointment to consult with our immigration consultant for the most up-to-date information:
                                    </p>

                                    <h3>1.	Choose a Designated Learning Institution (DLI):</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Select a Canadian institution that is recognized as a DLI, as only students enrolled in DLIs are eligible for a study permit in Canada.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2.  Admission to a Canadian Institution:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Apply and secure admission to a Canadian university, college, or other educational institution. Ensure that your chosen program meets the eligibility criteria for a study permit.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>3.	Apply for a Study Permit:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once accepted by a DLI, apply for a Canadian study permit. Provide all required documents, including proof of acceptance, proof of identity, and proof of financial support.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>4.	Arrival in Canada:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    After receiving the study permit, travel to Canada and complete the landing process at a port of entry.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>5.	Maintain Student Status:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Comply with the conditions of your study permit, including attending classes regularly and maintaining full-time enrollment. Be aware of your permit&apos;s expiry date and apply for extensions if needed.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>6.	Work While Studying:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Take advantage of work opportunities allowed under your study permit, such as part-time work during the academic session and full-time work during scheduled breaks.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>7.	Post-Graduation Work Permit (PGWP):</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    After completing your program, you may be eligible for a PGWP, allowing you to work in Canada for a certain duration. The length of the PGWP depends on the length of your program of study.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>8.	Gain Canadian Work Experience:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Work in Canada under the PGWP to gain valuable Canadian work experience. This experience can enhance your eligibility for permanent residency through Express Entry or Provincial Nominee Programs (PNPs).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>9.	Express Entry or Provincial Nominee Program (PNP):</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Depending on your situation and preferences, you can apply for PR through Express Entry or a PNP. Express Entry is a points-based system that assesses factors such as age, education, work experience, language proficiency, and adaptability. PNPs allow provinces and territories to nominate individuals for PR based on their specific labor market needs.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>10.	Permanent Residency Application:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Submit a PR application through Express Entry or the PNP stream you qualify for. The application will be assessed based on various factors, and if approved, you will receive PR status.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>11.	Settle in Canada:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Once granted PR status, fulfill any remaining requirements and start building your life in Canada. PR status provides you with most of the rights and benefits of Canadian citizens.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <p>
                                        Remember to regularly check for updates on immigration policies and requirements, as they may change. Consult official Canadian government websites or seek advice from our authorized immigration consultants for the latest information tailored to your situation.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default StadyCanadaPR