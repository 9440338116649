import React, {
  useState,
  useEffect,
  //  useRef
} from "react";

import {CButton,CAvatar,CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow,CCard, CCardBody, CCardFooter, CCol, CProgress, CRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import { cilPeople } from '@coreui/icons'
// import {cibCcAmex,cibCcApplePay,cibCcMastercard,cibCcPaypal,cibCcStripe,cibCcVisa,cibGoogle,cibFacebook,cibLinkedin,cifBr,cifEs,cifFr,cifIn,cifPl,cifUs,cibTwitter,cilCloudDownload,cilPeople,cilUser,cilUserFemale,} from '@coreui/icons'
// import WidgetsBrand from '../widgets/WidgetsBrand'
import AWidgetsDropdown from '../../src/views/widgets/AWidgetsDropdown'
import useAxiosPrivate from "../../src/hooks/useAxiosPrivate"
import { useNavigate, useLocation } from 'react-router-dom';
// import AuthContext from "../context/AuthProvider";
// import { useContext } from "react";

import Goals from '../components/Goals';
import Scheduling from "src/components/Scheduling";
import Meeting from "src/components/Meeting";
import UpcomingMeeting from "src/components/UpcomingMeeting";
import avatar8 from '../assets/images/avatars/avater.png';
import mygrationhubText from '../assets/images/logo/mygrationhub-text.jpg';
import '../assets/css/custom.css';


const AgentDashboard = () => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  // const [backPage,setBackPage] = useState("");
  const [agentId, setAgenId] = useState("");
  const [agentName, setAgenName] = useState("");

  const [dashboardData, setDashboardData] = useState([]);
  const [users, setUsers] = useState([]);
  const [clientProgressList, setClientProgressList] = useState([]);

  const [meetingCounter, setMeetingCounter] = useState(0);
  const [upcommingMeetingCounter, setUpcommingMeetingCounter] = useState(0);
  const [pictureContent, setPictureContent] = useState(avatar8);

  // const { setAuth } = useContext(AuthContext);


  /* const logout = async () => {

    let loginUrl = "/"
    const lua = localStorage.getItem('auth');
    if (typeof lua === 'string') {
      const luAuth = JSON.parse(localStorage.getItem('auth'));

      if (luAuth.roles[0] === "ROLE_ADMIN") {
        loginUrl = "/login";
      } else if (luAuth.roles[0] === "ROLE_AGENT") {
        loginUrl = "/agent-login";
      } else if (luAuth.roles[0] === "ROLE_USER") {
        loginUrl = "/client-login";
      } else {
      }

      setAuth({ redirect: loginUrl });;
      localStorage.clear();
      navigate(loginUrl, { replace: true });

    } else {
      alert(typeof lua);
      setAuth({});
      localStorage.clear();
      navigate(loginUrl, { replace: true });
    }
  } */



  useEffect(() => {
    // let isMounted = true;
    const controller = new AbortController();

    const lsAgenId = localStorage.getItem('agentboard-user-id');
    const lsAgenName = localStorage.getItem('agentboard-user-name');
    let id = location?.state?.id ? location.state.id : lsAgenId ? lsAgenId : 0;
    let name = location?.state?.name ? location.state.name : lsAgenName ? lsAgenName : "";
    setAgenId(id);
    setAgenName(name);

    const getAgetDashboard = async () => {
      try {

        let path = "";
        if (id === 0) {
          path = `/getAgentDashboard`;
        } else {
          path = `/getAgentDashboard/${id}`;
        }

        const response = await axiosPrivate.get(path, { signal: controller.signal });
        setDashboardData(response.data);
        setClientProgressList(response?.data?.clientProgressList);
      } catch (err) {
        //logout();
      }
    }

    const getUsers = async () => {

      const formData = {
        id: id
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/getUserProfileCompletionStatusList", JSON.stringify(formData), { headers: headers }).then((response) => {
        if (response.status === 200) {

          setUsers(response.data);

        }
      }, fail => {

      });
    }

    const getProfilePic = async () => {
      try {
        let path = "/downloadProfilePicture";
        axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            if (response?.data !== null && response?.data !=="" && response?.data!=='undefine') {
              setPictureContent(response?.data?.code);
            }
          }
  
        }, fail => {
  
        });;
  
      } catch (err) {
        console.log(err);
      }
    }


    getProfilePic();
    getAgetDashboard();
    getUsers();
    return () => {
      // isMounted = false;
      controller.abort();
    }
  }, []);


  function getColor(val) {
    if (val <= 50) {
      return "danger";
    } else if (val <= 60 && val > 50) {
      return "warning";
    } else if (val <= 70 && val > 60) {
      return "primary";
    } else if (val <= 80 && val > 70) {
      return "info";
    } else if (val <= 90 && val > 80) {
      return "success";
    } else if (val <= 100 && val > 90) {
      return "success";
    } else {
      return "";
    }

  }

  function viewClientDashboard(id, name) {
    /* 
    localStorage.setItem('clientboard-user-id', id);
    localStorage.setItem('clientboard-user-name', id);
    localStorage.setItem('back-page', '/home/agent-dashboard');
    navigate('/home/client-dashboard', { state: { id: id, name: name, backPage: '/home/agent-dashboard' }, replace: true });
  */
  }

  function onclickToAnother(page){
    navigate(page, { replace: true });
  }


  const handleStateChange = (value) => {
    setMeetingCounter(value);
  };

  const upMcStateChange = (value) => {
    setUpcommingMeetingCounter(value);
  };




  return (
    <>

      <AWidgetsDropdown id={agentId} agentName={agentName} clients={dashboardData?.aclient} partners={dashboardData?.partners} admins={dashboardData?.admins} />

      <CRow className="padding2px pl15 pr15">
        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <div className="mb-4 text-center marginBottom15px" >Profile</div>
              <div className="mb-4 text-center marginBottom15px">
                <span className="dppc" >
                  <img src={pictureContent} alt="" className="dprofilepic" ></img>
                </span>
              </div>
              <div className="mb-4 text-center marginBottom15px" >100% Complete</div>
              <div className="mb-4 text-center marginBottom15px" >
                <CButton color="dark" size="sm" onClick={() => onclickToAnother("/home/edit-user/0")} variant="outline" >View & Edit</CButton> 
              </div>

            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <Meeting onStageChange={handleStateChange} value={meetingCounter} upMc={upcommingMeetingCounter}></Meeting>

            </CCardBody>
          </CCard>
        </CCol>


        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <Scheduling></Scheduling>

            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight">
            <CCardBody>

              <div className="mb-4 text-center" >FUNDED BY</div>
              <div className="mb-4 text-center">
                <span className="" >
                  <img src={mygrationhubText} alt="" className="mygrationhubtext" ></img>
                </span>
              </div>
              <div className="mb-4 text-center" >&nbsp;</div>
              <div className="mb-4 text-center" >
                {/* <CButton color="" size="sm" variant="outline" >&nbsp;</CButton> */}
                &nbsp;
              </div>
              

            </CCardBody>
          </CCard>
        </CCol>

      </CRow>


      <CRow className="padding2px">
        <CCol md={6}>
          <CCard className="mb-4 cutomCardHight">
            {/* <CCardHeader>Upcoming Meetings</CCardHeader> */}
            <CCardBody>

                <UpcomingMeeting upcmOnStageChange={upMcStateChange} meetingCounter={meetingCounter}></UpcomingMeeting>

            </CCardBody>
          </CCard>
        </CCol>
      

        <CCol md={6}>
          <CCard className="mb-4 cutomCardHight">
            {/* <CCardHeader>&nbsp;</CCardHeader> */}
            <CCardBody>
              <Goals></Goals>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>


      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                MyGration hub
              </h4>
              <div className="small text-medium-emphasis">My clients status</div>
            </CCol>

          </CRow>
        </CCardBody>


        <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {clientProgressList?.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter>
      </CCard>


      <br />
      <br />

      <CTable align="middle" className="mb-0 border" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell className="text-center" width="10%">
              <CIcon icon={cilPeople} />
            </CTableHeaderCell>
            <CTableHeaderCell width="20%">My Clients</CTableHeaderCell>
            <CTableHeaderCell className="text-center" width="10%">Country</CTableHeaderCell>
            <CTableHeaderCell width="20%">Status</CTableHeaderCell>
            <CTableHeaderCell className="text-center" width="40%">Profile Completion Status</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {users?.map((user, index) => (
            <CTableRow v-for="item in tableItems" key={index} onClick={() => { viewClientDashboard(user?.id, user?.name) }} >
              <CTableDataCell className="text-center">

                {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                  <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status="success" />
                  :
                  <CAvatar size="md" classnames="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status="success" />
                }

              </CTableDataCell>
              <CTableDataCell>
                <div>{user?.name}</div>
                <div className="small text-medium-emphasis">
                  Registered:{' '}|{user?.dateCreated}
                </div>
              </CTableDataCell>
              <CTableDataCell className="text-center">
                {user?.country}
              </CTableDataCell>
              <CTableDataCell>
                {user?.status}
              </CTableDataCell>
              <CTableDataCell>
                <div className="clearfix">
                  <div className="float-start">
                    <strong>{user?.total} %</strong>
                  </div>
                  {/* <div className="float-end">
                      <small className="text-medium-emphasis">{user?.dateCreated}</small>
                    </div> */}
                </div>
                <CProgress thin color={getColor(user?.total)} value={user?.total} />
              </CTableDataCell>

            </CTableRow>
          ))}
        </CTableBody>
      </CTable>




    </>
  )
}

export default AgentDashboard