import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, Link, useParams } from "react-router-dom";
import { CForm, CCol, CFormLabel, CInputGroup, CInputGroupText, CButton, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CAvatar, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CLink ,CRow} from '@coreui/react'
// import { CForm, CCol, CFormLabel, CInputGroup, CInputGroupText, CFormSelect, CAvatar, CButton, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader, CFormInput, CRow, CLink } from '@coreui/react'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import '../assets/css/userDetails.css'
import CIcon from '@coreui/icons-react'
import { cilUser, cilPeople } from '@coreui/icons'
// import { cilPeople } from '@coreui/icons'
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody } from 'mdb-react-ui-kit';
import avater from '../assets/images/avatars/avater.png';

export default function RefeererDetails() {

  const { key } = useParams();

  const [userDetails, setUsersDetails] = useState();
  const [referreredUserList, setReferreredUserList] = useState();
  const axiosPrivate = useAxiosPrivate();
  // const location = useLocation();
  const [isShowButton, setIsShowButton] = React.useState(true)
  // const [isShowMessage, setIsShowMessage] = React.useState(false)
  const navigate = useNavigate();
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);

  const [isModalSpenierOpen, setIsModalSpenierOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [userName, setUserName] = useState("");
  const [clientId, setClientId] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [pictureContent, setPictureContent] = useState(avater);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    // const detailsUserId = localStorage.getItem('details-user-id');
    const getUserDetails = async () => {
      try {
        // const path = `/getUserInfo/${location?.state?.id ? location.state.id : detailsUserId}`;
        const path = `/getUserInfo/${key}`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });
        isMounted && setUsersDetails(response.data);
        setClientId(response?.data?.id);
        setClientName(response?.data?.fullName);
        setUserName(response?.data?.officeEmail);
        setIsEnabled(response?.data?.isEnabled);
        if (response?.data.picture !== null && response?.data?.picture !== "" && response?.data?.picture !== 'undefine') {
          setPictureContent(response?.data?.picture);
        }

      } catch (err) {
        console.log(err);
        // confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
      }
    }

    const getReferredUserList = async () => {
      try {
        // const path = `/getUserInfo/${location?.state?.id ? location.state.id : detailsUserId}`;
        // const path = `/getReferredUserList/${key}`;
        const formData = {
          id: key,
          fileName: "",
        }
        const headers = {
          'Content-Type': 'application/json'
        }
        await axiosPrivate.post("/getReferredUserList", JSON.stringify(formData), { headers: headers }).then((response) => {
          if (response.status === 200) {
            setReferreredUserList(response.data)
          }

        }, fail => {
          console.error(fail);
        });

      } catch (err) {

      }
    }

    getUserDetails();
    getReferredUserList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [key]);


  async function getResume(event) {
    event.preventDefault();
    downloadFile(userDetails?.id, userDetails.resume);
  }

  async function downloadFile(key, fileName) {
    setIsResumeSpinOpen(true);

    const formData = {
      key: key,
      fileName: fileName,
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    await axiosPrivate.post("/downloadFile", JSON.stringify(formData), { headers: headers }).then((response) => {
      if (response.status === 200) {
        setIsResumeSpinOpen(false);
        const linkSource = response?.data?.code;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
        setIsResumeSpinOpen(false);
      }
    }, fail => { setIsResumeSpinOpen(false); });

  }

  async function approved(event) {
    event.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        id: userDetails.id,
        userName: userDetails.userName,
        status: 4

      }
      console.log(formData);
      setIsSpenierOpen(true);
      await axiosPrivate.post("/updateStatus", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Approved successfully", buttons: [{ label: 'OK' }] });
          setIsShowButton(false);
          // setIsShowMessage(true);
        }
        else {
          alert("Please try again after sometimes later");
        }
        setIsSpenierOpen(false);

      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
    }

  }

  async function rejected(event) {
    event.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json'
      }

      const formData = {
        id: userDetails.id,
        userName: userDetails.userName,
        status: 2

      }

      console.log(formData);
      setIsSpenierOpen(true);
      await axiosPrivate.post("/updateStatus", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          alert("Rejected successfully");
          navigate('/home/pending-users');
        }
        else {
          alert("Please try again after sometimes later");
        }
        setIsSpenierOpen(false);
      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
      });
    }

    catch (err) {
      alert(err);
    }

  }

  const [isResumeSpinOpen, setIsResumeSpinOpen] = React.useState(false);


  function viewHandleClick(value) {
    localStorage.setItem('edit-user-id', value);
    localStorage.setItem('back-page', '/home/user-details');
    navigate('/home/edit-user/' + value, { state: { id: value, backPage: '/home/user-details' }, replace: true });
  }

  function viewHandleAgentView(value) {
    localStorage.setItem('details-user-id', value);
    navigate('/home/client-details', { state: { id: value }, replace: true });
  }


  //modal

  const [password, setPassword] = useState("abcd1234$");
  const [visible, setVisible] = useState(false)
  const [messageColor, setMessageColor] = useState("green");
  const messageRef = useRef();
  const [message, setMessage] = useState("");

  function openModal(name, id, selectedId) {
    setMessage('');
    setValidated(false);
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }



  const [validated, setValidated] = useState(false);
  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      const formData = {
        id: clientId,
        userName: userName,
        password: password
      }
      apicall(formData, "enableClient", "Refferal enabled successfully");
    }
    setValidated(true);
    event.preventDefault();
  }

  function disableClientFromSyntem() {
    const formData = {
      id: clientId,
      userName: userName,
      password: password
    }
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to disable " + userDetails?.fullName + " from mygrationhub ?",
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "disableClient", "Referral disabled successfully")
      },
      {
        label: 'No',
      }]
    });
  }

  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));
    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (apiName === 'enableClient') {
          setIsModalSpenierOpen(false);
          setMessageColor("green");
          setMessage(message);
          messageRef.current.focus();
          setIsEnabled(true);
        } else if (apiName === 'disableClient') {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
          setIsEnabled(false);
        }
      }
      else {
        if (apiName === 'enableClient') {
          setIsModalSpenierOpen(false);
          setMessageColor("red");
          setMessage('Refresh this page and try again later');
          messageRef.current.focus();
        } else if (apiName === 'disableClient') {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again later", buttons: [{ label: 'OK', }] });
        }
      }

    }, fail => {
      if (apiName === 'enableClient') {
        setIsModalSpenierOpen(false);
        setMessageColor("red");
        setMessage('Refresh this page and try again later');
        messageRef.current.focus();
      } else if (apiName === 'disableClient') {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again later", buttons: [{ label: 'OK', }] });
      }
    });
  }


  function downloadPicture() {
    const downloadLink = document.createElement("a");
    let ex = pictureContent.split(";")[0].split("/")[1];
    downloadLink.href = pictureContent;
    downloadLink.download = userDetails?.fullName + "." + ex;
    downloadLink.click();

  }


  function deleteUserInfo(id, userName, fullName, index) {
    console.log(index);
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to delete user - " + fullName,
      buttons: [{
        label: 'Yes',
        onClick: () => deleteDocument(id, userName, index)
      },
      {
        label: 'No',
      }]
    });
  }

  async function deleteDocument(id, userName, index) {
    try {
      const formData = {
        id: id,
        userName: userName
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/deleteUserInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "User deleted successfully", buttons: [{ label: 'OK' }] });
          if (referreredUserList.length > 0) {
            setReferreredUserList(referreredUserList.filter((v, i) => v.id !== id));
          } else {
            setReferreredUserList(referreredUserList.filter((v, i) => i !== index));
          }
        } else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });
    }
    catch (err) {
    }

  }

  function viewHandleEditClick(value) {
    localStorage.setItem('edit-user-id', value);
    localStorage.setItem('back-page', '/home/approved-users');
    navigate('/home/edit-user/' + value, { state: { id: value, backPage: '/home/approved-users' }, replace: true });
  }

  const [visibleA, setVisibleA] = useState(false);
  const [userActivities, setUserActivities] = useState([]);
  const [validatedA, setValidatedA] = useState(false);
  const [isActivityEdited, setIsActivityEdited] = useState(false);
  const [activityActionText, setActivityActionText] = useState("Save");
  const [activityId, setActivityId] = useState("");
  const [activity, setActivity] = useState("");

  function viewHandleClickA(client) {
    openModalA(client);
  }

  function openModalA(client) {
    console.log(client);
    setUserActivities();
    setClientId(client?.id);
    setClientName(client?.fullName);
    setVisibleA(true);
    setValidatedA(false);
    loadActivities(client?.id);
  }

  function viewHandleEditActivities(id, note, date) {
    setActivityId(id);
    setActivity(note);
    // setActivityDate(date);
    setIsActivityEdited(true);
    setActivityActionText("Update");
  }

  function cleanActivity() {
    setActivityId("");
    setActivity("");
    // setActivityDate("");
    setIsActivityEdited(false);
    setActivityActionText("Save");
  }




  const loadActivities = async (id) => {
    const controller = new AbortController();
    try {
      const path = `/getClientActivities/${id}`;
      const response = await axiosPrivate.get(path, {
        signal: controller.signal
      });
      setUserActivities(response.data);
    } catch (err) {
      confirmAlert({ title: 'MyGration Hub', message: "Relogin and try again", buttons: [{ label: 'OK', }] });
    }
  }


  const handleFormSubmitA = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      //console.log("no");
      event.preventDefault()
      event.stopPropagation()
    } else {
      //console.log("yes");
      saveActivities(true);
    }

    setValidatedA(false);
    event.preventDefault();


  }

  async function saveActivities() {
    try {
      const formData = {
        id: activityId,
        userInfoId: 0,
        clientInfoId: clientId,
        note: activity
        //,date: activityDate
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      setIsSpenierOpen(true);

      console.log(JSON.stringify(formData));

      let message = isActivityEdited ? "Note updated successfully" : "Note saved successfully";

      await axiosPrivate.post("/saveClientActivities", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          setIsSpenierOpen(false);
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK' }] });
          cleanActivity();
          loadActivities(clientId);
        }
        else {
          setIsSpenierOpen(false);
          confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
        }

      }, fail => {
        console.error(fail);
        setIsSpenierOpen(false);
        confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
      });
    }

    catch (err) {
      console.log(err);
      setIsSpenierOpen(false);
      confirmAlert({ title: 'MyGration Hub', message: "Failed, Try again later", buttons: [{ label: 'OK' }] });
    }

  }


  function closeModalA() {
    setClientId("");
    setClientName("");
    setVisibleA(false);
    setUserActivities();
    cleanActivity();
  }

  function deleteActivitiesConfirm(id, note, index) {
    console.log(index);
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to delete note - " + note,
      buttons: [{
        label: 'Yes',
        onClick: () => deleteActivities(id, note, index)
      },
      {
        label: 'No',
      }]
    });
  }

  async function deleteActivities(id, note, index) {
    try {
      const formData = {
        id: id,
        userName: note
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/deleteClientActivities", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {

          confirmAlert({ title: 'MyGration Hub', message: "Note deleted successfully", buttons: [{ label: 'OK' }] });
          if (userActivities?.length > 0) {
            setUserActivities(userActivities.filter((v, i) => v.id !== id));
          } else {
            setUserActivities(userActivities.filter((v, i) => i !== index));
          }
        } else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });
    }
    catch (err) {
    }

  }


  return (
    <section style={{ backgroundColor: '#fff' }}>

      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel" >

        <CModalHeader onClose={() => closeModal()}>
          <CModalTitle id="LiveDemoExampleLabel">Referral Enable To Mygrationhub</CModalTitle>
        </CModalHeader>
        <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" validated={validated} onSubmit={handleFormSubmit} >
          <CModalBody>
            <CCol md={12}>
              <CFormLabel htmlFor="validationClientName">
                <p ref={messageRef} className={messageColor + " font-size-20px"} aria-live="assertive">&nbsp; {message} &nbsp;</p>
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CInputGroupText>Name</CInputGroupText>
                <CFormInput type="text" aria-describedby="validationClientName" id="validationClientName" value={clientName} disabled feedbackInvalid="Required!" required></CFormInput>
              </CInputGroup>
            </CCol>

            <CCol md={12}>
              <CFormLabel htmlFor="validationClientName">
              </CFormLabel>
              <CInputGroup className="has-validation">
                <CInputGroupText>Username</CInputGroupText>
                <CFormInput type="text" aria-describedby="validationClientName" id="validationClientName" value={userName} disabled feedbackInvalid="Required!" required></CFormInput>
              </CInputGroup>
            </CCol>

            <CCol md={12}>
              <CFormLabel htmlFor="validationCustomAddressPostal">Password (Default abcd1234$)<span className='red'>*</span></CFormLabel>
              <CInputGroup className="has-validation">
                <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                <CFormInput type="password" id="validationCustomAddressPostal" feedbackValid="Looks good" feedbackInvalid="Required!" value={password} onChange={(event) => { setPassword(event.target.value); }} required={true} />
              </CInputGroup>
            </CCol>


          </CModalBody>
          <CModalFooter>
            {isModalSpenierOpen ?
              <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
              :
              <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Enable </CButton>
            }
          </CModalFooter>
        </CForm>
      </CModal>


      <CModal
        size="xl"
        backdrop="static"
        visible={visibleA}
        onClose={() => closeModalA()}
        aria-labelledby="StaticBackdropExampleLabelActivities" >
        <CModalHeader onClose={() => closeModalA()}>
          <CModalTitle id="LiveDemoExampleLabelActivities">Activities on {clientName} </CModalTitle>
        </CModalHeader>
        {/* <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleFormSubmit} ></CForm> */}
        <CForm style={{ padding: '20px' }} className="row g-3 needs-validation update-main-form" validated={validatedA} onSubmit={handleFormSubmitA} >
          <CModalBody>





            {/* <ClientActivities id={1}></ClientActivities> */}

            <CRow className="mb-12">
              <div className="col-sm-9"> Note <span className='red'>*</span>
                <CInputGroup className="has-validation">
                  <CFormInput aria-describedby="validationCustomGoal" id="validationCustomGoal" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={activity} onChange={(event) => { setActivity(event.target.value); }} maxLength={255} required />
                </CInputGroup>

              </div>
              {/* <div className="col-sm-3">Date <span className='red'>*</span>
                                <CInputGroup className="has-validation">
                                    <DatePicker
                                        //showMonthDropdown
                                        //showYearDropdown
                                        //yearDropdownItemNumber={100}
                                        //scrollableYearDropdown
                                        // id="validationlanguageTestReDate"
                                        aria-describedby="validationCustomActivityDate"
                                        // minDate={new Date()}
                                        className="form-control date-picker-custom"
                                        selected={Date.parse(activityDate)} onChange={(date) => setActivityDate(date)} showIcon icon="fa fa-calendar" dateFormat="yyyy-MM-dd" placeholderText="YYYY-MM-DD"
                                        required
                                    />
                                </CInputGroup>
                            </div> */}
              <div className="col-sm-3"> &nbsp;
                <CInputGroup className="has-validation">
                  {isSpenierOpen ?
                    <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                    :
                    <>
                      <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> {activityActionText} </CButton> &nbsp;
                      {isActivityEdited ? <CButton className="btn btn-danger" onClick={() => { cleanActivity() }} style={{ borderRadius: "5px" }} color="danger" > Cancel </CButton> : ''}
                    </>
                  }
                </CInputGroup>

              </div>
            </CRow>


          </CModalBody>



          <CModalFooter style={{ display: "unset" }}>

            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell scope="col" width="5%"> SL</CTableHeaderCell>
                  <CTableHeaderCell scope="col" width="55%">Note</CTableHeaderCell>
                  <CTableHeaderCell scope="col" width="10%">Date</CTableHeaderCell>
                  {/* <CTableHeaderCell scope="col" width="10%" className="text-center">Created Date</CTableHeaderCell> */}
                  <CTableHeaderCell scope="col" width="15%" className="text-center">Created By</CTableHeaderCell>
                  <CTableHeaderCell scope="col" width="15%" className="text-center" >Actions</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {userActivities?.length > 0 && userActivities?.map((row, i) => (
                  <CTableRow v-for="item in tableItems" key={i}>
                    <CTableDataCell>{i + 1} </CTableDataCell>
                    <CTableDataCell>{row?.note}</CTableDataCell>
                    {/* <CTableDataCell>{row?.date}</CTableDataCell> */}
                    <CTableDataCell className="text-center">{row?.dateCreated}</CTableDataCell>
                    <CTableDataCell className="text-center">{row?.userName}</CTableDataCell>
                    <CTableDataCell className="text-center">
                      {row?.isOperational === 1 ?
                        <>

                          <CButton color="success" onClick={() => { viewHandleEditActivities(row?.id, row?.note, row?.date) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                          <CButton color="danger" onClick={() => { deleteActivitiesConfirm(row?.id, row?.note, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                        </>
                        :
                        <>N/A</>
                      }


                    </CTableDataCell>

                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>

          </CModalFooter>
        </CForm>
      </CModal>








      <h2 className="padding10">Referral Details  </h2>
      <MDBContainer className="py-5">
        {/* <MDBRow>
          <MDBCol lg="12" >
            {(userDetails?.applyStatus === 4 || isShowMessage) ? <p> www.mygrationhub.com/#/update-info/{userDetails?.id}/{userDetails?.officeEmail}/{userDetails?.firstName}/{userDetails?.lastName}/{userDetails?.mobile}</p> : ""}
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="12" >
            {(userDetails?.refUserInfo !== null) ? <p>Referred by: {userDetails?.refUserInfo?.fullName} ({userDetails?.refUserInfo?.userId})</p> : ""}
          </MDBCol>
        </MDBRow> */}

        <MDBRow>
          <MDBCol lg="4" >
            <MDBCard className="mb-4" style={{ height: '345px' }}>
              <MDBCardBody className="text-center">
                <img src={pictureContent} alt="" onClick={downloadPicture} className="detailsPicture" ></img>

                <p className="text-muted mb-1">{userDetails?.fullName}</p>
                <p className="text-muted mb-2">
                  {userDetails?.userResidence?.map((row) => row?.country?.name)}
                </p>
                <p className="text-muted mb-4">
                  {userDetails?.userId}
                </p>
                <div className="d-flex justify-content-center mb-2">
                  {userDetails?.applyStatus < 4 && isShowButton ? (

                    isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> :
                      <>
                        <CButton color="success" onClick={approved}>Accept</CButton> &nbsp; &nbsp; &nbsp;
                        <CButton color="danger" onClick={rejected}>Reject</CButton>
                      </>

                  ) : isEnabled ? <CButton color="danger" onClick={disableClientFromSyntem}>Disable From System</CButton> : <CButton color="success" onClick={openModal}>Enable To System</CButton>}


                  <br />
                </div>
              </MDBCardBody>
              <br />
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>First Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.firstName}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Last Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.lastName}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.officeEmail}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>WhatsApp</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.whatsApp}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Gender</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.sex}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Date Of Birth</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.dob ? moment(userDetails?.dob).format("DD-MM-YYYY") : ''}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="2">
                    <MDBCardText>Country</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">: {userDetails?.userResidence[0].country?.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      :{userDetails?.address[0]?.address}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>City</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      :{userDetails?.address[0]?.city}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="2">
                    <MDBCardText>State</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      :{userDetails?.address[0]?.state}
                    </MDBCardText>
                  </MDBCol>

                  <MDBCol sm="2">
                    <MDBCardText>Postal Code</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="4">
                    <MDBCardText className="text-muted">
                      :{userDetails?.address[0]?.postal}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>



        <h2 className="padding10">Referred Client List </h2>

        {referreredUserList?.length
          ? (
            <div>

              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell className="text-center">
                      <CIcon icon={cilPeople} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Country</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Mobile/Email</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Status/Assigned Partner</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">Actions</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {referreredUserList?.map((user, i) => (
                    <CTableRow v-for="item in tableItems" key={i}>
                      <CTableDataCell className="text-center">

                        {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                          <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                          :
                          <CAvatar size="md" className="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                        }




                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{user?.fullName}</div>
                        <div className="small text-medium-emphasis">
                          <span>{user?.applyStatus === 3 ? 'New Applied' : 'Approved'}</span> | Apply Date:{user?.dateCreated}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{user?.country}</div>
                        <div className="small text-medium-emphasis">
                          ID:{user?.userId}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          {/* {user?.mobile} */}
                          <div>{user?.mobile}</div>
                          <div>{user?.officeEmail}</div>

                        </div>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{user?.status}</div>

                        <div>{user?.agentName}</div>
                      </CTableDataCell>
                      <CTableDataCell width={220}>
                        <CButton type="submit" color="info" onClick={() => { viewHandleClickA(user) }} ><i className="fa fa-comment"></i></CButton> &nbsp;
                        <CButton type="submit" color="info"   > <CLink target="_blank" href={'/#/home/user-details/' + user?.id} style={{ color: "black" }} > <i className="fa fa-eye"></i> </CLink> </CButton> &nbsp;
                        <CButton type="submit" color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                        <CButton type="submit" color="danger" onClick={() => { deleteUserInfo(user?.id, user?.userName, user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>


            </div>
          ) : <p>No referred clients</p>
        }
      </MDBContainer>
    </section>
  );
}