import React from 'react'
import { Helmet } from 'react-helmet'
import '../../src/assets/css/bootstrap.min.css'
import '../../src/assets/css/fontawesome.css'
import '../../src/assets/css/templatemo-edu-meeting.css'
import '../../src/assets/css/lightbox.css'
import '../../src/assets/css/default_theme.css'
import '../../src/assets/fonts/flexslider-icon.svg'
import { Outlet } from 'react-router-dom'
import SubHeader from './SubHeader'
import MenuHeader from '../layout/MenuHeader'
import HomeFooter from '../layout/HomeFooter'
import logo from '../assets/images/logo/logo.png';

const Home = () => {

const url = "https://mygrationhub.com";
const title = "MyGrationHub";
const keywords = "Migration hub, migrationhub,MygrationHub,MyGration Hub, My Gration Hub";
const description = "Navigate the world with ease - Trusted consultancy for immigration, education, and travel";
const image = url + logo;

  return (
    <div>
      <Helmet>
            
            <title>{title}</title>
            <meta name="title" content={title}></meta>
            <meta name="keywords" content={keywords}></meta>
            <meta name="description" content={description}></meta>
            
            <meta property="og:title" content={title}></meta>
            <meta property="og:description" content={description}></meta>
            <meta property="og:image" content={image}></meta>
            
            <meta name="twitter:title" content={title}></meta>
            <meta name="twitter:description" content={description}></meta>
            <meta name="twitter:image" content={image}></meta>

      </Helmet>

      <div>
        <div className="header-area" style={{ position: 'sticky', top: 0 }}>
          <SubHeader></SubHeader>
          <MenuHeader></MenuHeader>
        </div>


        <div>
          <Outlet></Outlet>
        </div>


        <div>
          <HomeFooter></HomeFooter>
        </div>

      </div>
    </div>

  )
}

export default Home