import React, {
  useState,
  useEffect,
  //  useRef
} from "react";

import {CAvatar,CButton,CCardHeader,CTable,CTableBody,CTableDataCell,CTableHead,CTableHeaderCell,CTableRow,CCard, CCardBody, CCardFooter, CCol, CProgress, CRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {cilPeople} from '@coreui/icons'
// import WidgetsBrand from '../widgets/WidgetsBrand'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useNavigate, useLocation } from "react-router-dom";
import {
  // CChartBar,
  // CChartDoughnut,
  // CChartLine,
  CChartPie,
  // CChartPolarArea,
  // CChartRadar,
} from '@coreui/react-chartjs'

import Goals from '../../components/Goals';
import Scheduling from "src/components/Scheduling";
import Meeting from "src/components/Meeting";
import UpcomingMeeting from "src/components/UpcomingMeeting";
import avatar8 from './../../assets/images/avatars/avater.png'
import mygrationhubText from './../../assets/images/logo/mygrationhub-text.jpg'
import '../../assets/css/custom.css';


const Dashboard = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const location = useLocation();
  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();
  const [dashboardData, setDashboardData] = useState([]);
  const [clientProgressList, setClientProgressList] = useState([]);
  const [titles, setTitles] = useState([]);
  const [values, setValues] = useState([]);
  const [partners, setPartners] = useState([]);
  const [meetingCounter, setMeetingCounter] = useState(0);
  const [upcommingMeetingCounter, setUpcommingMeetingCounter] = useState(0);
  const [pictureContent, setPictureContent] = useState(avatar8);

  /* const progressExample = [
    { title: 'Assessment Ongoing', value: '29.703 Users', percent: 40, color: 'success' },
    { title: 'Info Submitted', value: '24.093 Users', percent: 20, color: 'info' },
    { title: 'Discuss Later', value: '22.123 Users', percent: 80, color: 'danger' },
    { title: 'Case Profile Reviewed', value: '78.706 Views', percent: 60, color: 'warning' },
    { title: 'Success', value: 'Average Rate', percent: 40.15, color: 'primary' },
  ] */

  useEffect(() => {
    // let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get('/getAdminDashboard', {
          signal: controller.signal
        });
        console.log(response.data);
        setDashboardData(response.data);
        //console.log(dashboardData);
        setClientProgressList(response?.data?.clientProgressList);
        let title = [];
        let value = [];


        response?.data?.clientProgressList.forEach((v, i) => {
          title[i] = v.title;
          value[i] = v.value;
        });

        setTitles(title);
        setValues(value);

      } catch (err) {
        //console.error(err);
        console.log(err);
        //navigate('/login', { state: { from: location }, replace: true });
      }
    }


    const getPartners = async () => {
      try {
          const response = await axiosPrivate.get('/getAllAgentInfoList', {
              signal: controller.signal
          });
          setPartners(response.data);
      } catch (err) {
          navigate('/login', { state: { from: location }, replace: true });
      }
  }

  const getProfilePic = async () => {
    try {
      let path = "/downloadProfilePicture";
      axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
        if (response.status === 200) {
          if (response?.data !== null && response?.data !=="" && response?.data!=='undefine') {
            setPictureContent(response?.data?.code);
          }
        }

      }, fail => {

      });;

    } catch (err) {
      console.log(err);
    }
  }


    getUsers();
    getPartners();
    getProfilePic();

    return () => {
      // isMounted = false;
      controller.abort();
    }
  }, []);


  function viewPartnerDashboard(id,name) {
    /* 
    localStorage.setItem('agentboard-user-id', id);
    localStorage.setItem('agentboard-user-name', name);
    localStorage.setItem('back-page', '/home/dashboard');
    navigate('/home/agent-dashboard', { state: { id: id,name:name, backPage: '/home/dashboard' }, replace: true }); 
    */

  }

  function onclickToAnother(page){
    navigate(page, { replace: true });
  }


  const handleStateChange = (value) => {
    setMeetingCounter(value);
  };

  const upMcStateChange = (value) => {
    setUpcommingMeetingCounter(value);
  };
  

  return (
    <>

      {/* {dashboardData.length>0? */}
      <WidgetsDropdown pClients={dashboardData?.pclient} aClients={dashboardData?.aclient} partners={dashboardData?.partners} admins={dashboardData?.admins} news={dashboardData?.news} sessions={dashboardData?.sessions} />
      {/*   :""
      } */}

      <CRow className="padding2px pl15 pr15">
        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <div className="mb-4 text-center marginBottom15px" >Profile</div>
              <div className="mb-4 text-center marginBottom15px">
                <span className="dppc" >
                  <img src={pictureContent} alt="" className="dprofilepic" ></img>
                </span>
              </div>
              <div className="mb-4 text-center marginBottom15px" >100% Complete</div>
              <div className="mb-4 text-center marginBottom15px" >
                <CButton color="dark" size="sm" onClick={() => onclickToAnother("/home/edit-user/0")} variant="outline" >View & Edit</CButton> 
              </div>

            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <Meeting onStageChange={handleStateChange} value={meetingCounter} upMc={upcommingMeetingCounter}></Meeting>

            </CCardBody>
          </CCard>
        </CCol>


        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight borderRightUnset">
            <CCardBody>

              <Scheduling></Scheduling>

            </CCardBody>
          </CCard>
        </CCol>

        <CCol md={3} className="pz">
          <CCard className="mb-4 buim cardPheight">
            <CCardBody>

              <div className="mb-4 text-center" >FUNDED BY</div>
              <div className="mb-4 text-center">
                <span className="" >
                  <img src={mygrationhubText} alt="" className="mygrationhubtext" ></img>
                </span>
              </div>
              <div className="mb-4 text-center" >&nbsp;</div>
              <div className="mb-4 text-center" >
                {/* <CButton color="" size="sm" variant="outline" >&nbsp;</CButton> */}
                &nbsp;
              </div>
              

            </CCardBody>
          </CCard>
        </CCol>

      </CRow>


      <CRow className="padding2px">
        <CCol md={6}>
          <CCard className="mb-4 cutomCardHight">
            {/* <CCardHeader>Upcoming Meetings</CCardHeader> */}
            <CCardBody>

                <UpcomingMeeting upcmOnStageChange={upMcStateChange} meetingCounter={meetingCounter}></UpcomingMeeting>

            </CCardBody>
          </CCard>
        </CCol>
      

        <CCol md={6}>
          <CCard className="mb-4 cutomCardHight">
            {/* <CCardHeader>&nbsp;</CCardHeader> */}
            <CCardBody>
              <Goals></Goals>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                MyGration hub
              </h4>
              <div className="small text-medium-emphasis">Approved clients status</div>
            </CCol>

          </CRow>
        </CCardBody>


        <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {clientProgressList?.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter>

        <br />
        <br />

        <CRow className="padding2px">
          <CCol md={6}>
            <CCard className="mb-4">
              <CCardHeader>User Pie Chart</CCardHeader>
              <CCardBody>
               
                <CChartPie
                  data={{
                    // labels: ['Red', 'Green', 'Yellow'],
                    labels: titles,
                    datasets: [
                      {
                        // data: [300, 50, 100],
                        data: values,
                        backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'],
                        hoverBackgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'],
                      },
                    ],
                  }}
                />

              </CCardBody>
            </CCard>
          </CCol>
          {/* <CCol md={6}>
            <CCard className="mb-4">
              <CCardHeader>Polar Area Chart</CCardHeader>
              <CCardBody>
                <CChartPolarArea
                  data={{
                    labels: titles,
                    datasets: [
                      {
                        data:values, 
                        backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'],
                      },
                    ],
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol> */}
          <CCol md={6}>
            <CCard className="mb-4">
              <CCardHeader>Partner List</CCardHeader>
              <CCardBody>
                {partners?.length
                  ? (
                    <div>

                      <CTable align="middle" className="mb-0 border" hover responsive>
                        <CTableHead color="light">
                          <CTableRow>
                            <CTableHeaderCell className="text-center">  <CIcon icon={cilPeople} /></CTableHeaderCell>
                            <CTableHeaderCell scope="col">Partner Name</CTableHeaderCell>
                            <CTableHeaderCell scope="col">User ID / Country</CTableHeaderCell>
                            
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {partners?.map((user, i) => (
                            <CTableRow v-for="item in tableItems" key={i} onClick={() => { viewPartnerDashboard(user?.id,user?.fullName) }}>
                              <CTableDataCell className="text-center">

                                {(user?.pictureCode !== "" && user?.pictureCode !== null) ?
                                  <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                  :
                                  <CAvatar size="md" classnames="profile-picture-css" src={require(`../../assets/images/avatars/avater.png`)} status={user?.applyStatus === '3' ? 'danger' : 'success'} />
                                }


                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{user?.fullName}</div>
                                <div className="small text-medium-emphasis">
                                  <span>{user?.mobile}</span> | {user?.officeEmail}
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                {user?.userName} <br/>
                                {user?.userResidence.map((row) => row?.country?.name)}
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                        </CTableBody>
                      </CTable>


                    </div>
                  ) : <p>No partner to display</p>
                }
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>


      </CCard>
    </>
  )
}

export default Dashboard
