import React, { useState, useEffect } from "react";
import { CButton, CCol, CContainer, CRow, CForm, CFormInput, CInputGroup} from '@coreui/react'
// import { CButton, CCol, CContainer, CCard, CCardBody, CRow, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CFormLabel, CFormSwitch, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import { cilUser, cilMobile, cilSchool, cibWhenIWork, cilHouse, cilInfo, cilCalculator, cilOptions } from '@coreui/icons'
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css";
// import { useLocation, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from '../api/axios';
// import moment from 'moment';
import '../assets/css/userDetails.css';
// import Select from 'react-select';
import { Vortex } from 'react-loader-spinner'
import avatar8 from '../assets/images/avatars/avater.png';



const UpdateProfilePicture = () => {



  const axiosPrivate = useAxiosPrivate();

  // const [data, setData] = useState();
  const [pictureContent, setPictureContent] = useState(avatar8);
  const [picture, setPicture] = useState();
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);





  const [validatedBasic, setValidatedBasic] = useState(false);
  const handleSubmitBasic = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      if (picture === "" || picture === null) {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK', }] });
      } else {

        const formData = {
          picture: picture
        }
        confirmAlert({
          title: 'MyGration Hub', message: "Are you sure? you want to update profile picture?", buttons: [{
            label: 'Yes',
            onClick: () => apicall(formData, "saveProfilePicture", "Profile picture updated successfully")
          }, { label: 'No', }]
        });

      }
    }
    setValidatedBasic(true);
    event.preventDefault();
  }


  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));
    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
        if (apiName === 'saveProfilePicture') {
          document.getElementById("profilePictureId").src = pictureContent;
          //profilePictureRef.current.src= pictureContent;
          window.location.reload();
          setPicture("");
          setValidatedBasic(false);

        }
        //reloadFrom();
      }
      else {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK', }] });
      }

    }, fail => {
      confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
    });
  }


  const onFileChangeHandler = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    console.log(formData);


    if (e.target.files !== "") {

      const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
      if (!allowedTypes.includes(e.target.files[0]?.type)) {
        confirmAlert({ title: 'MyGration Hub', message: "Only JPEG and PNG supported", buttons: [{ label: 'OK' }] });
        e.target.value = null;
        return;
      }

      await axios.post("/uploadFileAndReturn", formData).then((res) => {

        console.log(formData);

        if (res.status === 200) {
          setPicture(res?.data?.fileName);
          setPictureContent(res?.data?.message);
          setIsSpenierOpen(false);
          console.log(res?.data);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, re login and try again later", buttons: [{ label: 'OK' }] });
          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, re login and try again later", buttons: [{ label: 'OK' }] });
        setIsSpenierOpen(false);
      });
    } else {
      console.log("empty")
    }
    //console.log(e.target.files);
  };

  useEffect(() => {
    //let isMounted = true;
    const controller = new AbortController();

    const getUserDetails = async () => {
      try {
        let path = "/downloadProfilePicture";
        axiosPrivate.get(path, { signal: controller.signal }).then((response) => {
          if (response.status === 200) {
            console.log("load image");
            console.log(response?.data);
            if(response?.data===null){
              //setData(response?.data);
              //setPictureContent(avatar8);
            }else{
              setPictureContent(response?.data?.code);
              //setData(response?.data);
            }
          }

        }, fail => {
          confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
        });;

      } catch (err) {
        console.log(err);
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      }
    }

    getUserDetails();

    return () => {
      //isMounted = false;
      controller.abort();
    }
  }, []);

  return (
    <>
      <section>

        <div className='update-info'>
          <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>

              <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validatedBasic} onSubmit={handleSubmitBasic}  >

                <CRow className="border1 paddingBottom10 marginLeft8 marginRight8 width99p">
                  <CCol md={12}>
                    <h2 className="paddingTop10">Update Profile Picture</h2>
                  </CCol>


                  <CCol md={12}>
                    Upload Picture (Only JPEG, PNG, GIF supported) : <span className='red'>*</span> <br></br>
                    To upload Picture click on the <b>Choose File</b> or drag and drop in gray segment
                  </CCol>

                  <CCol md={6}>
                    <CInputGroup className="has-validation">
                      <CFormInput accept="image/png, image/gif, image/jpeg, image/jpg" className="uploadFileInput300" type="file" size="lg" id="formFileLg" onChange={onFileChangeHandler} required />
                    </CInputGroup>
                  </CCol>

                  <CCol xs={6}>
                    <div className="pictureContainer">
                      <img src={pictureContent} alt="" width={300} height={300} className="profile-picture-display-upload"></img>
                    </div>
                  </CCol>

                  <CCol xs={12}>
                    &nbsp;&nbsp;
                  </CCol>

                  <CCol xs={12}>
                    {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <CButton color="primary" type="submit"> Save </CButton>}
                  </CCol>

                  <CCol xs={12}>
                    &nbsp;&nbsp;
                  </CCol>

                  <CCol xs={12}>
                    &nbsp;&nbsp;
                  </CCol>

                  <CCol xs={12}>
                    {/* <div className="pictureContainer">
                          <img src={avatar8} alt="" width={300} height={300}></img>

                      </div> */}
                  </CCol>

                </CRow>

              </CForm>




            </CContainer>




          </div>
        </div>
      </section>
    </>

  )
}

export default UpdateProfilePicture
