import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-work-usa.png';
import Meta from './Meta';

const WorkUsa = () => {
    const title = "The United States is a land of diverse opportunities, attracting skilled professionals from around the world.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>
                                    <h3>
                                        Working in the USA: Unlocking Opportunities
                                    </h3>

                                    <p>
                                        The United States is a land of diverse opportunities, attracting skilled professionals from around the world. Whether you&apos;re seeking to advance your career, explore new horizons, or contribute your expertise to the thriving American economy, our comprehensive immigration services are tailored to meet your specific needs.
                                    </p>

                                    <div className="section-heading-customise">
                                        <h2>Key Steps in the Application Process:</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ol>
                                                <li className='list-style-number' >
                                                    <b>Identifying the Right Visa Category:</b> The first step in your journey is determining the most suitable visa category for your employment goals. Common work-related visas include the H-1B, L-1, and O-1 visas. Our seasoned consultants will assess your qualifications and work closely with you to select the optimal visa that aligns with your career objectives.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Employer Sponsorship:</b> In many cases, securing employment in the USA requires sponsorship from a U.S.-based employer. Our team will guide you through the process of obtaining a job offer and ensuring that your prospective employer complies with all necessary regulations.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Filing the Petition:</b> Once you&apos;ve secured a job offer, our experts will assist you in preparing and filing the necessary petition with U.S. Citizenship and Immigration Services (USCIS). This involves submitting comprehensive documentation, such as proof of employment, educational qualifications, and other supporting materials.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Visa Interview Preparation:</b> Navigating the visa interview is a crucial aspect of the application process. Our team will provide detailed guidance on interview preparation, ensuring you are well-equipped to handle questions related to your qualifications, job offer, and intent to work in the United States.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Post-Approval Procedures:</b> After receiving approval, additional steps may be required, such as obtaining a visa stamp from a U.S. consulate or embassy and completing the necessary medical examinations. Our consultants will support you through each phase, ensuring compliance with all post-approval requirements.
                                                </li>

                                            </ol>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </section >
        </div >

    )
}

export default WorkUsa