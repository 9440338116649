import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { CAvatar,  CButton, CTable, CTableBody, CTableDataCell, CTableHead,CTableHeaderCell, CTableRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilPeople,cilCopy} from '@coreui/icons'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { CTooltip } from '@coreui/react'

const ReferrerList = () => {
    const [users, setUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/getActiveReferrerList', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setUsers(response.data);
            } catch (err) {
                //console.error(err);
                console.log(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    /* function viewHandleClick(value) {
        localStorage.setItem('agent-user-id', value);
        navigate('/home/agent-details', { state: { id: value }, replace: true });
    }

    function viewHandleEditClick(value) {
        localStorage.setItem('back-page', '/home/agent-list');
        navigate('/home/edit-agent/'+value, { state: { id: value ,backPage: '/home/agent-list' }, replace: true });
    } */

    function viewHandleClick(value) {
        localStorage.setItem('details-user-id',value);
        navigate('/home/referrer-details/'+value, { state: { id: value }, replace: true });
    }

    /* function viewHandleEditClick(value) {
        localStorage.setItem('edit-user-id', value);
        localStorage.setItem('back-page', '/home/referrer-list');
        navigate('/home/edit-user/'+value, { state: { id: value, backPage: '/home/referrer-list' }, replace: true });
    } */

    function viewHandleEditClick(value) {
        localStorage.setItem('back-page', '/home/referrer-list');
        localStorage.setItem('page-name', "Referral");
        navigate('/home/edit-agent/'+value, { state: { id: value ,backPage: '/home/referrer-list' }, replace: true });
    }

    function deleteUserInfo(id, userName, fullName, index) {
        confirmAlert({
          title: 'MyGration Hub', message: "Are you sure? You want to delete referre - " + fullName,
          buttons: [{
            label: 'Yes',
            onClick: () => deletePartnew(id, userName,index)
          },
          {
            label: 'No',
          }]
        });
      }

      async function deletePartnew(id, userName,index) {
        try {
          const formData = {
            id: id,
            userName: userName
          }
    
          const headers = {
            'Content-Type': 'application/json'
          }
    
          await axiosPrivate.post("/deleteUserInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
            if (res.status === 200) {
              confirmAlert({ title: 'MyGration Hub', message: "Referrer deleted successfully", buttons: [{ label: 'OK' }] });
              setUsers(users.filter((v, i) => i !== index));
            } else {
              confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
            }
          }, fail => {
            confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
          });
        }
        catch (err) {
        }
    
      }


      const handleCopy = (content) => {
        try {
            var url = "https://mygrationhub.com/#/register/"+content
            navigator.clipboard.writeText(url);
        } catch (error) {
        }
      };

    return (
        <article>
            <h2>Referrer List</h2>
            {/* {console.log(users?.length)} */}
            {users?.length
                ? (
                    <div>
                        
                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell className="text-center">
                                        <CIcon icon={cilPeople} />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Country of Residance<br></br>/Mobile/ Email</CTableHeaderCell>
                                    {/* <CTableHeaderCell scope="col">Email</CTableHeaderCell> */}
                                    <CTableHeaderCell scope="col">Reffer Link</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {users?.map((user, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell className="text-center">

                                            {(user?.pictureCode!=="" && user?.pictureCode!== null)?
                                            <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus==='3'?'danger':'success'} />
                                            :
                                            <CAvatar size="md"classnames="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus==='3'?'danger':'success'} />
                                            }


                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.fullName}</div>
                                            <div className="small text-medium-emphasis">
                                                <span>{user?.applyStatus=== 3 ? 'Pending' : 'Approved'}</span> | Create Date:{moment(user?.dateCreated).format("DD-MM-YYYY")}                                                
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.userResidence?.map((row) => row?.country?.name)} <br/> {user?.mobile} <br /> {user?.officeEmail} </div>
                                        </CTableDataCell>
                                        {/* <CTableDataCell>
                                            <div className="clearfix">
                                                {user?.officeEmail}    
                                            </div>
                                        </CTableDataCell> */}
                                        <CTableDataCell>
                                            <div className="clearfix">
                                                https://mygrationhub.com/#/register/{user?.userId} &nbsp;

                                                <CTooltip content="Copied!" delay={{'show':0,'hide':10}} onHide={()=> false} trigger={['click','focus']}>
                                                    <span className="d-inline-block" tabIndex={0}>
                                                        <CIcon onClick={() => { handleCopy(user?.userId) }} icon={cilCopy} />
                                                    </span>
                                                </CTooltip>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <CButton  color="info"  onClick={() => { viewHandleClick(user?.id) }}><i className="fa fa-eye"></i></CButton>&nbsp;
                                             <CButton color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                            <CButton  color="danger"   onClick={() => { deleteUserInfo(user?.id, user?.userName,user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>

                        
                    </div>
                ) : <p>No users to display</p>
            }
        </article>
    )
}
export default ReferrerList;
