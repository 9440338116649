import {React, useState,useEffect} from 'react';
import { CFormSelect} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser, cilMobile } from '@coreui/icons'
import { useParams,useNavigate } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select from 'react-select'
import axios from '../api/axios';
import '../../src/assets/css/register.css'
import { 
    // Audio,ProgressBar,
    // TailSpin,
    Vortex, 
    // Watch 
} from  'react-loader-spinner'
import PopupContent from '../../src/components/PopupContent';

// import { Link } from 'react-router-dom'
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const Register = () => {

    const navigate = useNavigate();
    // const [mobile, setMobile] = useState()
    const {key} = useParams();

    const [countryOfResidence, setCountryOfResidence] = useState("BD");
    const [fbPost, setFbPost] = useState([]);


    const countryList = [
        { value: '', label: 'Select country' },
        { value: '1', label: 'Afghanistan' },
        { value: '2', label: 'Albania' },
        { value: '3', label: 'Algeria' },
        { value: '4', label: 'American Samoa' },
        { value: '5', label: 'Andorra' },
        { value: '6', label: 'Angola' },
        { value: '7', label: 'Anguilla' },
        { value: '8', label: 'Antarctica' },
        { value: '9', label: 'Antigua and Barbuda' },
        { value: '10', label: 'Argentina' },
        { value: '11', label: 'Armenia' },
        { value: '12', label: 'Aruba' },
        { value: '13', label: 'Australia' },
        { value: '14', label: 'Austria' },
        { value: '15', label: 'Azerbaijan' },
        { value: '16', label: 'Bahamas' },
        { value: '17', label: 'Bahrain' },
        { value: '18', label: 'Bangladesh' },
        { value: '19', label: 'Barbados' },
        { value: '20', label: 'Belarus' },
        { value: '21', label: 'Belgium' },
        { value: '22', label: 'Belize' },
        { value: '23', label: 'Benin' },
        { value: '24', label: 'Bermuda' },
        { value: '25', label: 'Bhutan' },
        { value: '26', label: 'Bosnia and Herzegovina' },
        { value: '27', label: 'Botswana' },
        { value: '28', label: 'Bouvet Island' },
        { value: '29', label: 'Brazil' },
        { value: '30', label: 'British Indian Ocean Territory' },
        { value: '31', label: 'Brunei Darussalam' },
        { value: '32', label: 'Bulgaria' },
        { value: '33', label: 'Burkina Faso' },
        { value: '34', label: 'Burundi' },
        { value: '35', label: 'Cambodia' },
        { value: '36', label: 'Cameroon' },
        { value: '37', label: 'Canada' },
        { value: '38', label: 'Cape Verde' },
        { value: '39', label: 'Cayman Islands' },
        { value: '40', label: 'Central African Republic' },
        { value: '41', label: 'Chad' },
        { value: '42', label: 'Chile' },
        { value: '43', label: 'China' },
        { value: '44', label: 'Christmas Island' },
        { value: '45', label: 'Cocos (Keeling) Islands' },
        { value: '46', label: 'Colombia' },
        { value: '47', label: 'Comoros' },
        { value: '48', label: 'Congo' },
        { value: '49', label: 'Cook Islands' },
        { value: '50', label: 'Costa Rica' },
        { value: '51', label: 'Croatia' },
        { value: '52', label: 'Cuba' },
        { value: '53', label: 'Cyprus' },
        { value: '54', label: 'Czech Republic' },
        { value: '55', label: 'Denmark' },
        { value: '56', label: 'Djibouti' },
        { value: '57', label: 'Dominica' },
        { value: '58', label: 'Dominican Republic' },
        { value: '59', label: 'Ecuador' },
        { value: '60', label: 'Egypt' },
        { value: '61', label: 'El Salvador' },
        { value: '62', label: 'Equatorial Guinea' },
        { value: '63', label: 'Eritrea' },
        { value: '64', label: 'Estonia' },
        { value: '65', label: 'Ethiopia' },
        { value: '66', label: 'Falkland Islands (Malvinas)' },
        { value: '67', label: 'Faroe Islands' },
        { value: '68', label: 'Fiji' },
        { value: '69', label: 'Finland' },
        { value: '70', label: 'France' },
        { value: '71', label: 'French Guiana' },
        { value: '72', label: 'French Polynesia' },
        { value: '73', label: 'French Southern Territories' },
        { value: '74', label: 'Gabon' },
        { value: '75', label: 'Gambia' },
        { value: '76', label: 'Georgia' },
        { value: '77', label: 'Germany' },
        { value: '78', label: 'Ghana' },
        { value: '79', label: 'Gibraltar' },
        { value: '80', label: 'Greece' },
        { value: '81', label: 'Greenland' },
        { value: '82', label: 'Grenada' },
        { value: '83', label: 'Guadeloupe' },
        { value: '84', label: 'Guam' },
        { value: '85', label: 'Guatemala' },
        { value: '86', label: 'Guernsey' },
        { value: '87', label: 'Guinea' },
        { value: '88', label: 'Guinea-Bissau' },
        { value: '89', label: 'Guyana' },
        { value: '90', label: 'Haiti' },
        { value: '91', label: 'Heard Island and McDonald Islands' },
        { value: '92', label: 'Holy See (Vatican City State)' },
        { value: '93', label: 'Honduras' },
        { value: '94', label: 'Hong Kong' },
        { value: '95', label: 'Hungary' },
        { value: '96', label: 'Iceland' },
        { value: '97', label: 'India' },
        { value: '98', label: 'Indonesia' },
        { value: '99', label: 'Iran' },
        { value: '100', label: 'Iraq' },
        { value: '101', label: 'Ireland' },
        { value: '102', label: 'Isle of Man' },
        { value: '103', label: 'Israel' },
        { value: '104', label: 'Italy' },
        { value: '105', label: 'Jamaica' },
        { value: '106', label: 'Japan' },
        { value: '107', label: 'Jersey' },
        { value: '108', label: 'Jordan' },
        { value: '109', label: 'Kazakhstan' },
        { value: '110', label: 'Kenya' },
        { value: '111', label: 'Kiribati' },
        { value: '112', label: 'Kuwait' },
        { value: '113', label: 'Kyrgyzstan' },
        { value: '114', label: 'Lao Peoples Democratic Republic' },
        { value: '115', label: 'Latvia' },
        { value: '116', label: 'Lebanon' },
        { value: '117', label: 'Lesotho' },
        { value: '118', label: 'Liberia' },
        { value: '119', label: 'Libya' },
        { value: '120', label: 'Liechtenstein' },
        { value: '121', label: 'Lithuania' },
        { value: '122', label: 'Luxembourg' },
        { value: '123', label: 'Macao' },
        { value: '124', label: 'Madagascar' },
        { value: '125', label: 'Malawi' },
        { value: '126', label: 'Malaysia' },
        { value: '127', label: 'Maldives' },
        { value: '128', label: 'Mali' },
        { value: '129', label: 'Malta' },
        { value: '130', label: 'Marshall Islands' },
        { value: '131', label: 'Martinique' },
        { value: '132', label: 'Mauritania' },
        { value: '133', label: 'Mauritius' },
        { value: '134', label: 'Mayotte' },
        { value: '135', label: 'Mexico' },
        { value: '136', label: 'Monaco' },
        { value: '137', label: 'Mongolia' },
        { value: '138', label: 'Montenegro' },
        { value: '139', label: 'Montserrat' },
        { value: '140', label: 'Morocco' },
        { value: '141', label: 'Mozambique' },
        { value: '142', label: 'Myanmar' },
        { value: '143', label: 'Namibia' },
        { value: '144', label: 'Nauru' },
        { value: '145', label: 'Nepal' },
        { value: '146', label: 'Netherlands' },
        { value: '147', label: 'New Caledonia' },
        { value: '148', label: 'New Zealand' },
        { value: '149', label: 'Nicaragua' },
        { value: '150', label: 'Niger' },
        { value: '151', label: 'Nigeria' },
        { value: '152', label: 'Niue' },
        { value: '153', label: 'Norfolk Island' },
        { value: '154', label: 'Northern Mariana Islands' },
        { value: '155', label: 'Norway' },
        { value: '156', label: 'Oman' },
        { value: '157', label: 'Pakistan' },
        { value: '158', label: 'Palau' },
        { value: '159', label: 'Panama' },
        { value: '160', label: 'Papua New Guinea' },
        { value: '161', label: 'Paraguay' },
        { value: '162', label: 'Peru' },
        { value: '163', label: 'Philippines' },
        { value: '164', label: 'Pitcairn' },
        { value: '165', label: 'Poland' },
        { value: '166', label: 'Portugal' },
        { value: '167', label: 'Puerto Rico' },
        { value: '168', label: 'Qatar' },
        { value: '169', label: 'Romania' },
        { value: '170', label: 'Russian Federation' },
        { value: '171', label: 'Rwanda' },
        { value: '172', label: 'Saint Kitts and Nevis' },
        { value: '173', label: 'Saint Lucia' },
        { value: '174', label: 'Saint Martin (French part)' },
        { value: '175', label: 'Saint Pierre and Miquelon' },
        { value: '176', label: 'Saint Vincent and the Grenadines' },
        { value: '177', label: 'Samoa' },
        { value: '178', label: 'San Marino' },
        { value: '179', label: 'Sao Tome and Principe' },
        { value: '180', label: 'Saudi Arabia' },
        { value: '181', label: 'Senegal' },
        { value: '182', label: 'Serbia' },
        { value: '183', label: 'Seychelles' },
        { value: '184', label: 'Sierra Leone' },
        { value: '185', label: 'Singapore' },
        { value: '186', label: 'Sint Maarten (Dutch part)' },
        { value: '187', label: 'Slovakia' },
        { value: '188', label: 'Slovenia' },
        { value: '189', label: 'Solomon Islands' },
        { value: '190', label: 'Somalia' },
        { value: '191', label: 'South Africa' },
        { value: '192', label: 'South Georgia and the South Sandwich Islands' },
        { value: '193', label: 'South Sudan' },
        { value: '194', label: 'Spain' },
        { value: '195', label: 'Sri Lanka' },
        { value: '196', label: 'State of Palestine' },
        { value: '197', label: 'Sudan' },
        { value: '198', label: 'Suriname' },
        { value: '199', label: 'Svalbard and Jan Mayen' },
        { value: '200', label: 'Swaziland' },
        { value: '201', label: 'Sweden' },
        { value: '202', label: 'Switzerland' },
        { value: '203', label: 'Syrian Arab Republic' },
        { value: '204', label: 'Tajikistan' },
        { value: '205', label: 'Thailand' },
        { value: '206', label: 'Timor-Leste' },
        { value: '207', label: 'Togo' },
        { value: '208', label: 'Tokelau' },
        { value: '209', label: 'Tonga' },
        { value: '210', label: 'Trinidad and Tobago' },
        { value: '211', label: 'Tunisia' },
        { value: '212', label: 'Turkey' },
        { value: '213', label: 'Turkmenistan' },
        { value: '214', label: 'Turks and Caicos Islands' },
        { value: '215', label: 'Tuvalu' },
        { value: '216', label: 'Uganda' },
        { value: '217', label: 'Ukraine' },
        { value: '218', label: 'United Arab Emirates' },
        { value: '219', label: 'United Kingdom' },
        { value: '220', label: 'United States' },
        { value: '221', label: 'United States Minor Outlying Islands' },
        { value: '222', label: 'Uruguay' },
        { value: '223', label: 'Uzbekistan' },
        { value: '224', label: 'Vanuatu' },
        { value: '225', label: 'Viet Nam' },
        { value: '226', label: 'Wallis and Futuna' },
        { value: '227', label: 'Western Sahara' },
        { value: '228', label: 'Yemen' },
        { value: '229', label: 'Zambia' },
        { value: '230', label: 'Zimbabwe' }
    ]
    const ConsultationReList = [
        { value: '1', label: 'Immigration' },
        { value: '2', label: 'Work' },
        { value: '3', label: 'Sponsorship' },
        { value: '4', label: 'Study' },
        { value: '5', label: 'Visit' },
        { value: '6', label: 'Citizenship' }
    ]

    const countrys = [
        { value: '13', label: 'Australia' },
        { value: '37', label: 'Canada' },
        { value: '219', label: 'United Kingdom (UK)' },
        { value: '220', label: 'United States America (USA)' },
        { value: '1000', label: 'Others' }
    ]

    // let feedback = {
    //     options: {
    //         loop: true,
    //         autoplay:true,
    //         margin: 30,
    //         nav: true,
    //         responsive: {
    //             0: {
    //                 items: 2,
    //             },
    //             400: {
    //                 items: 2,
    //             },
    //             708: {
    //                 items: 2,
    //             },
    //             1100: {
    //                 items: 2,
    //             },
    //             1250: {
    //                 items: 2,
    //             },
    //         },
    //     },

    //     items: [
    //         <div className="item" key={1}>
    //             <div className="item-content">
    //                 <div className='socail-creator'>
    //                     <img src={require('./../assets/images/news/new1.webp')} alt="" />
    //                 </div>
    //                 <div className='social-date'>
    //                     <p>Shamim Khan</p>
    //                     <p>a Year ago</p>
    //                 </div>
    //                 <div className='social-icon'>
    //                     <div className='social-icon-image'>
    //                         <i className="fa fa-facebook"></i>
    //                     </div>
    //                 </div>

    //                 <div className='social-feedback-rating'>
    //                     * * * * *
    //                 </div>

    //                 <div className='social-feedback-content'>
    //                     IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits
    //                 </div>
    //             </div>
    //         </div>,

    //         <div className="item" key={2}>
    //             <div className="item-content">
    //                 <div className='socail-creator'>
    //                     <img src={require('./../assets/images/news/new1.webp')} alt="" />
    //                 </div>
    //                 <div className='social-date'>
    //                     <p>Shamim Khan</p>
    //                     <p>a Year ago</p>
    //                 </div>
    //                 <div className='social-icon'>
    //                     <div className='social-icon-image'>
    //                         <i className="fa fa-facebook"></i>
    //                     </div>
    //                 </div>

    //                 <div className='social-feedback-rating'>
    //                     * * * * *
    //                 </div>

    //                 <div className='social-feedback-content'>
    //                     IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits
    //                 </div>
    //             </div>
    //         </div>,

    //         <div className="item" key={3}>
    //             <div className="item-content">
    //                 <div className='socail-creator'>
    //                     <img src={require('./../assets/images/news/new1.webp')} alt="" />
    //                 </div>
    //                 <div className='social-date'>
    //                     <p>Shamim Khan</p>
    //                     <p>a Year ago</p>
    //                 </div>
    //                 <div className='social-icon'>
    //                     <div className='social-icon-image'>
    //                         <i className="fa fa-facebook"></i>
    //                     </div>
    //                 </div>

    //                 <div className='social-feedback-rating'>
    //                     * * * * *
    //                 </div>

    //                 <div className='social-feedback-content'>
    //                     IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits
    //                 </div>
    //             </div>
    //         </div>,

    //         <div className="item" key={4}>
    //             <div className="item-content">
    //                 <div className='socail-creator'>
    //                     <img src={require('./../assets/images/news/new1.webp')} alt="" />
    //                 </div>
    //                 <div className='social-date'>
    //                     <p>Shamim Khan</p>
    //                     <p>a Year ago</p>
    //                 </div>
    //                 <div className='social-icon'>
    //                     <div className='social-icon-image'>
    //                         <i className="fa fa-facebook"></i>
    //                     </div>
    //                 </div>

    //                 <div className='social-feedback-rating'>
    //                     * * * * *
    //                 </div>

    //                 <div className='social-feedback-content'>
    //                     IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits IRCC announces new eligibility criteria for Post-Graduation Work Permits
    //                 </div>
    //             </div>
    //         </div>
    //     ],
    // };


    const [isSpenierOpen, setIsSpenierOpen] = useState(false);

    const [consultation, setLegalCountryId] = useState([]);
    const onMultiSelectCon = (e) => {
        setLegalCountryId(Array.isArray(e) ? e?.map(x => x.value) : []);
    }


    const [interestedCountry, setInterestedCountry] = useState([]);
    const onMultiSelectCountry = (e) => {
        setInterestedCountry(Array.isArray(e) ? e?.map(x => x.value) : []);
    }




    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");

    const resultsCon = [];
    var consultati = {}

    const resultsIntCountry = [];
    var IntCountry = {}
    const [isLoading, setIsLoading] = useState(true);

    const onChangeHandler = () => {

        let inputEmail = email
        if (firstName === '') {
            return false;
        }
        else if (lastName === '') {
            return false;
        }

        
        if (typeof inputEmail !== "undefined") {
            let lastAtPos = inputEmail.lastIndexOf("@");
            let lastDotPos = inputEmail.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    inputEmail.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    inputEmail.length - lastDotPos > 2
                )
            ) {
                confirmAlert({
                    title: 'MyGration Hub',
                    message: 'Please Provide valid email addresss',
                    buttons: [
                        {
                            label: 'OK',
                        }
                        //   ,
                        //   {
                        //     label: 'No',
                        //     onClick: () => alert('Click No')
                        //   }
                    ]
                });
                return false;
            }
        }

        if(typeof mobile === "undefined"){
            confirmAlert({
                title: 'MyGration Hub',
                message: 'Please Provide valid phone number',
                buttons: [
                    {
                        label: 'OK',
                    }
                    
                ]
                
            });

            return false;

        }
        else if(mobile.length<8 || mobile.length>16){
            confirmAlert({
                title: 'MyGration Hub',
                message: 'Please Provide valid phone number',
                buttons: [
                    {
                        label: 'OK',
                    }
                    
                ]
                
            });

            return false;
        }
        
        return true;
    }


    async function register(event) {
        let refUserId = (key==='undefined'||key===""||key===null||key==='null')?"":key;
        const isValid = onChangeHandler();
        if (!isValid) {
            event.preventDefault();
            return false;
        } else {

            for (const consultationId of consultation) {
                resultsCon.push(
                    consultati = {
                        consultation: { id: consultationId }
                    })
            }


            for (const countryId of interestedCountry) {
                resultsIntCountry.push(
                    IntCountry = {
                        country: { id: countryId }
                    })
            }



            event.preventDefault();

            try {
                const formData = {
                    firstName: firstName,
                    lastName: lastName,
                    mobile: mobile,
                    officeEmail: email,
                    interestedCountry: resultsIntCountry,
                    userConsultation: resultsCon,
                    refUser:refUserId,
                    userResidence: [
                        {
                            country: { id: countryOfResidence }
                        }
                    ]


                }

                const headers = {
                    'Content-Type': 'application/json'
                }

                console.log(JSON.stringify(formData));

                setIsSpenierOpen(true);
                await axios.post("/new", JSON.stringify(formData), { headers: headers }).then((res) => {
                    if (res.status === 201) {
                        confirmAlert({
                            title: 'MyGration Hub',
                            message: 'Thank you ' + firstName + ' ' + lastName + ', Your information is saved successfully.Stay connect with Mygration Hub',
                            buttons: [
                                {
                                    label: 'OK',
                                }
                            ]
                        });
                        navigate('/');
                    }
                    setIsSpenierOpen(false);

                }, fail => {
                    console.log(fail.response.data);
                    confirmAlert({
                        title: 'MyGration Hub',
                        message: fail.response.data,
                        buttons: [
                            {
                                label: 'OK',
                            }
                            
                        ]
                        
                    });

                    setIsSpenierOpen(false);
                });
            }

            catch (err) {
                alert(err);
            }
        }

    }



    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getPostInfoListOnLoad = async () => {
            try {
                const path = "/getAllPostListToDisplay";
                const response = await axios.get(path, {
                    signal: controller.signal
                });
                isMounted && response?.data && setFbPost(response?.data);

            } catch (err) {
            }
        }

        getPostInfoListOnLoad();

        /* let x = [];
        let a = {
            src:"https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fshegufta.farhat%2Fposts%2Fpfbid02k9QxhyAfb9b4zPKm24Lz63sX6iRTZDiyYphm7tbWHS2ZkhuTahwgvvKFJmrCEpPVl&show_text=true&width=500"
        }
        x.push(a) 
        setFbPost(x);
        */


        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    

    return (


        <div className='register-main-container'>


            <div className="row" style={{ paddingTop: 110}}>
                <div className="col-sm-12 cl-md-6 col-lg-6" style={{paddingBottom:40}}>


                    <div className='container'>
                        <div className='container'>
                            <div className='card'>
                                <div className='card-body'>

                                    <div className="card-body">

                                        <div className='card-body'>
                                            <form onSubmit={register} className="signin-form">
                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-6 col-lg-6">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"><CIcon icon={cilUser} /></span>
                                                            <input type="text" className="form-control" placeholder="First Name" autoComplete="off" value={firstName} onChange={(event) => { setFirstName(event.target.value); }} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 cl-md-6 col-lg-6">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"><CIcon icon={cilUser} /></span>
                                                            <input type="text" className="form-control" placeholder="Last Name" autoComplete="off" value={lastName} onChange={(event) => { setLastName(event.target.value); }} required />
                                                        </div>
                                                    </div>



                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">@</span>
                                                            <input type="text" pattern="[^ @]*@[^ @]*" title="example@mail.com" className="form-control" placeholder="Email" autoComplete="off" value={email} onChange={(event) => { setEmail(event.target.value); }} required />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"><CIcon icon={cilMobile} /></span>
                                                            <PhoneInput  className='form-control' international countryCallingCodeEditable={false} defaultCountry="BD" value={mobile} onChange={setMobile} required style={{ width: "80%", display: "inline flex" }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        <h6>Country of Residence</h6>
                                                        <div className="input-group mb-1">
                                                            <CFormSelect size="sm" className="form-control mb-3" aria-label="" options={countryList} value={countryOfResidence} onChange={(event) => { setCountryOfResidence(event.target.value); }} required ></CFormSelect>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        <h6>Consultation Requirement</h6>
                                                        <div className="input-group mb-1 z-index-bottom">
                                                            <Select isMulti name="Consultation" options={ConsultationReList}
                                                                className="width100p col-form-label"
                                                                classNamePrefix="Consultations Requirement"
                                                                value={ConsultationReList.filter(obj => consultation.includes(obj.value))}
                                                                onChange={onMultiSelectCon}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        <h6>Interested Country</h6>
                                                        <div className="input-group mb-1">
                                                            <Select isMulti name="country" options={countrys} className="width100p col-form-label"
                                                                classNamePrefix="Interested Country" value={countrys.filter(obj => interestedCountry.includes(obj.value))} // set selected values
                                                                onChange={onMultiSelectCountry}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        &nbsp;
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                    <input type="checkbox" id="trams" name="trams" value="1" required /> &nbsp;<PopupContent></PopupContent>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        &nbsp;
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12" style={{textAlign:'center'}}>
                                                    {/* <Watch visible={true} height="80" width="80" radius="48" color="#4fa94d" ariaLabel="watch-loading" wrapperStyle={{textAlign:'center'}} wrapperClass="" /> */}
                                                    {/* <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}></Vortex> */}
                                                    {/* <TailSpin visible={true} height="80" width="80" color="#4fa94d" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{textAlign:'center'}} wrapperClass="" /> */}
                                                        {isSpenierOpen?<Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480','#036480','#036480','#036480','#036480','#036480']}></Vortex>:<button className="form-control btn btn-success submit px-3">Submit</button>}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                    &nbsp;
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 cl-md-12 col-lg-12">
                                                        &nbsp;
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>



                </div>

                <div className="col-sm-12 cl-md-6 col-lg-6">
                    <div>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className='container'>
                                    <div className='container'>
                                        <div className="item-self accordions-rigth-self padding-top-10">
                                            <div className='row'>
                                                <div className='col-lg-12 font-head'>
                                                    {/* <img src={require('./../assets/images/mot.jpeg')} className='register-image' alt=""/> */}
                                                    {/* <span className='font-text'>Dreams Don&apos;t Work Unless You Do.</span> */}
                                                    <img className='' alt='' src={require('./../assets/images/dream-1.png')}></img>

                                                    
                                                </div>
                                                <div className='col-lg-12 padding-top-30 feedback-container'>
                                                    {/* <OwlCarousel className='owl-service-item' {...feedback.options}>
                                                        {feedback.items}
                                                    </OwlCarousel> */}
                                                    {/* <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fronighosh07%2Fposts%2Fpfbid0XBW9ZpiXCQvXY8omsU6hDWX959Zg7vSKSv4XLPYvqS1XsXdzi3xxexVHwyFq8Nk9l&show_text=true" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden', borderRadius: 10 }} scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fshegufta.farhat%2Fposts%2Fpfbid02k9QxhyAfb9b4zPKm24Lz63sX6iRTZDiyYphm7tbWHS2ZkhuTahwgvvKFJmrCEpPVl&show_text=true" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden', borderRadius: 10 }} scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmygrationhub%2Fposts%2F122126740268165852&show" width="100%" height="auto" style={{ border: "none", overflow: "hidden" }} allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                                    <iframe key={1} src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fenayetur.rahim%2Fposts%2Fpfbid0P3rZ8XHpP1iMLDsrcn44r6QjA86EZbBPGBfthFLaCZ9Hub6gWwXRa4r2Jf9WAYDcl&show_text=true&width=500" width="100%" height="auto" style={{ border: "none", overflow: "hidden" }} allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                                    <iframe key={1} src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fenayetur.rahim%2Fposts%2Fpfbid0P3rZ8XHpP1iMLDsrcn44r6QjA86EZbBPGBfthFLaCZ9Hub6gWwXRa4r2Jf9WAYDcl&show_text=true&width=700" width="100%" height="auto" style={{ border: "none", overflow: "hidden" }} allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
 */}
                                                    {fbPost?.map((row, i) => (

                                                        <iframe title={i} key={i} src={row?.src} width="100%" height="auto" style={{ border: "none", overflow: "hidden" }} allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

                                                    ))};
                                                </div>
                                            </div>
                                          
                                            
                                        </div>
                                    </div>
                                </div>
                                
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Register
