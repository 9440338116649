import React from 'react'
import { Link } from 'react-router-dom'

import './../assets/css/custom.css'

const CoreExpertise = () => {
    return (
        <div>

            <section>
                <div className="footer-new">
                    <div className="row">
                        <div className='col-lg-12'>
                            <div className='row'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 office'>
                                        <p>Bangladesh Office:</p>
                                        <p>Sheltech Niribili (1st Floor),210/2</p>
                                        <p>Elephant Road, Dhaka-1205</p>
                                        <p>Email: connect@mygrationhub.com</p>
                                        <p>Phone: +880 255165344</p>
                                        <p>Cell: +880 1619966666</p>
                                        {/* <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : +880 1619966666</p> */}
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 office'>
                                        <p>USA Office:</p>
                                        <p>Opening soon</p>
                                        {/* <p>Email: connect@mygrationhub.com</p> */}
                                        {/* <p>Cell : +880 1723854845</p> */}
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 office'>
                                        <p>Australia Office:</p>
                                        <p>Opening soon</p>
                                        {/* <p>Email: connect@mygrationhub.com</p> */}
                                        {/* <p>Cell : +880 1723854845</p> */}
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 office'>
                                        <p>Canada Office:</p>
                                        <p>Dubois Cres, Saskatoon</p>
                                        <p>Email: connect@mygrationhub.com</p>
                                        {/* <p>Cell : +880 1723854845</p> */}
                                    </div>
                                    <div className='col-sm-12 padding-bottom-20px'>
                                        <Link to="/disclimer">Disclaimer & Privacy Policy</Link>
                                        <Link to="/about-us" className='padding-left-50px'>About us</Link>
                                        <Link to="/register" className='padding-left-50px'>Connect with us</Link>
                                    </div>
                                    <div className='col-sm-12'>
                                        <p className='color-golden'>Copyright © 2024 MyGrationHub. All Rights Reserved.</p>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>

    )
}

export default CoreExpertise