import React from 'react'
import './../assets/css/custom.css'

import logo from './../assets/images/banner-canada.png';
import Meta from './Meta';

const ToCanada = () => {
    const title = "The immigration procedure to Canada is dynamic, offering diverse routes for individuals depending on their skills, background, and individual situations.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="imi-canada">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <p>
                                    The immigration procedure to Canada is dynamic, offering diverse routes for individuals depending on their skills, background, and individual situations. The Canadian government has devised numerous channels designed to accommodate various immigrant demographics, each possessing distinct eligibility requirements. Here&apos;s an overview:
                                </p>

                                {/* <h3>Express Entry</h3> */}

                                <div className="section-heading-customise">
                                    <h2>1. Express Entry </h2>
                                </div>
                                <p>
                                    The Express Entry program is a Canadian immigration system designed to select skilled workers for immigration to Canada. It is one of the primary pathways for professionals looking to settle in Canada. Following are the different streams of Express Entry:
                                </p>
                                <div className='padding-left-20px'>
                                    <h3>Federal Skilled Worker Program</h3>
                                    <p>
                                        The Federal Skilled Worker Program is a Canadian immigration category designed for skilled workers with foreign work experience who wish to immigrate to Canada
                                    </p>
                                    <p>
                                        Eligibility: Minimum one year of skilled work experience, language proficiency (English or French), education (Canadian or foreign with an Educational Credential Assessment), and a minimum score in the Comprehensive Ranking System (CRS).
                                    </p>

                                    <h3>Federal Skilled Trades Program </h3>
                                    <p>
                                        The Federal Skilled Trades Program is geared towards proficient tradespeople engaged in occupations such as construction, electrical work, and manufacturing.
                                    </p>
                                    <p>
                                        Eligibility: Valid job offer or certification in a skilled trade, two years of work experience in a skilled trade within five years, and language proficiency.
                                    </p>

                                    <h3>Canadian Experience Class</h3>
                                    <p>
                                        The Canadian Experience Class is designed for individuals with Canadian work experience who aim to obtain permanent residency.
                                    </p>
                                    <p>
                                        Eligibility: At least one year of skilled work experience in Canada, language proficiency, and planning to live outside Quebec.
                                    </p>


                                    <p>
                                        The immigration procedure to Canada is dynamic, offering diverse routes for individuals depending on their skills, background, and individual situations. The Canadian government has devised numerous channels designed to accommodate various immigrant demographics, each possessing distinct eligibility requirements. Here&apos;s an overview:
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>2. Provincial Nominee Program (PNP)</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                        The Provincial Nominee Program (PNP) enables Canadian provinces and territories to select individuals who desire to immigrate to Canada and express a preference for settling in a specific province.
                                    </p>

                                    <p>
                                        Eligibility: Varies by province, generally includes job offer in the province, language skills, education, and work experience.
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>3. Family Class Sponsorship</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                        Family Class Sponsorship program facilitates the reunification of families by enabling residents to sponsor their relatives.
                                    </p>

                                    <p>
                                        Eligibility: Sponsor must be a Canadian citizen or permanent resident, prove financial stability, and commit to supporting the family member.
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>4. Refugee and Asylum Program</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                        The Refugee and Asylum Program extends protection to individuals fleeing severe threats in their home countries, granting them refuge and safety.
                                    </p>

                                    <p>
                                        Eligibility: Must demonstrate fear of persecution or risk to life in home country due to race, religion, nationality, political opinion, or membership in a particular social group.
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>5. Business Immigration</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <h3>Start-Up Visa Program</h3>
                                    <p>
                                        Aims to attract innovative entrepreneurs and link them with Canadian private sector businesses.
                                    </p>

                                    <p>
                                        Eligibility: Have a qualifying business, get a letter of support from a designated organization, meet language requirements, and have enough money to settle.
                                    </p>

                                    <h3>Self-Employed Persons Program</h3>

                                    <p>
                                        For self-employed individuals with significant experience in cultural activities or sports.
                                    </p>
                                    <p>Eligibility: Relevant experience in cultural activities or athletics and intention and ability to contribute significantly to the cultural or athletic life of Canada.</p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>6. Refugee and Asylum Program</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                        Permits caregivers to become permanent residents after gaining sufficient work experience in Canada.
                                    </p>

                                    <p>
                                        Eligibility: Work experience in Canada as a home child care provider or home support worker, meet language and education requirements.	
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>7. Humanitarian and Compassionate Grounds</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                        For those who may not meet the criteria for other immigration streams but have strong ties to Canada.
                                    </p>

                                    <p>
                                        Eligibility: Demonstrate significant establishment in Canada, adverse effects if returned to home country, and other compelling factors.
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>8. Temporary Residence</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                    Temporary Foreign Worker Program: For individuals with a job offer in Canada; eligibility depends on the job offer and labor market needs.
                                    </p>

                                    <p>
                                        International Student Program: For those admitted to Canadian educational institutions; requires proof of acceptance, financial support, and intention to leave Canada upon studies completion.
                                    </p>
                                    <p>
                                        Visitor Visas: For tourists, family visits, or business trips; requires proof of financial support and intent to return to home country.
                                    </p>

                                </div>

                                <div className="section-heading-customise">
                                    <h2>General Considerations for Immigration</h2>
                                </div>
                                <div className='padding-left-20px padding-top-20px'>
                                    <p>
                                        •	Admissibility: All applicants must pass security and health checks
                                    </p>

                                    <p>
                                        •	Financial Requirements: Proof of funds is often necessary, except for some streams like the Canadian Experience Class.
                                    </p>

                                </div>

                                <p>
                                    Each stream has its application process, which might include online application submissions, document submissions, interviews, and biometrics. It&apos;s crucial to stay updated with the latest guidelines from Immigration, Refugees and Citizenship Canada (IRCC), as immigration policies and procedures can change.
                                </p>
                            </div>
                        </div>


                    </div>
                </div>

            </section>
        </div>

    )
}

export default ToCanada