import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import moment from 'moment';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import { CButton, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
// import '../assets/css/userDetails.css'




export default function PostsInfo() {

  // const navigate = useNavigate();
  const [postInfoList, setPostInfoList] = useState([]);
  const axiosPrivate = useAxiosPrivate();


  ////////////

  const [updateText] = useState("Update");
  const [saveText,] = useState("Save");
  const [buttonText, setButtonText] = useState(saveText);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [postInfoId, setPostInfoId] = useState("");
  const [src, setSrc] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [validated, setValidated] = useState(false);

  


  async function view(id, src, position, status) {
    window.open("#/news-details/"+id);
  }
  function editPostInfo(id, src, position, status) {
    setButtonText(updateText);
    setIsUpdate(true);
    setPostInfoId(id);
    setSrc(src);
    setPosition(position);
    setStatus(status);
  }

  function deleteDocumentInfo(id, src) {

    const formData = {
      id: id
    }

    confirmAlert({
      title: 'MyGration Hub', message: <div><p>Are you sure? You want to delete Post</p><iframe title="post" src={src} width="100%" height="auto" style={{ border: "none", overflow: "hidden" }} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>,
      buttons: [{
        label: 'Yes',
        onClick: () => apicall(formData, "deletePostInfo", "Post deleted successfully")
      },
      {
        label: 'No',
      }]
    });
  }

  function clear() {
    setIsUpdate(false);
    setButtonText(saveText);
    setSrc("");
    setPosition("");
    setStatus("Initiated")
    setValidated(false);
  }




  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      const formData = {
        id: postInfoId,
        position: position,
        status: status,
        src: src
      }

      let message = isUpdate ? "Are you sure? You want to update Post information?" : "Are you sure? You want to save Post information";

      confirmAlert({
        title: 'MyGration Hub', message: message,
        buttons: [{
          label: 'Yes',
          onClick: () => isUpdate ?  apicall(formData, "updatePostInfo", "Post information updated successfully"): apicall(formData, "savePostInfo", "Post information saved successfully")
        },
        {
          label: 'No',
        }]
      });

    }

    setValidated(true);
    event.preventDefault();


  }

  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }

    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        if (message !== "") {
          confirmAlert({ title: 'MyGration Hub', message: message, buttons: [{ label: 'OK', }] });
        }
        getPostInfoList();
        clear();
      }
      else {
        confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK', }] });
      }

    }, fail => {
      confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
    });
  }

  const getPostInfoList = async () => {
    try {
      const path = "/getAllPostInfoListForAdmin";
      await axiosPrivate.get(path).then((response) => {
        if (response.status === 200) {
          response?.data && setPostInfoList(response?.data);
        }
      });
    } catch (err) {
      confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
    }
  }



  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getPostInfoListOnLoad = async () => {
      try {
        const path = `/getAllPostInfoListForAdmin`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });
        isMounted && response?.data && setPostInfoList(response?.data);
      } catch (err) {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      }
    }

    getPostInfoListOnLoad();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);


  return (
    <section style={{ backgroundColor: '#fff' }}>
      <h2 className="padding10">Post Information </h2>
      <MDBContainer className="">

        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>

                <CContainer>

                  <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >

                    
                    <CCol md={6}>
                      {/* <CFormLabel htmlFor="validationPosition">Position <span className='red'>*</span></CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">Position <span className='red'>*</span></CInputGroupText>
                        <CFormInput type="number" aria-describedby="validationPosition" id="validationPosition" value={position} onChange={(event) => { setPosition(event.target.value); }} required />
                      </CInputGroup>
                    </CCol>
                    <CCol md={6}>
                      {/* <CFormLabel htmlFor="validationStatus">Status <span className='red'>*</span></CFormLabel> */}
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">Status <span className='red'>*</span></CInputGroupText>
                        <CFormSelect id="validationStatus"  value={status} onChange={(event) => { setStatus(event.target.value); }} required >
                          <option value="Initiated">Initiated</option>
                          <option value="Published">Published</option>
                          <option value="Unpublished">Unpublished</option>
                        </CFormSelect>
                      </CInputGroup>
                    </CCol>

                    <CCol md={12}>
                      <CInputGroup className="has-validation">
                        <CInputGroupText className="border-right-redious-zero width-138px">Facebook Link<span className='red'>*</span></CInputGroupText>
                        <CFormInput type="text" aria-describedby="validationHeadline" id="validationHeadline" value={src} onChange={(event) => { setSrc(event.target.value); }} required />
                      </CInputGroup>
                    </CCol>

                    <CCol xs={12}>
                      {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <><CButton color="primary" type="submit"> {buttonText} </CButton> {isUpdate ? <CButton onClick={clear} color="info" > Clear </CButton> : ''}</>}
                    </CCol>

                  </CForm>
                </CContainer>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>


        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <CCol md={12} className='font-header text-primary'>
                  Post Infomation List
                </CCol>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Post</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Position</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Create Date</CTableHeaderCell>
                      {/* <CTableHeaderCell scope="col" >View</CTableHeaderCell> */}
                      <CTableHeaderCell scope="col" >Edit</CTableHeaderCell>
                      <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {postInfoList?.map((row, i) => (
                      <CTableRow v-for="item in tableItems" key={i}>
                        <CTableDataCell>
                          <div>{i + 1}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <iframe title={i} src={row?.src} width="100%" height="auto" style={{ border: "none", overflow: "hidden" }} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </CTableDataCell>
                        <CTableDataCell>
                          {row?.status}
                        </CTableDataCell>
                        <CTableDataCell>
                          {row?.position}
                        </CTableDataCell>
                        <CTableDataCell>
                          {row?.dateCreated ? moment(row?.dateCreated).format("DD-MM-YYYY") : ''}
                        </CTableDataCell>
                        {/* <CTableDataCell>
                          <CButton color="primary" onClick={() => { view(row?.id, row?.src,row?.position,row?.status) }} ><i className="fa fa-eye"></i></CButton>
                        </CTableDataCell> */}
                        <CTableDataCell>
                          <CButton color="success" onClick={() => { editPostInfo(row?.id, row?.src,row?.position,row?.status) }} ><i className="fa fa-edit"></i></CButton>
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="danger" onClick={() => { deleteDocumentInfo(row?.id, row?.src) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>


      </MDBContainer>
    </section>
  );
}