import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-usa.png';
import Meta from './Meta';

const Disclaimer = () => {
    const title = "The United States government offers three student visa types including F, J, and M.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        {/* <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div> */}

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items text-align-center'>

                                <div className='col-lg-12 items'>
                                    <h3>Terms of Use</h3>
                                    <p>
                                        Mygration Hub is a platform designed to streamline the visa application process, but it&apos;s important to understand that we are not a law firm and do not provide any kind of legal services. Our software serves as a tool to guide individuals through the complexities of the application process before their submissions undergo review by qualified immigration lawyers.
                                    </p>
                                    <p>
                                        While Mygration Hub facilitates the connection between Users and immigration consultant, it does not offer any legal advice or recommendations to its customers. Instead, we support the users to prepare and submit their immigration files properly under careful supervision of professionals. It&apos;s crucial to note that Mygration Hub does not establish attorney-client relationships through its platform and maintains no involvement in the communications or agreements between Users and lawyers.

                                    </p>
                                    <p>
                                        Additionally, any communication occurring via social media, email, or chat, whether on or off the Mygration Hub platform, should not be interpreted as legal advice. Such interactions are intended for informational purposes only, and Mygration Hub bears no responsibility for actions taken by Users based on this information.
                                    </p>

                                    <p>
                                        Furthermore, any legal documents generated by Mygration Hub or its legal representatives remain the intellectual property of Mygration Hub. Without explicit consent and written permission, third parties are prohibited from copying, generating, or using such correspondence to support their endeavors.
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>

    )
}

export default Disclaimer