import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-australia.png';
import Meta from './Meta';

const StadyAustralia = () => {
    const title = "The process of obtaining student visa for Australia involves several steps. Below is a detailed outline of the typical process";
    const description = title;
    return (
        <div>
            
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        The process of obtaining student visa for Australia involves several steps. Below is a detailed outline of the typical process:
                                    </p>

                                    <h3>1. Choose a Course and Institution:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Research and select a course and institution in Australia that suits your academic and career goals.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>2. Receive Offer of Admission: </h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Apply to the chosen institution and receive an offer of admission. The institution will provide you with an electronic Confirmation of Enrollment (CoE) once you accept the offer.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>3. Meet English Language Proficiency Requirements:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Provide evidence of English language proficiency through recognized tests such as IELTS, TOEFL, or PTE Academic.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>4. Financial Capacity:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Demonstrate sufficient financial capacity to cover tuition fees, living expenses, and other costs. This may include providing bank statements, sponsorship letters, or evidence of a scholarship.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h3>5. Health Insurance:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Purchase Overseas Student Health Cover (OSHC) to cover the duration of your stay in Australia. You will need to provide evidence of OSHC when applying for your visa.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h3>6. Create an ImmiAccount:</h3>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Create an account on the Department of Home Affairs online portal, ImmiAccount, to start the application process.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <h3>7. Gather Required Documents:</h3>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <p>
                                            Prepare the necessary documents, including:
                                        </p>
                                        <div className='padding-left-20px'>
                                            
                                            <ul>
                                                <li className='list-style' >
                                                    Passport
                                                </li>
                                                <li className='list-style' >
                                                    CoE from the institution
                                                </li>
                                                <li className='list-style' >
                                                    Evidence of English language proficiency
                                                </li>
                                                <li className='list-style' >
                                                    Proof of financial capacity
                                                </li>
                                                <li className='list-style' >
                                                    OSHC policy details
                                                </li>
                                                <li className='list-style' >
                                                    Academic transcripts and qualifications
                                                </li>
                                                <li className='list-style' >
                                                    Statement of purpose
                                                </li>
                                                <li className='list-style' >
                                                    Passport-sized photographs
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <h3>8. Visa Application:</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                Submit the student visa application online through ImmiAccount. You will need to pay the visa application fee.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h3>9. Biometrics:</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                Depending on your nationality, you may be required to provide biometrics. This is typically done at an Australian Visa Application Centre (AVAC) or a contracted service provider.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h3>10. Health Examination:</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                In some cases, you may need to undergo a health examination. You will receive instructions on how to complete this requirement.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h3>11. Visa Decision:</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                The processing time for visa applications varies. Once a decision is made, you will be notified of the outcome.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3>12. Arrival in Australia:</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                Upon approval, you can travel to Australia. You must arrive before the commencement of your course, as indicated on your CoE.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h3>13. Attend a Visa Compliance Session (if required):</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                Some institutions may require international students to attend a visa compliance session upon arrival.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h3>14. Attend Orientation:</h3>
                                <div className='padding-left-20px padding-top-20px'>

                                    <div className='padding-left-20px'>
                                        <ul>
                                            <li className='list-style' >
                                                Attend the orientation sessions provided by your institution to familiarize yourself with the campus, services, and important information.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>
                                    It&apos;s important to note that immigration policies and procedures may change, so it&apos;s advisable to check the official website of the Department of Home Affairs in Australia for the most up-to-date information. Additionally, you can contact our registered immigration Lawyer for specific guidance related to your circumstances.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default StadyAustralia