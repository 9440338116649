import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { CAvatar,  CButton, CTable, CTableBody, CTableDataCell, CTableHead,CTableHeaderCell, CTableRow } from '@coreui/react'
import CIcon from '@coreui/icons-react';
import {cilPeople} from '@coreui/icons';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

const StaffList = () => {
    const [users, setUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/getAllStaffList', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setUsers(response.data);
            } catch (err) {
                //console.error(err);
                console.log(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    function viewHandleClick(value) {
        localStorage.setItem('agent-user-id', value);
        localStorage.setItem('page-name', "Staff");
        navigate('/home/agent-details', { state: { id: value ,role:"Staff"}, replace: true });
    }

    function viewHandleEditClick(value) {
        localStorage.setItem('back-page', '/home/agent-list');
        localStorage.setItem('page-name', "Staff");
        navigate('/home/edit-agent/'+value, { state: { id: value ,backPage: '/home/agent-list' }, replace: true });
    }

    function deleteUserInfo(id, userName, fullName, index) {
        confirmAlert({
          title: 'MyGration Hub', message: "Are you sure? You want to delete staff - " + fullName,
          buttons: [{
            label: 'Yes',
            onClick: () => deletePartnew(id, userName,index)
          },
          {
            label: 'No',
          }]
        });
      }

      async function deletePartnew(id, userName,index) {
        try {
          const formData = {
            id: id,
            userName: userName
          }
    
          const headers = {
            'Content-Type': 'application/json'
          }
    
          await axiosPrivate.post("/deleteUserInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
            if (res.status === 200) {
              confirmAlert({ title: 'MyGration Hub', message: "Staff deleted successfully", buttons: [{ label: 'OK' }] });
              setUsers(users.filter((v, i) => i !== index));
            } else {
              confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
            }
          }, fail => {
            confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
          });
        }
        catch (err) {
        }
    
      }

    return (
        <article>
            <h2>Staff List</h2>
            {/* {console.log(users?.length)} */}
            {users?.length
                ? (
                    <div>
                        
                        <CTable align="middle" className="mb-0 border" hover responsive>
                            <CTableHead color="light">
                                <CTableRow>
                                    <CTableHeaderCell className="text-center">
                                        <CIcon icon={cilPeople} />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Country of Residance</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {users?.map((user, i) => (
                                    <CTableRow v-for="item in tableItems" key={i}>
                                        <CTableDataCell className="text-center">

                                            {(user?.pictureCode!=="" && user?.pictureCode!== null)?
                                            <CAvatar size="md" className="profile-picture-css" src={user?.pictureCode} status={user?.applyStatus==='3'?'danger':'success'} />
                                            :
                                            <CAvatar size="md"classnames="profile-picture-css" src={require(`../assets/images/avatars/avater.png`)} status={user?.applyStatus==='3'?'danger':'success'} />
                                            }


                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{user?.fullName}</div>
                                            <div className="small text-medium-emphasis">
                                                <span>{user?.applyStatus=== 3 ? 'Pending' : 'Approved'}</span> | Create Date:{moment(user?.dateCreated).format("DD-MM-YYYY")}                                                
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {user?.userResidence?.map((row) => row?.country?.name)}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="clearfix">
                                                {user?.mobile}
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {user?.officeEmail}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <CButton  color="info"  onClick={() => { viewHandleClick(user?.id) }}><i className="fa fa-eye"></i></CButton>&nbsp;
                                             <CButton color="success" onClick={() => { viewHandleEditClick(user?.id) }} ><i className="fa fa-edit"></i></CButton> &nbsp;
                                            <CButton  color="danger"   onClick={() => { deleteUserInfo(user?.id, user?.userName,user?.fullName, i) }} ><i className="fa fa-trash" aria-hidden="true"></i></CButton>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>

                        
                    </div>
                ) : <p>No users to display</p>
            }
        </article>
    )
}
export default StaffList;
