import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { confirmAlert } from 'react-confirm-alert';
import { Vortex } from 'react-loader-spinner'
import axios from '../api/axios';
import { CButton, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CFormSelect, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CFormLabel } from '@coreui/react'
import '../assets/css/userDetails.css'


export default function MyDocuments() {
  const [userDetails, setUsersDetails] = useState();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [documents, setDocuments] = useState([]);
  const [documentCheckList, setDocumentCheckList] = useState([]);


  ////////////

  const [updateText] = useState("Update");
  const [saveText,] = useState("Save");
  const [buttonText, setButtonText] = useState(saveText);
  const [isUpdate, setIsUpdate] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [isSpenierOpen, setIsSpenierOpen] = useState(false);
  const [documentCategoryId, setDocumentCategoryId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [displayName, setDisplayName] = useState(false);
  const [e, setEvent] = useState("");
  const [validated, setValidated] = useState(false);
  const [documentCheckListForDd, setDocumentCheckListForDd] = useState([]);

  const onFileChangeHandler = async (e) => {
    setEvent(e);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (e.target.files !== "") {

      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(e.target.files[0]?.type)) {
        confirmAlert({ title: 'MyGration Hub', message: "Only pdf supported", buttons: [{ label: 'OK' }] });
        e.target.value = null;
        setIsSpenierOpen(false);
        return;
      }

      await axios.post("/uploadFile", formData).then((res) => {
        if (res.status === 200) {
          setDocumentName(res?.data?.fileName);
          setIsSpenierOpen(false);
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
          setIsSpenierOpen(false);
        }

      }, fail => {
        console.error(fail);
        confirmAlert({ title: 'MyGration Hub', message: "Fail to Upload file, please try again or contact connect@mygrationhub.com", buttons: [{ label: 'OK' }] });
        setIsSpenierOpen(false);
      });
    } else {
      console.log("empty")
    }
    //console.log(e.target.files);
  };

  async function uploadDocumentInfo() {
    try {
      const formData = {
        userInfoId: 0,
        documentCategoryId: documentCategoryId,
        documentName: documentName,
        displayName: displayName,
        userDocumentCheckListId: documentCategoryId,
        status:"Uploaded"
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      console.log(JSON.stringify(formData));
      await axiosPrivate.post("/uploadDocumentInfo", JSON.stringify(formData), { headers: headers }).then((res) => {

        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Document saved successfully", buttons: [{ label: 'OK' }] });
          e.target.value = null;
          setValidated(false);
          getDocumentList();
          getDocumentCheckList();
            getDocumentCheckListForDd();
          clear();

        } else if (res.status === 404) {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
        else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }

      }, fail => {
        console.error(fail);
      });


    }

    catch (err) {
      alert(err);
    }

  }

  async function updateDocumentInfo() {

    if (documentId === "") {
      confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
    } else {

      try {
        const formData = {
          id: documentId,
          userInfoId: 0,
          documentCategoryId: documentCategoryId,
          documentName: documentName,
          displayName: displayName,
        }

        const headers = {
          'Content-Type': 'application/json'
        }
        await axiosPrivate.post("/updateDocumentInfo", JSON.stringify(formData), { headers: headers }).then((res) => {

          if (res.status === 200) {
            confirmAlert({ title: 'MyGration Hub', message: "Document updated successfully", buttons: [{ label: 'OK' }] });
            e.target.value = null;
            setValidated(false);
            getDocumentList();
            clear();
          }
          else {
            confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
          }

        }, fail => {
          console.error(fail);
          setIsSpenierOpen(false);
        });
      }

      catch (err) {
        setIsSpenierOpen(false);
      }

    }

  }



  function deleteDocumentInfo(key, fileName, displayName) {
    confirmAlert({
      title: 'MyGration Hub', message: "Are you sure? You want to delete document- " + displayName,
      buttons: [{
        label: 'Yes',
        onClick: () => deleteDocument(key, fileName)
      },
      {
        label: 'No',
      }]
    });
  }


  async function deleteDocument(key, fileName) {
    try {
      const formData = {
        key: key,
        fileName: fileName
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/deleteDocumentInfo", JSON.stringify(formData), { headers: headers }).then((res) => {
        if (res.status === 200) {
          confirmAlert({ title: 'MyGration Hub', message: "Document deleted successfully", buttons: [{ label: 'OK' }] });
          getDocumentList();
          getDocumentCheckList();
          getDocumentCheckListForDd();
        } else {
          confirmAlert({ title: 'MyGration Hub', message: "Refresh this page and try again", buttons: [{ label: 'OK' }] });
        }
      }, fail => {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      });
    }
    catch (err) {
    }

  }


  function editDocuments(id, fileName, documentCategoryId) {
    setButtonText(updateText);
    setIsUpdate(true);
    setDocumentId(id);
    setDocumentCategoryId(documentCategoryId);
  }

  function clear() {
    setIsUpdate(false);
    setButtonText(saveText);
    setDocumentId("");
    setDocumentCategoryId("");
    setDisplayName("");
  }




  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      let message = isUpdate ? "Are you sure? You want to do update document?" : "Are you sure? You want to do save document?";

      confirmAlert({
        title: 'MyGration Hub', message: message,
        buttons: [{
          label: 'Yes',
          onClick: () => isUpdate ? updateDocumentInfo() : uploadDocumentInfo()
        },
        {
          label: 'No',
        }]
      });

    }

    setValidated(true);
    event.preventDefault();


  }


  ///////////////


  const getDocumentList = async () => {

    try {

      const formData = {
        id: userDetails?.id,
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/getUserDocumentList", JSON.stringify(formData), { headers: headers }).then((response) => {
        if (response.status === 200) {
          setDocuments(response?.data);

        } else {
        }
      }, fail => {
      });

    } catch (err) {

    }
  }

  const getDocumentCheckList = async () => {

    try {

      const formData = {
        id: 0,
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axiosPrivate.post("/getDocumentCheckListAfterSave", JSON.stringify(formData), { headers: headers }).then((response) => {
        if (response.status === 200) {
          setDocumentCheckList(response?.data);

        } else {
        }
      }, fail => {
      });

    } catch (err) {

    }
  }

  const getDocumentCheckListForDd = async () => {
    try {
      await axiosPrivate.get("/getDocumentCheckListForUserDd").then((response) => {
        if (response.status === 200) {
          setDocumentCheckListForDd(response?.data);

        } else {
        }
      }, fail => {
      });

    } catch (err) {

    }
  }


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const detailsUserId = localStorage.getItem('details-user-id');
    const getUserDetails = async () => {
      try {
        const path = `/getUserInfo/${location?.state?.id ? location.state.id : detailsUserId}`;
        const response = await axiosPrivate.get(path, {
          signal: controller.signal
        });
        isMounted && setUsersDetails(response?.data);
      } catch (err) {
        confirmAlert({ title: 'MyGration Hub', message: "Re-Login and try again", buttons: [{ label: 'OK' }] });
      }
    }


    const getDocumentListOnload = async () => {

      try {

        const formData = {
          id: detailsUserId,
        }
        const headers = {
          'Content-Type': 'application/json'
        }

        await axiosPrivate.post("/getUserDocumentList", JSON.stringify(formData), { headers: headers }).then((response) => {
          if (response.status === 200) {
            setDocuments(response?.data);

          } else {
          }
        }, fail => {
        });

      } catch (err) {

      }
    }

    const getDocumentCheckListOnLoad = async () => {

      try {

        const formData = {
          id: 0,
        }
        const headers = {
          'Content-Type': 'application/json'
        }
  
        await axiosPrivate.post("/getDocumentCheckListAfterSave", JSON.stringify(formData), { headers: headers }).then((response) => {
          if (response.status === 200) {
            setDocumentCheckList(response?.data);
  
          } else {
          }
        }, fail => {
        });
  
      } catch (err) {
  
      }
    }

    const getDocumentCheckListForDdOnLoad = async () => {

      try {
        await axiosPrivate.get("/getDocumentCheckListForUserDd").then((response) => {
          if (response.status === 200) {
            setDocumentCheckListForDd(response?.data);
  
          } else {
          }
        }, fail => {
        });
  
      } catch (err) {
  
      }
    }

    //getUserDetails();
    getDocumentListOnload();
    getDocumentCheckListOnLoad();
    getDocumentCheckListForDdOnLoad();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);




  function downnloadDocu(userId, fileName) {
    downloadFile(userId, fileName);
  }

  async function downloadFile(key, fileName) {
    const formData = {
      key: key,
      fileName: fileName,
    }
    const headers = {
      'Content-Type': 'application/json'
    }

    await axiosPrivate.post("/downloadFile", JSON.stringify(formData), { headers: headers }).then((response) => {
      if (response.status === 200) {
        const linkSource = response?.data?.code;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
      }
    }, fail => { });

  }


  function setDocumentCategory(val) {
    setDocumentCategoryId(val);
    let sel = document.getElementById("validationCustomCategory");
    setDisplayName(sel.options[sel.selectedIndex].text);
  }


  return (
    <section style={{ backgroundColor: '#fff' }}>
      <h2 className="padding10">My Documents  </h2>
      <MDBContainer className="">

        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>

                <CContainer>

                  <CForm className="row g-3 needs-validation update-main-form" noValidate validated={validated} onSubmit={handleSubmit} >
                    <CCol md={12} className='font-header text-primary'>
                      Document Upload Section
                    </CCol>

                    <CCol md={12}>
                      <CInputGroup className="has-validation">
                        <CInputGroupText> Document Category <span className='red'>*</span></CInputGroupText>
                        <CFormSelect id="validationCustomCategory" feedbackValid="Looks good" feedbackInvalid="Required!" value={documentCategoryId} onChange={(event) => { setDocumentCategory(event.target.value); }} required >
                        <option value="">Select</option>
                          {documentCheckListForDd?.map((docu, i)=> (
                            <option key={i} value={docu.id}>{docu.name}</option>
                          ))}
                          
                        </CFormSelect>
                      </CInputGroup>
                    </CCol>

                    <CCol md={12}>
                      Upload Document (PDF Only) : <span className='red'>*</span> <br></br>
                      To upload document click on the <b>Choose File</b> or drag and drop in gray segment
                    </CCol>

                    <CCol md={12}>
                      <CInputGroup className="has-validation">
                        <CFormInput accept="application/pdf" className="fileUpload" type="file" size="lg" id="formFileLg" onChange={onFileChangeHandler} required />
                      </CInputGroup>

                    </CCol>

                    <CCol xs={12}>
                      {isSpenierOpen ? <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex> : <><CButton color="primary" type="submit"> {buttonText} </CButton> {isUpdate ? <CButton onClick={clear} color="info" > Clear </CButton> : ''}</>}
                    </CCol>

                  </CForm>
                </CContainer>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>


        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <CCol md={12} className='font-header text-primary'>
                  Your Docments
                </CCol>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Document Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Upload Date</CTableHeaderCell>
                      {/* <CTableHeaderCell scope="col" >Edit</CTableHeaderCell> */}
                      <CTableHeaderCell scope="col" >Delete</CTableHeaderCell>
                      <CTableHeaderCell scope="col" >Download</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {documents?.map((docu, i) => (
                      <CTableRow v-for="item in tableItems" key={i}>
                        <CTableDataCell>
                          <div>{i + 1}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{docu?.displayName}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div className="clearfix">
                            {docu?.dateCreated ? moment(docu?.dateCreated).format("DD-MM-YYYY") : ''}
                          </div>
                        </CTableDataCell>
                        {/* <CTableDataCell>
                          {docu?.userDocumentCheckListId > 0 ?
                            <CButton color="success" onClick={() => { editDocuments(docu?.id, docu?.documentName, docu?.documentCategoryId) }} >Edit</CButton>
                            : "N/A"}
                        </CTableDataCell> */}
                        <CTableDataCell>
                          {docu?.userDocumentCheckListId > 0 ?
                            <CButton color="success" onClick={() => { deleteDocumentInfo(docu?.id, docu?.documentName,docu?.displayName) }} >Delete</CButton>
                            : "N/A"}
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="success" onClick={() => { downnloadDocu(docu?.userInfoId, docu?.documentName) }} >download</CButton>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <CCol md={12} className='font-header text-primary'></CCol>


        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <CCol md={12} className='font-header text-primary'>
                  Your Document Check List
                </CCol>

                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell scope="col">SL</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Document Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Document Of</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {documentCheckList?.map((docu, i) => (
                      <CTableRow v-for="item in tableItems" key={i}>
                        <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                          {i + 1}
                        </CTableDataCell>
                        <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                          {docu?.name}
                        </CTableDataCell>
                        <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                          {docu?.docType===1?'Principle Applicant':docu?.docType===2?'Dependant Spouse':docu?.docType===3?'Dependant Child':'N/A'}
                        </CTableDataCell>
                        <CTableDataCell className={docu?.status==='Pending'?'red':docu?.status==='Uploaded'?'green':''}>
                          {docu?.status}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>


      </MDBContainer>
    </section>
  );
}