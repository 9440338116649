import { React, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { useParams } from "react-router-dom"
import axios from '../api/axios';
import { Link} from 'react-router-dom'
import './../assets/css/custom.css'
import moment from 'moment';


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import Meta from './Meta';


const NewsDetails = () => {


    const { key } = useParams();

    // const url = "https://mygrationhub.com";
    // const title = "MyGrationHub | A reliable website for every student, traveler, and immigrant. ";
    const keywords = "Migration hub, migrationhub, MygrationHub, MyGration Hub, My Gration Hub";
    // const description = "Our team members at Mygration Hub are more than just immigration professionals - they are your trusted allies in navigating the intricate paths of immigration. With roots in Canada, Bangladesh, Australia, and the US, our team consists of same-country citizens who intimately understand the challenges and opportunities inherent in the immigration process.";
    // const image = url + logo;

    const [newsInfoList, setNewsInfoList] = useState([]);
    const [newsItems] = useState([]);
    const [newsDetails, setNewsDetails] = useState(null);
    const [detailsImage, setDetailsImage] = useState("");

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getNewsInfoListOnLoad = async () => {
            try {
                const path = "/getAllNewsInfoListToDisplay";
                const response = await axios.get(path, {
                    signal: controller.signal
                });
                isMounted && setNewsInfoList(response?.data);

            } catch (err) {
            }
        }

        const getNewsDetailsOnload = async () => {
            try {
                const path = "/getNewsInfo/" + key;
                const response = await axios.get(path, {
                    signal: controller.signal
                });
                isMounted && setNewsDetails(response?.data);
                getNewsDetailsImageOnload();
            } catch (err) {
            }
        }

        const getNewsDetailsImageOnload = async () => {
            try {
                const path = "/getNewsInfoImage/" + key;
                const response = await axios.get(path, {
                    signal: controller.signal
                });
                setDetailsImage(response?.data?.detailsImage);

            } catch (err) {
            }
        }


        getNewsDetailsOnload();
        getNewsInfoListOnLoad();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [key]);

    let news = {
        options: {
            loop: true,
            autoplay: true,
            margin: 30,
            nav: true,
            responsive: {
                0: {
                    items: 2,
                },
                400: {
                    items: 2,
                },
                708: {
                    items: 3,
                },
                1100: {
                    items: 4,
                },
                1250: {
                    items: 5,
                },
            },
            lazyLoad: true,
        },

        items: newsItems,
    };


    return (
        <div>
            {/* <Meta title={newsDetails?.headline} keywords={newsDetails?.headline} description={newsDetails?.details?.substring(0, 110)} type="" image={newsDetails?.imageCode} iwidth="" iheight=""></Meta> */}

            <Helmet>

                <title>{newsDetails?.headline}</title>
                <meta name="title" content={newsDetails?.headline}></meta>

                <meta name="keywords" content={keywords}></meta>
                <meta name="description" content={newsDetails?.details?.substring(0, 110)}></meta>

                <meta property="og:title" content={newsDetails?.headline}></meta>
                
                <meta property="og:description" content={newsDetails?.details?.substring(0, 110)}></meta>
                <meta property="og:image" content={newsDetails?.imageCode}></meta>

                <meta name="twitter:title" content={newsDetails?.headline}></meta>
                
                <meta name="twitter:description" content={newsDetails?.details?.substring(0, 110)}></meta>
                <meta name="twitter:image" content={newsDetails?.imageCode}></meta>

            </Helmet>

            <section className="news padding-top-90px" id="news">
                <div className="container">

                    <div className='row items'>
                        <div className='col-lg-12 margin-top-20p '>
                            <h3 className='readable-color'>{newsDetails?.headline}</h3><br/>
                            <div><span className='classPublishedText'>Published: </span> <span className='classPublishedDate'>{newsDetails?.lastUpdated?moment(newsDetails?.lastUpdated).format("MMMM D, YYYY"):''}</span></div>
                        </div>
                    </div>

                    <div className="row">
                        
                            <div className="col-lg-8 margin-top-20p padding-righ-100px-lg">
                                <div className="items">
                                    <div className='text-align-justify '>
                                        <p id="newsDetailsContainer" className='newsDetailsContainer' dangerouslySetInnerHTML={{__html: newsDetails?.details}}>
                                        {/* <div dangerouslySetInnerHTML={{__html: html}}></div> */}
                                        </p>
                                    </div>
                                </div>

                                <div className="is-first-expanded padding-top-50px">
                                    {/* {detailsImage != null ? <img className='' alt='' src={detailsImage}></img> : ''} */}
                                    <img className='' alt='' src={detailsImage}></img>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="items">
                                    <div className='text-align-justify'>
                                        {/* <p id="newsDetailsContainer">
                                        </p> */}

                                    {newsInfoList?.map((news, i) => (

                                        <div className="row margin-top-20p" key={i}>
                                            <div className="col-lg-12">
                                                <div className='row padding-bottom-10px border-bottom-thin'>
                                                    
                                                    <div className="col-lg-8">
                                                        {/* <Link className='hover-under-line neaws-head' target="_blank" to={news?.newsLink === "" ? "/news-details/" + news?.id : news?.newsLink} ><p className='newsDetailsContainer'>{news?.headline}</p></Link> */}
                                                        <Link className='hover-under-line neaws-head' target={news?.newsLink === "" ? "": "_blank"} to={news?.newsLink === "" ? "/news-details/" + news?.id : news?.newsLink} ><p className='newsDetailsContainer'>{news?.headline}</p></Link>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <img src={news?.image} alt="" className='newsHeadImage'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))};
                                    </div>
                                </div>
                            </div>
                        
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="col-lg-12">
                                {newsInfoList.length > 0 ?
                                    <OwlCarousel className='owl-service-item' {...news.options}>
                                        {/* {news.items} */}
                                        {newsInfoList?.map((news, i) => (

                                            <div className="item" key={i}>
                                                <div className="item-content">
                                                    <img src={news?.image} alt="" />
                                                    <Link className='hover-under-line neaws-head' target={news?.newsLink === "" ? "": "_blank"} to={news?.newsLink === "" ? "/news-details/" + news?.id : news?.newsLink} ><p>{news?.headline}</p></Link>
                                                </div>
                                            </div>

                                        ))};
                                    </OwlCarousel>
                                    : ''}
                            </div>
                        </div>
                    </div>

                </div>
            </section>


        </div>

    )
}

export default NewsDetails