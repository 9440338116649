import React, { useState, useEffect,useRef } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { cilArrowCircleLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CAvatar } from '@coreui/react'
import '../assets/css/chatUi.css'
import {cilUserPlus,cilPeople} from '@coreui/icons'
import { CForm, CCol,  CButton,  CModalFooter, CModal, CModalBody, CModalTitle, CModalHeader,CInputGroup, CInputGroupText, CFormInput} from '@coreui/react'
import { Vortex } from 'react-loader-spinner'


const Chats = () => {

  const axiosPrivate = useAxiosPrivate();
  const [sender] = useState('0');
  const [selfName, setSelfName] = useState("");
  const [selfId, setSelfId] = useState("");
  const [otherName, setOtherName] = useState("");
  const [otherPictureCode, setOtherPictureCode] = useState("");
  // const [type, setGroupPicture] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState([]);
  const [receiver, setReceiver] = useState("");
  const [id,setId] = useState(0);
  const [type,setType] = useState(0);
  const [name,setName] = useState(0);
  const [groupCount,setGroupCount] = useState(0);
  

  const [isApiRunning, setIsApiRunning] = useState(false);
  const messageRef = useRef();
  // Data Section

  //modal
  const [isModalSpenierOpen, setIsModalSpenierOpen] = useState(false);
  const [visible, setVisible] = useState(false)
  const [groupName, setGroupName] = useState("");
  const [groupNameTwo, setGroupNameTwo] = useState("");
  const [role, setRole] = useState(0);
  const [groupUserList, setGroupUserList] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false)
  //modal 2
  const [visibleTwo, setVisibleTwo] = useState(false)
  const [messageHistory, setMessageHistory] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [groupMemberIdList, setGroupMemberIdList] = useState([]);

  async function apicall(formData, apiName, message) {

    const headers = { 'Content-Type': 'application/json' }
    console.log(JSON.stringify(formData));

    await axiosPrivate.post("/" + apiName, JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {

        if (apiName === 'saveMessage') {

          appendMethodSelf(res?.data);
          //pushRow(res?.data);
          setMessage('');

        } else if (apiName === 'getUnreadMessage') {
          res?.data?.map((row, i) => appendMethodOthers(row));
          setIsApiRunning(false);
        }

        else if (apiName === 'getMessageHistory') {
          if(res?.data!=null){
            setMessageHistory(res?.data);
          }
          /* if (res?.data?.length > 0) {
            console.log(res?.data[0]?.id);
          } */

          let chatHistory = document.getElementById("chat-history-s");
          messageHistory?.map((row, i) =>
            chatHistory.scrollTo({ top: chatHistory.scrollHeight, behavior: "smooth" })
          );

        }
        else if (apiName === 'getChatListByRole') {
          setChatList(res?.data);
        }
        else if (apiName === 'saveGroupMemberInfo') {
          setIsModalSpenierOpen(false);
          setChatList(oldArray => [res?.data,...chatList] );
          loadActiveChat(res?.data,0);
          closeModal();
          // console.log(res?.data);
          
        }else if (apiName === 'getGroupMemberInfo') {
          setGroupMemberList(res?.data);
          if (message === "add") {
            let ids = [];
            for (const row of res?.data) {
              ids.push(row?.sender_id);
            }
            setGroupMemberIdList(ids);
            openModalEdit();
          }else if(message==="details"){
            openModalTwo();
          }
        }
        else if (apiName === 'deleteGroupMember' && message==='self') {
          window.location.reload();
        }
        else if (apiName === 'deleteGroupMember') {
          setGroupMemberList(groupMemberList.filter((v, i) => v.members !== formData.id));
          //getGroupDetails();
          setGroupCount(groupCount-1);
        }else if(apiName === 'deleteGroupInfo'){
          window.location.reload();

        }
        else if(apiName === 'updateGroupMemberInfo'){
          setIsModalSpenierOpen(false);
          let l = groupUserList.length;
          if(l>0){
            setGroupCount(groupCount+l);
          }
          setName(groupName);
          setOtherName(groupName);
          closeModalEdit();

        }


      }
      else {
        setIsApiRunning(false);
      }

    }, fail => {
      setIsApiRunning(false);
    });
  }


  function appendMethodSelf(data) {

    // let container = document.getElementById("chatContainer");

    const br = document.createElement("br");

    const rootDiv = document.createElement("div");
    rootDiv.className = 'col-lg-12 col-md-12 col-sm-12 align-right myClass clear-both false_add';

    const subDiv1 = document.createElement("div");
    subDiv1.className = 'padding-bottom-7px';

    const subDiv1Span = document.createElement("span");
    subDiv1Span.classList.add('message-data-name');
    subDiv1Span.innerHTML = selfName;
    subDiv1.append(subDiv1Span);
    subDiv1.append(br);

    const subDiv2Span = document.createElement("span");
    subDiv2Span.classList.add('message-data-time');
    subDiv2Span.innerHTML = data?.dateCreated ? moment(data.dateCreated).format("hh:mm A MMMM DD, YYYY") : '';
    subDiv1.append(subDiv2Span);

    rootDiv.append(subDiv1);

    const subDiv2 = document.createElement("div");
    subDiv2.className = 'message my-message float-right';
    subDiv2.innerHTML = data?.message;
    rootDiv.append(subDiv2);

    // container.append(rootDiv);
    messageRef.current.append(rootDiv);

    let chatHistory = document.getElementById("chat-history-s");
    chatHistory.scrollTo({ top: chatHistory.scrollHeight, behavior: "smooth" });

  }

  

  function appendMethodOthers(data) {

    //let container = document.getElementById("chatContainer");
    const br = document.createElement("br");


    const rootDiv = document.createElement("div");
    rootDiv.className = 'col-lg-12 col-md-12 col-sm-12 othersClass clear-both false_add';

    const subDiv1 = document.createElement("div");
    subDiv1.className = 'padding-bottom-7px';

    const subDiv1Span = document.createElement("span");
    subDiv1Span.classList.add('message-data-name');
    subDiv1Span.innerHTML = data?.senderName;

    subDiv1.append(subDiv1Span);
    subDiv1.append(br);

    const subDiv2Span = document.createElement("span");
    subDiv2Span.classList.add('message-data-time');
    subDiv2Span.innerHTML = data?.dateCreated ? moment(data.dateCreated).format("hh:mm A MMMM DD, YYYY") : '';

    subDiv1.append(subDiv2Span);


    rootDiv.append(subDiv1);

    const subDiv2 = document.createElement("div");
    subDiv2.className = 'message other-message';
    subDiv2.innerHTML = data?.message;
    rootDiv.append(subDiv2);

    //container.append(rootDiv);
    messageRef.current.append(rootDiv);

    let chatHistory = document.getElementById("chat-history-s");
    chatHistory.scrollTo({ top: chatHistory.scrollHeight, behavior: "smooth" });

  }

  /* function pushRow(row) {
    setMessageHistory(oldArray => [...messageHistory,row] );
  } */

  function sendMessage() {

    const formData = {
      message: message,
      sender: sender,
      receiver: receiver,
      type: type
    }
    if (Number(receiver) > 0 && message !== "") {
      apicall(formData, "saveMessage", message)
    }
  }

  const loadActiveChat = (row, i) => {
    document.querySelectorAll(".false_add").forEach(el => el.remove());

    setRole(row?.role)
    setType(row?.type);
    setId(row?.sender_id);
    setReceiver(row?.sender_id);

    setName(row?.sender_name);
    setGroupNameTwo(row?.sender_name);
    setOtherName(row?.sender_name);

    setGroupCount(row?.members);

    setOtherPictureCode(row?.code);
    if (row?.user_type === 1000) {
      setStatus("Admin")
    } else if (row?.user_type === 100) {
      setStatus("Partner Advisor")
    } else if (row?.user_type === 1) {
      setStatus("Client")
    }

    if(row?.type===2){
      setStatus("participants")
    }

    const formData = {
      id: row?.sender_id,
      type:row?.type
    }


    

    apicall(formData, "getMessageHistory", "");

    document.getElementById(`div_name_${i}`)?.classList?.remove("font-wait-bold");
    document.getElementById(`li_${i}`)?.classList?.remove("red");
    document.getElementById(`span_${i}`).innerHTML = "";


    /* let chatHistory = document.getElementById("chat-history-s");
    chatHistory.scrollTo({ top: chatHistory.scrollHeight, behavior: "smooth" }); */


    scrollBottomDelayCall(500);

    if (window.innerWidth <= 767) {
      document.getElementById("chatLeftPannel").classList.add("display-none");
    } else {
      document.getElementById("chatLeftPannel").classList.remove("display-none");
    }

  }

  async function enterController(e, t) {
    //e.preventDefault();
    if (e.key === 'Enter' && e.ctrlKey) {
      let messageContent = message + "\n";
      setMessage(messageContent);
      e.preventDefault();

    } else if (e.key === 'Enter') {
      sendMessage();
      e.preventDefault();
    } else {

    }

  }


  async function loadChatList() {
    //console.log('load list');
    const formData = {
      id: receiver
    }
    const headers = { 'Content-Type': 'application/json' }
    await axiosPrivate.post("/getChatListByRole", JSON.stringify(formData), { headers: headers }).then((res) => {
      if (res.status === 200) {
        setChatList(res?.data);
      }
    }, fail => {});
  }

  async function loadMessageList() {
    var receiverIntervalId = document.getElementById("receiverId")?.value;
    var typeId = document.getElementById("typeId")?.value;
    if (receiverIntervalId !== undefined) {
      if (Number(receiverIntervalId) > 0 && !isApiRunning) {
        setIsApiRunning(true);
        const formData = {
          id: receiverIntervalId,
          type:typeId
        }
        const headers = { 'Content-Type': 'application/json' }
        // console.log(JSON.stringify(formData));

        await axiosPrivate.post("/getUnreadMessage", JSON.stringify(formData), { headers: headers }).then((res) => {
          if (res.status === 200) {
            res?.data?.map((row, i) => appendMethodOthers(row));
            setIsApiRunning(false);
            /* if(res?.data?.length > 0){
              console.log(res?.data);
            } */
          }
          else {
            setIsApiRunning(false);
          }

        }, fail => {
          setIsApiRunning(false);
        });
      } else {
        setIsApiRunning(false);
      }
    }
  }

  function scrollBottomDelayCall(time) {
    setTimeout(scrollBottom, time);
  }

  function scrollBottom() {
    let chatHistory = document.getElementById("chat-history-s");
    chatHistory.scrollTo({ top: chatHistory.scrollHeight});
  }


  //modal 1 create
  function openModal(name, id, selectedId) {
    setValidated(false);
    setGroupName("");
    setGroupUserList([]);
    setVisible(true);
  }

  function closeModal() {
    setValidated(false);
    setGroupName("");
    setGroupUserList([]);
    setVisible(false);
  }

  const [validated, setValidated] = useState(false);
  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      if(groupUserList.length>0){

        const formData = {
          name: groupName,
          groupUserList:groupUserList
        }
        apicall(formData,"saveGroupMemberInfo","")
        
      }else{
        confirmAlert({ title: 'MyGration Hub', message: "Please select at least one member", buttons: [{ label: 'OK' }] });
      }
      
    }
    setValidated(true);
    event.preventDefault();
  }

  
  const addOrRemove = (id, name, status,code, i) => {

    if (groupUserList.includes(id)) {
      var inputdata = [...groupUserList]; // make a separate copy of the array
      var index = inputdata.indexOf(id)
      if (index !== -1) {
        inputdata.splice(index, 1);
        setGroupUserList(inputdata);
      }
    } else {
      
      if(id!==null && id!=="" && id!=='null' && id!=='undefine' && id>0){
        setGroupUserList(oldArray => [...groupUserList,id] );
      }
    }


    //alert(id+" "+ name);
    // groupUserList.forEach{

    // }

  //   for (const countryId of interestedCountry) {
  //     resultsIntCountry.push(
  //         IntCountry = {
  //             country: { id: countryId }
  //         })
  // }

  // value={countrys.filter(obj => interestedCountry.includes(obj.value))}
  }

  const getCode= (id) =>{
    if(groupUserList.includes(id)){
        // return '<span style={{fontSize:"20px"}}>&#x2705;</span>';
        return '<span class="font-size-check">&#x2705;</span>';
    }else{
      // return '<span style={{fontSize:"30px"}}>&#x25a2;</span>';
      return '<span class="font-size-uncheck">&#x25a2;</span>';
    }
  }

  //end modal

  //start modal 2 view

  function openModalTwo() {
    setVisibleTwo(true);
  }

  function closeModalTwo() {
    setVisibleTwo(false);
  }

  //modal 3 edit

  function openModalEdit() {
    setVisibleEdit(true);
    setGroupName(name);
    setGroupUserList([]);
    setValidatedEdit(false);
  }

  function closeModalEdit() {
    setVisibleEdit(false);
    setGroupName("");
    setGroupUserList([]);
    setValidatedEdit(false);
  }
  const [validatedEdit, setValidatedEdit] = useState(false);
  const handleFormSubmitEdit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      if(groupUserList.length > 0 || name !== groupName){

        const formData = {
          id: id,
          name:groupName,
          groupUserList:groupUserList
        }
        apicall(formData,"updateGroupMemberInfo","");
        console.log(formData);
        
      }else{
        
        
      }

      
      
    }
    setValidatedEdit(true);
    event.preventDefault();
  }

  
  const addOrRemoveEdit = (id, name, status,code, i) => {

    if (groupUserList.includes(id)) {
      var inputdata = [...groupUserList]; // make a separate copy of the array
      var index = inputdata.indexOf(id)
      if (index !== -1) {
        inputdata.splice(index, 1);
        setGroupUserList(inputdata);
      }
    } else {
      
      if(id!==null && id!=="" && id!=='null' && id!=='undefine' && id>0){
        setGroupUserList(oldArray => [...groupUserList,id] );
      }
    }


    
  }

  const getCodeEdit= (id) =>{

    
    if (groupMemberIdList.includes(id)) {
      return '';

    } else {
      if (groupUserList.includes(id)) {
        return '<span class="font-size-check">&#x2705;</span>';
      } else {
        return '<span class="font-size-uncheck">&#x25a2;</span>';
      }
    }

    
  }

  //end modal 2

  let counter = 1;
  useEffect(() => {

      /* const interval = setInterval(() => {
        if (Number(receiver) > 0 && !isApiRunning) {
          setIsApiRunning(true);
          const formData = {
            id: receiver
          }
          apicall(formData, "getUnreadMessage", "")
        } else {
          setIsApiRunning(false);
        }

      }, 4000); */

      const interval = setInterval(() => {
        if(counter%15===0){
          loadChatList();
        }
        loadMessageList();
        counter++;

      }, 4000);

      return () => clearInterval(interval);
    }, [])



    /* useEffect(() => {
      const chatInterval = setInterval(() => {
        try {
  
          const formData = {
            id: receiver
          }
          apicall(formData, "getChatListByRole", "")
  
        } catch (err) {
          confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
        }
  
  
      }, 60000);    
  
      return () => {
        return () => clearInterval(chatInterval);
      }
    }, []); */


    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();

      const getChatListOnLoad = async () => {
        try {
          const path = "/getChatListByRole";
          const response = await axiosPrivate.post(path, {
            signal: controller.signal
          });
          isMounted && response?.data && setChatList(response?.data);
          response?.data && setSelfId(response?.data[0].receiver_id);
          response?.data && setSelfName(response?.data[0].receiver_name);
          console.log(response?.status);

        } catch (err) {
          console.log(err);
          confirmAlert({ title: 'MyGration Hub', message: "Re-login and try again", buttons: [{ label: 'OK', }] });
        }
      }

      getChatListOnLoad();
      // document.getElementsByClassName('header-toggler')[0].click();
      document.getElementsByClassName('sidebar-fixed')[0].classList.add("hide");


      return () => {
        isMounted = false;
        controller.abort();
      }
    }, []);


    async function searchChatItems(e, t) {
      //let filter = document.getElementById("searchItemId").value;
      //console.log(filter);
      var input, filter, ul, li, a, i, txtValue;
      input = document.getElementById("searchItemId");
      filter = input.value.toUpperCase();
      ul = document.getElementById("searchItemUl");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        // a = li[i].getElementsByTagName("a")[0];
        a = li[i].getElementsByClassName("name")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    };

    async function searchChatItemsMcc(e, t) {
      var input, filter, ul, li, a, i, txtValue;
      input = document.getElementById("searchItemIdMcc");
      filter = input.value.toUpperCase();
      ul = document.getElementById("searchItemUlMcc");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        // a = li[i].getElementsByTagName("a")[0];
        a = li[i].getElementsByClassName("name")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    };

    async function handleChange(e, t) {
      document.getElementById("chatLeftPannel").classList.remove("display-none");
    }
    
    async function getGroupDetails() {
      if(type===2){

        const formData = {
          id:id,
          type: type
        }

        apicall(formData,"getGroupMemberInfo","details");
        
        //openModalTwo();

      }else{

      }
    }
    
    async function addGroupMemberToList() {
      if(type===2){
        const formData = {
          id:id,
          type: type
        }
        apicall(formData,"getGroupMemberInfo","add");

      }else{

      }
      openModalEdit();


    }
    
    async function deleteGroupMamber(row) {
      const formData = {
        id:row?.members
      }
      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? delete " + row?.sender_name + " from group "+ row?.receiver_name +" ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "deleteGroupMember", "Group Member deleted successfully")
        }, { label: 'No', }]
      });
    }
    
    async function deleteGroupMamberSelf(row) {
      const formData = {
        id:row?.members
      }
      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? you want to left the group "+ row?.receiver_name +" ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "deleteGroupMember", "self")
        }, { label: 'No', }]
      });
      
    }


    async function deleteGroupInfo() {      
      const formData = {
        id:id
      }
      confirmAlert({
        title: 'MyGration Hub', message: "Are you sure? delete Group "+ name +" ?", buttons: [{
          label: 'Yes',
          onClick: () => apicall(formData, "deleteGroupInfo", "Group deleted successfully")
        }, { label: 'No', }]
      });
    }
   
  


    return (
      <>
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => closeModal()}
          aria-labelledby="StaticBackdropExampleLabel" >

          <CModalHeader onClose={() => closeModal()}>
            <CModalTitle id="LiveDemoExampleLabel">Add to Group</CModalTitle>
          </CModalHeader>
          <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" validated={validated} onSubmit={handleFormSubmit} >
            <CModalBody>
              <CCol md={12}>

                <CInputGroup className="has-validation">
                  <CInputGroupText> Group Name <span className='red'>*</span></CInputGroupText>
                  <CFormInput id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={groupName} onChange={(event) => { setGroupName(event.target.value); }} required={true} />
                </CInputGroup>

              </CCol>

              <CCol md={12}>
                &nbsp;
              </CCol>

              <CCol md={12}>

              <div className='chatList-container-root-mcc'>

                  <div className="people-list" id="people-list">

                    <div className="search">
                      {/* <input type="text" placeholder="search" onKeyDown={(e)=> {enterController(e,this)}} value={message} onChange={(event) => { setMessage(event.target.value); }}/> */}
                      <input type="text" id='searchItemIdMcc' placeholder="search" onKeyUp={(e) => { searchChatItemsMcc(e) }} />
                      <i className="fa fa-search"></i>
                    </div>
                    

                    <div className='chatList-container-mcc'>

                      <ul className="list" id="searchItemUlMcc">

                        {chatList.length > 0 ?

                          chatList?.map((row, i) =>

                            row?.type === 1?

                            <li key={i} className="clearfix hover-class" onClick={() => addOrRemove(row?.sender_id, row?.sender_name, row?.user_type, row?.code, i)}>
                              <div className='chatAvater-left'>
                                {(row?.code !== "" && row?.code !== null) ?
                                  <CAvatar size="md" className="chatAvater-code-left" src={row?.code} />
                                  :
                                  Array.from(row?.sender_name)[0]
                                }

                              </div>
                              <div className="about-left font-size-14px">
                                <div id={`name_div_${i}`} className={row?.pending_count > 0 ? "name font-wait-bold" : 'name'}>{row?.sender_name}</div>
                                <div className="status">
                                  <i id={`li_${i}`} className={row?.pending_count > 0 ? "fa fa-circle online red" : "fa fa-circle online"}></i>
                                  <span>{row?.user_type === 1000 ? "Admin" : row?.user_type === 100 ? "Partner Advisor" : row?.user_type === 1 ? "Client" : ""}</span>
                                  <span id={`span_${i}`}>{row?.pending_count > 0 ? row?.pending_count : ""}</span>
                                </div>
                              </div>
                              <div className='float-right'>
                                <p  className='newsDetailsContainer' dangerouslySetInnerHTML={{__html: getCode(row?.sender_id)}}></p>
                              </div>
                            </li>
                            :""

                          )
                          : ""}

                      </ul>

                    </div>


                  </div>
                </div>
                
              </CCol>


            </CModalBody>
            <CModalFooter>
              {isModalSpenierOpen ?
                <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                :
                <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Done </CButton>
              }
            </CModalFooter>
          </CForm>
        </CModal>


        <CModal
          backdrop="static"
          visible={visibleTwo}
          onClose={() => closeModalTwo()}
          aria-labelledby="StaticBackdropExampleLabel" >

          <CModalHeader onClose={() => closeModalTwo()}>
            <CModalTitle id="LiveDemoExampleLabel">Group Member Information</CModalTitle>
           {role===1?
            <CButton title='Delete Group' className="danger" style={{ borderRadius: "5px" }} color="" type="submit" onClick={(e)=>{deleteGroupInfo()}}> <i className="fa fa-trash red" aria-hidden="true"></i> </CButton>
           :
           ""}
            
          </CModalHeader>
          <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" >
            <CModalBody>
              <CCol md={12}>

                <CInputGroup className="has-validation">
                  <CInputGroupText> Group Name <span className='red'>*</span></CInputGroupText>
                  <CFormInput id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={groupNameTwo} onChange={(event) => { setGroupNameTwo(event.target.value); }} required={true} />
                </CInputGroup>

              </CCol>

              <CCol md={12}>
                &nbsp;
              </CCol>

              <CCol md={12}>

              <div className='chatList-container-root-mcc'>

                  <div className="people-list" id="people-list">

                    <div className="search">
                      <input type="text" id='searchItemIdMcc' placeholder="search" onKeyUp={(e) => { searchChatItemsMcc(e) }} />
                      <i className="fa fa-search"></i>
                    </div>
                    

                    <div className='chatList-container-mcc'>

                      <ul className="list" id="searchItemUlMcc">

                        {groupMemberList.length > 0 ?

                          groupMemberList?.map((row, i) =>

                            row?.type === 1?

                            <li key={i} className="clearfix hover-class" >
                              <div className='chatAvater-left'>
                                {(row?.code !== "" && row?.code !== null) ?
                                  <CAvatar size="md" className="chatAvater-code-left" src={row?.code} />
                                  :
                                  Array.from(row?.sender_name)[0]
                                }

                              </div>
                              <div className="about-left font-size-14px">
                                <div id={`name_div_${i}`} className={row?.pending_count > 0 ? "name font-wait-bold" : 'name'}>{row?.sender_name}</div>
                                <div className="status">
                                  <i id={`li_${i}`} className={row?.pending_count > 0 ? "fa fa-circle online red" : "fa fa-circle online"}></i>
                                  <span>{row?.user_type === 1000 ? "Admin" : row?.user_type === 100 ? "Partner Advisor" : row?.user_type === 1 ? "Client" : ""}</span>
                                  <span id={`span_${i}`}>{row?.pending_count > 0 ? row?.pending_count : ""}</span>
                                </div>
                              </div>
                              <div className='float-right'>
                                {role===1?
                                <CButton title='remove member' className="danger" style={{ borderRadius: "5px" }} color="" type="submit" onClick={()=>{deleteGroupMamber(row)}}> <i className="fa fa-trash red" aria-hidden="true"></i> </CButton>
                                :
                                row?.sender_id === selfId?
                                  <CButton title='Left Group' className="danger" style={{ borderRadius: "5px" }} color="" type="submit" onClick={()=>{deleteGroupMamberSelf(row)}}> Left </CButton>
                                :""
                                }
                                  
                              </div>
                            </li>
                            :""

                          )
                          : ""}

                      </ul>

                    </div>


                  </div>
                </div>
                
              </CCol>


            </CModalBody>
            {/* <CModalFooter>
              {isModalSpenierOpen ?
                <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                :
                <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Done </CButton>
              }
            </CModalFooter> */}
          </CForm>
        </CModal>

        <CModal
          backdrop="static"
          visible={visibleEdit}
          onClose={() => closeModalEdit()}
          aria-labelledby="StaticBackdropExampleLabel" >

          <CModalHeader onClose={() => closeModalEdit()}>
            <CModalTitle id="LiveDemoExampleLabel">Add to Group</CModalTitle>
          </CModalHeader>
          <CForm style={{ padding: '10px' }} className="row g-3 needs-validation update-main-form" validated={validatedEdit} onSubmit={handleFormSubmitEdit} >
            <CModalBody>
              <CCol md={12}>

                <CInputGroup className="has-validation">
                  <CInputGroupText> Group Name <span className='red'>*</span></CInputGroupText>
                  <CFormInput id="validationCustomFirstName" type="text" feedbackValid="Looks good" feedbackInvalid="Required!" value={groupName} onChange={(event) => { setGroupName(event.target.value); }} required={true} />
                </CInputGroup>

              </CCol>

              <CCol md={12}>
                &nbsp;
              </CCol>

              <CCol md={12}>

              <div className='chatList-container-root-mcc'>

                  <div className="people-list" id="people-list">

                    <div className="search">
                      <input type="text" id='searchItemIdMcc' placeholder="search" onKeyUp={(e) => { searchChatItemsMcc(e) }} />
                      <i className="fa fa-search"></i>
                    </div>
                    

                    <div className='chatList-container-mcc'>

                      <ul className="list" id="searchItemUlMcc">

                        {chatList.length > 0 ?

                          chatList?.map((row, i) =>

                            row?.type === 1?

                            <li key={i} className="clearfix hover-class" onClick={() => addOrRemoveEdit(row?.sender_id, row?.sender_name, row?.user_type, row?.code, i)}>
                              <div className='chatAvater-left'>
                                {(row?.code !== "" && row?.code !== null) ?
                                  <CAvatar size="md" className="chatAvater-code-left" src={row?.code} />
                                  :
                                  Array.from(row?.sender_name)[0]
                                }

                              </div>
                              <div className="about-left font-size-14px">
                                <div id={`name_div_${i}`} className={row?.pending_count > 0 ? "name font-wait-bold" : 'name'}>{row?.sender_name}</div>
                                <div className="status">
                                  <i id={`li_${i}`} className={row?.pending_count > 0 ? "fa fa-circle online red" : "fa fa-circle online"}></i>
                                  <span>{row?.user_type === 1000 ? "Admin" : row?.user_type === 100 ? "Partner Advisor" : row?.user_type === 1 ? "Client" : ""}</span>
                                  <span id={`span_${i}`}>{row?.pending_count > 0 ? row?.pending_count : ""}</span>
                                </div>
                              </div>
                              <div className='float-right'>
                                <p  className='newsDetailsContainer' dangerouslySetInnerHTML={{__html: getCodeEdit(row?.sender_id)}}></p>
                              </div>
                            </li>
                            :""

                          )
                          : ""}

                      </ul>

                    </div>


                  </div>
                </div>
                
              </CCol>


            </CModalBody>
            <CModalFooter>
              {isModalSpenierOpen ?
                <Vortex visible={true} height="80" width="80" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['#036480', '#036480', '#036480', '#036480', '#036480', '#036480']}></Vortex>
                :
                <CButton className="btn btn-success" style={{ borderRadius: "5px" }} color="success" type="submit"> Done </CButton>
              }
            </CModalFooter>
          </CForm>
        </CModal>

        <div className="chatApp col-lg-12 col-md-12 col-sm-12">
          <div className='row'>
            <div className='col-lg-3 col-md-4 col-sm-12 padding-right-zero' id="chatLeftPannel">
              <div className='chatList-container-root'>

                <div className="people-list" id="people-list">

                  <div className="search">
                    {/* <input type="text" placeholder="search" onKeyDown={(e)=> {enterController(e,this)}} value={message} onChange={(event) => { setMessage(event.target.value); }}/> */}
                    <input type="text" id='searchItemId' placeholder="search" onKeyUp={(e) => { searchChatItems(e) }} />
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="search">

                    {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                    <div onClick={() => { openModal() }}>
                      <CIcon icon={cilUserPlus} /> Create new group
                    </div>
                  </div>

                  <div className='chatList-container'>



                    <ul className="list" id="searchItemUl">
                      {chatList.length > 0 ?

                        chatList?.map((row, i) =>

                          <li key={i} className="clearfix hover-class" onClick={() => loadActiveChat(row, i)}>
                            <div className='chatAvater-left'>
                              {(row?.code !== "" && row?.code !== null) ?
                                <CAvatar size="md" className="chatAvater-code-left" src={row?.code} />
                                :
                                row?.type===2?
                                // <CAvatar size="md" className="chatAvater-code-left" src={row?.code} />
                                <CIcon icon={cilPeople} className='groupImage' />
                                :
                                Array.from(row?.sender_name)[0]
                              }

                            </div>
                            <div className="about-left font-size-14px">
                              <div id={`name_div_${i}`} className={row?.pending_count > 0 ? "name font-wait-bold" : 'name'}>{row?.sender_name}</div>
                              <div className="status">
                                <i id={`li_${i}`} className={row?.pending_count > 0 ? "fa fa-circle online red" : "fa fa-circle online"}></i>
                                <span>{ row?.type===2? 'Group' :row?.user_type === 1000 ? "Admin" : row?.user_type === 100 ? "Partner Advisor" : row?.user_type === 1 ? "Client" : "" }</span>
                                <span id={`span_${i}`}>{row?.pending_count > 0 ? row?.pending_count : ""}</span>
                              </div>
                            </div>
                          </li>

                        )
                        : ""}

                    </ul>

                  </div>


                </div>
              </div>
            </div>

            <div className='col-lg-9 col-md-8 col-sm-12 padding-left-zero'>

              <div className='massageList-container'>

                <div className="chat col-lg-12 col-md-12 col-sm-12">

                  <div className="chat-header clearfix col-lg-12 col-md-12 col-sm-12">
                    <div><CIcon size='3xl' className='chatLeftArrow' id="chatLeftArrow" onClick={handleChange} icon={cilArrowCircleLeft} /></div>
                    {otherName !== "" ?
                      <>
                        <div >
                          <div className={(otherPictureCode !== "" && otherPictureCode !== null) ? 'chatAvater-code chatAvater-head-code' : 'chatAvater chatAvater-head'} onClick={()=>{getGroupDetails()}}>
                            {(otherPictureCode !== "" && otherPictureCode !== null) ?
                              <CAvatar size="md" className="chatAvater-code" src={otherPictureCode} />
                              :
                              type === 2 ?
                                <CIcon icon={cilPeople} className='groupImage' />
                                :
                                Array.from(otherName)[0]
                            }
                          </div>
                          <div className="about">
                            <div className="name" onClick={()=>{getGroupDetails()}}>{otherName}</div>
                            <div className="status">
                              <span onClick={() => { getGroupDetails() }}>
                                <i className="fa fa-circle online"></i>{type === 2 ? groupCount : ""} {status}
                                <input type='hidden' id="receiverId" value={receiver}></input>
                                <input type='hidden' id="typeId" value={type}></input>
                              </span>
                              
                              {type===2 && role===1?
                                <span onClick={() => { addGroupMemberToList() }}>
                                   &nbsp;|| &nbsp; <CIcon icon={cilUserPlus} /> Add
                                </span>
                              :""}
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      ""
                    }

                  </div>


                  <div className="chat-history col-lg-12 col-md-12 col-sm-12" id="chat-history-s">


                    {messageHistory.length > 0 ?

                      <div className='chatContainer' id='chatContainer' ref={messageRef}>

                        {messageHistory?.map((message, i) => message?.senderId !== selfId ?
                          <div key={i}>
                            <div className='col-lg-12 col-md-12 col-sm-12 othersClass clear-both'>
                              <div className='padding-bottom-7px'>
                                <span className="message-data-name">{message?.senderName}</span><br></br>
                                <span className="message-data-time">{message?.dateCreated ? moment(message?.dateCreated).format("hh:mm A MMMM DD, YYYY") : ''}</span>
                              </div>
                              <div>
                                <div className="message other-message">
                                  {message?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div key={i}>
                            <div className='col-lg-12 col-md-12 col-sm-12 align-right myClass clear-both'>
                              <div className='padding-bottom-7px'>
                                <span className="message-data-name">{message?.senderName}</span><br></br>
                                <span className="message-data-time">{message?.dateCreated ? moment(message?.dateCreated).format("hh:mm A MMMM DD, YYYY") : ''}</span>
                                {/* <span className="message-data-name">{myName}</span> */}
                              </div>
                              <div>
                                <div className="message my-message float-right">
                                  {message?.message}
                                </div>
                              </div>

                            </div>

                          </div>
                        )}

                      </div>

                      :


                      <div className='chatContainer' id='chatContainer' ref={messageRef}></div>


                    }

                  </div>


                  <div className="chat-message col-lg-12 col-md-12 col-sm-12">
                    <textarea name="message-to-send" id="message-to-send" placeholder="Type your message" rows="1" onKeyDown={(e) => { enterController(e, this) }} value={message} onChange={(event) => { setMessage(event.target.value); }} ></textarea>
                    <button onClick={sendMessage}>Send</button>
                  </div>

                </div>

              </div>
            </div>

          </div>


        </div>
      </>
    );
  }

export default Chats