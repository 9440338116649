import React, { useState, useEffect } from "react";
import axios from '../api/axios';
import { Link } from 'react-router-dom'
import service1 from './../assets/images/icons/team.png'
import service2 from './../assets/images/icons/expertise.png'
import service3 from './../assets/images/icons/university.png'
import service4 from './../assets/images/icons/oneStop.png'
import service5 from './../assets/images/icons/sucess.png'
import videoUrl from './../assets/bg/mygrationhub.mp4'
import './../assets/css/custom.css'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const HomeDefault = () => {
    const [newsInfoList, setNewsInfoList] = useState([]);
    //const [newsItems, setnNwsItems] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getNewsInfoListOnLoad = async () => {
          try {
            const path = "/getAllNewsInfoListToDisplay";
            const response = await axios.get(path, {
              signal: controller.signal
            });
            isMounted && setNewsInfoList(response?.data);

            // response?.data?.map((news, i) => (

            //     <div className="item" key={1}>
            //     <div className="item-content">
            //         <img src={require('./../assets/images/news/new1.webp')} alt=""/>
            //         <Link className='hover-under-line neaws-head' target="_blank" to="https://www.cicnews.com/2024/01/breaking-ircc-to-announce-a-two-year-cap-on-admissions-for-international-students-0142492.html#gs.3wtq4v" ><p>IRCC announces cap on admissions for international students</p></Link>
            //     </div>
            // </div>
                
            //   ));

            /* response?.data?.map((news, i) => (

                <div className="item" key={i}>
                    <div className="item-content">
                        <img src={news?.image} alt=""/>
                        <Link className='hover-under-line neaws-head' target="_blank" to={news?.newsLink} ><p>{news?.headline}</p></Link>
                    </div>
                </div>

            )); */

            // console.log(response?.data);
          } catch (err) {
          }
        }
    
        getNewsInfoListOnLoad();
    
        return () => {
          isMounted = false;
          controller.abort();
        }
      }, []);

    let state = {
        options: {
            loop: true,
            autoplay:true,
            margin: 30,
            nav: true,
            responsive: {
                0: {
                    items: 1,
                },
                800: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
            },
        },

        items: [
            <div className="item" key={1}>
                <div className="icond">
                    <img src={service1} alt="" />
                </div>

                <div className="down-content">
                    <h4>Our Team</h4>

                    <p>Mygration Hub offers expert immigration consultancy, blending global knowledge with local insight, driven by integrity and innovation.</p>
                </div>
            </div>,

            <div className="item" key={2}>
                <div className="icond">
                    <img src={service2} alt="" />
                </div>

                <div className="down-content">
                    <h4>Core Expertise</h4>

                    <p>Global immigration consultancy, personalized guidance, ethical practices, technological innovation, and comprehensive documentation accuracy.</p>
                </div>
            </div>,

            <div className="item" key={3}>
                <div className="icond">
                    <img src={service3} alt="" />
                </div>

                <div className="down-content">
                    <h4>Affiliated Universities</h4>

                    <p>Affilliated with top DLI institutes across Canada and rest of the world.</p>
                </div>
            </div>,

            <div className="item" key={4}>
                <div className="icond">
                    <img src={service4} alt="" />
                </div>

                <div className="down-content">
                    <h4>One Stop Solution</h4>

                    <p>From consultation, IELTS, documentation to your VISA & PR, everythig happens under our umbrella</p>
                </div>
            </div>,

            <div className="item" key={5}>
                <div className="icond">
                    <img src={service5} alt="" />
                </div>

                <div className="down-content">
                    <h4>Success Stories</h4>

                    <p>Our clients speak for us. Read their stories.</p>
                </div>
            </div>
        ],
    };

    let news = {
        options: {
            loop: true,
            //autoplay:true,
            margin: 30,
            nav: true,
            responsive: {
                0: {
                    items: 2,
                },
                400: {
                    items: 2,
                },
                708: {
                    items: 3,
                },
                1100: {
                    items: 4,
                },
                1250: {
                    items: 5,
                },
            },
            lazyLoad: true,
        },

        //items:newsItems,

        /* items: [
            <div className="item" key={1}>
                <div className="item-content">
                    <img src={require('./../assets/images/news/new1.webp')} alt=""/>
                    <Link className='hover-under-line neaws-head' target="_blank" to="https://www.cicnews.com/2024/01/breaking-ircc-to-announce-a-two-year-cap-on-admissions-for-international-students-0142492.html#gs.3wtq4v" ><p>IRCC announces cap on admissions for international students</p></Link>
                </div>
            </div>,

            <div className="item" key={2}>
                <div className="item-content">
                    <img src={require('./../assets/images/news/new2.webp')} alt="" />
                    <Link className='hover-under-line neaws-head' target="_blank" to="https://www.cicnews.com/2024/01/ircc-announces-new-eligibility-criteria-for-the-post-graduation-work-permit-0142511.html#gs.3wsptd" ><p>IRCC announces new eligibility criteria for Post-Graduation Work Permits</p></Link>
                </div>
            </div>,

            <div className="item" key={3}>
                <div className="item-content">
                    <img src={require('./../assets/images/news/new3.webp')} alt="" />
                    <Link className='hover-under-line neaws-head' target="_blank" to="https://www.cicnews.com/2024/01/canada-may-not-see-any-new-study-permit-applications-until-march-31-2024-0142521.html#gs.3wst5z" ><p>Canada may not see any new study permit applications until March 31, 2024</p></Link>
                </div>
            </div>,

            <div className="item" key={4}>
                <div className="item-content">
                    <img src={require('./../assets/images/news/new4.webp')} alt="" />
                    <Link className='hover-under-line neaws-head' target="_blank" to="https://www.cicnews.com/2024/01/ircc-invites-1040-express-entry-candidates-in-latest-draw-0142312.html#gs.3wsv33" ><p>IRCC invites 1,040 Express Entry candidates in latest draw</p></Link>
                </div>
            </div>,

            <div className="item" key={5}>
                <div className="item-content">
                    <img src={require('./../assets/images/news/news5.webp')} alt="" />
                    <Link className='hover-under-line neaws-head' target="_blank" to="https://www.cicnews.com/2024/01/newfoundland-and-labrador-immigration-fairs-continue-this-week-0142160.html#gs.3wsvww" ><p>Newfoundland and Labrador immigration fairs continue this week</p></Link>
                </div>
            </div>
        ], */
    };
    let keys = {
        options: {
            loop: true,
            autoplay:true,
            margin: 15,
            nav: false,
            autoplayTimeout:2000,
            autoplayHoverPause:true,
            smartSpeed:300,
            responsive: {
                0: {
                    items: 1,
                },
                500: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
            },
        },

        items: [
            <div className='item' key={1}>
                <div className="count-area-content">
                    <div className="count-digit counter-loaded">10+</div>
                    <div className="count-title">Countries</div>
                </div>
            </div>,

            <div className='item' key={2}>
                <div className="count-area-content">
                    <div className="count-digit counter-loaded">100+</div>
                    <div className="count-title">Immigrants</div>
                </div>
            </div>,

            <div className='item' key={3}>
                <div className="count-area-content">
                    <div className="count-digit counter-loaded">100+</div>
                    <div className="count-title">Affiliated Universities</div>
                </div>
            </div>
        ],
    };
    return (
        <div>

            <section className="section main-banner" id="top" data-section="section1">

                <video autoPlay muted loop id="bg-video">
                    <source src={videoUrl} type="video/mp4" />
                </video>

                {/* <div id="slide_wrap">
                    <ul>
                        <li data-srcset="./../src/assets/bg/image-1-1920.jpg 1920w, ./../src/assets/bg/image-1-1024x684.jpg 1024w, ./../src/assets/bg/image-1-512x342.jpg 512w"></li>
                        <li data-srcset="./../src/assets/bg/image-2-1920.jpg 1920w, ./../src/assets/bg/image-2-1024x684.jpg 1024w, ./../src/assets/bg/image-2-512x342.jpg 512w"></li>
                        <li data-srcset="./../src/assets/bg/image-3-1920.jpg 1920w, ./../src/assets/bg/image-3-1024x684.jpg 1024w, ./../src/assets/bg/image-3-512x342.jpg 512w"></li>
                        <li data-srcset="./../src/assets/bg/image-4-1920.jpg 1920w, ./../src/assets/bg/image-4-1024x684.jpg 1024w, ./../src/assets/bg/image-4-512x342.jpg 512w"></li>
                    </ul>
                </div> */}

                <div className="video-overlay header-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="caption">
                                    {/* <h6>Hello Migration Seeker</h6> */}

                                    <h2>Welcome to MyGration Hub</h2>

                                    <p>Embark on a journey of possibilities with Mygration Hub. Whether you are seeking opportunities for study, work, or a fresh start in a new country, we are here to guide you every step of the way. Your dreams are the compass, and we are the navigators, charting a course for success together.</p>

                                    <div className="main-button-red">
                                        <div className="scroll-to-section"><Link to="/register">Book Your Free Consultation Now!</Link></div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>


            <section className="services" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel className='owl-service-item'  {...state.options}>
                                    {state.items}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about-us" id="about-us">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="accordions is-first-expanded">

                            </div>
                        </div>

                        <div className="col-lg-6 align-self-center">
                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="item accordions-rigth">
                                        <h3>About Us</h3>
                                        <div className='accordions-rigth-inner'>
                                            <p>
                                                We aspire to be the trusted partner in every traveler&apos;s and immigrant&apos;s pursuit of adventure, growth, and a brighter future. Through innovative solutions, unwavering integrity, and personalized service, we aim to redefine the landscape of travel and immigration, making it accessible, enriching, and filled with endless possibilities. Our commitment is to build bridges, break barriers, and create a world where borders are not limitations but gateways to new horizons.
                                            </p>
                                            <p>
                                                At Mygration Hub, we believe in the power of dreams and the pursuit of a better life. As a pioneering online-based immigration consultancy service with roots in both Bangladesh and Canada, we are committed to redefining the landscape of the immigration consultancy industry. Our mission is to empower individuals and families with the knowledge, guidance, and support they need to embark on transformative journeys towards new horizons.
                                            </p>
                                        </div>
                                        <div className="main-button-red">
                                            <br/>
                                            <div className="scroll-to-section"><Link to="register">Book Your Free Consultation Now!</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="success-stories" id="success-stories">

                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>Success Stories </h2>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <OwlCarousel className='owl-service-item' loop  margin={0} items={1} nav>
                            {/* loop autoplay */}

                                <div className="item">

                                    <div className="row">

                                        <div className="col-lg-6 align-self-center">
                                            <div className="row" >
                                                <div className="col-lg-12">
                                                    <div className="accordions-rigth">
                                                        <div className="inner-accordions-rigth">
                                                            <p>
                                                                I had a strong desire to make Canada my permanent home, but the more I delved into the process, the more challenging it appeared. At one point, I considered giving up and staying put, thinking the journey was too difficult. The key lies in the advice you receive – not everyone is reliable. Simply wishing to live in Canada permanently isn&apos;t enough; you need a trustworthy guide to help you navigate the right path and provide constant support and information to help you achieve your goals. If this path isn&apos;t right for you, it&apos;s okay to say no from the beginning.
                                                            </p>
                                                            <p>

                                                                My dream of a better life and a beautiful future for my children has been a long-held aspiration. I&apos;ve always believed that if you truly want something, you can achieve it. That&apos;s exactly what happened for my family – we are now proud permanent residents of Canada. My journey with Migration Hub mirrors this sentiment. They became a friend during my preparation period, supporting me without making the impossible seem possible or trying to gain any undue advantage. Thanks, Migration Hub! Keep empowering deserving individuals from our country to showcase their talents globally. Let&apos;s prove ourselves just like the remarkable countries around the world, hand in hand with Migration Hub. 
                                                            </p>
                                                            <p>

                                                            </p>
                                                        </div>

                                                        <div className="main-button-red">
                                                            <div className="scroll-to-section">
                                                                {/* <Link to="register">CONNECT WITH US</Link> */}
                                                                <p className='familyNameHead'>
                                                                    Mohammad Jaynul Abedin & Family
                                                                </p>
                                                                <p className='familyNameDown'>
                                                                    Permanent Resident – Canada<br/>
                                                                    Ex Director (Standered Bank, Bangladesh)
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="accordions is-first-expanded accordions1">
                                                {/* <div className="name-address1">
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mohammad <br/>&nbsp;&nbsp;&nbsp;Jaynul Abedin & Family <br/>Permanent Resident – Canada</span>
                                                </div> */}
                                                
                                            </div>
                                        </div>


                                    </div>

                                </div>

                                <div className="item">

                                    <div className="row">

                                        <div className="col-lg-6">
                                            <div className="accordions is-first-expanded accordions2">
                                                {/* <div className="name-address2">
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enayetur <br />&nbsp;&nbsp;&nbsp;Rahim & Family <br />Permanent Resident – Canada</span>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 align-self-center">
                                            <div className="row" >
                                                <div className="col-lg-12">
                                                    <div className="accordions-rigth">
                                                        <div className="inner-accordions-rigth">
                                                            <p>
                                                                In 2010, my family and I embarked on our Canada immigration journey, a dream shared by many in Bangladesh. After completing my IELTS and credential assessment, we enlisted the services of an immigration consultancy firm in Bangladesh. Unfortunately, our initial optimism was met with disappointment, as it became evident that their encouraging words were primarily a tactic to secure our registration and initial payments.
                                                            </p>
                                                            <p>
                                                                Two years into this discouraging experience, on the brink of giving up, we were fortunate to be introduced to Mygration Hub through a colleague. What immediately set Mygration Hub apart was their unwavering honesty, professionalism, and commitment to crafting personalized plans for each individual. From the outset, they provided genuine guidance, ensuring that every step of our journey – from form submissions to our eventual landing in Canada – was meticulously navigated.
                                                            </p>
                                                            <p>
                                                                Unlike our previous encounter, Mygration Hub&apos;s support extended beyond the immediate requirements. Even after securing Permanent Residency in Canada in June 2022, our connection with Mygration Hub persists, with their team readily available for any assistance we may require. 
                                                                {/* Their dedication and transparency transformed our immigration experience into a success story, making our dream a reality. */}
                                                            </p>
                                                            {/* <p>
                                                                I wholeheartedly endorse Mygration Hub for their exceptional services and express my sincere gratitude for guiding us to achieve our Canadian dreams. Their integrity, professionalism, and ongoing support make them a standout immigration consultancy firm. I wish Mygration Hub continued success in their future endeavors, confident that they will continue to help others realize their aspirations of settling in Canada.
                                                            </p> */}
                                                        </div>

                                                        <div className="main-button-red">
                                                        <div className="scroll-to-section familyNameHeadrRight">
                                                                {/* <Link to="register">CONNECT WITH US</Link> */}
                                                                <p className='familyNameHead'>
                                                                    Enayetur Rahim & Family
                                                                </p>
                                                                <p className='familyNameDown'>
                                                                    Permanent Resident – Canada<br/>
                                                                    {/* Ex Director (Standered Bank, Bangladesh) */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="row">

                                        <div className="col-lg-6 align-self-center">
                                            <div className="row" >
                                                <div className="col-lg-12">
                                                <div className="accordions-rigth">
                                                        <div className="inner-accordions-rigth">
                                                            <p>
                                                                Anyway, Canada didn&apos;t seem like the perfect fit for someone as disorganized as me, caught up in the hustle and bustle of the country. Moving from agency to agency, navigating through university requirements, dealing with IELTS—how would it all work out? But they say where there&apos;s a will, there&apos;s a way. It started by sharing my aspirations with the Migration Hub. Suddenly, the seemingly impossible tasks became surprisingly easy.
                                                            </p>
                                                            <p>
                                                                Collecting all the necessary documents, I sat down to take the IELTS one early morning. Just when everything was set, my son was born. I realized I might have to start from scratch again, but the Migration Hub guided me through that too. One afternoon, I bought a ticket and landed at Pearson Airport. Despite the challenge of deciphering my own handwriting, my journey turned out to be easier than expected!
                                                            </p>
                                                            <p>
                                                            The key was communicating with the right people at the right time. The Migration Hub made all the difference by providing the necessary guidance. Looking back, I can confidently say that every step, every challenge, and even the unexpected joys have shaped my Canadian journey into something uniquely mine. 
                                                            </p>
                                                            {/* <p>
                                                                I wholeheartedly endorse Mygration Hub for their exceptional services and express my sincere gratitude for guiding us to achieve our Canadian dreams. Their integrity, professionalism, and ongoing support make them a standout immigration consultancy firm. I wish Mygration Hub continued success in their future endeavors, confident that they will continue to help others realize their aspirations of settling in Canada.
                                                            </p> */}
                                                        </div>

                                                        <div className="main-button-red">
                                                        <div className="scroll-to-section">
                                                                {/* <Link to="register">CONNECT WITH US</Link> */}
                                                                <p className='familyNameHead'>
                                                                    Shegufta Farhat & Family
                                                                </p>
                                                                <p className='familyNameDown'>
                                                                    Permanent Resident – Canada<br/>
                                                                    {/* Ex Director (Standered Bank, Bangladesh) */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="accordions is-first-expanded accordions3">
                                                {/* <div className="name-address3">
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shegufta<br />&nbsp;&nbsp;&nbsp;Farhat & Family<br />Permanent Resident – Canada</span>
                                                </div> */}

                                            </div>
                                        </div>


                                    </div>

                                </div>

                                



                                
                            </OwlCarousel>
                        </div>


                    </div>
                </div>
            </section>


            <section className="news" id="news">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>News Link</h2>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            {newsInfoList.length>0?
                            <OwlCarousel className='owl-service-item' {...news.options}>
                                    {/* {news.items} */}
                                    {newsInfoList?.map((news, i) => (

                                        <div className="item" key={i}>
                                            <div className="item-content">
                                                <img src={news?.image} alt=""/>
                                                <Link className='hover-under-line neaws-head' target={news?.newsLink === "" ? "": "_blank"} to={news?.newsLink === "" ? "/news-details/" + news?.id : news?.newsLink} ><p>{news?.headline}</p></Link>
                                            </div>
                                        </div>
                                                        
                                    ))};
                            </OwlCarousel>
                            :''}
                        </div> 

                        <div className="col-lg-12">

                        </div>
                    </div>
                </div>
            </section>


            <section className="key">
                <div className="container">
                    <div className="row">

                    <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>Key Highlights of MyGration Hub</h2>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <OwlCarousel className='owl-service-item'  {...keys.options}>
                                    {keys.items}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}

export default HomeDefault