import React from 'react'
import Select from 'react-select'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormSelect,
  CFormLabel,
  CFormTextarea,
  CFormCheck,
  CFormSwitch,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser, cilMobile, 
  // cilCalendar, 
  cilSchool, cibWhenIWork, cilHouse } from '@coreui/icons'
import { useState } from "react"
import axios from '../api/axios';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
// import EducationInfoTab from './EducationInfoTab'
import { useParams,useNavigate } from 'react-router-dom';



const options = [
  { value: '1', label: 'Bangladesh' },
  { value: '2', label: 'USA' },
  { value: '3', label: 'Australia' }
]

const countryList = [
        { value: '0 ', label: 'Select country' },
        { value: '1 ', label: 'Afghanistan' },
        { value: '2 ', label: 'Albania' },
        { value: '3 ', label: 'Algeria' },
        { value: '4 ', label: 'American Samoa' },
        { value: '5 ', label: 'Andorra' },
        { value: '6 ', label: 'Angola' },
        { value: '7 ', label: 'Anguilla' },
        { value: '8 ', label: 'Antarctica' },
        { value: '9 ', label: 'Antigua and Barbuda' },
        { value: '10', label: 'Argentina' },
        { value: '11', label: 'Armenia' },
        { value: '12', label: 'Aruba' },
        { value: '13', label: 'Australia' },
        { value: '14', label: 'Austria' },
        { value: '15', label: 'Azerbaijan' },
        { value: '16', label: 'Bahamas' },
        { value: '17', label: 'Bahrain' },
        { value: '18', label: 'Bangladesh' },
        { value: '19', label: 'Barbados' },
        { value: '20', label: 'Belarus' },
        { value: '21', label: 'Belgium' },
        { value: '22', label: 'Belize' },
        { value: '23', label: 'Benin' },
        { value: '24', label: 'Bermuda' },
        { value: '25', label: 'Bhutan' },
        { value: '26', label: 'Bosnia and Herzegovina' },
        { value: '27', label: 'Botswana' },
        { value: '28', label: 'Bouvet Island' },
        { value: '29', label: 'Brazil' },
        { value: '30', label: 'British Indian Ocean Territory' },
        { value: '31', label: 'Brunei Darussalam' },
        { value: '32', label: 'Bulgaria' },
        { value: '33', label: 'Burkina Faso' },
        { value: '34', label: 'Burundi' },
        { value: '35', label: 'Cambodia' },
        { value: '36', label: 'Cameroon' },
        { value: '37', label: 'Canada' },
        { value: '38', label: 'Cape Verde' },
        { value: '39', label: 'Cayman Islands' },
        { value: '40', label: 'Central African Republic' },
        { value: '41', label: 'Chad' },
        { value: '42', label: 'Chile' },
        { value: '43', label: 'China' },
        { value: '44', label: 'Christmas Island' },
        { value: '45', label: 'Cocos (Keeling) Islands' },
        { value: '46', label: 'Colombia' },
        { value: '47', label: 'Comoros' },
        { value: '48', label: 'Congo' },
        { value: '49', label: 'Cook Islands' },
        { value: '50', label: 'Costa Rica' },
        { value: '51', label: 'Croatia' },
        { value: '52', label: 'Cuba' },
        { value: '53', label: 'Cyprus' },
        { value: '54', label: 'Czech Republic' },
        { value: '55', label: 'Denmark' },
        { value: '56', label: 'Djibouti' },
        { value: '57', label: 'Dominica' },
        { value: '58', label: 'Dominican Republic' },
        { value: '59', label: 'Ecuador' },
        { value: '60', label: 'Egypt' },
        { value: '61', label: 'El Salvador' },
        { value: '62', label: 'Equatorial Guinea' },
        { value: '63', label: 'Eritrea' },
        { value: '64', label: 'Estonia' },
        { value: '65', label: 'Ethiopia' },
        { value: '66', label: 'Falkland Islands (Malvinas)' },
        { value: '67', label: 'Faroe Islands' },
        { value: '68', label: 'Fiji' },
        { value: '69', label: 'Finland' },
        { value: '70', label: 'France' },
        { value: '71', label: 'French Guiana' },
        { value: '72', label: 'French Polynesia' },
        { value: '73', label: 'French Southern Territories' },
        { value: '74', label: 'Gabon' },
        { value: '75', label: 'Gambia' },
        { value: '76', label: 'Georgia' },
        { value: '77', label: 'Germany' },
        { value: '78', label: 'Ghana' },
        { value: '79', label: 'Gibraltar' },
        { value: '80', label: 'Greece' },
        { value: '81', label: 'Greenland' },
        { value: '82', label: 'Grenada' },
        { value: '83', label: 'Guadeloupe' },
        { value: '84', label: 'Guam' },
        { value: '85', label: 'Guatemala' },
        { value: '86', label: 'Guernsey' },
        { value: '87', label: 'Guinea' },
        { value: '88', label: 'Guinea-Bissau' },
        { value: '89', label: 'Guyana' },
        { value: '90', label: 'Haiti' },
        { value: '91', label: 'Heard Island and McDonald Islands' },
        { value: '92', label: 'Holy See (Vatican City State)' },
        { value: '93', label: 'Honduras' },
        { value: '94', label: 'Hong Kong' },
        { value: '95', label: 'Hungary' },
        { value: '96', label: 'Iceland' },
        { value: '97', label: 'India' },
        { value: '98', label: 'Indonesia' },
        { value: '99', label: 'Iran' },
        { value: '100', label: 'Iraq' },
        { value: '101', label: 'Ireland' },
        { value: '102', label: 'Isle of Man' },
        { value: '103', label: 'Israel' },
        { value: '104', label: 'Italy' },
        { value: '105', label: 'Jamaica' },
        { value: '106', label: 'Japan' },
        { value: '107', label: 'Jersey' },
        { value: '108', label: 'Jordan' },
        { value: '109', label: 'Kazakhstan' },
        { value: '110', label: 'Kenya' },
        { value: '111', label: 'Kiribati' },
        { value: '112', label: 'Kuwait' },
        { value: '113', label: 'Kyrgyzstan' },
        { value: '114', label: 'Lao Peoples Democratic Republic' },
        { value: '115', label: 'Latvia' },
        { value: '116', label: 'Lebanon' },
        { value: '117', label: 'Lesotho' },
        { value: '118', label: 'Liberia' },
        { value: '119', label: 'Libya' },
        { value: '120', label: 'Liechtenstein' },
        { value: '121', label: 'Lithuania' },
        { value: '122', label: 'Luxembourg' },
        { value: '123', label: 'Macao' },
        { value: '124', label: 'Madagascar' },
        { value: '125', label: 'Malawi' },
        { value: '126', label: 'Malaysia' },
        { value: '127', label: 'Maldives' },
        { value: '128', label: 'Mali' },
        { value: '129', label: 'Malta' },
        { value: '130', label: 'Marshall Islands' },
        { value: '131', label: 'Martinique' },
        { value: '132', label: 'Mauritania' },
        { value: '133', label: 'Mauritius' },
        { value: '134', label: 'Mayotte' },
        { value: '135', label: 'Mexico' },
        { value: '136', label: 'Monaco' },
        { value: '137', label: 'Mongolia' },
        { value: '138', label: 'Montenegro' },
        { value: '139', label: 'Montserrat' },
        { value: '140', label: 'Morocco' },
        { value: '141', label: 'Mozambique' },
        { value: '142', label: 'Myanmar' },
        { value: '143', label: 'Namibia' },
        { value: '144', label: 'Nauru' },
        { value: '145', label: 'Nepal' },
        { value: '146', label: 'Netherlands' },
        { value: '147', label: 'New Caledonia' },
        { value: '148', label: 'New Zealand' },
        { value: '149', label: 'Nicaragua' },
        { value: '150', label: 'Niger' },
        { value: '151', label: 'Nigeria' },
        { value: '152', label: 'Niue' },
        { value: '153', label: 'Norfolk Island' },
        { value: '154', label: 'Northern Mariana Islands' },
        { value: '155', label: 'Norway' },
        { value: '156', label: 'Oman' },
        { value: '157', label: 'Pakistan' },
        { value: '158', label: 'Palau' },
        { value: '159', label: 'Panama' },
        { value: '160', label: 'Papua New Guinea' },
        { value: '161', label: 'Paraguay' },
        { value: '162', label: 'Peru' },
        { value: '163', label: 'Philippines' },
        { value: '164', label: 'Pitcairn' },
        { value: '165', label: 'Poland' },
        { value: '166', label: 'Portugal' },
        { value: '167', label: 'Puerto Rico' },
        { value: '168', label: 'Qatar' },
        { value: '169', label: 'Romania' },
        { value: '170', label: 'Russian Federation' },
        { value: '171', label: 'Rwanda' },
        { value: '172', label: 'Saint Kitts and Nevis' },
        { value: '173', label: 'Saint Lucia' },
        { value: '174', label: 'Saint Martin (French part)' },
        { value: '175', label: 'Saint Pierre and Miquelon' },
        { value: '176', label: 'Saint Vincent and the Grenadines' },
        { value: '177', label: 'Samoa' },
        { value: '178', label: 'San Marino' },
        { value: '179', label: 'Sao Tome and Principe' },
        { value: '180', label: 'Saudi Arabia' },
        { value: '181', label: 'Senegal' },
        { value: '182', label: 'Serbia' },
        { value: '183', label: 'Seychelles' },
        { value: '184', label: 'Sierra Leone' },
        { value: '185', label: 'Singapore' },
        { value: '186', label: 'Sint Maarten (Dutch part)' },
        { value: '187', label: 'Slovakia' },
        { value: '188', label: 'Slovenia' },
        { value: '189', label: 'Solomon Islands' },
        { value: '190', label: 'Somalia' },
        { value: '191', label: 'South Africa' },
        { value: '192', label: 'South Georgia and the South Sandwich Islands' },
        { value: '193', label: 'South Sudan' },
        { value: '194', label: 'Spain' },
        { value: '195', label: 'Sri Lanka' },
        { value: '196', label: 'State of Palestine' },
        { value: '197', label: 'Sudan' },
        { value: '198', label: 'Suriname' },
        { value: '199', label: 'Svalbard and Jan Mayen' },
        { value: '200', label: 'Swaziland' },
        { value: '201', label: 'Sweden' },
        { value: '202', label: 'Switzerland' },
        { value: '203', label: 'Syrian Arab Republic' },
        { value: '204', label: 'Tajikistan' },
        { value: '205', label: 'Thailand' },
        { value: '206', label: 'Timor-Leste' },
        { value: '207', label: 'Togo' },
        { value: '208', label: 'Tokelau' },
        { value: '209', label: 'Tonga' },
        { value: '210', label: 'Trinidad and Tobago' },
        { value: '211', label: 'Tunisia' },
        { value: '212', label: 'Turkey' },
        { value: '213', label: 'Turkmenistan' },
        { value: '214', label: 'Turks and Caicos Islands' },
        { value: '215', label: 'Tuvalu' },
        { value: '216', label: 'Uganda' },
        { value: '217', label: 'Ukraine' },
        { value: '218', label: 'United Arab Emirates' },
        { value: '219', label: 'United Kingdom' },
        { value: '220', label: 'United States' },
        { value: '221', label: 'United States Minor Outlying Islands' },
        { value: '222', label: 'Uruguay' },
        { value: '223', label: 'Uzbekistan' },
        { value: '224', label: 'Vanuatu' },
        { value: '225', label: 'Viet Nam' },
        { value: '226', label: 'Wallis and Futuna' },
        { value: '227', label: 'Western Sahara' },
        { value: '228', label: 'Yemen' },
        { value: '229', label: 'Zambia' },
        { value: '230', label: 'Zimbabwe' }
    ]

const RegisterNew = () => {

  const navigate = useNavigate();

  const {key,email,fName,lName,cell} = useParams();
  const [id] = useState(key);
  const [firstName] = useState(fName);
  const [lastName] = useState(lName);
  const [dob, setDob] = useState("");
  const [cityOfBirth, setCityOfBirth] = useState("");
  // const [picture, setPicture] = useState("");
  
  
  const [mobile] = useState(cell);

  const [whatsApp, setWhatsApp] = useState("");
  const [imo, setImo] = useState("");
  const [viber, setViber] = useState("");




  const [officeemail] = useState(email);
  // const [personalemail, setPersonalemail] = useState("");
   const [sex, setSex] = useState("Male");
   const [maritalStatus, setMaritalStatus] = useState("1");
  // const [familyMemberNo, setFamilyMemberNo] = useState("");
  // const [resume, setResume] = useState("");


  const [addressType1, setAddressType1] = useState("");
  const [address1, setaddress1] = useState("");
  const [addressType2, setAddressType2] = useState("");
  const [address2, setaddress2] = useState("");


  const [legalFirstName, setLegalFirstName] = useState("");
  const [legalCountryId, setLegalCountryId] = useState([]);

  const onMultiSelect = (e) => {
    setLegalCountryId(Array.isArray(e) ? e.map(x => x.value) : []);
  }


  const [educationLevel1, setEducationLevel1] = useState("");
  const [eduStartDate, setEduStartDate] = useState("");
  const [eduCompleteDate, setEduCompleteDate] = useState("");
  const [eduDegreeName, setEduDegreeName] = useState("");
  const [eduInstituteName, setEduInstituteName] = useState("");

  const [educationLevel2, setEducationLevel2] = useState("");
  const [eduStartDate2, setEduStartDate2] = useState("");
  const [eduCompleteDate2, setEduCompleteDate2] = useState("");
  const [eduDegreeName2, setEduDegreeName2] = useState("");
  const [eduInstituteName2, setEduInstituteName2] = useState("");

  const [educationLevel3, setEducationLevel3] = useState("");
  const [eduStartDate3, setEduStartDate3] = useState("");
  const [eduCompleteDate3, setEduCompleteDate3] = useState("");
  const [eduDegreeName3, setEduDegreeName3] = useState("");
  const [eduInstituteName3, setEduInstituteName3] = useState("");

  const [educationLevel4, setEducationLevel4] = useState("");
  const [eduStartDate4, setEduStartDate4] = useState("");
  const [eduCompleteDate4, setEduCompleteDate4] = useState("");
  const [eduDegreeName4, setEduDegreeName4] = useState("");
  const [eduInstituteName4, setEduInstituteName4] = useState("");


  // language 
  // const[languageTest,setLanguageTest] = useState("");
  const [languageTestType, setLanguageTestType] = useState("");
  const [languageTestVersion, setLanguageTestVersion] = useState("");
  const [languageCertificationNumber, setLanguageCertificationNumber] = useState("");
  const [languageTestDate, setLanguageTestDate] = useState("");
  const [languageResultDate, setLanguageResultDate] = useState("");
  const [listeningScore, setListeningScore] = useState("");
  const [readingScore, setReadingScore] = useState("");
  const [writingScore, setWritingScore] = useState("");
  const [speakingScore, setSpeakingScore] = useState("");
  const [totalLanguageScore, setTotalLanguageScore] = useState("");



  const [workStartDate, setWorkStartDate] = useState("");
  const [workEndDate, setWorkEndDate] = useState("");
  const [workDesignation, setWorkDesignation] = useState("");
  const [workOrganization, setWorkOrganization] = useState("");
  // const [responsibilities, setresponsibilities] = useState("");

  const [workStartDate2, setWorkStartDate2] = useState("");
  const [workEndDate2, setWorkEndDate2] = useState("");
  const [workDesignation2, setWorkDesignation2] = useState("");
  const [workOrganization2, setWorkOrganization2] = useState("");

  const [startDate, setStartDate] = useState(null);
  // const [responsibilities, setresponsibilities] = useState("");


  // const [educationLevel, setEducationLevel] = useState("");
  // const [educationLevel, setEducationLevel] = useState("");

  
  async function register(event) {

    event.preventDefault();

    try {
      const formData = {
        id:id,
        firstName: firstName,
        lastName: lastName,
        dob: dob,
        mobile: mobile,
        whatsApp: whatsApp?cell:'',
        imo:imo?cell:'',
        viber:viber?cell:'',
        officeEmail: officeemail,
        //personalemail: personalemail,
        maritalStatus:{id:maritalStatus},
        sex:sex,
        address: [
          {
            position: 1,
            addressType: addressType1,
            address: address1,
          },
          {
            position: 2,
            addressType: addressType2,
            address: address2,
          }
        ],


        legal: {
          legalFirstName: legalFirstName,
          legalCountryId: legalCountryId,
        },

        educationInfo:
          [{
            educationLevel: {
              id: educationLevel1
            },
            eduStartDate: eduStartDate,
            eduCompleteDate: eduCompleteDate,
            eduDegreeName: eduDegreeName,
            eduInstituteName: eduInstituteName,
            country: {
              id: null
            },
          },

          {
            educationLevel: {
              id: educationLevel2
            },
            // educationLevel2: educationLevel2,
            eduStartDate: eduStartDate2,
            eduCompleteDate: eduCompleteDate2,
            eduDegreeName: eduDegreeName2,
            eduInstituteName: eduInstituteName2,
            country: {
              id: null
            },
          },
          {
            educationLevel: {
              id: educationLevel3
            },
            // educationLevel3: educationLevel3,
            eduStartDate: eduStartDate3,
            eduCompleteDate: eduCompleteDate3,
            eduDegreeName: eduDegreeName3,
            eduInstituteName: eduInstituteName3,
            country: {
              id: null
            },
          },
          {
            educationLevel: {
              id: educationLevel4
            },
            // educationLevel: educationLevel4,
            eduStartDate: eduStartDate4,
            eduCompleteDate: eduCompleteDate4,
            eduDegreeName: eduDegreeName4,
            eduInstituteName: eduInstituteName4,
            country: {
              id: null
            },
          }
          ],
        workExperience:
          [{
            position: 1,
            startDate: workStartDate,
            endDate: workEndDate,
            designation: workDesignation,
            organization: workOrganization,
            responsibilities: 1,
          },
          {
            position: 1,
            startDate: workStartDate2,
            endDate: workEndDate2,
            designation: workDesignation2,
            organization: workOrganization2,
            responsibilities: 1,
          }
          ],
        languageTest: [
          {
            languageTestType: {
              id: languageTestType
            },
            languageTestVersion: {
              id: languageTestVersion,
            },
            testDate: languageTestDate,
            resultDate: languageResultDate,
            listeningScore: listeningScore,
            readingScore: readingScore,
            writingScore: writingScore,
            speakingScore: speakingScore,

          }
        ]


        // firstName: firstName,
        // password: password
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      console.log(JSON.stringify(formData));

      //await axios.post("http://35.213.150.114:8080/api/userUpdate", JSON.stringify(formData), { headers: headers }).then((res) => {
      await axios.post("/userUpdate", JSON.stringify(formData), { headers: headers }).then((res) => {
       
      console.log(res);
      console.log(res.data);
        if (res.status===200) {
          alert("Information Updated successfully");
          navigate('/');
        }
        else {
          alert("Incorrect link");
        }

      }, fail => {
        console.error(fail);
      });
    }

    catch (err) {
      alert(err);
    }

  }






  return (
    <section>
      <div>

        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={9} lg={7} xl={12}>
                <CCard className="mx-4">
                  <CCardBody className="p-4">
                    <CForm>
                      <h3>Personal Info</h3>


                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CInputGroup className="mb-3">
                              <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                              <CFormInput placeholder="First Name" aria-label="firstName"
                                value={firstName} 
                                // onChange={(event) => { setFirstName(event.target.value); }} 
                                disabled={true}
                                />
                              <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                              <CFormInput placeholder="Last Name" aria-label="lastNmae"
                                value={lastName} 
                                // onChange={(event) => { setLastName(event.target.value); }}
                                disabled={true}
                                 />
                            </CInputGroup>


                            <CInputGroup className="mb-3">
                              <CInputGroupText> <CIcon icon={cilMobile} /></CInputGroupText>
                              <CFormInput placeholder="Mobile" aria-label="mobile"
                                value={mobile} 
                                // onChange={(event) => { setMobile(event.target.value); }} 
                                disabled={true}
                                /> <CIcon />
                              This mobile number have any ?<CIcon /> <CIcon />
                              <CFormCheck inline id="isWhatsApp" label="What's app" 
                              //  checked = {isWhatsApp  ? true : false}
                              onChange={(event) => { setWhatsApp(event.target.checked); }}
                                
                              />
                              <CFormCheck inline id="isImo" value="option2" label="Emo"
                              onChange={(event) => { setImo(event.target.checked); }}
                              
                              />
                              <CFormCheck inline id="isViber" value="option3" label="Viber"
                              onChange={(event) => { setViber(event.target.checked); }}
                              
                              />
                              
                            </CInputGroup>

                            <CInputGroup className="mb-3">
                              <CInputGroupText> @</CInputGroupText>
                              <CFormInput placeholder="Email" aria-label="email"
                                value={officeemail} 
                                disabled={true}
                                // onChange={(event) => { setOfficeemail(event.target.value); }} 
                                />
                              <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>

                              <CFormSelect size="sm" className="" aria-label="Large select example" onChange={(event) => { setMaritalStatus(event.target.value); }} >
                                <option value="1">Single</option>
                                <option value="2">Married</option>
                                <option value="3">Divorced</option>
                                <option value="4">Widowed</option>
                              </CFormSelect>
                            </CInputGroup>

                            <CInputGroup className="mb-3">

                            <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>

                              <CFormSelect size="sm" className="" aria-label="Select Gender"
                                onChange={(event) => { setSex(event.target.value); }} >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </CFormSelect>

                            <div className="col-sm-6">
                              <CInputGroup className="mb-3">
                                <CIcon /> <CIcon />
                                Have you ever used any other name?
                                <CIcon /> <CIcon />
                                <CFormSwitch
                                  size="xl"
                                  label=""
                                  id="formSwitchCheckDefaultXL"
                                />
                              </CInputGroup>
                            </div>

                            </CInputGroup>


                            <CInputGroup className="mb-3 ">
                              <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>

                              <CFormSelect size="sm" className="" aria-label="Large select example">
                                <option>Country Of Birth</option>
                                <option value="1">Bangladesh</option>
                                <option value="2">USA</option>
                                <option value="3">Australia</option>
                              </CFormSelect>



                              <CInputGroupText><CIcon icon={cilHouse} /></CInputGroupText>
                              <CFormInput placeholder="City Of Birth" aria-label="cityOfBirth"
                                value={cityOfBirth} onChange={(event) => { setCityOfBirth(event.target.value); }} />
                            </CInputGroup>


                            <CInputGroup className="mb-3">
                              {/* <CInputGroupText> <CIcon icon={cilCalendar} /></CInputGroupText> */}

                              {/* <div style={{width:"50% "}}> */}
                              <DatePicker
                                className="form-control"
                                type="text"
                                size="lg"
                                selected={dob}
                                onChange={(date) => setDob(date)}
                                showIcon
                                icon="fa fa-calendar"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Date Of Birth"
                              />
                              {/* </div> */}
                              {/* <CFormInput placeholder="Date Of Birth" aria-label="dob"
                            value={dob} onChange={(event) => { setDob(event.target.value); }} /> */}

                              <CIcon></CIcon> <CIcon></CIcon> <CIcon></CIcon>

                              <CFormInput type="file" id="inputGroupFile02" />
                              <CInputGroupText component="label" htmlFor="inputGroupFile02">
                                Upload CV
                              </CInputGroupText>


                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>






                      <h3>Address</h3>



                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>

                            <CRow className="mb-3">
                              <CFormLabel className="col-sm-3 col-form-label">
                                <CFormSelect size="sm" className="mb-3" aria-label=""
                                  value={addressType1} onChange={(event) => { setAddressType1(event.target.value); }}>
                                  <option>Select Address Type</option>
                                  <option value="1">Permanent</option>
                                  <option value="2">Current</option>
                                  <option value="3">Other</option>
                                </CFormSelect>
                              </CFormLabel>
                              <div className="col-sm-9"  >
                                <CFormTextarea id="exampleFormControlTextarea1" rows="3" style={{ height: 50 }}
                                  value={address1} onChange={(event) => { setaddress1(event.target.value); }}></CFormTextarea>
                                {/* <CFormInput type="password" id="inputPassword" /> */}
                              </div>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>


                      {/* --------------- */}

                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CRow className="mb-3">
                              <CFormLabel className="col-sm-3 col-form-label">
                                <CFormSelect size="sm" className="mb-3" aria-label="Large select example"
                                  value={addressType2} onChange={(event) => { setAddressType2(event.target.value); }}>
                                  <option>Select Address Type</option>
                                  <option value="1">Permanent</option>
                                  <option value="2">Current</option>
                                  <option value="3">Other</option>
                                </CFormSelect>
                              </CFormLabel>
                              <div className="col-sm-9">
                                <CFormTextarea id="exampleFormControlTextarea2" rows="3" style={{ height: 50 }}
                                  value={address2} onChange={(event) => { setaddress2(event.target.value); }}></CFormTextarea>
                                {/* <CFormInput type="password" id="inputPassword" /> */}
                              </div>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>






                      <h3>Legal Information</h3>



                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CRow className="mb-12">
                              <CFormLabel className="col-sm-4 col-form-label">
                                {/* <CInputGroupText className="col-sm-1 col-form-label"> <CIcon icon={cilUser} /></CInputGroupText> */}
                                <CFormInput className="col-sm-4 col-form-label" placeholder="Legal First Name" aria-label="legalFirstName"
                                  value={legalFirstName} onChange={(event) => { setLegalFirstName(event.target.value); }} />
                              </CFormLabel>


                              <div className="col-sm-8">
                                <Select
                                  defaultValue={[options[0]]}
                                  isMulti
                                  name="country"
                                  options={options}
                                  className="col-sm-12 col-form-label"
                                  // className="basic-multi-select"
                                  classNamePrefix="select Country"

                                  value={options.filter(obj => legalCountryId.includes(obj.value))} // set selected values
                                  onChange={onMultiSelect}

                                // value={legalCountryId} onChange={(event) => {handleChange(event.target.value);}}
                                />
                              </div>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>

                      {/* <h3>Education Information 1</h3>


                            <EducationInfoTab /> */}

                      <h3>Education Information</h3>






                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CRow className="mb-12">
                              <div className="col-sm-4">
                                <CFormSelect size="sm" className="mb-3" aria-label="Large select example"
                                  value={educationLevel1} onChange={(event) => { setEducationLevel1(event.target.value); }}>
                                  <option>Degree level</option>
                                  <option value="1">SSC</option>
                                  <option value="2">HSC</option>
                                  <option value="3">Bachelor</option>
                                  <option value="4">Masters</option>
                                </CFormSelect>

                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduStartDate}
                                  onChange={(date) => setEduStartDate(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Start Date"
                                />


                                {/* <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                              value={eduStartDate} onChange={(event) => { setEduStartDate(event.target.value); }} /> */}
                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduCompleteDate}
                                  onChange={(date) => setEduCompleteDate(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Date Completed"
                                />
                                {/* <CFormInput className="col-sm-2" placeholder="Date Completed" aria-label="eduEndDate"
                              value={eduCompleteDate} onChange={(event) => { setEduCompleteDate(event.target.value); }} /> */}
                              </div>
                            </CRow>

                            <CInputGroup className="mb-3">
                              <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Degree" aria-label="email"
                                value={eduDegreeName} onChange={(event) => { setEduDegreeName(event.target.value); }} />
                              <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Institution" aria-label="optionalEmail"
                                value={eduInstituteName} onChange={(event) => { setEduInstituteName(event.target.value); }} />
                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>



                      {/* ------------------ */}
                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CRow className="mb-12">
                              <div className="col-sm-4">
                                <CFormSelect size="sm" className="mb-3" aria-label="Large select example"
                                  value={educationLevel2} onChange={(event) => { setEducationLevel2(event.target.value); }}>
                                  <option>Degree level</option>
                                  <option value="1">SSC</option>
                                  <option value="2">HSC</option>
                                  <option value="3">Bachelor</option>
                                  <option value="4">Masters</option>                            </CFormSelect>

                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduStartDate2}
                                  onChange={(date) => setEduStartDate2(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Start Date"
                                />
                                {/* 
                            <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                              value={eduStartDate2} onChange={(event) => { setEduStartDate2(event.target.value); }} /> */}
                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduCompleteDate2}
                                  onChange={(date) => setEduCompleteDate2(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Date Completed"
                                />

                                {/* <CFormInput className="col-sm-2" placeholder="Date Completed" aria-label="eduEndDate"
                              value={eduCompleteDate2} onChange={(event) => { setEduCompleteDate2(event.target.value); }} /> */}
                              </div>
                            </CRow>

                            <CInputGroup className="mb-3">
                              <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Degree" aria-label="email"
                                value={eduDegreeName2} onChange={(event) => { setEduDegreeName2(event.target.value); }} />
                              <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Institution" aria-label="optionalEmail"
                                value={eduInstituteName2} onChange={(event) => { setEduInstituteName2(event.target.value); }} />
                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>




                      {/* ------------------ */}
                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CRow className="mb-12">
                              <div className="col-sm-4">
                                <CFormSelect size="sm" className="mb-3" aria-label="Large select example"
                                  value={educationLevel3} onChange={(event) => { setEducationLevel3(event.target.value); }}>
                                  <option>Degree level</option>
                                  <option value="1">SSC</option>
                                  <option value="2">HSC</option>
                                  <option value="3">Bachelor</option>
                                  <option value="4">Masters</option>                            </CFormSelect>

                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduStartDate3}
                                  onChange={(date) => setEduStartDate3(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Start Date"
                                />
                                {/* 
                            <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                              value={eduStartDate2} onChange={(event) => { setEduStartDate2(event.target.value); }} /> */}
                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduCompleteDate3}
                                  onChange={(date) => setEduCompleteDate3(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Date Completed"
                                />

                                {/* <CFormInput className="col-sm-2" placeholder="Date Completed" aria-label="eduEndDate"
                              value={eduCompleteDate2} onChange={(event) => { setEduCompleteDate2(event.target.value); }} /> */}
                              </div>
                            </CRow>

                            <CInputGroup className="mb-3">
                              <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Degree" aria-label="email"
                                value={eduDegreeName3} onChange={(event) => { setEduDegreeName3(event.target.value); }} />
                              <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Institution" aria-label="optionalEmail"
                                value={eduInstituteName3} onChange={(event) => { setEduInstituteName3(event.target.value); }} />
                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>




                      {/* ------------------ */}
                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CRow className="mb-12">
                              <div className="col-sm-4">
                                <CFormSelect size="sm" className="mb-3" aria-label="Large select example"
                                  value={educationLevel4} onChange={(event) => { setEducationLevel4(event.target.value); }}>
                                  <option>Degree level</option>
                                  <option value="1">SSC</option>
                                  <option value="2">HSC</option>
                                  <option value="3">Bachelor</option>
                                  <option value="4">Masters</option>                            </CFormSelect>

                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduStartDate4}
                                  onChange={(date) => setEduStartDate4(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Start Date"
                                />

                                {/* 
                            <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                              value={eduStartDate2} onChange={(event) => { setEduStartDate2(event.target.value); }} /> */}
                              </div>
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control"
                                  type="text"
                                  size="lg"
                                  selected={eduCompleteDate4}
                                  onChange={(date) => setEduCompleteDate4(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Date Completed"
                                />

                                {/* <CFormInput className="col-sm-2" placeholder="Date Completed" aria-label="eduEndDate"
                              value={eduCompleteDate2} onChange={(event) => { setEduCompleteDate2(event.target.value); }} /> */}
                              </div>
                            </CRow>

                            <CInputGroup className="mb-3">
                              <CInputGroupText>  <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Degree" aria-label="email"
                                value={eduDegreeName4} onChange={(event) => { setEduDegreeName4(event.target.value); }} />
                              <CInputGroupText> <CIcon icon={cilSchool} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Institution" aria-label="optionalEmail"
                                value={eduInstituteName4} onChange={(event) => { setEduInstituteName4(event.target.value); }} />
                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>





                      <h3>IELTS </h3>

                      <CCol xs={12}>
                        <CCard className="mb-2">

                          {/* <CCardHeader>
<strong>React Form Control</strong>
</CCardHeader> */}
                          <CCardBody>

                            <CInputGroup className="mb-3 ">
                              <CFormSelect size="sm" className="" aria-label="Large select example" value={languageTestType} onChange={(event) => { setLanguageTestType(event.target.value); }}>
                                <option>Have you taken IELTS test?</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>

                              </CFormSelect>

                              <CInputGroupText>  <CIcon /></CInputGroupText>


                              <CFormSelect size="sm" className="" aria-label="Large select example" value={languageTestVersion} onChange={(event) => { setLanguageTestVersion(event.target.value); }}>
                                <option>Language test version:</option>
                                <option value="1">GT</option>
                                <option value="2">Academic</option>
                              </CFormSelect>
                            </CInputGroup>



                            <CInputGroup className="mb-3">

                              <CInputGroupText>  <CIcon icon={cibWhenIWork} /></CInputGroupText>
                              <CFormInput placeholder="IELTS certificate number:" aria-label="email"
                                value={languageCertificationNumber} onChange={(event) => { setLanguageCertificationNumber(event.target.value); }} />


                              <CRow className="mb-6">

                                <div className="col-sm-6">

                                  <DatePicker
                                    className="form-control"
                                    type="text"
                                    size="lg"
                                    selected={languageTestDate}
                                    onChange={(date) => setLanguageTestDate(date)}
                                    showIcon
                                    icon="fa fa-calendar"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Test Date"
                                  />

                                  {/* <CFormInput className="col-sm-2" placeholder="Test Date" aria-label="eduStartDate"
              value={languageTestDate} onChange={(event) => { setLanguageTestDate(event.target.value); }} /> */}
                                </div>
                                <div className="col-sm-6">

                                  <DatePicker
                                    className="form-control"
                                    type="text"
                                    size="lg"
                                    selected={languageResultDate}
                                    onChange={(date) => setLanguageResultDate(date)}
                                    showIcon
                                    icon="fa fa-calendar"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Result Date"
                                  />
                                  {/* 
            <CFormInput className="col-sm-2" placeholder="Result Date" aria-label="eduEndDate"
              value={languageResultDate} onChange={(event) => { setLanguageResultDate(event.target.value); }} /> */}
                                </div>
                              </CRow>


                            </CInputGroup>


                            <CInputGroup className="mb-3">
                              <CRow className="mb-12">
                                <div className="col-sm-2">
                                  IELTS Test Score
                                </div>
                                {/* <CIcon/>IELTS Test Score */}
                                <div className="col-sm-2">
                                  <CFormInput className="col-sm-2" placeholder="Listening" aria-label="eduStartDate"
                                    value={listeningScore} onChange={(event) => { setListeningScore(event.target.value); }} />
                                </div>
                                <div className="col-sm-2">
                                  <CFormInput className="col-sm-2" placeholder="Reading" aria-label="eduStartDate"
                                    value={readingScore} onChange={(event) => { setReadingScore(event.target.value); }} />
                                </div>
                                <div className="col-sm-2">
                                  <CFormInput className="col-sm-2" placeholder="Writing" aria-label="eduStartDate"
                                    value={writingScore} onChange={(event) => { setWritingScore(event.target.value); }} />
                                </div>
                                <div className="col-sm-2">
                                  <CFormInput className="col-sm-2" placeholder="Speaking" aria-label="eduStartDate"
                                    value={speakingScore} onChange={(event) => { setSpeakingScore(event.target.value); }} />
                                </div>
                                <div className="col-sm-2">
                                  <CFormInput className="col-sm-3" placeholder="Overall" aria-label="eduEndDate"
                                    value={totalLanguageScore} onChange={(event) => { setTotalLanguageScore(event.target.value); }} />
                                </div>
                              </CRow>


                            </CInputGroup>

                          </CCardBody>
                        </CCard>
                      </CCol>




                      <h3>Work Experience</h3>

                      <CCol xs={12}>
                        <CCard className="mb-2">

                          {/* <CCardHeader>
            <strong>React Form Control</strong>
          </CCardHeader> */}
                          <CCardBody>
                            <CInputGroup className="mb-3">
                              <CRow className="mb-12">

                                <div className="col-sm-4">
                                  <DatePicker
                                    className="form-control form-control-sm"
                                    type="text"
                                    size="sm"
                                    selected={workStartDate}
                                    onChange={(date) => setWorkStartDate(date)}
                                    showIcon
                                    icon="fa fa-calendar"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Start Date"
                                  />

                                  {/* <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                                value={workStartDate} onChange={(event) => { setWorkStartDate(event.target.value); }} /> */}
                                </div>
                                <div className="col-sm-4">
                                  <DatePicker
                                    className="form-control form-control-sm"
                                    type="text"
                                    size="sm"
                                    selected={workEndDate}
                                    onChange={(date) => setWorkEndDate(date)}
                                    showIcon
                                    icon="fa fa-calendar"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="End Date"
                                  />
                                  {/* <CFormInput className="col-sm-2" placeholder="End Date" aria-label="eduEndDate"
                                value={workEndDate} onChange={(event) => { setWorkEndDate(event.target.value); }} /> */}
                                </div>
                              </CRow>
                            </CInputGroup>

                            <CInputGroup className="mb-3">
                              <CInputGroupText>  <CIcon icon={cibWhenIWork} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Position" aria-label="email"
                                value={workDesignation} onChange={(event) => { setWorkDesignation(event.target.value); }} />
                              <CInputGroupText> <CIcon icon={cibWhenIWork} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Organization" aria-label="optionalEmail"
                                value={workOrganization} onChange={(event) => { setWorkOrganization(event.target.value); }} />
                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>


                      {/* ----------- */}


                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>
                            <CInputGroup className="mb-3">
                              <CRow className="mb-12">

                                <div className="col-sm-4">
                                  <DatePicker
                                    className="form-control form-control-sm"
                                    type="text"
                                    size="sm"
                                    selected={workStartDate2}
                                    onChange={(date) => setWorkStartDate2(date)}
                                    showIcon
                                    icon="fa fa-calendar"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Start Date"
                                  />
                                  {/* <CFormInput className="col-sm-2" placeholder="Start Date" aria-label="eduStartDate"
                                value={workStartDate2} onChange={(event) => { setWorkStartDate2(event.target.value); }} /> */}
                                </div>
                                <div className="col-sm-4">

                                  <DatePicker
                                    className="form-control form-control-sm"
                                    type="text"
                                    size="sm"
                                    selected={workEndDate2}
                                    onChange={(date) => setWorkEndDate2(date)}
                                    showIcon
                                    icon="fa fa-calendar"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="End Date"
                                  />
                                  {/* <CFormInput className="col-sm-2" placeholder="End Date" aria-label="eduEndDate"
                                value={workEndDate2} onChange={(event) => { setWorkEndDate2(event.target.value); }} /> */}
                                </div>
                              </CRow>
                            </CInputGroup>

                            <CInputGroup className="mb-3">
                              <CInputGroupText>  <CIcon icon={cibWhenIWork} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Position" aria-label="email"
                                value={workDesignation2} onChange={(event) => { setWorkDesignation2(event.target.value); }} />
                              <CInputGroupText> <CIcon icon={cibWhenIWork} /></CInputGroupText>
                              <CFormInput placeholder="Name Of Organization" aria-label="optionalEmail"
                                value={workOrganization2} onChange={(event) => { setWorkOrganization2(event.target.value); }} />
                            </CInputGroup>
                          </CCardBody>
                        </CCard>
                      </CCol>


                      <h3>Spouse Detaails</h3>

                      <CCol xs={12}>
                        <CCard className="mb-2">
                          <CCardBody>

                            <CInputGroup className="mb-3">
                              <CInputGroupText> <CIcon icon={cilUser} /></CInputGroupText>
                              <CFormInput placeholder="Legal First Name" aria-label="firstName" />
                              <CInputGroupText></CInputGroupText>
                              <CFormInput placeholder="Legal Last Name" aria-label="lastNmae" />
                            </CInputGroup>

                            <CInputGroup className="mb-3">
                              <CFormSwitch
                                size="xl"
                                label="Have you been previously married?"
                                id="formSwitchCheckDefaultXL"
                              />
                            </CInputGroup>


                            <CInputGroup className="mb-3">
                              <div className="col-sm-4">

                                <DatePicker
                                  className="form-control form-control-sm"
                                  type="text"
                                  size="sm"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Date of Marriage"
                                />

                                {/* <CFormInput className="col-sm-2" placeholder="Date of Marriage" aria-label="eduStartDate" /> */}
                              </div>
                            </CInputGroup>

                            <CInputGroup className="mb-3">
                              <CFormSelect size="sm" className="mb-3" aria-label="Large select example">
                                <option>Select Country Of Birth</option>
                                <option value="1">Bangladesh</option>
                                <option value="2">USA</option>
                                <option value="3">Australia</option>
                              </CFormSelect>



                              <CFormSelect size="sm" className="mb-3" aria-label="Large select example">
                                <option>Select Country of Citizenship</option>
                                <option value="1">Bangladesh</option>
                                <option value="2">USA</option>
                                <option value="3">Australia</option>
                              </CFormSelect>


                              <CFormSelect size="sm" className="mb-3" aria-label="Large select example">
                                <option>Passport Issuing Country</option>
                                <option value="1">Bangladesh</option>
                                <option value="2">USA</option>
                                <option value="3">Australia</option>
                              </CFormSelect>
                            </CInputGroup>

                            <CInputGroup className="mb-3">
                              <div className="col-sm-4">
                                {/* <CFormInput className="col-sm-2" placeholder="Date of Expiry of your Passport" aria-label="eduStartDate" /> */}

                                {/* <DatePicker
                                showIcon
                                onChange={(date) => setStartDate(date)}
                                icon="fa fa-calendar"
                              /> */}

                                <DatePicker
                                  className="form-control form-control-sm"
                                  type="text"
                                  size="sm"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  showIcon
                                  icon="fa fa-calendar"
                                  dateFormat="yyyy-MM-dd"
                                  placeholderText="Passport Expiry Date"
                                />

                              </div>
                            </CInputGroup>

                          </CCardBody>
                        </CCard>
                      </CCol>

                      <div className="d-grid">
                        <CButton color="success" onClick={register} >Submit</CButton>
                      </div>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </section>
  )
}

export default RegisterNew
