// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-family-my{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.pointer {cursor: pointer;}

.asLikeAnchor{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/popupContent.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,gBAAgB;AAClB;AACA,UAAU,eAAe,CAAC;;AAE1B;IACI,WAAW;IACX,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".font-family-my{\r\n  font-family: \"Roboto\", sans-serif;\r\n  font-weight: 400;\r\n}\r\n.pointer {cursor: pointer;}\r\n\r\n.asLikeAnchor{\r\n    color: blue;\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
