import React from 'react';
import './../assets/css/custom.css';
import logo from './../assets/images/banner-stady-canada.png';
import Meta from './Meta';

const StadyCanada = () => {
    const title = "Canada offers a wide range of programs and areas of study for international students applying for a study permit.";
    const description = title;
    return (
        <div>
            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="about-us">

                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='imgMinHeight130px' alt='' src={logo}></img>
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>
                                    <h3>
                                        Programs offered by Canadian educational institutions & Areas of Study
                                    </h3>

                                    <p>
                                        Canada offers a wide range of programs and areas of study for international students applying for a study permit. These programs are offered at various educational institutions, including universities, colleges, and vocational schools. Here are some of the major categories and types of programs:
                                    </p>


                                    <div className="section-heading-customise">
                                        <h2>Undergraduate programs: </h2>
                                    </div>
                                    <p>
                                        Undergraduate programs in Canada are diverse and span across various fields of study. Here is a breakdown of some of the broad categories these programs fall into, along with a brief description of each:
                                    </p>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ol>
                                                <li className='list-style-number' >
                                                    <b>Arts & Humanities:</b> These include degrees like Bachelor of Arts in English, History, Philosophy, and modern languages. Students learn critical thinking, communication, and analytical skills.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Science & Engineering:</b> Offering Bachelor of Science or Bachelor of Engineering, these programs cover fields like Biology, Chemistry, Physics, Computer Science, and various engineering disciplines. They focus on technical skills and scientific knowledge.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Business & Economics:</b> Programs like Bachelor of Commerce or Bachelor of Business Administration cover Accounting, Finance, Marketing, and Economics. They aim to prepare students for the business world with management and financial skills.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Health Sciences:</b> Degrees like Bachelor of Nursing, Bachelor of Health Sciences, or Kinesiology involve courses focused on health care, medical knowledge, and patient care skills.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Environmental Studies:</b> Degrees in Environmental Science or Environmental Studies provide knowledge about sustainability, ecology, and environmental policy.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Social Sciences:</b> These degrees explore aspects of society and include Psychology, Sociology, Political Science, and Anthropology, teaching students about human behavior and social systems.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Fine & Applied Arts:</b> These include Bachelor of Fine Arts or Bachelor of Design and venture into fields like visual arts, theatre, and industrial design, emphasizing creative and design skills.
                                                </li>

                                                <li className='list-style-number' >
                                                    <b>Computer & Information Technologies:</b> Degrees like Bachelor of Computer Science or Bachelor of Information Technology equip students with skills in programming, software development, and information systems.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Education:</b> Bachelor of Education programs train students to become teachers, focusing on pedagogy, curriculum, and educational psychology.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Agriculture & Forestry:</b> These programs educate students on agricultural sciences, forest resource management, and related biotechnologies.
                                                </li>
                                                <li className='list-style-number' >
                                                    <b>Professional Programs:</b> These include specialized degrees like Bachelor of Laws (LLB) and Bachelor of Architecture (BArch), which are professional designations in specific fields.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>Graduate programs: </h2>
                                    </div>
                                    <p>
                                        Canada offers a wide range of graduate programs for international students, and the options cover various disciplines and fields of study. Here are some common types of graduate programs for which international students often seek study permits in Canada:
                                    </p>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ol>
                                                <li className='list-style-number' >
                                                    Master&apos;s Programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className='padding-left-20px'>
                                                        <ul>
                                                            <li className='list-style' >
                                                                Master of Science (MSc)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Arts (MA)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Business Administration (MBA)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Engineering (MEng)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Public Health (MPH)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Social Work (MSW)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Education (MEd)
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Fine Arts (MFA)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Doctoral Programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className='padding-left-20px'>
                                                        <ul>
                                                            <li className='list-style' >
                                                                Doctor of Philosophy (Ph.D.)
                                                            </li>

                                                            <li className='list-style' >
                                                                Doctor of Science (DSc)
                                                            </li>

                                                            <li className='list-style' >
                                                                Doctor of Education (Ed.D.)
                                                            </li>

                                                            <li className='list-style' >
                                                                Doctor of Business Administration (DBA)
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Professional Programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className='padding-left-20px'>
                                                        <ul>
                                                            <li className='list-style' >
                                                                Juris Doctor (JD) - for law
                                                            </li>

                                                            <li className='list-style' >
                                                                Doctor of Medicine (MD) - for medical studies
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Laws (LLM) - for advanced legal studies
                                                            </li>

                                                            <li className='list-style' >
                                                                Master of Business Administration (MBA) - for business professionals
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>


                                                <li className='list-style-number' >
                                                    Graduate Certificates and Diplomas:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Graduate certificates and diplomas are shorter, focused programs that provide specialized skills and knowledge in a particular area. They are often chosen by students looking for practical and industry-specific training.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Research Programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Many graduate programs in Canada, especially at the master&apos;s and doctoral levels, involve a significant research component. Students may be required to conduct original research and write a thesis or dissertation.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Interdisciplinary Programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Some programs are designed to be interdisciplinary, allowing students to integrate knowledge and skills from multiple disciplines.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Health Sciences Programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Master&apos;s and Ph.D. programs in health-related fields, such as public health, nursing, and biomedical sciences.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Environmental Studies:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Programs focusing on environmental science, sustainability, and related fields.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Information Technology and Computer Science:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Master&apos;s programs in computer science, information technology, and related fields.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Creative Arts and Design:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Master&apos;s programs in fine arts, design, and related creative disciplines.
                                                        </p>
                                                    </div>
                                                </div>
                                            </ol>
                                        </div>
                                    </div>

                                    <p>
                                        Besides these undergraduate and graduate programs there are a few other programs as well. These are:
                                    </p>

                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ol>

                                                <li className='list-style-number' >
                                                    Diploma and certificate programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Often offered by community colleges or technical institutions, focusing on practical skills in trades, technology, health services, etc.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Language programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            English or French as a second language (ESL/FSL) to improve language proficiency.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Vocational and technical training:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Programs that provide hands-on experience and training for specific careers such as culinary arts, automotive repair, or graphic design.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Exchange programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Short-term study opportunities that are part of agreements between Canadian institutions and international partners.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Pathway programs:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className=''>
                                                        <p>
                                                            Designed for students who need additional preparation before enrolling in a degree program, such as foundation or bridging courses.
                                                        </p>
                                                    </div>
                                                </div>

                                                <li className='list-style-number' >
                                                    Academic areas in Canada span traditional disciplines like:
                                                </li>
                                                <div className='padding-left-20px'>
                                                    <div className='padding-left-20px'>
                                                        <ul>
                                                            <li className='list-style' >
                                                                Natural Sciences: Biology, Chemistry, Physics, Environmental Science.
                                                            </li>

                                                            <li className='list-style' >
                                                                Engineering & Technology: Civil, Mechanical, Electrical, Computer Science.
                                                            </li>

                                                            <li className='list-style' >
                                                                Health Sciences: Nursing, Medicine, Public Health.
                                                            </li>

                                                            <li className='list-style' >
                                                                Social Sciences: Psychology, Sociology, Political Science, Economics.
                                                            </li>

                                                            <li className='list-style' >
                                                                Humanities: History, Philosophy, Languages, Literature.
                                                            </li>

                                                            <li className='list-style' >
                                                                Business & Management: Marketing, Finance, Accounting, Human Resources.
                                                            </li>

                                                            <li className='list-style' >
                                                                Arts & Design: Visual Arts, Theatre, Music, Fashion.
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>

                                            </ol>
                                        </div>
                                    </div>

                                    <p>
                                        The specific requirements and eligibility for a study permit can depend on the level, duration, and type of program chosen. Programs vary greatly between institutions, and students should choose programs that align with their educational goals and career aspirations.
                                    </p>
                                    <p>
                                        For detailed information on each program, including course specifics, program length, potential career paths, and professional certifications, you should visit the websites of Canadian universities or contact their admissions offices.
                                    </p>
                                    <p>
                                        Keep in mind that the availability of programs and specific curriculum details will vary from institution to institution. Additionally, for a study permit in Canada, you should explore the designated learning institutions (DLIs) list provided by the Government of Canada to ensure that the university and program you are interested in are approved for international students.
                                    </p>

                                </div>

                            </div>
                        </div>



                    </div>
                </div>

            </section>
        </div>

    )
}

export default StadyCanada