import React from "react";
import AddResource from "./AddResource";


const AddAgent = () => {


  return (
    <>
      <AddResource role="Partner"></AddResource>
    </>

  )
}

export default AddAgent;
