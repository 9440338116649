import React from 'react'
import './../assets/css/custom.css'
import logo from './../assets/images/banner-core-1.png';
import Meta from './Meta';

const ToUk = () => {
    const title = "Immigration to the United Kingdom offers a range of routes for different individuals and circumstances.";
    const description = title;
    return (
        <div>

            <Meta title={title} keywords="" description={description} type="" image={logo} iwidth="" iheight=""></Meta>
            <section className="padding-top-adjuster common-segment" id="imi-uk">
            
                <div className='card-body'>
                    <div className="container">

                        <div className='row'>
                            <div className='col-lg-12'>
                                {/* <img className='' src={require('./../assets/images/banner-core-1.png')}></img> */}
                            </div>
                        </div>

                        <div className="row margin-top-50p">

                            <div className='col-lg-12 items'>

                                <div className='col-lg-12 items'>

                                    <p>
                                        Immigration to the United Kingdom offers a range of routes for different individuals and circumstances. The UK immigration system includes various categories, each with specific eligibility criteria. Here&apos;s an overview:
                                    </p>

                                    <div className="section-heading-customise">
                                        <h2>1. Work Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <div className='padding-left-20px'>
                                            <ul>
                                                <li className='list-style' >
                                                    Eligibility: Based on job offer, skill level, salary, and sometimes a labor market test.
                                                </li>
                                                <li className='list-style'>Categories:</li>
                                                <div className='padding-left-20px'>
                                                    <ul className='square'>
                                                        <li className='list-style'>Skilled Worker Visa: For those with a job offer from a UK employer in a skilled occupation.</li>
                                                        <li className='list-style'>Health and Care Worker Visa: For medical professionals working in the NHS or similar.</li>
                                                        <li className='list-style'>Global Talent Visa: For leaders or potential leaders in academia, research, arts, culture, or digital technology.</li>
                                                    </ul>
                                                </div>
                                            </ul>
                                        </div>


                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>2. Study Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Admission to a UK educational institution, financial stability, and English language proficiency.</li>
                                            <li className='list-style'>Types:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Student Visa: For longer-term studies.</li>
                                                    <li className='list-style'>Child Student Visa: For children aged 4-17 attending independent schools.</li>
                                                </ul>
                                            </div>

                                        </ul>

                                    </div>


                                    <div className="section-heading-customise">
                                        <h2>3. Family Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>

                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Based on a family relationship with a UK citizen or someone settled in the UK.</li>
                                            <li className='list-style'>Categories:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Spouse or Partner Visa: For partners of UK residents.</li>
                                                    <li className='list-style'>Parent Visa: For parents of a UK resident child.</li>
                                                    <li className='list-style'>Child Visa: For children of UK residents.</li>
                                                </ul>
                                            </div>

                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>4. Visitor Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: For tourism, visiting family, short business trips, or short-term studies.</li>
                                            <li className='list-style'>Duration: Generally for 6 months or less.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>5. Settlement (Indefinite Leave to Remain)</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Varies; often requires living in the UK for a certain number of years, knowledge of English, and passing the ‘Life in the UK’ test.</li>
                                            <li className='list-style'>Details: Settlement allows you to stay in the UK without time restrictions.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>6. Refugee and Asylum Seekers</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Eligibility: Fear of persecution in home country due to race, religion, nationality, political opinion, or membership in a particular social group.</li>
                                            <li className='list-style'>Process: Must apply once in the UK.</li>
                                        </ul>

                                    </div>

                                    <div className="section-heading-customise">
                                        <h2>7. Points-Based Immigration System</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Details: The UK uses a points-based system for work and study visas, requiring points for qualifications, salary, sponsorship, and English language skills.</li>
                                            
                                        </ul>

                                    </div>
                                    
                                    
                                    <div className="section-heading-customise">
                                        <h2>8. Business Visas</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Types:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Innovator Visa: For those setting up or running a business in the UK.</li>
                                                    <li className='list-style'>Start-Up Visa: For those starting a new business for the first time in the UK.</li>
                                                    <li className='list-style'>Investor Visa: For high-net-worth individuals making a substantial financial investment in the UK.</li>
                                                </ul>
                                            </div>
                                        </ul>

                                    </div>
                                    
                                    
                                    <div className="section-heading-customise">
                                        <h2>9. Other Visas and Schemes</h2>
                                    </div>
                                    <div className='padding-left-20px padding-top-20px'>
                                        <ul className='padding-left-20px'>
                                            <li className='list-style'>Youth Mobility Scheme: For young people from certain countries to live and work in the UK for up to 2 years.</li>
                                            <li className='list-style'>Ancestry Visa: For Commonwealth citizens with a UK grandparent.</li>
                                            <li className='list-style'>General Eligibility Considerations:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Financial Requirements: Proof of financial means to support oneself and dependents.</li>
                                                    <li className='list-style'>Health Surcharge: Most visa categories require payment of a healthcare surcharge.</li>
                                                    <li className='list-style'>Biometric Information: Often required as part of the application.</li>
                                                </ul>
                                            </div>
                                            <li className='list-style'>Application Process:</li>
                                            <div className='padding-left-20px'>
                                                <ul className='square'>
                                                    <li className='list-style'>Involves completing an application form, providing supporting documents, and sometimes attending an interview or biometric appointment.</li>
                                                </ul>
                                            </div>
                                        </ul>

                                    </div>

                                    <p>
                                        UK immigration laws and regulations are subject to change, and the system is designed to cater to various needs, from work and study to family reunification and protection. As each category has specific criteria, it is important for potential immigrants to stay informed about the latest policies and seek professional advice if necessary.
                                    </p>


                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>

            </section>
        </div>

    )
}

export default ToUk